import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"
import { useAppDispatch } from "../../app/store"
import { logout, setnPagesElect } from "../../features/Auth/authSlice"
import { CircularProgress, Grid } from "@mui/material"
import { setResendButton } from "../../features/Auth/WrapperSlice"
import { useAppSelector } from "../../app/hooks"
import { toast } from "react-toastify"
import { api, useLogoutProviderMutation } from "../../features/Auth/authApi"
import { emailVerifyToken } from "../../features/EmailVerifyAuth/emailVerifySlice"
import { overViewApi } from "../../features/OverViewAuth/overViewApi"
import { notificationsApi } from "../../features/Socket/socketApi"
import { chatMessageApi } from "../../features/ChatSocket/chat"
import { chatViewApi } from "../../features/ChatAuth/chatApi"
import {
  _setMessage,
  _setSearchTickets,
  _setTicket,
  _setUnseenCount,
} from "../../features/ChatAuth/chatSlice"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #999",
  borderRadius: "20px",
  boxShadow: 24,
  width: "400px",
  height: "200px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}
const viewProfileBtn = {
  display: "inline-block",
  width: "100px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#F475A4",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
}
const viewProfileBtnTwo = {
  display: "inline-block",
  width: "100px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#fff",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
  backgroundColor: "#F475A4",
  "&:hover": {
    color: "#F475A4",
  },
}
interface props {
  logoutModal: () => void
  handleClose: () => void
}
export default function LogoutConfirm({ logoutModal, handleClose }: props) {
  const dispatch = useAppDispatch()
  const socketId = useAppSelector((state) => state.auth)

  const [logoutProvider, { data, isLoading, error }] =
    useLogoutProviderMutation()

  const handleNoClick = () => {
    handleClose()
  }

  const handleLogout = async () => {
    const payload = {
      chatId: socketId?.socketId,
      notificationId: socketId?.notificationId,
    }
    try {
      const response: any = await logoutProvider(payload)

      if (response?.data?.message == "Logout successfully") {
        toast.success("Logged out successfully!", { autoClose: 1000 })
        dispatch(api.util.resetApiState())
        dispatch(overViewApi.util.resetApiState())
        dispatch(notificationsApi.util.resetApiState())
        dispatch(chatMessageApi.util.resetApiState())
        dispatch(chatViewApi.util.resetApiState())
        localStorage.clear()
        sessionStorage.clear()
        dispatch(_setMessage(null as any))
        dispatch(_setTicket(null as any))
        dispatch(_setSearchTickets(null as any))
        dispatch(_setUnseenCount(null as any))
        dispatch(setResendButton(null as any))
        dispatch(setnPagesElect(null as any))
        dispatch(emailVerifyToken(null as any))
        dispatch(logout())
        handleClose()
      }
    } catch (error) {
      console.error("Logout mutation error:", error)
    }
  }

  return (
    <div>
      <Modal
        open={logoutModal as any}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}></Grid>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 5, textAlign: "center", fontSize: "16px" }}
            >
              Are you sure you want to logout?
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <Button
                sx={viewProfileBtnTwo}
                className="me-2"
                onClick={handleLogout}
              >
                {isLoading ? (
                  <Typography fontSize={1}>
                    <CircularProgress
                      style={{ color: "pink", fontSize: 20 }}
                      size={20}
                    />
                  </Typography>
                ) : (
                  "Yes"
                )}
              </Button>
              <Button sx={viewProfileBtn} onClick={handleNoClick}>
                No
              </Button>
            </Box>
          </Box>
        </Grid>
      </Modal>
    </div>
  )
}
