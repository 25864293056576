import { styled } from "@mui/material"

const Overview = styled("div")(({ theme }) => ({
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
    marginBottom: "0px",
    "@media (max-width: 900px)": {
      fontSize: "16px",
    },
  },
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.pink.main,
    "& .MuiListItem-root ": {
      color: theme.palette.white.main,
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },
  "& .Advertisement": {
    borderRadius: " 16px",
    background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    "& .MuiButtonBase-root": {
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
    },
  },
  "& .dark-divider": {
    borderColor: theme.palette.gray.main400,
  },
  "& .text-sec": {
    fontSize: "30px",
    lineHeight: "38px",
    fontWeight: "500",
    color: "#5D5D5D",
  },
  "& .text-sec span": {
    display: "block",
    fontSize: "48px",
    lineHeight: "58px",
    color: "#000",
  },

  "& .card_sec": {
    display: "flex",
    gap: "20px",
    justifyContent: "center",
    marginTop: "10px",
  },
  "& .card_content": {
    textAlign: "center",
    padding: "20px",
    background: "#fff",
    boxShadow: "0px 4px 21px 0px #0000000A",
    borderRadius: "27px",
    width: "100%",
    maxWidth: "350px",
    display: "flex",
    flexDirection: "column",
    alignItems:"center",
    justifyContent:"space-between",
  },
  "& .card_content h3": {
    marginTop: "8px",
    fontSize: "24px",
    lineHeight: "24px",
    fontWeight: "700",
    marginBottom: "15px",
    color: "#000",
  },
  "& .card_content ul": {
    listStyle: "none",
    paddingLeft: "15px!important",
    marginBottom:"0",
  },
  "& .card_content ul li": {
    fontSize: "14px",
    fontWeight: "500",
    color: "#565656",
    marginBottom: "2px",
    textAlign: "left",
  },
  "& .card_content ul li img": {
    width: "25px",
    height: "25px",
    marginRight: "5px",
  },
  "& .card_content img": {
    width: "50px",
    height: "50px",
  },
  "& .green-text": {
    color: "#00C14D",
  },
  "& .approved-card": {
    backgroundColor: theme.palette.white.main,
    padding: "9px 19px",
    border: "1px solid #00C14D",
    boxShadow: "0px 4px 20px 0px #00000008",
    borderRadius: "16px",
  },
  "& .view-profile": {
    display: "inline-block",
    minWidth: "80px",
    padding: "12px 10px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.pink.main,
    fontFamily: "Montserrat",
    color: theme.palette.pink.main,
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "none",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: theme.palette.pink.main, // Apply hover background color
      color: "white", // Change text color on hover
      cursor: "pointer", // Change cursor to pointer on hover
    },
  },
  "& .dashboard-table-wrapper": {
    backgroundColor: "transparent",
    boxShadow: "none",
    "& .dashboard-table": {
      backgroundColor: "transparent",
      boxShadow: "none",
      "& thead": {
        "& th": {
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 15px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: " 13px",
          minWidth: "66px",
          "@media (max-width: 900px)": {
            fontSize: "13px",
          },
        },
      },
      "& .minh-1": {
        minHeight: "1px",
      },
      "& .service-head": {},

      "& .datetime": {
        "& span": {
          width: "130px",
          lineHeight: "23px",
          minHeight: "21px",
        },
        "& .weekday": {
          fontWeight: "bold",
          color: "#777",
        },
      },
      "& .Pending": {
        color: "#FFA639",
      },
      "& .Assigned": {
        color: "#009C35",
      },
      "& .Rejected": {
        color: "#FE2323",
      },
      "& .location": {
        "& span": {
          maxWidth: "184px",
          lineHeight: "23px",
          fontSize: "12px",
        },
      },
      "& .action-btn": {
        gap: "20px",
      },
      "& tbody tr ": {
        borderRadius: "16px ",
        overflow: "hidden",
        backgroundColor: "#fff",
        boxShadow: "0px 8px 6px -3px #00000008",
        transition: "all 0.4s ease",
      },
      "& tbody tr:hover ": {
        backgroundColor: "#f1f1f1",
      },
      "& tbody tr td:first-child": {
        width: "85px",
        borderRadius: "16px 0 0 16px ",
        overflow: "hidden",
        "& div": {
          "& img": {
            width: "100%",
          },
        },
      },
      "& tbody tr td:last-child": {
        "& span": {
          borderRadius: "0px 16px 16px 0",
        },
      },
      "& td": {
        padding: "0",
        paddingRight: "10px",
        // borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          //   boxShadow: "0px 8px 6px -3px #00000008",
          "@media (max-width: 900px)": {
            fontSize: "12px",
          },
        },
      },
      "& th": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          backgroundColor: "transparent",
          color: theme.palette.black,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          gap: "6px",
          padding: "0 20px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
          "& span": {
            minHeight: "auto",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
            fontSize: "12px",
            color: theme.palette.gray.main700,
            padding: 0,
            "& svg": {
              fill: theme.palette.yellow.main,
            },
          },
        },
      },
    },
  },
}))
const Flexbox = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: "15px",
  "& .MuiTypography-body2": {
    color: theme.palette.pink.main,
    fontFamily: "Nunito Sans",
    fontSize: " 18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
    textAlign: "center",
    marginTop: "10px",
  },
  "& .MuiTypography-body1": {
    color: theme.palette.grey,
    fontFamily: "Montserrat",
    fontSize: " 19px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
    textAlign: "center",
  },
  "& .MuiTypography-body3": {
    color: theme.palette.gray.main300,
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
    lineHeight: " 13px",
  },
  "& .MuiTypography-body5": {
    color: theme.palette.gray.main300,
    fontFamily: "Montserrat",
    fontSize: " 10px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
  },
  "& .overview-card": {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "20px 17px",
    width: "auto",
    minWidth: "158px",
    margin: "10px 0",
    borderRadius: "16px",
    transition: ".2s ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  // Media query for smaller screens
  "@media (max-width: 1200px)": {
    "& .overview-card": {
      width: "auto", // Make the cards take full width on smaller screens
      margin: "5px 0", // Add some margin for better spacing
      borderRadius: "8px",
      minWidth: "100px",
      padding: "5px",
      "& p ": {
        fontSize: "12px", // Adjust border radius for smaller cards
      },
    },
  },
  "& .pink-text": {
    color: theme.palette.pink.main,
  },
  "& .ovr-sm-text": {
    fontSize: "14px",
  },
  "& .pink-lg-text": {
    fontSize: "24px",
  },
}))
const Upcoming = styled("div")(({ theme }) => ({
  maxWidth: "500px",

  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
    marginBottom: "0px",
  },
  "& .Headingbox": {
    borderRadius: "16px 16px 0 0",

    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
    padding: "15px",
  },
  "& .ContentBox": {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    background: theme.palette.white.main,
    "& .MuiGrid-root": {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "20px",
    },
    "& .MuiButton-root": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,
      fontFamily: "Montserrat",
    },
  },
}))
const Bookedlog = styled("div")(({ theme }) => ({
  padding: "45px 0",

  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
    marginBottom: "0px",
  },
}))
const Toprated = styled("div")(({ theme }) => ({
  display: "flex",

  gap: "10px",
  img: {
    height: "113px",
    objectFit: "Cover",
    width: "100%",
    borderRadius: " 8px",
  },
  "& .flexdiv": {
    display: "flex",
    gap: "16px",
    padding: "16px",
    backgroundColor: theme.palette.white.main,
    borderRadius: "8px",
  },
  "& .MuiTypography-root ": {
    fontFamily: "Poppins",
    fontSize: " 12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: " normal",
    textAlign: "start",
  },
}))
const Toprated1 = styled("div")(({ theme }) => ({
  padding: "45px 0",
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
    marginBottom: "0px",
  },
}))
const TableAppointment = styled("div")(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontFamily: " Nunito Sans",
    padding: "0",
    borderBottom: "0",
  },
  "th.MuiTableCell-root.MuiTableCell-head ": {
    color: "#7c7c7c",
    padding: "10px 0",
  },
  td: {
    background: theme.palette.white.main,
  },
}))

export {
  Overview,
  Flexbox,
  Upcoming,
  Bookedlog,
  Toprated,
  Toprated1,
  TableAppointment,
}
