import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import profileimage from "../../images/personal-info.svg"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import * as Style from "./Style"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { GoPlusCircle } from "react-icons/go"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { useAppSelector } from "../../app/hooks"
import {
  setLanguage,
  setResendButton,
  setStepsCount,
  setString,
} from "../../features/Auth/WrapperSlice"
import { useAppDispatch } from "../../app/store"
import { useProviderDetailsMutation } from "../../features/Auth/authApi"
import { onBoardKey, userDetails } from "../../features/Auth/authSlice"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"
import { Spinner } from "react-bootstrap"

interface PersonalAccount {
  description: string
}

interface PersonalStepProps {
  activeStep: string
  step: string
  handleNext: () => void
  handleBack: () => void
  handleReset: () => void
}

const ProfileStep: React.FC<PersonalStepProps> = ({
  activeStep,
  step,
  handleNext,
  handleReset,
  handleBack,
}) => {
  const [createJob, { data: jobData, error: jobError, isLoading }] =
    useProviderDetailsMutation()
  const dispatch = useAppDispatch()
  const stringValue = useAppSelector((state) => state?.wrapper?.stringValue)
  const userId = useAppSelector((state) => state?.auth?.userDetails)
  const navigate = useNavigate()
  const fileInputRef = useRef(null)
  const [imagePreview, setImagePreview] = useState(null)
  const [fileUpload, setFileUpload] = useState(null)
  const [imageError, setImageError] = useState("")
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)

  const handleBoxClick = () => {
    ;(fileInputRef.current as any)?.click()
  }

  const handleFileChange = (e: any) => {
    const file = e.target.files[0]
    if (file) {
      const maxSize = 5 * 1024 * 1024
      if (file.size > maxSize) {
        toast.error("File size exceeds 5MB. Please select a smaller file.", {
          autoClose: 1000,
        })
        return
      }

      // File type validation (allowed types: image/jpeg, image/png)
      if (!["image/jpeg", "image/png"].includes(file.type)) {
        toast.error(
          "Unsupported file type. Please select an image file (JPEG or PNG).",
          { autoClose: 1000 },
        )
        return
      }
      setFileUpload(file)
      setImageError("")
      const reader = new FileReader()

      reader.onloadend = () => {
        setImagePreview(reader.result as any)
      }
      reader.readAsDataURL(file)
    }
  }

  const schemaResolver = yupResolver(
    yup.object().shape({
      description: yup
        .string()
        .required("Please fill the Mandatory fields")
        .min(20, "Bio must be at least 20 characters.")
        .max(500, "Bio should be maximum 500 characters"),
    }),
  )

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalAccount>({
    resolver: schemaResolver,
  })
  const onSubmit = (value: any) => {
    if (!fileUpload) {
      toast.error("Please fill the Mandatory fields", { autoClose: 800 })
      setIsButtonDisabled(true)

      setTimeout(() => {
        setIsButtonDisabled(false)
      }, 1000)
      return
    }
    const formData = new FormData()

    // Append each property to the FormData object individually
    formData.append("name", stringValue?.name)
    formData.append("email", stringValue?.email)
    formData.append("contactNo", stringValue?.contactNo)
    formData.append("gender", stringValue?.gender)
    formData.append("region", stringValue?.region)
    formData.append("address", stringValue?.address)
    formData.append("dob", stringValue?.dob)
    formData.append("services", (stringValue as any)?.services)
    formData.append("language", (stringValue as any)?.languageAll)
    formData.append("experience", (stringValue as any)?.experience)
    formData.append("description", value?.description)
    formData.append("_id", userId?.user?._id)
    formData.append("profilePic", fileUpload as any)
    createJob(formData)
  }
  useEffect(() => {
    if (jobData?.message) {
      dispatch(setResendButton("resend"))
      toast.success(jobData?.message)
      dispatch(setString(null))
      dispatch(userDetails(null as any))
      dispatch(setStepsCount(null))
      dispatch(setLanguage(null))
      dispatch(onBoardKey(null as any))
      handleReset()
      navigate("/")
      // handleNext()
    }
  }, [jobData])

  useEffect(() => {
    if (errors?.description?.message) {
      toast.error(errors?.description?.message, { autoClose: 800 })
      setIsButtonDisabled(true)

      setTimeout(() => {
        setIsButtonDisabled(false)
      }, 1000)
    }
  }, [errors])

  return (
    <Style.PersonalInfo className="p-5">
      <Box>
        <Box className="pt-3">
          <Box>
            <Typography>
              Profile picture<span className="text-danger">*</span>
            </Typography>
          </Box>
          <Box
            className="upload-photo position-relative my-3"
            onClick={handleBoxClick}
          >
            <input
              ref={fileInputRef}
              type="file"
              style={{ display: "none" }}
              onChange={handleFileChange}
              accept=".jpg,.png"
            />
            {imagePreview ? (
              <img
                src={imagePreview}
                alt="Preview"
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            ) : (
              <GoPlusCircle
                className="upload-icon"
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              />
            )}
          </Box>
        </Box>

        <p className="text-danger">{imageError}</p>
        <Box>
          <Typography>
            Bio<span className="text-danger">*</span>
          </Typography>
        </Box>
        <form className="pt-2" onSubmit={handleSubmit(onSubmit)}>
          <textarea
            placeholder="Write your bio"
            style={{
              width: "100%",
              height: "100px",
              borderRadius: "10px",
              padding: "10px",
            }}
            {...register("description")}
          />

          {/* <p className="text-danger">{errors?.description?.message}</p> */}
          <Button
            color="inherit"
            disabled={(activeStep as any) === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            className="next-button my-2"
          >
            Back
          </Button>
          <Button type="submit" className="next-button grey-button my-two">
            {isLoading ? (
              <CircularProgress style={{ color: "pink" }} size={24} />
            ) : (
              <>{(activeStep as any) === step.length - 1 ? "Finish" : "Next"}</>
            )}
          </Button>
        </form>
      </Box>
    </Style.PersonalInfo>
  )
}

export default ProfileStep
