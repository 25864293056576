import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"

import { CircularProgress, Grid } from "@mui/material"

import { useNavigate, useSearchParams } from "react-router-dom"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #999",
  borderRadius: "20px",
  boxShadow: 24,
  width: "400px",
  height: "200px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}

const viewProfileBtnTwo = {
  display: "inline-block",
  width: "100px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#fff",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
  backgroundColor: "#F475A4",
  "&:hover": {
    color: "#F475A4",
  },
}
interface props {
  ReviewPop: boolean
  setDatareview: (value: string) => void
}
export default function ReviewmPopup({ ReviewPop, setDatareview }: props) {
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()

  const handleYesClick = () => {
    navigate("/dashboard/rating")
    setDatareview("overview")
  }

  return (
    <div>
      <Modal
        open={ReviewPop}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12}></Grid>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 5, textAlign: "center", fontSize: "16px" }}
            >
              How was your service? Give your review{" "}
            </Typography>

            <Box sx={{ display: "flex", justifyContent: "center", gap: 2 }}>
              <Button
                sx={viewProfileBtnTwo}
                className="me-2"
                onClick={handleYesClick}
              >
                Reivew
              </Button>
            </Box>
          </Box>
        </Grid>
      </Modal>
    </div>
  )
}
