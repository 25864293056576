import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import { Spinner, Stack, Table } from "react-bootstrap"
import tableCellImg from "../../images/table-img.png"
import { Upcoming } from "@mui/icons-material"
import singleSeekerImg from "../../images/img-rounded.png"
import { FaStar } from "react-icons/fa6"
import { IoMailOutline } from "react-icons/io5"
import { FiPhone } from "react-icons/fi"
import { SlLocationPin } from "react-icons/sl"
import { Link, useNavigate, useParams } from "react-router-dom"
import { FaChevronLeft } from "react-icons/fa"
import { IoStar } from "react-icons/io5"
import StarIcon from "@mui/icons-material/Star"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import CircularIndeterminate from "../Loading/Loading"
import { CiLocationOn } from "react-icons/ci"
import {
  useCreateReviewPostMutation,
  useProviderReviewsQuery,
} from "../../features/Auth/authApi"
import dummy from "../../images/dummy-image.jpg"
import { toast } from "react-toastify"
import CenteredSpinner from "../common/CenteredSpinner"
import { FormatChangeTime } from "../common/utctolocalDate"
interface ReviewProps {
  message: string
}

const WriteReview = () => {
  const [values, setValues] = React.useState<number | null>(null)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const postIdProvider = urlSearchParams.get("postId")
  const { data: seekerReview, isLoading: isloadingSeekers } =
    useProviderReviewsQuery({})
  const [reviewPost, { data, error, isLoading }] = useCreateReviewPostMutation()
  // const postId = urlSearchParams.get("postId")
  const [errorloading, setErrorLoading] = useState<boolean>(false)

  const { id } = useParams()

  const navigate = useNavigate()

  const schemaResolver = yup.object().shape({
    message: yup.string().required("Please fill the Mandatory fields."),
  })

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ReviewProps, any>({
    resolver: yupResolver(schemaResolver),
  })

  const onSubmit = (value: any) => {
    const trimmedMessage = value?.message?.trim() || ""

    const wordCount = trimmedMessage.split(/\s+/).filter(Boolean).length

    if (values === null || values < 0) {
      toast.error("Please provide a valid rating.", { autoClose: 800 })
      setErrorLoading(true)
      setTimeout(() => {
        setErrorLoading(false)
      }, 1000)
      return
    }

    if (trimmedMessage.length === 0) {
      toast.error("Message is required.", { autoClose: 800 })
      setErrorLoading(true)
      setTimeout(() => {
        setErrorLoading(false)
      }, 1000)
      return
    }

    if (wordCount > 200) {
      toast.error("Message exceeds the maximum length of 200 words.", {
        autoClose: 800,
      })
      setErrorLoading(true)
      setTimeout(() => {
        setErrorLoading(false)
      }, 1000)
      return
    }

    const payload = {
      seekerData: id,
      rating: values,
      message: value?.message,
      postId: postIdProvider,
    }
    reviewPost(payload)

    // if (values !== null && values >= 0 && trimmedMessage.length > 0) {
    //   reviewPost(payload)
    // } else {
    //   toast.error("Please fill the Mandatory fields.")
    // }
  }

  useEffect(() => {
    if (data?.message) {
      navigate("/dashboard/rating")
      toast.success(data?.message)
    } else {
      toast.error((error as any)?.data?.message)
    }
  }, [data, error])

  useEffect(() => {
    if ((errors as any)?.message?.message) {
      toast.error((errors as any)?.message?.message)
    }
  }, [errors])

  return (
    <>
      {isloadingSeekers ? (
        <CenteredSpinner />
      ) : (
        <>
          <Style.Overview>
            <Box className="d-flex align-items-center gap-4 mb-4">
              <Button
                className="back-btn mb-0"
                onClick={() => navigate(`/dashboard/rating`)}
              >
                <FaChevronLeft />
              </Button>
              <Typography className="font-20 service-text pink-text fw-semibold mb-0">
                Write Review
              </Typography>
            </Box>
          </Style.Overview>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Style.Overview>
                <Box>
                  <Typography
                    variant="body1"
                    className="text-start text-black fw-bold  pt-2 pb-3"
                  >
                    Service Seeker
                  </Typography>
                  <Box className="seeker-card">
                    <Box className="d-flex gap-2 align-items-center mb-3">
                      <Box>
                        <img
                          src={
                            seekerReview?.data?.[0]?.seekerData?.profilePic ||
                            dummy
                          }
                          className="seeker-img rounded"
                          style={{ width: "50px", height: "50px" }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="body1"
                          className="mb-2 text-black"
                        ></Typography>
                        <Typography
                          variant="body1"
                          className="mb-2"
                        ></Typography>
                        <Typography variant="body1"></Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Box className="py-3 listing-seeker ">
                        <Typography variant="body1" className=" fw-semibold">
                          Name - {seekerReview?.data?.[0]?.seekerData?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography variant="body1" className="fw-semibold">
                          Service - {seekerReview?.data?.[0]?.service?.name}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography
                          variant="body1"
                          className=" fw-semibold d-flex"
                        >
                          Date -{" "}
                          {moment(seekerReview?.data?.[0]?.from).format(
                            "ddd-DD-MMM",
                          )}{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography
                          variant="body1"
                          className=" fw-semibold d-flex"
                        >
                          Time - &nbsp;{" "}
                          <FormatChangeTime
                            dateString={seekerReview?.data?.[0]?.from}
                          />{" "}
                          -
                          <FormatChangeTime
                            dateString={seekerReview?.data?.[0]?.till}
                          />
                        </Typography>
                      </Box>
                    </Box>
                    <Box></Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography
                          variant="body1"
                          className="d-flex align-items-center gap-2 lh-sm  fw-semibold"
                        >
                          Location - {seekerReview?.data?.[0]?.location}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box className="py-3 listing-seeker">
                        <Typography variant="body1" className=" fw-semibold">
                          Status - {seekerReview?.data?.[0]?.status}
                          <span className="status-text completed"></span>
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Style.Overview>
            </Grid>
            <Grid item xs={8}>
              <Style.Overview>
                <Box className="write-review-card">
                  <Typography
                    variant="body1"
                    className="text-center pink-text mb-2 fw-semibold"
                  >
                    How would you rate service seeker{" "}
                    <span className="text-danger">*</span>
                  </Typography>
                  <Box className="d-flex justify-content-center align-items-center gap-3 mb-5">
                    <Rating
                      name="hover-feedback"
                      value={values}
                      onChange={(event, newValue) => {
                        setValues(newValue)
                      }}
                      emptyIcon={
                        <StarIcon
                          style={{ opacity: 0.55 }}
                          fontSize="inherit"
                        />
                      }
                    />
                  </Box>
                  <Box>
                    <Typography
                      variant="body1"
                      className="pink-text fw-semibold mb-3"
                    >
                      Write a review <span className="text-danger">*</span>
                    </Typography>
                    <TextField
                      id="outlined-textarea"
                      placeholder="Write your review here...."
                      multiline
                      rows="4"
                      className="review-field w-100 mb-1"
                      {...register("message")}
                    />

                    <Box className="text-end">
                      <Button
                        className="view-profile text-capitalize"
                        onClick={handleSubmit(onSubmit)}
                      >
                        {isLoading ? (
                          <Typography fontSize={1}>
                            <CircularProgress
                              style={{ color: "pink", fontSize: 20 }}
                              size={20}
                            />
                          </Typography>
                        ) : (
                          " Send"
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Style.Overview>
            </Grid>
          </Grid>
        </>
      )}
    </>
  )
}
export default WriteReview
