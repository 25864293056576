import * as React from "react"
import Box from "@mui/material/Box"
import Modal from "@mui/material/Modal"
import Button from "@mui/material/Button"
import user from "../../images/user.jpeg"
import { BsFillInfoCircleFill } from "react-icons/bs"
import styles from "./ChatUi.module.css"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import RatingStars from "../common/rating"
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "15px",
  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.3)", // Reduce shadow intensity
  pt: 3,
  px: 4,
  pb: 3,
}

interface props {
  onShowDeatils: any
  open: boolean
  handleClose: () => void
}

export default function ChatDeatilsModal({
  onShowDeatils,
  open,
  handleClose,
}: props) {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
        className={` ${styles["chat-bg"]}`}
      >
        <Box sx={{ ...style, width: 600 }}>
          <h2
            className={`mb-5 ${styles["chat-modal-head"]}`}
            id="parent-modal-title text-align-center"
          >
            Booking Detail
          </h2>
          <div className="d-flex gap-3 justify-center">
            <h5 className={`${styles["chat-modal-text"]}`}>
              Service Booked{" "}
              <span
                className={`${styles["chat-modal-details"]}`}
                style={{ color: "#000" }}
              >
                {onShowDeatils?.postId?.serviceDetails?.name}
              </span>
            </h5>
            <h5 className={`${styles["chat-modal-text"]}`}>
              Service Customer
              <div
                className={` d-flex gap-3 align-items-center mt-2 ${styles["chat-modal-details"]}`}
              >
                <img
                  src={onShowDeatils?.postId?.serviceDetails?.image}
                  style={{
                    width: "60px",
                    height: "60px",
                    marginTop: "10px",
                    borderRadius: "15px",
                  }}
                />
                <div className={`${styles["details"]}`}>
                  <p className="mb-0 mt-2" style={{ color: "#000" }}>
                    {onShowDeatils?.postId?.seekerData?.name}
                    <RatingStars
                      rating={
                        onShowDeatils?.postId?.seekerData?.rating
                          ?.$numberDecimal
                      }
                    />
                  </p>
                </div>
              </div>
            </h5>
            <h5 className={`borderRight-none ${styles["chat-modal-text"]}`}>
              Service date & time
              <span className={` ${styles["chat-modal-details"]}`}>
                <span className="d-flex flex-column text-black">
                  <span className="weekday">
                    {/* {activePost?.data.availabeJobs} */}
                    <FormatDate dateString={onShowDeatils?.postId?.from} />
                  </span>
                  {/* <span> */}
                  <span>
                    <FormatChangeTime
                      dateString={onShowDeatils?.postId?.from}
                      // timezone={"india"}
                    />
                  </span>
                  <span>
                    <FormatTime dateString={onShowDeatils?.postId?.from} />
                  </span>
                </span>
              </span>
            </h5>
          </div>
        </Box>
      </Modal>
    </div>
  )
}
