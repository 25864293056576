import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  Typography,
} from "@mui/material"
import { useEffect, useRef, useState } from "react"
import RocketIcon from "../../images/rocket.png"
import stopImgIcon from "../../images/no-trips.png"
import startedImgIcon from "../../images/service-started.png"

interface props {
  otpGenrate: () => void
  otpFill: string
  genRateLoading: boolean
}
const GenRateotpCustomer = ({ otpGenrate, otpFill, genRateLoading }: props) => {
  const [otp, setOtp] = useState(Array(6).fill(""))
  const inputRefs = useRef([])

  useEffect(() => {
    if (otpFill) {
      setOtp(otpFill.split("").concat(Array(6 - otpFill.length).fill("")))
    } else {
      setOtp(Array(6).fill(""))
    }
  }, [otpFill])

  return (
    <Box>
       <Typography
          align="left"
          variant="h2"
          className=" fw-medium font-24"
          mb={0}
        >
          Generate OTP{" "}
        </Typography>
      <Box className="otp-box text-center d-flex justify-content-center align-items-center flex-column">
        <>
          <Box pb={3}>
            <FormControl className="d-flex justify-content-center gap-3 flex-row">
              {otp?.map((digit, index) => (
                <input
                  disabled
                  key={index}
                  ref={(el) => ((inputRefs as any).current[index] = el)}
                  type="text"
                  value={digit}
                  style={{
                    maxWidth: "46px",
                    height: "56px",
                    textAlign: "center",
                    fontFamily: "Montserrat",
                    color: "#F475A4",
                    border: "1px solid #F475A4",
                    borderRadius: "6px",
                    fontSize: "20px",
                  }}
                  maxLength={1}
                  pattern="[0-9]"
                  inputMode="numeric"
                  required
                />
              ))}
            </FormControl>
          </Box>
          <Button
            className="view-profile py-2 text-capitalize"
            onClick={otpGenrate}
          >
            {genRateLoading ? (
              <Typography fontSize={1}>
                <CircularProgress
                  style={{ color: "pink", fontSize: 20 }}
                  size={20}
                />
              </Typography>
            ) : (
              "Generate Otp"
            )}
          </Button>
        </>
      </Box>

      {/* <>
        <Typography
          align="left"
          variant="h2"
          className=" fw-medium font-24"
          mb={1}
        >
          Service stopped{" "}
        </Typography>
        <Box className="d-flex justify-content-between">
          <Box
            className="otp-box h-100 text-center d-flex flex-column align-items-center justify-content-center"
            sx={{ mt: 1 }}
          >
            <Typography
              align="center"
              variant="body1"
              className="green-heading"
              mb={2}
            >
              Your service is completed successfully{" "}
            </Typography>
          </Box>
          <Box sx={{}}>
            <img
              src={startedImgIcon}
              alt="service started"
              className="otp-box-img"
            />
          </Box>{" "}
        </Box>
      </> */}
    </Box>
  )
}
export default GenRateotpCustomer

interface propsServiceStart {
  isloadingShow: boolean
  blankOtp: () => void
}

const ServiceStart = ({ isloadingShow, blankOtp }: propsServiceStart) => {
  useEffect(() => {
    blankOtp()
  }, [])
  return (
    <>
      <Typography
        align="left"
        variant="h2"
        className=" fw-medium font-24"
        mb={1}
      >
        Otp Generated{" "}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={7}>
          <Box
            className="otp-box h-100 text-center d-flex flex-column align-items-center justify-content-center"
            sx={{ mt: 1 }}
          >
            <Typography
              align="center"
              variant="body1"
              className="green-heading p-4"
              mb={2}
            >
              Your service has started{" "}
            </Typography>
            <Typography align="center" variant="body1" className="" mb={2}>
              To stop your service, click here{" "}
            </Typography>
            <Button
              className="view-profile py-2 text-capitalize"
              // onClick={
              //   service stop kr dena
              // }
            >
              {isloadingShow ? (
                <Typography fontSize={1}>
                  <CircularProgress
                    style={{ color: "pink", fontSize: 20 }}
                    size={20}
                  />
                </Typography>
              ) : (
                "Stop Service"
              )}
            </Button>
          </Box>{" "}
        </Grid>
        <Grid item xs={12} lg={5} className="d-none d-xl-block m-auto">
          <Box
            sx={{
              maxWidth: 300, // set width as needed
              height: "auto", // set height as needed
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={startedImgIcon}
              alt="Description"
              style={{ width: "100%", height: "auto" }}
            />
          </Box>{" "}
        </Grid>
      </Grid>
      <Box className="d-flex gap-1 justify-content-between "></Box>
    </>
  )
}

export { ServiceStart }
