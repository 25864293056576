import { Middleware } from "@reduxjs/toolkit"
import { api } from "../features/Auth/authApi"
import { overViewApi } from "../features/OverViewAuth/overViewApi"
import { notificationsApi } from "../features/Socket/socketApi"
import { chatMessageApi } from "../features/ChatSocket/chat"
import { chatViewApi } from "../features/ChatAuth/chatApi"
import {
  _setMessage,
  _setSearchTickets,
  _setTicket,
  _setUnseenCount,
} from "../features/ChatAuth/chatSlice"

const unauthorizedMiddleware: Middleware =
  (store: any) => (next: any) => (action: any) => {
    const state = store.getState()
    const authToken = state.auth.token
    const socketId = state.auth.socketId
    if (action.type.endsWith("rejected")) {
      // Assuming your error structure has a status field indicating the HTTP status

      let status = action.error?.status || action.payload?.status
      if (status === 401) {
        // Dispatch a logout action or handle the error globally
        console.log("Unauthorized access detected, handling logout.")
        // window.location.reload()
        if (authToken) {
          store.dispatch(
            api.endpoints.LogoutProvider.initiate({
              chatId: socketId?.chatId,
              notificationId: socketId?.notificationId,
            }),
          )

          store.dispatch(api.util.resetApiState())
          store.dispatch(overViewApi.util.resetApiState())
          store.dispatch(notificationsApi.util.resetApiState())
          store.dispatch(chatMessageApi.util.resetApiState())
          store.dispatch(chatViewApi.util.resetApiState())
          store.dispatch(_setMessage(null as any))
          store.dispatch(_setTicket(null as any))
          store.dispatch(_setSearchTickets(null as any))
          store.dispatch(_setUnseenCount(null as any))
        }

        // Optionally, redirect or handle other UI effects
      }
    }
    return next(action)
  }

export default unauthorizedMiddleware
