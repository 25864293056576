import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  Typography,
  Grid,
  FormControl,
  Input,
} from "@mui/material"
import {
  AiFillLock,
  AiFillUnlock,
  AiOutlineMail,
  AiOutlineUser,
} from "react-icons/ai"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  useForgetPasswordMutation,
  useResetPasswordMutation,
} from "../../features/Auth/authApi"
import mommylogo from "../../images/login-logo.png"
import physioTherapy1 from "../../images/physioTherapy1.jpg"
import physioTherapy2 from "../../images/physioTherapy2.jpg"
import physioTherapy3 from "../../images/physioTherapy3.jpg"
import physioTherapy4 from "../../images/physioTherapy4.jpg"
import { Controller, useForm } from "react-hook-form"
import CircularProgress from "@mui/material/CircularProgress"
import { updateToken } from "../../features/Auth/authSlice"
import { useAppDispatch } from "../../app/store"
import * as Style from "./style"
import { IoIosArrowBack } from "react-icons/io"
import { IoKey } from "react-icons/io5"
import { toast } from "react-toastify"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

type Props = {}

interface resetUser {
  password: string
  confirmedPassword: string
}

interface IconProps {
  newPass: boolean
  confiPass: boolean
}

function ResetPassword({}: Props) {
  const [showPassword, setShowPassword] = React.useState<IconProps>({
    newPass: false,
    confiPass: false,
  })
  const [loading, setLoading] = useState(false)
  const dispatch = useAppDispatch()
  const [resetLogin, { isError, error, data }] = useResetPasswordMutation()

  const handleClickShowPassword = (key: any) => {
    setShowPassword((prevState: any) => ({
      ...prevState,
      [key]: !prevState[key] as any,
    }))
  }

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const urlSearchParams = new URLSearchParams(window.location.search)
  const token = urlSearchParams.get("token")
  const navigate = useNavigate()
  dispatch(updateToken(token as any))
  const schemaResolver = yupResolver(
    yup.object().shape({
      password: yup
        .string()
        .required("New password is a required field")
        .test(
          "password-length",
          "Password must be 8 characters or more",
          (value: any) => value && (value as any).length >= 8,
        )
        .test(
          "password-strength",
          "Please choose a strong password. Try a mix of letters, numbers, and symbols",
          (value) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%#*?&]{8,}$/.test(
              value,
            ),
        ),
      confirmedPassword: yup
        .string()
        .required("Confirm password is a required field")
        .oneOf(
          [yup.ref("password"), ""],
          "New password and Confirm password must be Same",
        ),
    }),
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<resetUser>({
    resolver: schemaResolver,
  })

  const onSubmit = (values: any) => {
    const payload = {
      password: values.password,
      confirmedPassword: values.confirmedPassword,
    }

    resetLogin(payload)
    setLoading(true)
  }

  useEffect(() => {
    if (data?.message) {
      toast.success(data?.message)
      navigate("/")
      setLoading(false)
    } else if ((error as any)?.data?.message) {
      toast.error((error as any)?.data?.message)
      setLoading(false)
    }
  }, [data, error])

  return (
    <Style.AuthPage>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          lg={6}
          className="d-none d-lg-block"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Box className="box-wrapper">
            <Typography
              sx={{
                textAlign: "center",
                color: " #858585",
                fontFamily: "Montserrat",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Welcome to
            </Typography>
            <Typography textAlign="center">
            <h1>Send A Therapist</h1>
              {/* <img src={mommylogo} width="300" height="auto" /> */}
            </Typography>
            <Box
              className="Imgbox"
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              gap={2}
            >
              <Box>
                <img src={physioTherapy1} className="login-left-img1"/>
              </Box>
              <Box mt={5}>
                <img src={physioTherapy2} className="login-left-img2"/>
              </Box>

              <Box>
                <img src={physioTherapy3} className="login-left-img3"/>
              </Box>

              <Box mt={5}>
                <img src={physioTherapy4} className="login-left-img3"/>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box className="Loginform">
              <Box>
                <Typography
                  variant="h2"
                  mt={2}
                  mb={5}
                  fontFamily="Montserrat"
                  fontSize={22}
                  fontWeight="600"
                  color="pink.main"
                  textAlign="center"
                >
                  {/* <span onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <ChevronLeftIcon />
              </span> */}
                  Forget password{" "}
                </Typography>{" "}
                <Typography mb={5} className="text-login">
                  Enter the email connected with your account and we will send
                  an email to you.
                </Typography>
                <FormControl
                  sx={{ mb: 2 }}
                  variant="outlined"
                  className="auth-field"
                  fullWidth
                >
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }: any) => (
                      <Input
                        {...field}
                        id="outlined-adornment-password"
                        type={showPassword?.newPass ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => handleClickShowPassword("newPass")}
                              onMouseDown={handleMouseDownPassword}
                              edge="start"
                            >
                              {showPassword?.newPass ? (
                                <AiFillUnlock />
                              ) : (
                                <AiFillLock />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                        placeholder="New Password"
                        fullWidth
                        sx={{ mb: 0 }}
                      />
                    )}
                  />
                  <p className="text-danger">{errors?.password?.message}</p>
                </FormControl>
                <FormControl
                  sx={{ mt: 0 }}
                  variant="outlined"
                  className="auth-field"
                  fullWidth
                >
                  <Controller
                    name="confirmedPassword"
                    control={control}
                    render={({ field }: any) => (
                      <Input
                        {...field}
                        id="outlined-adornment-password"
                        type={showPassword?.confiPass ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="start">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                handleClickShowPassword("confiPass")
                              }
                              onMouseDown={handleMouseDownPassword}
                              edge="start"
                            >
                              {showPassword?.confiPass ? (
                                <AiFillUnlock />
                              ) : (
                                <AiFillLock />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Confim Password"
                        placeholder="Confirm Password"
                        fullWidth
                      />
                    )}
                  />
                  <p className="text-danger">
                    {errors?.confirmedPassword?.message}
                  </p>
                </FormControl>
              </Box>

              <Button
                type="submit"
                size="large"
                variant="contained"
                className="auth-btn mx-auto"
                sx={{ mt: 3, display: "block" }}
                fullWidth
              >
                {loading ? (
                  <Typography fontSize={1}>
                    <CircularProgress
                      style={{ color: "white", fontSize: 23 }}
                      size={23}
                    />
                  </Typography>
                ) : (
                  "Reset Password"
                )}
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Style.AuthPage>
  )
}

export default ResetPassword
