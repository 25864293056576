import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import Modal from "@mui/material/Modal"

import { CircularProgress, Grid } from "@mui/material"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { FormatDate, FormatSlot } from "../utctolocalDate"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  border: "1px solid #999",
  borderRadius: "20px",
  boxShadow: 24,
  width: "400px",
  height: "300px",
  p: 4,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
}
const viewProfileBtn = {
  display: "inline-block",
  width: "100px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#F475A4",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
}
const viewProfileBtnTwo = {
  display: "inline-block",
  width: "100px",
  padding: "8px 8px",
  border: "1px solid #F475A4",
  borderRadius: "8px",
  color: "#fff",
  fontSize: "14px",
  textAlign: "center",
  textDecoration: "none",
  backgroundColor: "#F475A4",
  "&:hover": {
    color: "#F475A4",
  },
}
interface props {
  showModalSlots: boolean
  slotdetails: any
  handleClose: () => void
  dateHandle: string
}
export default function SlotModal({
  handleClose,
  slotdetails,
  showModalSlots,
  dateHandle,
}: props) {
  const navigate = useNavigate()
  const navigatePost = () => {
    if (slotdetails?.status == "Accepted") {
      navigate(`/dashboard/approved-detail/${slotdetails?._id}`)
    } else if (slotdetails?.status == "Active") {
      navigate(`/dashboard/verify-otp/${slotdetails?._id}`)
    } else {
      navigate(
        `/dashboard/approved-detail/${slotdetails?._id}?view=booking-detail'`,
      )
    }
    slotdetails
  }
  return (
    <div>
      <Modal
        open={showModalSlots}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container>
          <Box sx={style}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              sx={{ mb: 0, textAlign: "center", fontSize: "16px" }}
            >
              <h5 style={{ marginBottom: "0" }}>
                {" "}
                {dateHandle ? (
                  <FormatSlot dateString={dateHandle} />
                ) : (
                  <FormatSlot dateString={moment().format("YYYY-MM-DD")} />
                )}
                {dateHandle ? (
                  <FormatDate dateString={dateHandle} />
                ) : (
                  <FormatDate dateString={moment().format("YYYY-MM-DD")} />
                )}
              </h5>
            </Typography>
            <Box>
              <Typography className="field-label1">
                <h4
                  style={{
                    textAlign: "center",
                    backgroundColor: "#F6F6F6",
                    color: "#F475A4",
                    fontSize: "16px",
                    fontWeight: "500",
                    padding: "15px",
                    width: "330px",
                    marginLeft: "0",
                    marginTop: "20px",
                    marginBottom: "10px",
                    borderRadius: "11px",
                  }}
                >
                  {moment(slotdetails?.from).format("h:mm A")} -{" "}
                  {moment(slotdetails?.till).format("h:mm A")}
                </h4>
              </Typography>
            </Box>
            <Box>
              <Typography className="field-label1">
                <h4
                  style={{
                    textAlign: "center",
                    color: "#F475A4",
                    fontSize: "16px",
                    fontWeight: "500",
                    padding: "15px",
                    width: "330px",
                    marginLeft: "0",
                    marginBottom: "0px",
                    borderRadius: "11px",
                  }}
                >
                  Service - {slotdetails?.service?.name}
                </h4>
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <Button
                sx={viewProfileBtn}
                style={{ width: "260px" }}
                onClick={navigatePost}
              >
                See Details
              </Button>
            </Box>
          </Box>
        </Grid>
      </Modal>
    </div>
  )
}
