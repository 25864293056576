import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

import { toast } from "react-toastify"
import { emailVerifyAuth } from "./emailVerifyAuth"

const slice = createSlice({
  name: "emailVerifySlice",
  initialState: { emailVerifyToken: null } as {
    emailVerifyToken: null | any
  },
  reducers: {
    emailVerifyToken: (state: any, action: any) => {
      state.emailVerifyToken = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      emailVerifyAuth.endpoints.emailVerify.matchFulfilled,
      (state, { payload }) => {
        state.emailVerifyToken = payload.emailVerifyToken
      },
    )
  },
})

export default slice.reducer
export const { emailVerifyToken } = slice.actions

export const selectCurrentUser = (state: RootState) =>
  state.emailVerifySlice.emailVerifyToken
