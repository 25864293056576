import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import io from "socket.io-client"
import { RootState, store, useAppDispatch } from "../../app/store"
import { toast } from "react-toastify"
import { _setMessage, _setTicket } from "../ChatAuth/chatSlice"
import { Message, Ticket } from "../../components/ChatenterFace/chatinterface"
import { array } from "yup"
import { setnewMessage } from "../OverViewAuth/overViewSlice"
import { chatViewApi } from "../ChatAuth/chatApi"
// import { useGetBookingHistoryQuery } from "../OverViewAuth/overViewApi"

let socket: any

export const chatMessageApi = createApi({
  reducerPath: "chatApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["chat"],
  endpoints: (builder) => ({
    socketChatMessage: builder.query<any, any>({
      queryFn: (arg: string | null) => ({ data: [] }), // Dummy query to satisfy RTK Query
      async onCacheEntryAdded(
        token,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // let dispatch = useAppDispatch()
        // const token = (store.getState() as RootState).auth.token
        if (!token) {
          return
        }

        const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL
        const socket = io(`${BASE_URL}/chat?token=${token}`, {
          reconnection: true,
          reconnectionAttempts: Infinity,
          reconnectionDelay: 1000,
          reconnectionDelayMax: 1000,
          timeout: 20000,
          autoConnect: true,
        })

        socket.on("message", (message: any) => {
          // store.dispatch(chatViewApi.util.invalidateTags(["socketMessage"]))
          store.dispatch(_setMessage(message))
          store.dispatch(setnewMessage(message))

          // updateCachedData((draft) => {
          //   draft.push(message as Message)
          // })
        })

        socket.on("ping", () => {
          socket.emit("pong") // Respond with pong to server
        })

        socket.on("chat-tickets", (chattickets: any) => {
          const ticketNewArray: any = new Array(chattickets)

          store.dispatch(_setTicket(ticketNewArray))
          // updateCachedData((draft) => {
          //   draft.push(chattickets as Ticket)
          // })
        })

        await cacheDataLoaded
        await new Promise((resolve) => socket.on("disconnect", resolve))
      },
      providesTags: ["chat"],
    }),
  }),
})

export const { useSocketChatMessageQuery } = chatMessageApi
