import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Pagination,
  Paper,
  Rating,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import reviewImg from "../../images/babysitting.png"
import {
  useGetActivePostApiQuery,
  useGetGenrateostApiQuery,
  useUpcomingAppointmentsQuery,
} from "../../features/OverViewAuth/overViewApi"
import { CiSearch } from "react-icons/ci"
import { IoFilter, IoStarOutline } from "react-icons/io5"
import { TbArrowsSort } from "react-icons/tb"
import { IoStar } from "react-icons/io5"
import { FaStar } from "react-icons/fa6"
import tableCellImg from "../../images/table-img.png"
import { FaPencilAlt } from "react-icons/fa"
import { Link, useSearchParams } from "react-router-dom"
import {
  useProviderReviewsQuery,
  useSeekerReceivedReviewasQuery,
} from "../../features/Auth/authApi"
import dummy from "../../images/dummy-image.jpg"
import nodata from "../../images/no-results.svg"
import { Spinner } from "react-bootstrap"
import CenteredSpinner from "../common/CenteredSpinner"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import { MdOutlineLocationOn } from "react-icons/md"

interface Array {}
const SeekerProvider = () => {
  const [selectedPage, setSelectedPage] = useState(1)

  const [showMore, setShowMore] = useState<Array>([])

  const {
    data: seekerReview,
    isLoading: isloadingSeekers,
    error: seekerReviewError,
  } = useProviderReviewsQuery({})

  const {
    data: receviedReviews,
    isLoading,
    isFetching,
  } = useSeekerReceivedReviewasQuery({
    pageNo: selectedPage,
  })

  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  const RatingStars = ({ rating }: props) => {
    const starsArray = Array.from({ length: rating }, (_, index) => index + 1)
    let emptyArray: JSX.Element[] = []

    if (rating < 5) {
      for (let i = 0; i < 5 - rating; i++) {
        emptyArray.push(
          <IoStarOutline
            key={rating + i}
            style={{ color: "rgb(175 174 174)" }}
          />,
        )
      }
    }
    return (
      <Box className="d-flex review-stars gap-1">
        {starsArray.map((star) => (
          <IoStar key={star} className="active" />
        ))}
        {emptyArray}
      </Box>
    )
  }

  const toggleShowMore = (index: any) => {
    setShowMore((prevShowMore: any) => {
      const newShowMore = [...prevShowMore] // Create a copy of the previous state
      newShowMore[index] = !newShowMore[index] // Toggle the visibility at the specified index
      return newShowMore // Return the updated state
    })
  }

  return (
    <>
      {isLoading || isFetching ? (
        <CenteredSpinner />
      ) : (
        <Style.Overview>
          <Grid container>
            <Grid md={12}>
              {/* <Box className="d-flex flex-none w-100 max-w-550  mt-5">
                <TextField
                  id="outlined-basic"
                  className="search-field"
                  label="Search"
                  variant="outlined"
                />
                <Button variant="contained" className="search-btn">
                  <CiSearch />
                </Button>
              </Box> */}

              {seekerReview?.alreadyReviewed !== true && (
                <>
                  <Box className="d-flex justify-content-between mt-5">
                    <Typography variant="h2" className="pink-text">
                      Reviews
                    </Typography>
                  </Box>
                  {/* <TableContainer
                    component={Paper}
                    className="dashboard-table-wrapper custom-scrollbar mb-5"
                  >
                    <Table
                      sx={{
                        minWidth: 650,
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      className="dashboard-table"
                      aria-label="simple table"
                    ></Table>
                    </TableContainer> */}
                    
                  <TableContainer
                    component={Paper}
                    className="dashboard-table-wrapper custom-scrollbar mb-5"
                  >
                    <Table
                      sx={{
                        minWidth: 650,
                        border: "none",
                        backgroundColor: "transparent",
                      }}
                      className="dashboard-table"
                      aria-label="simple table"
                    >
                      <TableHead>
                        <TableCell component="th"></TableCell>
                        <TableCell component="th" className="service-head">
                          Service Seeker <TbArrowsSort />
                        </TableCell>
                        <TableCell component="th" align="center">
                          Email
                        </TableCell>
                        <TableCell component="th" align="center">
                          Service required
                        </TableCell>
                        <TableCell component="th" align="center">
                          Location <MdOutlineLocationOn />
                        </TableCell>
                        {/* <TableCell component="th" align="center">
                          Service Duration
                        </TableCell> */}
                        <TableCell component="th" align="center">
                          Action
                        </TableCell>
                      </TableHead>
                      <TableBody>
                        {seekerReview?.data?.length > 0
                          ? seekerReview?.data?.map((item: any) => (
                              <TableRow
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <div
                                    style={{
                                      width: "74px",
                                      height: "74px",
                                      objectFit: "cover",
                                    }}
                                  >
                                    <img
                                      src={
                                        item?.seekerData?.profilePic || dummy
                                      }
                                      style={{
                                        width: "74px",
                                        height: "74px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </div>
                                </TableCell>
                                <TableCell
                                  component="td"
                                  align="center"
                                  scope="row"
                                >
                                  <span className="text flex-column gap-2">
                                    {item?.seekerData?.name}

                                    <span className="minh-1 gold ">
                                      <RatingStars
                                        rating={
                                          item?.seekerData?.rating
                                            ?.$numberDecimal
                                        }
                                      />
                                    </span>
                                  </span>
                                </TableCell>
                                <TableCell align="center">
                                  <span>{item?.seekerData?.email}</span>
                                </TableCell>
                                <TableCell align="center">
                                  <span>{item?.service?.name}</span>
                                </TableCell>
                                <TableCell align="center">
                                  <span className="location">
                                    <span>{item?.location}</span>
                                  </span>
                                </TableCell>
                                {/* <TableCell align="center">
                            <span className="datetime">
                              <span className="d-flex flex-column">
                                <span className="weekday">
                                  <FormatDate
                                    dateString?.from}
                                  />
                                </span>

                                <span>
                                  <FormatChangeTime
                                    dateString?.from}
                                  />
                                </span>
                                <span>
                                  <FormatTime
                                    dateString?.from}
                                  />
                                </span>
                              </span>
                            </span>
                          </TableCell> */}
                                <TableCell align="center">
                                  <span>
                                    <Link
                                      to={`/dashboard/write-review/${item?.seekerData?._id}?postId=${item?._id}`}
                                      // onClick={() =>
                                      //   setSearchParams({
                                      //     postId?.data?._id,
                                      //   })
                                      // }
                                      className="view-profile d-flex align-items-center gap-1 "
                                    >
                                      Write Review
                                      <FaPencilAlt />
                                    </Link>
                                  </span>
                                </TableCell>
                              </TableRow>
                            ))
                          : ""}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}

              {/* <Box className="mb-5  custom-scrollbar overflow-auto">
                <Box className="d-flex justify-content-between mb-5">
                  <Typography variant="h2" className="pink-text">
                    Reviews from seekers
                  </Typography>
                </Box>

                {receviedReviews?.data?.length > 0 ? (
                  <>
                    <Box className="d-flex justify-content-between m-3  rating-review-card pe-5">
                      <Typography variant="body1">Service seeker</Typography>
                      <Typography variant="body1">Review</Typography>
                      <Box className="d-flex gap-7">
                        <Typography variant="body1">Date</Typography>

                        <Typography variant="body1">Rating</Typography>
                      </Box>
                    </Box>

                    {receviedReviews?.data?.map((item: any, index: number) => (
                      <Box className="d-flex align-items-center justify-content-between review-card gap-3 pe-4 mb-3 rating-review-card">
                        <Box className="d-flex align-items-center">
                          <img
                            src={reviewImg || dummy}
                            className="reviewimg me-4"
                          />
                          <Box>
                            <Typography variant="body1" className="pink-text">
                              {item?.seekerData?.name}
                            </Typography>
                          </Box>
                        </Box>

                        <Box>
                          <Typography variant="body1" className="review-text">
                            {(showMore as any)[index] ||
                            item?.message?.split(" ")?.length <= 10 ? (
                              <>
                                {item?.message}
                                {item?.message?.split(" ")?.length > 10 && (
                                  <span
                                    className="cursor-pointer fw-bold"
                                    onClick={() => toggleShowMore(index)}
                                  >
                                    ... See less
                                  </span>
                                )}
                              </>
                            ) : (
                              <>
                                {item?.message
                                  ?.split(" ")
                                  ?.slice(0, 10)
                                  ?.join(" ")}
                                ...
                                <span
                                  className="cursor-pointer fw-bold"
                                  onClick={() => toggleShowMore(index)}
                                >
                                  See more
                                </span>
                              </>
                            )}
                          </Typography>
                        </Box>
                        <Box className="d-flex gap-5">
                          <Box>
                            <Typography variant="body1" className="">
                              <span className="datetime">
                                <span className="d-flex flex-column">
                                  <span className="weekday lh-1">
                                    <strong>
                                      {" "}
                                      <FormatDate
                                        dateString={item?.createdAt}
                                      />
                                    </strong>
                                  </span>
                                  <span className=" lh-1">
                                    <FormatChangeTime
                                      dateString={item?.createdAt}
                                    />
                                  </span>
                                  <span className=" lh-1">
                                    <FormatTime dateString={item?.createdAt} />
                                  </span>
                                </span>
                              </span>
                            </Typography>
                          </Box>
                          <Box className="d-flex review-stars gap-1">
                            <RatingStars rating={item?.rating} />
                          </Box>
                        </Box>
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box className="d-flex align-items-center justify-content-center review-card gap-2 pe-4 mb-3 rating-review-card">
                    <img
                      style={{
                        width: "100%",
                        maxWidth: "200px",
                      }}
                      src={nodata}
                    />
                  </Box>
                )}
                </Box> */}
              {/* <Box className="d-flex justify-content-between mb-5">
                  <Typography variant="h2" className="pink-text">
                    Reviews from seekers
                  </Typography>
                </Box> */}
 <Box className="d-flex justify-content-between mt-5">
                    <Typography variant="h2" className="pink-text">
                      Reviews from Customers
                    </Typography>
                  </Box>
              <TableContainer
                component={Paper}
                className="dashboard-table-wrapper custom-scrollbar mb-5"
              >
                <Table
                  sx={{
                    minWidth: 650,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  className="dashboard-table"
                  aria-label="simple table"
                >
                  {receviedReviews?.data?.length > 0 && (
                    <TableHead>
                      <TableCell component="th"></TableCell>
                      <TableCell component="th" className="service-head">
                        Service Seeker
                      </TableCell>
                      <TableCell component="th" align="center">
                        Review
                      </TableCell>
                      <TableCell component="th" align="center">
                        Date & Time
                      </TableCell>
                      <TableCell component="th" align="center">
                        Rating
                      </TableCell>
                    </TableHead>
                  )}
                  <TableBody>
                    {receviedReviews?.data?.length > 0 ? (
                      receviedReviews?.data?.map((item: any, index: number) => (
                        <>
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  width: "74px",
                                  height: "74px",
                                  objectFit: "cover",
                                }}
                              >
                                <img
                                  src={item?.seekerData?.profilePic || dummy}
                                  style={{
                                    width: "74px",
                                    height: "74px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </TableCell>
                            <TableCell component="td" scope="row">
                              <span className=" d-flex justify-content-start">
                                {item?.seekerData?.name}

                                <span className="minh-1 gold ">
                                  <RatingStars
                                    rating={
                                      item?.seekerData?.rating?.$numberDecimal
                                    }
                                  />
                                </span>
                              </span>
                            </TableCell>

                            <TableCell align="center">
                              <Box
                                style={{
                                  maxWidth: "320px",
                                  overflowWrap: "anywhere",
                                  margin: "0 auto",
                                }}
                              >
                                <Typography
                                  variant="body1"
                                  className="review-text minh-1"
                                >
                                  {(showMore as any)[index] ||
                                  item?.message?.split(" ")?.length <= 10 ? (
                                    <>
                                      {item?.message}
                                      {item?.message?.split(" ")?.length >
                                        10 && (
                                        <span
                                          className="cursor-pointer fw-bold minh-1"
                                          onClick={() => toggleShowMore(index)}
                                        >
                                          ... See less
                                        </span>
                                      )}
                                    </>
                                  ) : (
                                    <>
                                      {item?.message
                                        ?.split(" ")
                                        ?.slice(0, 10)
                                        ?.join(" ")}
                                      ...
                                      <span
                                        className="cursor-pointer fw-bold minh-1"
                                        onClick={() => toggleShowMore(index)}
                                      >
                                        See more
                                      </span>
                                    </>
                                  )}
                                </Typography>
                              </Box>{" "}
                            </TableCell>
                            <TableCell align="center">
                              <span className="datetime">
                                <span className="d-flex flex-column">
                                  <span className="weekday">
                                    {" "}
                                    <FormatDate dateString={item?.createdAt} />
                                  </span>
                                  {/* <span> */}
                                  <span className="">
                                    <FormatChangeTime
                                      dateString={item?.createdAt}
                                    />
                                  </span>
                                  <span className="">
                                    <FormatTime dateString={item?.createdAt} />
                                  </span>
                                </span>
                                {/* </span> aman*/}
                              </span>{" "}
                            </TableCell>
                            <TableCell align="center">
                              <span style={{ color: "#FFB800" }}>
                                <RatingStars rating={item?.rating} />
                              </span>
                            </TableCell>
                          </TableRow>
                        </>
                      ))
                    ) : (
                      <>
                        <TableRow
                          sx={{
                            height: "40vh",
                            backgroundColor: "white",
                          }}
                        >
                          <TableCell
                            align="center"
                            colSpan={7}
                            sx={{
                              width: "100%",
                              pointerEvents: "none",
                              border: "none",
                            }}
                          >
                            <img
                              src={nodata}
                              style={{
                                width: "100%",
                                maxWidth: "180px",
                              }}
                            />
                            <span className="no-data-text"
                            style={{
                              fontSize: "20PX",
                              fontWeight: "600",
                              color: "#000",
                            }}>No data found</span>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {receviedReviews?.data?.length > 1 && (
                <Box className="d-md-flex justify-content-between">
                  <Typography variant="body1" className="w-auto white-space">
                    {" "}
                    Showing {receviedReviews?.totalDocument?.showingData} -{" "}
                    {receviedReviews?.totalDocument?.showingEnd} list
                  </Typography>
                  <Grid container justifyContent="flex-end">
                    <Grid item md={3}>
                      <Box display="flex" className="flex-column">
                        <Stack spacing={2} className="mb-3">
                          <Pagination
                            count={receviedReviews?.totalDocument?.totalPages}
                            color="secondary"
                            className="custom-pagination"
                            page={receviedReviews?.totalDocument?.pageNo}
                            onChange={handlePageChange}
                            sx={{
                              "& .MuiPaginationItem-page.Mui-selected": {
                                backgroundColor: "#F475A4",
                                boxSizing: "border-box",
                              },
                              "& .css-wjh20t-MuiPagination-ul": {
                                flexWrap: "nowrap",
                                justifyContent: "end",
                              },
                            }}
                          />
                        </Stack>
                        {/* <Typography className="page-text">
                        Showing {receviedReviews?.totalDocument?.totalCount} -
                        {receviedReviews?.totalDocument?.totalCount} of list
                      </Typography> */}
                        {/* commit */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
        </Style.Overview>
      )}
    </>
  )
}
interface props {
  rating: number
}
export default SeekerProvider
