import React, { useEffect, useRef, useState } from "react"
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { FaChevronLeft, FaStar } from "react-icons/fa6"
import * as Style from "../ProviderLayout/Style1"
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { IoCalendarClearOutline } from "react-icons/io5"
import { CiClock2 } from "react-icons/ci"
import { IoLocationOutline } from "react-icons/io5"
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineConnector,
  TimelineDot,
  TimelineContent,
} from "@mui/lab"
import {
  useGenrateOtpMutation,
  useProviderJobPostQuery,
  useTimeLineProviderQuery,
  useVerifyOtpMutation,
} from "../../features/Auth/authApi"
import moment from "moment"
import dummy from "../../images/dummy-image.jpg"
import { toast } from "react-toastify"
import { Spinner } from "react-bootstrap"
import chat from "../../images/chat.svg"
import RatingStars from "../common/rating"
import RocketIcon from "../../images/rocket.png"
import CenteredSpinner from "../common/CenteredSpinner"
import servicecompleted from "../../images/servicecompleted.png"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import GenRateotpCustomer from "./genrateOtp"
import ReviewmPopup from "./reviewnavigate/reviewmodal"

import { FaChevronRight } from "react-icons/fa"
import { useCreateTicketMutation } from "../../features/ChatAuth/chatApi"
import PreviewImage from "../common/previewImage"
const VerifyOtp = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [open, setOpen] = React.useState(false)
  const [otp, setOtp] = useState(["", "", "", "", "", ""])
  const otpRef = useRef<HTMLInputElement[]>([])
  const [verifyopen, setVerifyOpen] = React.useState(false)
  const [showOtp, setShowOtp] = useState<string>("")
  const [selectedImage, setSelectedImage] = useState<string | null>(null)

  // more button handle for timeline
  const [showFullTimeline, setShowFullTimeline] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [chatView, { data: CharacterData }] = useCreateTicketMutation()

  const handleToggleTimeline = () => {
    setShowFullTimeline(!showFullTimeline)
  }

  const handleChatView = async () => {
    const payload = {
      postId: id,
    }

    try {
      const response: any = await chatView(payload)
      if (response?.data?.status === 200) {
        const chatId = response.data?.data?._id
        const adminId = response.data?.data?.adminstration

        if (chatId && adminId) {
          navigate(`/dashboard/help-center?chatId=${chatId}&adminId=${adminId}`)
        } else {
          console.error("Invalid chatId or adminId in response:", response)
        }
      } else {
        console.error("API call was not successful:", response)
      }
    } catch (error) {
      console.error("Error making API call:", error)
    } finally {
    }
  }

  const [
    verifyOptConfirm,
    { data: otpData, error: otpError, isLoading: otpIsloading },
  ] = useVerifyOtpMutation()
  const { data: timeLine } = useTimeLineProviderQuery({ postId: id })

  const {
    data: activePostData,
    isLoading: activeIsloading,
    refetch,
  } = useProviderJobPostQuery()

  const [
    getrateOtpProvider,
    { data: otpgenRateData, error: otpgenError, isLoading: genRateLoading },
  ] = useGenrateOtpMutation()

  useEffect(() => {
    if (otpgenRateData?.data) {
      setShowOtp(otpgenRateData?.data?.otp)
    }
  }, [otpgenRateData])

  const handleVerifyClickOpen = () => {
    const payload = {
      postId: id,
    }

    getrateOtpProvider(payload)
  }

  const handleVerifyClose = () => {
    setVerifyOpen(false)
  }

  useEffect(() => {
    if (otpgenRateData?.message) {
      toast.success(otpgenRateData?.message)
      setVerifyOpen(true)
    } else {
      toast.error((otpgenError as any)?.data?.message)
    }
  }, [otpgenRateData, otpgenError])

  const conFirmOtp = () => {
    setShowOtp("") as any
    const payload = {
      postId: id,
      jobAction:
        activePostData?.data?.ifJobEndOtpGenBySeeker == true &&
        activePostData?.data?.status == "Completed"
          ? "End"
          : "Start",
      otp: otp.join(""),
    }
    verifyOptConfirm(payload)
  }

  const handleKeyDownConfirmOtp = (event: any) => {
    if (event.key === "Enter") {
      conFirmOtp()
    }
  }

  // opt handler

  const handleChange = (index: any, event: any) => {
    const newOtp = [...otp]
    const newValue = event.target.value

    if (!otp[index]) {
      newOtp[index] = newValue
    } else {
      newOtp[index] = newValue
    }

    setOtp(newOtp)

    // Focus next input field if available
    if (event.target.nextSibling && newValue !== "") {
      event.target.nextSibling.focus()
    }
  }

  const handleKeyDown = (index: any, event: any) => {
    if (event.keyCode === 8 && !otp[index] && otpRef.current[index - 1]) {
      ;(otpRef as any).current[index - 1].focus()
    }
  }

  const handleClick = (index: number) => {
    // Focus on the clicked input field
    ;(otpRef as any).current[index].focus()
  }

  useEffect(() => {
    if (otpData?.message && otpData?.data?.isVerified === undefined) {
      toast.success(otpData?.message)
      setOtp(["", "", "", "", "", ""])
    } else {
      toast.error((otpError as any)?.data?.message)
    }
  }, [otpData, otpError])

  useEffect(() => {
    if (otpData?.data?.isVerified === true) {
      toast.success(otpData?.message)
      setOtp(["", "", "", "", "", ""])
      // navigate("/dashboard/rating")
      // setSearchParams({ type: "review-post" })
    } else if (otpData?.data?.isVerified === false) {
      toast.error((otpError as any)?.data?.message)
    }
  }, [otpData, otpError])

  useEffect(() => {
    if (activePostData?.data?.isData === false) {
      // navigate("/dashboard")
      navigate("/dashboard/rating")
      // setSearchParams({ type: "review-post" })
    } else if (activePostData?.data?.status == "Expired") {
      navigate("/dashboard")
    } else if (activePostData?.data?.isData == true) {
      navigate(
        `/dashboard/write-review/${activePostData?.data?.customerId}?postId=${activePostData?.data?.postId}`,
      )
      //     navigate("/dashboard")
    }
  }, [activePostData])

  // image preview  with on click

  const handleImageClick = (image: string) => {
    setSelectedImage(image)
  }

  const handleClosePreview = () => {
    setSelectedImage(null)
  }

  return (
    <>
      <Box>
        <Style.Overview>
          {activeIsloading ? (
            <CenteredSpinner />
          ) : (
            <>
              <Box className="d-flex justify-content-between align-items-center pb-5">
                <Box className="d-flex align-items-center gap-4">
                  <Button
                    className="back-btn mb-0"
                    onClick={() => navigate("/dashboard")}
                  >
                    <FaChevronLeft />
                  </Button>
                </Box>
                <Box>
                  <Typography className="status-text mb-0 ">
                    Status-{" "}
                    <span className="completed fw-bold">
                      {activePostData?.data?.status}
                    </span>{" "}
                    {/* <span className="cancelled">Cancelled</span> */}
                  </Typography>
                </Box>
              </Box>

              {/* new design of otp and service */}
              <Grid
                container
                spacing={2}
                justifyContent="center"
                sx={{ mb: 5 }}
              >
                {activePostData?.data?.activeJobflow !== "END_OTP_VERIFIED" &&
                  activePostData?.data?.activeJobflow !== "PAYMENT_SUCCESS" && (
                    <Grid item xs={12} lg={6} order={{ xs: 2, lg: 1 }}>
                      {/* Content for the first column */}
                      <Typography
                        align="left"
                        variant="h2"
                        className=" fw-medium font-24"
                        mb={1}
                      >
                        Service details
                      </Typography>
                      <Grid
                        container
                        className="otp-box otp-box-3 p-4 "
                        sx={{ mt: 1 }}
                      >
                        <Grid item xs={12}>
                          <Box>
                            <Typography
                              variant="body1"
                              mb={3}
                              className="text-black d-flex gap-3"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <span className="fw-bold">
                                    Service required
                                  </span>{" "}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <span>
                                    {" "}
                                    {activePostData?.data?.service?.name}
                                  </span>
                                </Grid>
                              </Grid>
                            </Typography>
                            <Typography
                              variant="body1"
                              mb={3}
                              className="text-black d-flex gap-3"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <span className="fw-bold">
                                    {/* <IoCalendarClearOutline /> */}
                                    Service date
                                  </span>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <span>
                                    {moment(activePostData?.data?.from).format(
                                      "DD-MM-YYYY",
                                    )}
                                  </span>
                                </Grid>
                              </Grid>
                            </Typography>
                            <Typography
                              variant="body1"
                              mb={3}
                              className="text-black d-flex gap-3"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  <span className="fw-bold">
                                    {/* <CiClock2 /> */}
                                    Service time
                                  </span>{" "}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <div className="d-flex gap-4">
                                    <span className="text-center">
                                      <strong>
                                        {/* <span className="font-12 pe-2">From</span> */}
                                      </strong>

                                      <FormatChangeTime
                                        dateString={activePostData?.data?.from}
                                      />
                                      {/* {moment(activePostData?.data?.from).format("h:mm A")}{" "} */}
                                    </span>{" "}
                                    <span className="text-center">
                                      <strong>
                                        {" "}
                                        {/* <span className="font-12 pe-2">To</span> */}
                                      </strong>
                                      {/* {moment(activePostData?.data?.till).format("h:mm A")}{" "} */}
                                      <FormatChangeTime
                                        dateString={activePostData?.data?.till}
                                      />
                                    </span>
                                  </div>
                                </Grid>
                              </Grid>{" "}
                            </Typography>
                            <Typography
                              variant="body1"
                              mb={1}
                              className="text-black d-flex gap-3"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  {" "}
                                  <span className="fw-bold">Language</span>{" "}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <span>
                                    {" "}
                                    {activePostData?.data?.language?.join(" ")}
                                  </span>
                                </Grid>
                              </Grid>
                            </Typography>
                          </Box>
                        </Grid>

                        <Grid item xs={12}>
                          <Box>
                            <Typography
                              variant="body1"
                              mb={3}
                              className="text-black"
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                  {" "}
                                  <span className="fw-bold">
                                    {/* <IoLocationOutline /> */}
                                    Location
                                  </span>{" "}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                  <span> {activePostData?.data?.location}</span>
                                </Grid>
                              </Grid>
                            </Typography>
                            <Typography
                              variant="body1"
                              mb={3}
                              className="text-black"
                            >
                              {activePostData?.data?.description && (
                                <Grid container spacing={2}>
                                  <Grid item xs={12} md={6}>
                                    {" "}
                                    <span className="fw-bold">
                                      Additional instructions
                                    </span>{" "}
                                    -
                                  </Grid>
                                  <Grid item xs={12} md={6}>
                                    <span>
                                      {" "}
                                      {activePostData?.data?.description}
                                    </span>
                                  </Grid>
                                </Grid>
                              )}
                            </Typography>
                            {activePostData?.data?.attachments?.length > 0 && (
                              <Typography
                                variant="body1"
                                mb={1}
                                className="text-black d-flex gap-3"
                              >
                                <span className="fw-bold">Photo</span>
                              </Typography>
                            )}

                            {activePostData?.data?.attachments?.map(
                              (item: any) => (
                                <img
                                  src={item}
                                  className="photo-upload"
                                  onClick={() => handleImageClick(item)}
                                />
                              ),
                            )}

                            <PreviewImage
                              selectedImage={selectedImage}
                              handleClosePreview={handleClosePreview}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  )}

                <Grid item xs={12} lg={6} order={{ xs: 1, lg: 2 }}>
                  {/* Content for the second column */}

                  {(activePostData?.data?.activeJobflow == "JOB_ACTIVE" ||
                    activePostData?.data?.activeJobflow ==
                      "CUSTOMER_STOP_OTP") && (
                    <>
                      <Typography
                        align="left"
                        variant="h2"
                        className=" fw-medium font-24"
                        mb={1}
                      >
                        Enter OTP{" "}
                      </Typography>
                      <Box className="otp-box text-center" sx={{ mt: 1 }}>
                        <Typography
                          align="center"
                          variant="h2"
                          className=""
                          mb={5}
                        >
                          To{" "}
                          {activePostData?.data?.ifJobEndOtpGenBySeeker ==
                            true && activePostData?.data?.status == "Completed"
                            ? "End"
                            : "Start"}{" "}
                          a service, enter a 6 digit OTP, given by client.{" "}
                        </Typography>
                        <Box py={5}>
                          <FormControl className="d-flex justify-content-center gap-3 flex-row">
                            {otp.map((digit: any, index: number) => (
                              <input
                                key={index}
                                type="text"
                                maxLength={1}
                                value={digit}
                                onChange={(e) => handleChange(index, e)}
                                onKeyDown={(e) => handleKeyDown(index, e)}
                                onKeyPress={(event) => {
                                  const keyCode = event.keyCode || event.which
                                  const keyValue = String.fromCharCode(keyCode)
                                  const numericRegex = /^[0-9]+$/

                                  if (!numericRegex.test(keyValue)) {
                                    event.preventDefault()
                                  }
                                }}
                                onClick={() => handleClick(index)}
                                ref={(ref: any) =>
                                  (otpRef.current[index] = ref as any)
                                }
                                style={{
                                  maxWidth: "clamp(30px, 3vw, 46px)",
                                  height: "clamp(30px, 3vw, 56px)",
                                  textAlign: "center",
                                  fontFamily: "Montserrat",
                                  color: "#F475A4",
                                  border: "1px solid #F475A4",
                                  borderRadius: "6px",
                                  fontSize: "20px",
                                }}
                              />
                            ))}
                          </FormControl>
                        </Box>
                        <Button
                          className="view-profile py-2 text-capitalize"
                          onClick={conFirmOtp}
                          disabled={otp[0] == ""}
                          type="button"
                          tabIndex={0}
                          onKeyDown={handleKeyDownConfirmOtp}
                        >
                          {otpIsloading ? (
                            <Typography fontSize={1}>
                              <CircularProgress
                                style={{ color: "pink", fontSize: 20 }}
                                size={20}
                              />
                            </Typography>
                          ) : (
                            " Confim OTP"
                          )}
                        </Button>
                      </Box>
                    </>
                  )}
                  <>
                    <Typography
                      variant="body1"
                      className="mb-1"
                      style={{
                        color: "green",
                        fontSize: "large",
                        fontWeight: "bold",
                      }}
                    >
                      {activePostData?.data?.activeJobflow ==
                        "START_OTP_VERIFIED" && (
                        <>
                          <Typography
                            align="left"
                            variant="h2"
                            className=" fw-medium font-24"
                            mb={1}
                          >
                            Current Status{" "}
                          </Typography>
                          <Box
                            className="otp-box h-100 text-center d-flex flex-column align-items-center justify-content-center"
                            sx={{ mt: 1 }}
                          >
                            <Typography
                              align="center"
                              variant="body1"
                              className="green-heading"
                              mb={2}
                            >
                              Your service has started{" "}
                            </Typography>
                            <Grid container justifyContent="center">
                              <img
                                src={RocketIcon}
                                alt="service started"
                                height={180}
                                width={180}
                              />
                            </Grid>
                          </Box>
                        </>
                      )}
                    </Typography>
                  </>

                  {/* generate-otp  new vipul ...*/}
                  {(activePostData?.data?.activeJobflow == "STOP_SERVICE" ||
                    activePostData?.data?.activeJobflow ==
                      "PROVIDER_STOP_OTP") && (
                    <GenRateotpCustomer
                      otpGenrate={handleVerifyClickOpen}
                      otpFill={showOtp as any}
                      genRateLoading={genRateLoading}
                    />
                  )}
                </Grid>
              </Grid>
              {/* new design of otp and service */}
              {(activePostData?.data?.activeJobflow == "END_OTP_VERIFIED" ||
                activePostData?.data?.activeJobflow == "PAYMENT_SUCCESS") && (
                <Grid container spacing={2} style={{ marginBottom: "50px" }}>
                  <Grid item xs={12} lg={7}>
                    <Box
                      className="otp-box h-100 text-center d-flex flex-column align-items-center justify-content-center"
                      sx={{ mt: 1 }}
                    >
                      <Typography
                        align="center"
                        variant="body1"
                        className="green-heading1 p-2"
                        mb={2}
                      >
                        Total Amount{" "}
                        <span style={{ color: "#FFB800" }}>
                          CAD${activePostData?.paymentDetails?.totalAmount}
                        </span>{" "}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body1"
                        className="payment-status"
                        mb={2}
                      >
                        Payment Status
                        {/* {activePostData?.data?.activeJobflow ==
                        "PAYMENT_SUCCESS" ? (
                          <button className="pending"> Paid</button>
                        ) : ( */}
                        <button className="pending"> Pending</button>
                        {/* )} */}
                      </Typography>
                      <Typography
                        align="center"
                        variant="body1"
                        className="text-data mt-2"
                        mb={3}
                      >
                        (Ask customer to pay the amount){" "}
                      </Typography>
                    </Box>{" "}
                  </Grid>
                  <Grid item xs={12} lg={5} className="d-none d-xl-block ">
                    <Box
                      sx={{
                        // set width as needed
                        height: "auto", // set height as needed
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                      }}
                    >
                      <p
                        style={{
                          fontSize: "24px",
                          fontWeight: "700",
                          color: "#009C35",
                          marginTop: "15px",
                        }}
                      >
                        Service Completed Successfully!
                      </p>
                      <img
                        src={servicecompleted}
                        alt="Description"
                        style={{
                          width: "200px",
                          height: "auto",
                          marginTop: "15px",
                        }}
                      />
                    </Box>{" "}
                  </Grid>
                </Grid>
              )}

              <Divider className="dark-divider mb-5" />

              {/* {activePostData?.data?.activeJobflow == "PAYMENT_SUCCESS" && (
                <>
                  <span>
                    How was your service? Give your review
                    <Button
                      className="timeline-btn me-4"
                      onClick={() => navigate("/dashboard/rating")}
                    >
                      Review
                    </Button>
                  </span>
                </>
              )} */}

              <Box
                className="d-flex mb-5"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  align="left"
                  variant="h2"
                  className=" font-20"
                  mb={2}
                >
                  Timeline
                </Typography>

                <Box display="flex">
                  <Button className="timeline-btn " onClick={handleChatView}>
                    Chat
                    <img
                      src={chat}
                      alt="chat"
                      style={{ color: "#F475A4", marginLeft: "10px" }}
                    />
                  </Button>
                </Box>
              </Box>
              <Box className="timeline-graph">
                {timeLine?.data
                  ?.slice(0, showFullTimeline ? timeLine?.data?.length : 3)
                  .map((timeline: any, index: number) => (
                    <Timeline
                      key={timeline._id}
                      position={index % 2 === 0 ? "alternate" : "left"}
                    >
                      <TimelineItem>
                        <TimelineOppositeContent
                          sx={{ m: "10px 0 0" }}
                          align="right"
                          className="timeline-desc"
                          color="text.secondary"
                        >
                          {/* {new Date(timeline.createdAt).toLocaleDateString()} */}
                          {/* new date format */}
                          <span className="datetime">
                            <span className="d-flex flex-column">
                              <span className="weekday">
                                <FormatDate dateString={timeline.createdAt} />
                              </span>
                              <span>
                                <FormatChangeTime
                                  dateString={timeline.createdAt}
                                />
                              </span>
                              <span>
                                <FormatTime dateString={timeline.createdAt} />
                              </span>
                            </span>
                          </span>

                          <br />
                          {/* {moment(timeline.updatedAt).format("h:mm a")} */}
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                          <TimelineConnector />
                          <TimelineDot>
                            <Typography className="timeline-step">
                              0{timeLine?.data?.length - index}
                            </Typography>
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: "12px", px: 2 }}>
                          <Box className="timeline-content">
                            <Typography className="timeline-heading">
                              Status - {timeline?.postStatus}{" "}
                            </Typography>
                            <Typography className="timeline-desc"></Typography>
                            <Typography className="timeline-desc">
                              {timeline?.message}{" "}
                            </Typography>
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    </Timeline>
                  ))}
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={handleToggleTimeline}
                    className="view-profile py-2 text-capitalize"
                  >
                    {showFullTimeline ? "Show Less" : "Show More"}
                  </Button>
                </div>
              </Box>
            </>
          )}
        </Style.Overview>
      </Box>
    </>
  )
}
export default VerifyOtp
