import { styled } from "@mui/material";


const Overview = styled("div")(({ theme }) => ({
    '& .MuiIconButton-root': {
        color: theme.palette.black?.main,
    },
    '& .MuiPaper-root': {
        backgroundColor: theme.palette.white?.main,
        boxShadow: '0px 4px 25px 0px rgba(0, 0, 0, 0.03)',
        color: theme.palette.black?.main,
        position: "relative",
    },
    '& .MuiPaper-paper': {
        position: "absolute",
        // top: "10%",
        top: "calc(100% + 10px)",
        left: "50%",
        transform: "translateX(-50%)"
    },
    '& .dropdown-card': {
        height:"900px ",
         width:"200px"
    },
    '& .pt-lg-inner': {
        backgroundColor:"red",
        display:"none"
    },
    "& .notification-container":{
        backgroundColor:"red"
    },
  
    
}));


export { Overview }

export const boxStyle = {
    height: "347px",
    width: "457px",
    padding: "12px",
    '@media (max-width: 600px)': {
      height: "350px",
      width: "300px",
    },
  };
  
  export const flexboxStyle = {
    display:"flex",
    justifyContent:"space-between",
    paddingBottom:"12px",
    borderBottom:"0.1px solid #F4E6E3 ",
    margin:"0 6px",
    fontSize: "12px",
    
  };
