import React from "react"
import { styled } from "@mui/material"

const AuthPage = styled("div")(({ theme }: any) => ({
  minHeight: "100vh",
  padding: "40px 40px 0px",
  "@media (max-width: 575px)": {
    padding: "30px 20px 30px 20px",
  },
  "& .auth-wrapper": {
    backgroundImage: `url(../../src/images/auth-background.png)`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    minHeight: "100vh",
  },
  "& .box-wrapper": {
    padding: "75px 15px 10px 15px",
    "@media (max-width: 575px)": {
      padding: "30px 20px 30px 20px",
    },
    "@media (max-width: 1200px)": {
      display: "none",
    },
  },
  "& .auth-btn": {
    textTransform: "capitalize",
    fontWeight: "600",
    letterSpacing: "0px",
    fontSize: "16px",
    maxWidth: "300px",
    boxShadow: "none",
  },
  "& .text-login": {
    fontSize: "16px",
    color: "#7B7B7B",
    fontWeight: "400",
    fontFamily: "Montserrat",
  },
  "& .check-text": {
    "& span": {
      fontSize: "16px",
      color: "#7B7B7B",
      fontWeight: "400",
      fontFamily: "Montserrat",
    },
    "& svg": {
      fill: "#7B7B7B",
    },
    "& .Mui-checked svg": {
      fill: theme.palette.pink.main,
    },
  },
  "& .auth-field": {
    "& .MuiInputBase-root": {
      flexDirection: "row-reverse",
      padding: "19px 27px",
      border: "1px solid #D3D3D3",
      borderRadius: "12px",
      "& button": {
        padding: "0px",
      },
      "& input": {
        padding: "0px",
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "Montserrat",
        background: theme.palette.white.main,
      },
      "&::before": {
        display: "none",
      },
      "&::after": {
        display: "none",
      },
    },
  },
  "& .Loginform": {
    borderRadius: "25px",
    padding: "53px 70px 111px",
    boxShadow: " 0px 4px 21px 1px rgba(0, 0, 0, 0.06)",
    background: "#fff",
    color: theme.palette.black.main,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    minHeight: "500px",
    "@media (max-width: 575px)": {
      minHeight: "300px",
      padding: "0px 10px 16px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "25px",
    },
    "& .MuiButtonBase-root.MuiButton-root ": {
      borderRadius: " 25px",
      backgroundColor: theme.palette.pink.main,
    },
    " & .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input:-webkit-autofill":
      {
        borderRadius: " 28px",
        WebkitBorderRadius: "28px",
        MozBorderRadius: "28px",
    },
    " & .css-1uvydh2:-webkit-autofill":
      {
        borderRadius: " 28px",
        WebkitBorderRadius: "28px",
        MozBorderRadius: "28px",
    },  
  },
  "& .Imgbox": {
    justifyContent: "center",
    img: {
      width: "130px",
      height: "277px",
      borderRadius: " 500px",
      background: " #EBEFF2",
      boxShadow: " 0px 21px 35px 0px rgba(0, 0, 0, 0.22)",
      objectFit: "cover",
      "@media (max-width: 1280px)": {
        width: "100px",
      },
    
    },
    "& .login-left-img1": {
      objectPosition: "-75px",
    },
    "& .login-left-img3": {
      objectPosition: "-75px",
    },
  },
}))

export { AuthPage }
