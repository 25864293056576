import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Grid,
  Stack,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { FaStar } from "react-icons/fa6"
import * as Style from "./style"
import {
  useAppliedJobsQuery,
  useApprovedJobsQuery,
  useJobHistoryQuery,
} from "../../features/Auth/authApi"
import { Button, Spinner } from "react-bootstrap"
import { CiSearch } from "react-icons/ci"
import moment from "moment"
import dummy from "../../images/dummy-image.jpg"
import nodata from "../../images/no-results.svg"
import { TbArrowsSort } from "react-icons/tb"
import { Link, useNavigate } from "react-router-dom"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import CenteredSpinner from "../common/CenteredSpinner"
import RatingStars from "../common/rating"

const JobApproved = () => {
  const [selectedPage, setSelectedPage] = useState()
  const navigate = useNavigate()

  const { data: approvedjobs, isLoading } = useApprovedJobsQuery({
    pageNo: selectedPage,
  })

  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  return (
    <>
      <Style.Overview>
        {isLoading ? (
          <CenteredSpinner />
        ) : (
          <>
            <TableContainer
              component={Paper}
              className="dashboard-table-wrapper custom-scrollbar"
            >
              <Table
                sx={{
                  minWidth: 650,
                  border: "none",
                  backgroundColor: "transparent",
                }}
                className="dashboard-table"
                aria-label="simple table"
              >
                {approvedjobs?.data?.length > 0 && (
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="center" className="service-head">
                        Name
                      </TableCell>
                      <TableCell align="center">Service</TableCell>
                      <TableCell align="center">Start Time</TableCell>
                      <TableCell align="center">End Time</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {approvedjobs?.data?.length > 0 ? (
                    approvedjobs?.data?.map((row: any) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          navigate(`/dashboard/approved-detail/${row?._id}`)
                        }
                      >
                        <TableCell>
                          <div
                            style={{
                              width: "74px",
                              height: "74px",
                              objectFit: "cover",
                            }}
                          >
                            <img
                              src={row.seekerData?.profilePic || dummy}
                              style={{
                                width: "74px",
                                height: "74px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="td" align="center" scope="row">
                          <span className="text flex-column gap-2">
                            {row?.seekerData?.name + " "}
                            <span className="minh-1">
                              <RatingStars
                                rating={row?.seekerData?.rating?.$numberDecimal}
                              />{" "}
                            </span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{row?.service?.name}</span>
                        </TableCell>

                        <TableCell align="center">
                          <span className="datetime">
                            <span className="d-flex flex-column">
                              <span className="weekday">
                                <FormatDate dateString={row?.from} />
                              </span>
                              {/* <span> */}
                              <span>
                                <FormatChangeTime dateString={row?.from} />
                              </span>
                              <span>
                                <FormatTime dateString={row?.from} />
                              </span>
                            </span>
                            {/* </span> */}
                          </span>
                        </TableCell>

                        <TableCell align="center">
                          <span className="datetime">
                            <span className="d-flex flex-column">
                              <span className="weekday">
                                <FormatDate dateString={row?.till} />
                              </span>
                              {/* <span> */}
                              <span>
                                <FormatChangeTime dateString={row?.till} />
                              </span>
                              <span>
                                <FormatTime dateString={row?.till} />
                              </span>
                            </span>
                            {/* </span> */}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{row?.status}</span>
                        </TableCell>
                        <TableCell align="center">
                          <div>
                            <Link
                              to={`/dashboard/approved-detail/${row?._id}`}
                              className="view-profile"
                            >
                              Track Service{" "}
                            </Link>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <TableRow
                        sx={{
                          height: "70vh",
                          backgroundColor: "white",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={7}
                          sx={{
                            width: "100%",
                            pointerEvents: "none",
                            border: "none",
                          }}
                        >
                          <img
                            src={nodata}
                            style={{
                              width: "100%",
                              maxWidth: "180px",
                            }}
                          />
                          <span className="no-data-text">No data found</span>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {approvedjobs?.data?.length > 0 && (
          <Box className="d-flex justify-content-between mt-2 ">
            <Box>
              <Typography variant="body1" className="w-auto white-space">
                {" "}
                Showing {approvedjobs?.totalDocument?.showingData} -{" "}
                {approvedjobs?.totalDocument?.totalCount} Total
              </Typography>
            </Box>
            <Box>
              <Stack spacing={2} className="mb-3">
                <Pagination
                  count={approvedjobs?.totalDocument?.totalPages}
                  color="secondary"
                  className="custom-pagination "
                  page={approvedjobs?.totalDocument?.pageNo}
                  onChange={handlePageChange}
                  sx={{
                    "& .MuiPaginationItem-page.Mui-selected": {
                      backgroundColor: "#F475A4",
                      boxSizing: "border-box",
                    },
                    "& .css-wjh20t-MuiPagination-ul": {
                      flexWrap: "nowrap",
                      justifyContent: "end",
                    },
                  }}
                />
              </Stack>
            </Box>
          </Box>
        )}
      </Style.Overview>
    </>
  )
}

export default JobApproved
