import React, { useEffect, useState } from "react"
import {
  Box,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import profileimage from "../../images/personal-info.svg"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import * as Style from "./Style"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import {
  useGetCountrysQuery,
  useGetServiceProvierQuery,
} from "../../features/Auth/authApi"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { useAppSelector } from "../../app/hooks"
import { useAppDispatch } from "../../app/store"
import { setStepsCount, setString } from "../../features/Auth/WrapperSlice"

interface PersonalAccount {
  services: (string | undefined)[]
  language: (string | undefined)[]
  experience: string
}

const Experience = [
  {
    value: "1",
    label: "1 year",
  },
  {
    value: "2",
    label: "2 years",
  },
  {
    value: "3",
    label: "3 years",
  },
  {
    value: "4",
    label: "4 years",
  },
  {
    value: "5",
    label: "5 years",
  },
  {
    value: "6",
    label: "6 years",
  },
  {
    value: "7",
    label: "7 years",
  },
  {
    value: "8",
    label: "8 years",
  },
  {
    value: "9",
    label: "9 years",
  },
  {
    value: "10",
    label: "10 years",
  },
  {
    value: "11",
    label: "11 years",
  },
]

interface PersonalStepProps {
  activeStep: string
  step: string
  handleNext: () => void
  handleBack: () => void
}

const ServiceStep: React.FC<PersonalStepProps> = ({
  activeStep,
  step,
  handleNext,
  handleBack,
}) => {
  const { data: servicePost } = useGetServiceProvierQuery({})
  const dispatch = useAppDispatch()
  const [experienceAdd, setExperienceAdd] = useState("")
  const [isFocused, setIsFocused] = useState(false)

  const schemaResolver = yupResolver(
    yup.object().shape({
      // services: yup.string().required("Please select at least one service."),
      services: yup
        .array()
        .of(yup.string())
        .min(1, "Please select at least one services")
        .required("Please select services"),
      language: yup
        .array()
        .of(yup.string())
        .min(1, "Please select at least one language")
        .max(3, "Please select up to 3 languages")
        .required("Please select languages"),
      experience: yup.string().required("Please select experience."),
    }),
  )

  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalAccount>({
    resolver: schemaResolver,
  })
  const stringValue = useAppSelector((state) => state?.wrapper?.stringValue)
  const { data: countryList } = useGetCountrysQuery({})

  const onSubmit = (value: any) => {
    dispatch(
      setString({
        services: value?.services,
        languageAll: value?.language,
        experience: value?.experience,
        name: stringValue?.name,
        email: stringValue?.email,
        contactNo: stringValue?.contactNo,
        gender: stringValue?.gender,
        region: stringValue?.region,
        address: stringValue?.address,
        dob: stringValue?.dob,
      }),
    ),
      handleNext()
  }

  useEffect(() => {
    setValue("services", (stringValue as any)?.services)
    setValue("language", (stringValue as any)?.languageAll)
    setValue("experience", (stringValue as any)?.experience)

    // setValue("experience", (stringValue as any)?.experience)
  }, [stringValue])

  const Experiencehandle = (e: any) => {
    setExperienceAdd(e?.target?.value)
  }

  return (
    <Style.PersonalInfo className="p-5">
      <Box className="d-flex justify-content-center align-items-center">
        <form onSubmit={handleSubmit(onSubmit)}>
          <Typography sx={{ mt: 2, mb: 2 }}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { mb: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <InputLabel sx={{ mb: 0 }} htmlFor="outlined-basic">
                Services<span className="text-danger">*</span>
              </InputLabel>
              <FormControl
                sx={{
                  mb: 1,
                  width: 300,
                  " & .form-input": {
                    width: "400px",
                    borderRadius: "34px",
                    backgroundColor: "white",
                  },
                }}
              >
                <InputLabel id="language-label">Services</InputLabel>
                <Select
                  labelId="language-label"
                  className="form-input"
                  label="services"
                  multiple
                  id="demo-simple-select"
                  {...register("services" as any)}
                  value={watch("services" as any) || []}
                  // input={<OutlinedInput id="demo-simple-select" label="Chip" />}
                  onChange={(event) => {
                    const selectedValues = event.target.value
                    setValue("services" as any, selectedValues as any, {
                      shouldValidate: true,
                    })
                  }}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {selected.map((selectedItemId: any) => {
                        const selectedItem = servicePost?.data?.find(
                          (item: any) => item?._id === selectedItemId,
                        )
                        return (
                          <Chip
                            key={selectedItemId}
                            label={selectedItem?.name}
                          />
                        )
                      })}
                    </Box>
                  )}
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 150,
                        overflowY: "auto", // Enable vertical scrolling
                        scrollbarWidth: "thin", // Width of the scrollbar
                        scrollbarColor: "#f07fa9 transparent", // Color of the scrollbar thumb
                        borderRadius: "12px", // Border radius of the scrollbar thumb
                        cursor: "pointer", // Change cursor to pointer
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  {servicePost?.data &&
                    servicePost?.data?.map((serviceName: any) => (
                      <MenuItem
                        sx={{
                          "&.Mui-selected": {
                            backgroundColor: "#F475A4",
                            color: "#ffffff",
                          },
                          "&:hover": {
                            backgroundColor: "#F475A4",
                            color: "#000000",
                          },

                          color: "#000000",
                        }}
                        key={serviceName?._id}
                        value={serviceName?._id}
                      >
                        {serviceName?.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <p className="text-danger  m-0">{errors?.services?.message}</p>
            </Box>
          </Typography>

          <Typography sx={{ mb: 1 }}>
            <Box
              component="form"
              sx={{
                "& > :not(style)": { mb: 1 },
              }}
              noValidate
              autoComplete="off"
            >
              <InputLabel sx={{ mb: 2 }} htmlFor="outlined-basic">
                Languages<span className="text-danger">*</span>
              </InputLabel>
              <FormControl
                sx={{
                  mb: 1,
                  width: 300,
                  " & .form-input": {
                    width: "400px",
                    borderRadius: "34px",
                    backgroundColor: "white",
                  },
                }}
              >
                <InputLabel id="language-label">Languages</InputLabel>
                <Select
                  labelId="language-label"
                  className="form-input"
                  multiple
                  id="demo-simple-select"
                  {...register("language" as any)}
                  value={watch("language" as any) || []}
                  // input={<OutlinedInput id="demo-simple-select" label="Chip" />}
                  onChange={(event) => {
                    const selectedValues = event.target.value
                    setValue("language" as any, selectedValues as any, {
                      shouldValidate: true,
                    })
                  }}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}
                    >
                      {selected.map((selectedItemId: any) => {
                        const selectedItem = countryList?.languages?.find(
                          (item: any) => item?.name === selectedItemId,
                        )
                        return (
                          <Chip
                            key={selectedItemId}
                            label={selectedItem?.name}
                          />
                        )
                      })}
                    </Box>
                  )}
                  fullWidth
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 150,
                        overflowY: "auto", // Enable vertical scrolling
                        scrollbarWidth: "thin", // Width of the scrollbar
                        scrollbarColor: "#f07fa9 transparent", // Color of the scrollbar thumb
                        borderRadius: "12px", // Border radius of the scrollbar thumb
                        cursor: "pointer", // Change cursor to pointer
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  }}
                >
                  {countryList?.languages?.map((item: any) => (
                    <MenuItem
                      sx={{
                        "&.Mui-selected": {
                          backgroundColor: "#F475A4",
                          color: "#ffffff",
                        },
                        "&:hover": {
                          backgroundColor: "#F475A4",
                          color: "#000000",
                        },

                        color: "#000000",
                      }}
                      key={item.name}
                      value={item.name}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <p className="text-danger  m-0">{errors?.language?.message}</p>
            </Box>
          </Typography>

          <Typography
            sx={{
              mt: 2,
              mb: 3,
              width: 300,
              " & .form-input": {
                width: "400px",
                borderRadius: "34px",
                backgroundColor: "white",
              },
            }}
          >
            <Box
              component="form"
              sx={{
                "& > :not(style)": { mb: 0 },
              }}
              noValidate
              autoComplete="off"
            >
              <InputLabel
                className="mb-2"
                sx={{ mb: 2 }}
                htmlFor="outlined-basic"
              >
                Experience<span className="text-danger">*</span>
              </InputLabel>
              <TextField
                id="filled-select-currency"
                select
                variant="filled"
                className="form-input"
                label={
                  isFocused || watch("experience") ? "" : "Your experience"
                }
                {...register("experience")}
                value={watch("experience") || []}
                onChange={(event) => {
                  const selectedValues = event.target.value
                  setValue("experience" as any, selectedValues as any, {
                    shouldValidate: true,
                  }),
                    setIsFocused(true)
                }}
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        maxHeight: 250,
                        width: 150,
                        overflowY: "auto",
                        scrollbarWidth: "thin",
                        scrollbarColor: "#f07fa9 transparent",
                        borderRadius: "12px",
                        cursor: "pointer",
                      },
                    },
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left",
                    },
                  },
                }}
              >
                {Experience.map((option) => (
                  <MenuItem
                    sx={{
                      "&.Mui-selected": {
                        backgroundColor: "#F475A4",
                        color: "#ffffff",
                      },
                      "&:hover": {
                        backgroundColor: "#F475A4",
                        color: "#000000",
                      },

                      color: "#000000",
                    }}
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <p className="text-danger  mt-1">{errors?.experience?.message}</p>
            </Box>
          </Typography>

          <Button
            color="inherit"
            disabled={(activeStep as any) === 0}
            onClick={handleBack}
            sx={{ mr: 1 }}
            className="next-button me-xl-5"
          >
            Back
          </Button>
          <Button type="submit" className="next-button grey-button ms-xl-5">
            {(activeStep as any) === step.length - 1 ? "Finish" : "Next"}
          </Button>
        </form>
      </Box>
    </Style.PersonalInfo>
  )
}

export default ServiceStep
