import React, { Fragment, memo, useEffect, useRef, useState } from "react"
import styles from "./ChatUi.module.css"

import ChatImage from "./ChatImage"

import { Message, Ticket } from "../ChatenterFace/chatinterface"
import { CalculateTimeDifferenceChat } from "../Notification/timeNotification"
import { useAppSelector } from "../../app/hooks"
import { useSearchParams } from "react-router-dom"
import {
  useGetChatsMessageUpdateQuery,
  useRemoveSeenCountMutation,
} from "../../features/ChatAuth/chatApi"
import { _setMessage, _setUnseenCount } from "../../features/ChatAuth/chatSlice"
import { useAppDispatch } from "../../app/store"

import { CircularProgress } from "@mui/material"
import DummyChatSecreen from "../ProviderLayout/dummyChatSecreen"
import NullScreen from "../common/NullScreen"

interface DialogueProps {
  setMessageLoading: (value: boolean) => void
  scrollLimit: number
  loadingMore: boolean
  topOfChatRef: any
}

const Dialogue = ({
  setMessageLoading,
  scrollLimit,
  loadingMore,
  topOfChatRef,
}: DialogueProps) => {
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const [searchParams] = useSearchParams()
  const _chatId = searchParams.get("chatId")
  const dispatch = useAppDispatch()
  const [countRemove] = useRemoveSeenCountMutation()
  const [page, setPage] = useState(1)
  const [shouldFetch, setShouldFetch] = useState(false)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const state: any = useAppSelector((state) => state.authChatView)
  const TicketPath: Ticket[] =
    state._search.split("").length > 0 ? state._searchedTickets : state._Tickets
  const selectedUser = TicketPath.find((user) => user._id === _chatId)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldFetch(true)
    }, 1000)

    return () => clearTimeout(timer)
  }, [])

  const {
    data: allMessages,
    isLoading,
    isFetching,
    refetch,
  } = useGetChatsMessageUpdateQuery(
    { pageNo: page, pageLimit: scrollLimit, ticketId: _chatId },
    { skip: !_chatId || !shouldFetch, refetchOnMountOrArgChange: true },
  )

  useEffect(() => {
    if (isLoading || !shouldFetch) {
      setLoading(true)
      setError(null)
    } else {
      if (allMessages) {
        dispatch(_setMessage(allMessages.data ?? []))
        setLoading(false)
      } else {
        setLoading(false)
        setError("No messages found.")
      }
    }
  }, [isLoading, allMessages, dispatch])

  const TicketRedux: Ticket[] = useAppSelector(
    (state) => state.authChatView?._Tickets,
  )
  const ticket = TicketRedux?.find((ticket) => ticket._id === _chatId)
  const messages = ticket?.messages || []
  useEffect(() => {
    const timer = setTimeout(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
      }
    }, 100)
    return () => clearTimeout(timer)
  }, [messages])
  const [lastProcessedMessageId, setLastProcessedMessageId] = useState<
    string | null
  >(null)
  useEffect(() => {
    const lastMessage = messages[messages.length - 1] || {}
    if (
      lastMessage.ticketId === _chatId &&
      lastMessage._id !== lastProcessedMessageId
    ) {
      const payload = {
        userType: "ADMIN",
        ticketId: lastMessage.ticketId,
      }
      countRemove(payload)

      setLastProcessedMessageId(lastMessage._id)
    }
  }, [messages, _chatId, lastProcessedMessageId, countRemove])

  const findLastMessage = (messages: Message[]): Message | undefined => {
    if (!messages || messages.length === 0) return undefined

    const sortedMessages = messages.slice().sort((a, b) => {
      return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    })

    return sortedMessages[0]
  }

  const lastMessage = findLastMessage(messages)

  useEffect(() => {
    if (lastMessage?.content) {
      dispatch(_setUnseenCount(_chatId as any))
    }
  }, [_chatId, lastMessage])

  useEffect(() => {
    if (!isLoading && allMessages) {
      setMessageLoading(false)
    }
  }, [allMessages, isLoading])

  useEffect(() => {
    if (topOfChatRef.current) {
      topOfChatRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [scrollLimit])

  return (
    <Fragment>
      {!_chatId ? (
        <DummyChatSecreen />
      ) : (
        <Fragment>
          {loading || isFetching ? (
            <CircularProgress
              style={{
                color: "pink",
                fontSize: 24,
                position: "absolute",
                left: "50%",
                top: loadingMore ? "none%" : "50%",
              }}
              size={24}
            />
          ) : (
            <Fragment>
              {messages.length ? (
                messages.map((message: Message, index: number) => {
                  const isAdmin = message.sender.userType === "PROVIDER"

                  return (
                    <div
                      key={index}
                      className={`${styles["messagesss"]} ${
                        isAdmin ? styles["align-self-end"] : ""
                      }`}
                      style={{
                        filter:
                          selectedUser?.status === "CLOSED" ? "blur(4px)" : "",
                      }}
                    >
                      <ChatImage
                        imageUpload={message}
                        isProvider={isAdmin as any}
                      />
                      {isAdmin ? (
                        <>
                          <div
                            className={`position-relative right-2 ${styles["user"]} d-flex justify-content-end align-self-end gap-2 ms-5 mt-2`}
                          >
                            <div
                              className={`${
                                message.content !== undefined &&
                                styles["user-message"]
                              } ${
                                message.content !== undefined &&
                                styles["message"]
                              } ${
                                message.content !== undefined &&
                                styles["user-message"]
                              }`}
                            >
                              <div className={`${styles["message-content"]}`}>
                                {""}
                              </div>
                              <div className={`${styles["lh-11"]} text-start`}>
                                {/* <span className={`${styles["views-text"]} text-white`}>
                    <FiCheck />
                  </span> */}

                                <span
                                  className={`${styles["views-text"]} text-white`}
                                >
                                  {message.content}
                                </span>
                              </div>
                            </div>
                            <div className={styles["dialouge-img"]}>
                              {/* <img src={message.profilePic} /> */}
                            </div>
                          </div>
                          <div className={styles["chat-time"]}>
                            <CalculateTimeDifferenceChat
                              createdAts={message.createdAt}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div
                            className={`d-flex flex-column position-relative ${styles["chat-message"]}`}
                          >
                            <div
                              className={`position-absolute px-sm-1 pb-1 ${styles["chat-imgbx-sm"]} position-relative`}
                            >
                              {/* <img src={Logo} /> */}
                              <div
                                className={styles["profile-pic-placeholder"]}
                              ></div>
                            </div>
                            <div
                              className={`${styles["message"]} ${styles["other-message"]}`}
                            >
                              <div className={styles["message-content"]}>
                                {message.content}
                              </div>
                              <div className={`${styles["lh-11"]} text-end`}>
                                <span className={styles["views-text"]}></span>
                              </div>
                            </div>
                            <div className={styles["other-msg-time"]}>
                              <CalculateTimeDifferenceChat
                                createdAts={message.createdAt}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )
                })
              ) : (
                <NullScreen />
              )}
              <div />
              <div ref={scrollLimit <= 50 ? messagesEndRef : topOfChatRef} />
            </Fragment>
          )}
        </Fragment>
      )}
    </Fragment>
  )
}

export default memo(Dialogue)
