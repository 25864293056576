import React from "react"
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
  Typography,
} from "@mui/material"
import * as Style from "./Style"
import profileimage from "../../images/personal-info.svg"
import { useAppSelector } from "../../app/hooks"
import { useNavigate } from "react-router-dom"
import { useAppDispatch } from "../../app/store"
import {
  setLanguage,
  setStepsCount,
  setString,
} from "../../features/Auth/WrapperSlice"
const ProfileSaved = () => {
  const stringValue = useAppSelector((state) => state?.wrapper?.stringValue)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const clearFunnel = () => {
    dispatch(setString({}))
    dispatch(setStepsCount(""))
    dispatch(setLanguage(""))
    navigate("/")
  }

  return (
    <Style.PersonalInfo>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6} className="m-auto">
          <Card className="card-background">
            <CardContent>
              <Typography variant="h6" className="card-content">
                Your profile is saved
              </Typography>
              <Typography variant="h6" className="card-content pt-0">
                Lorem ipsum dolor sit amet consectetur. Diam purus felis massa
                id quisque rhoncus sed egestas. Adipiscing amet eget vel morbi.
              </Typography>
              <Button
                variant="contained"
                className="save-button"
                onClick={clearFunnel}
              >
                Next
              </Button>
            </CardContent>
            <Box className="profile-saved text-center">
              <img src={profileimage} className="profile-saved" />
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Style.PersonalInfo>
  )
}

export default ProfileSaved
