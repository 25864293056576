import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"

import {
  Message,
  Ticket,
  Tickets,
} from "../../components/ChatenterFace/chatinterface"
import { api } from "../Auth/authApi"

const slice = createSlice({
  name: "chatAuth",
  initialState: {
    _Tickets: [] as Ticket[],
    _searchedTickets: [] as Ticket[],
    _search: "" as string,
    _MessageUpdate: null as Message | null,
  } as { _Tickets: null | any; _MessageUpdate: null | any },
  reducers: {
    token: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },
    _setTicket: (state: any, action: any) => {
      const respTickets = Array.isArray(action.payload) ? action.payload : []

      // const respTickets = action.payload as Ticket[]
      if (state._Tickets.length < 1) {
        state._Tickets = [...respTickets]
      } else {
        const existingTicketsMap = new Map(
          state._Tickets.map((ticket: Ticket) => [ticket._id, ticket]),
        )
        respTickets.forEach((newTicket: any) => {
          if (existingTicketsMap.has(newTicket._id)) {
            existingTicketsMap.set(newTicket._id, newTicket)
          } else {
            existingTicketsMap.set(newTicket._id, newTicket)
          }
        })
        state._Tickets = Array.from(existingTicketsMap.values())
      }
    },
    _setMessage: (state: any, action: any) => {
      const payload =
        typeof action?.payload === "object" && action?.payload !== null
          ? [action?.payload]
          : []
      const newCount = action?.payload
      let messages: Message[] = Array.isArray(payload) ? [...payload] : []
      messages = messages.flat(10)
      state._Tickets.map((ticket: Ticket) => {
        messages.map((msg: Message) => {
          if (ticket._id === msg.ticketId) {
            const ifMessageExist: any = ticket.messages?.find(
              (each: Message) => each._id === msg._id,
            )
            if (!ifMessageExist) {
              if (
                msg?.delivered_status == true ||
                msg?.delivered_status == false
              ) {
                ticket.messages = [...ticket.messages, msg]
                return
              }

              if (
                newCount?.ticketId == ticket._id &&
                newCount?.seenStatus &&
                msg.content
              ) {
                ticket.unSeen = 0
                ticket.updatedAt = msg.createdAt
                ticket.messages = [...ticket.messages, msg]
                return
              }
              ticket.unSeen += 1
              ticket.updatedAt = msg.createdAt
              ticket.messages = [...ticket.messages, msg]
              state._Tickets = removeFromArray(state._Tickets, ticket)
              state._Tickets = [ticket].concat(state._Tickets)
            } else {
              if (msg?.delivered_status === true) {
                return
              }
              if (newCount?.ticketId == ticket._id && newCount?.seenStatus) {
                ticket.unSeen = 0
              }
              const index = ticket.messages.findIndex(
                (each: Message) => each._id === msg._id,
              )
              ticket.messages.splice(index, 1)
              ticket.messages.push(msg)
              // else {
              //   const index = ticket.messages.findIndex(
              //     (each: Message) => each._id === msg._id,
              //   )
              //   ticket.messages[index] = msg
              // }
              // ticket.unSeen = ticket.messages.filter(
              //   (msg: Message) =>
              //     msg.reciever.userType === "PROVIDER" &&
              //     msg.seenStatus === false,
              // ).length
            }
          }
        })
      })
    },

    // _setUnseenCount: (state: any, action: any | null) => {
    //   const ticket: Ticket = state._Tickets.find(
    //     (ticket: Ticket) => ticket?._id == action?.payload,
    //   )
    //   if (ticket) {
    //     ticket.unSeen = 0
    //   }
    // },

    _setUnseenCount: (state, action: PayloadAction<string>) => {
      const ticketId = action.payload

      state._Tickets = state._Tickets.map((ticket: Ticket) => {
        if (ticket._id === ticketId) {
          return { ...ticket, unSeen: 0 }
        }
        return ticket
      })
    },
    _setSearchTickets: (state: any, action: any) => {
      const lowerSearchText = action.payload.toLowerCase()
      state._search = lowerSearchText
      if (lowerSearchText.split().length === "") return
      const matchedObjects = [] as Ticket[]
      state._Tickets.forEach((data: Ticket) => {
        const adminName = (data as any).adminstration?.name?.toLowerCase()
        if (adminName?.includes(lowerSearchText)) {
          matchedObjects.push(data)
          return
        }
        const serviceName = data.postId.serviceDetails.name.toLowerCase()
        if (serviceName.includes(lowerSearchText)) {
          matchedObjects.push(data)
          return
        }
      })
      state._searchedTickets = matchedObjects
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.LogoutProvider.matchFulfilled,
      (state, { payload }) => {
        state._Tickets = []
        state._MessageUpdate = null
      },
    )
  },
})

function removeFromArray(array: Array<any>, ...items: any[]) {
  let what
  let a = items
  let L = a.length
  let ax

  while (L && array.length) {
    what = a[--L]
    while ((ax = array.indexOf(what)) !== -1) {
      array.splice(ax, 1)
    }
  }
  return array
}
export default slice.reducer
export const {
  token,
  _setTicket,
  _setMessage,
  _setSearchTickets,
  _setUnseenCount,
} = slice.actions

export const selectCurrentUser = (state: RootState) => state?.chatAuth
