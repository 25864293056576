import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import approvedImg from "../../images/babysitting.png"
import { CiSearch } from "react-icons/ci"
import * as Style from "./style"
import { Link, useNavigate } from "react-router-dom"
import { Button, Spinner } from "react-bootstrap"
import { Theme, useTheme } from "@mui/material/styles"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import OutlinedInput from "@mui/material/OutlinedInput"
import { FaChevronLeft, FaStar } from "react-icons/fa6"
import tableCellImg from "../../images/table-img.png"
import {
  useAccountProfileQuery,
  useGetAvailablePostQuery,
} from "../../features/Auth/authApi"
import moment from "moment"
import dummy from "../../images/dummy-image.jpg"
import { TbArrowsSort } from "react-icons/tb"
import CenteredSpinner from "../common/CenteredSpinner"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import RatingStars from "../common/rating"
import { MdOutlineLocationOn } from "react-icons/md"
import { useAppDispatch } from "../../app/store"
import { appliedPostKey, setnPagesElect } from "../../features/Auth/authSlice"
import noData from "../../images/no-results.svg"
import { useAppSelector } from "../../app/hooks"
import { clockClasses } from "@mui/x-date-pickers"

const MyJobs = () => {
  const [personName, setPersonName] = React.useState<String>()
  const [pageSelect, setPageSelect] = useState<string>("")
  const [selectedPage, setSelectedPage] = useState(0)
  const [dateShortList, setDateListShort] = useState(1)
  const { data: serviceDetails } = useAccountProfileQuery({})
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const pageNoSelected = useAppSelector((state) => state.auth.pagesElect)

  const handleChange = (event: any, name: any) => {
    if (name == "service") {
      setPersonName(event.target.value)
    } else if (name == "page") {
      setPageSelect(event.target.value)
    } else {
      setSelectedPage(null as any)
    }
  }

  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  useEffect(() => {
    dispatch(setnPagesElect(selectedPage as any))
  }, [selectedPage])

  const {
    data: availabeJobs,
    isLoading,
    isFetching,
  } = useGetAvailablePostQuery({
    serviceId: personName == "All" ? "" : personName,
    pageNo: pageNoSelected,
    pageLimit: pageSelect,
    createdAt: dateShortList,
  })

  const handleDateShort = () => {
    const newValue = dateShortList === 1 ? -1 : 1
    setDateListShort(newValue)
  }

  useEffect(() => {
    setPageSelect(availabeJobs?.totalDocument?.pageLimit?.toString())
    if (pageNoSelected) {
      setSelectedPage(pageNoSelected)
    }
  }, [pageNoSelected, availabeJobs])

  return (
    <>
      <Style.Overview>
        <Box>
          <Box className="d-flex align-items-center justify-content-between">
            <Box className="d-flex flex-none w-100 max-w-550  mt-5">
              {/* <TextField
                  id="outlined-basic"
                  className="search-field"
                  label="Search"
                  variant="outlined"
                />
                <Button variant="contained" className="search-btn">
                  <CiSearch />
                </Button> */}
            </Box>

            <>
              <div className="w-100 me-2 display-contents">
                <FormControl
                  sx={{
                    m: 1,
                    mt: 3,
                  }}
                  className="custom-field"
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ backgroundColor: "#f9f9f9" }}
                  >
                    Services
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label "
                    id="demo-simple-select"
                    value={personName}
                    onChange={(event) => handleChange(event, "service" as any)}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 250,
                          width: 150,
                          overflowY: "auto", // Enable vertical scrolling
                          scrollbarWidth: "thin", // Width of the scrollbar
                          scrollbarColor: "#f07fa9 transparent", // Color of the scrollbar thumb
                          borderRadius: "12px", // Border radius of the scrollbar thumb
                          cursor: "pointer", // Change cursor to pointer
                        },
                      },
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      transformOrigin: {
                        vertical: "top",
                        horizontal: "left",
                      },
                    }}
                  >
                    <MenuItem value={"All"}>{"All"}</MenuItem>
                    {serviceDetails?.data?.services?.map((item: any) => (
                      <MenuItem key={item?._id} value={item?._id}>
                        {item?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="w-100 display-contents">
                <FormControl
                  sx={{ m: 1, maxWidth: 200, mt: 3 }}
                  className="custom-field view-field"
                >
                  <InputLabel id="demo-simple-select-label">View</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={pageSelect}
                    onChange={(event) => handleChange(event, "page" as any)}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </>
          </Box>
          {availabeJobs?.data?.length > 0 && (
            <Box>
              <Typography align="left" variant="h2" mt={2}>
                Jobs for you
              </Typography>
            </Box>
          )}
          <Box>
            <Style.Overview>
              <TableContainer
                component={Paper}
                className="dashboard-table-wrapper custom-scrollbar "
              >
                <Table
                  sx={{
                    minWidth: 650,
                    border: "none",
                    backgroundColor: "transparent",
                    position: "relative",
                  }}
                  className="dashboard-table "
                  aria-label="simple table"
                >
                  {availabeJobs?.data?.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell align="center"></TableCell>

                        <TableCell align="center">Name</TableCell>
                        <TableCell align="center">Service required</TableCell>

                        <TableCell align="center">Phone Number</TableCell>
                        <TableCell align="center">
                          Location <MdOutlineLocationOn />
                        </TableCell>
                        <TableCell align="center" onClick={handleDateShort}>
                          Starts on <TbArrowsSort />
                        </TableCell>
                        <TableCell align="center">Action</TableCell>
                      </TableRow>
                    </TableHead>
                  )}

                  {isLoading ? (
                    <CenteredSpinner />
                  ) : (
                    <TableBody>
                      {availabeJobs?.data?.length > 0 ? (
                        availabeJobs?.data?.map((row: any) => (
                          <TableRow
                            key={row.serviceseeker}
                            sx={{
                              backgroundColor: "#fff",
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              navigate(
                                `/dashboard/job-detail/${
                                  row?._id
                                }?type=${"applied-job"}`,
                                dispatch(appliedPostKey(null as any)) as any,
                              )
                            }}
                          >
                            <TableCell>
                              <div
                                style={{
                                  width: "74px",
                                  height: "74px",
                                  objectFit: "cover",
                                }}
                              >
                                <img
                                  src={row.seekerData?.profilePic || dummy}
                                  alt="User"
                                  style={{
                                    width: "74px",
                                    height: "74px",
                                    objectFit: "cover",
                                  }}
                                />
                              </div>
                            </TableCell>

                            <TableCell
                              component="td"
                              align="center"
                              scope="row"
                            >
                              <span className="text flex-column gap-2">
                                {row.seekerData?.name + " "}
                                {row?.seekerData?.rating?.$numberDecimal !==
                                "0" ? (
                                  <span className="minh-1">
                                    <RatingStars
                                      rating={
                                        row?.seekerData?.rating?.$numberDecimal
                                      }
                                    />
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </TableCell>

                            <TableCell align="center">
                              <span>{row?.service?.name}</span>
                            </TableCell>
                            <TableCell align="center">
                              <span>{row?.seekerData?.contactNo}</span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="location">
                                <span>{row.location}</span>
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span className="datetime">
                                <span className="d-flex flex-column">
                                  <span className="weekday">
                                    {/* {activePost?.data.availabeJobs} */}
                                    <FormatDate dateString={row?.from} />
                                  </span>
                                  {/* <span> */}
                                  <span>
                                    <FormatChangeTime dateString={row?.from} />
                                  </span>
                                  <span>
                                    <FormatTime dateString={row?.from} />
                                  </span>
                                </span>
                              </span>
                            </TableCell>
                            <TableCell align="center">
                              <span>
                                <Link
                                  to={`/dashboard/job-detail/${row?._id}`}
                                  className="view-profile"
                                  onClick={() =>
                                    dispatch(appliedPostKey(null as any))
                                  }
                                >
                                  Apply
                                </Link>
                              </span>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <TableRow
                          sx={{
                            height: "50vh",
                            backgroundColor: "white",
                          }}
                        >
                          {" "}
                          <TableCell
                            align="center"
                            colSpan={7}
                            sx={{
                              width: "100%",
                              pointerEvents: "none",
                              border: "none",
                            }}
                          >
                            <img
                              src={noData}
                              style={{
                                width: "100%",
                                maxWidth: "180px",
                              }}
                            />
                            <span
                              className="no-data-text"
                              style={{
                                fontSize: "20PX",
                                fontWeight: "600",
                                color: "#000",
                              }}
                            >
                              No data found
                            </span>
                          </TableCell>
                        </TableRow>
                      )}
                    </TableBody>
                  )}
                </Table>
              </TableContainer>
              {availabeJobs?.data?.length > 0 && (
                <Box className="d-md-flex justify-content-between mt-2">
                  <Typography variant="body1" className="w-auto white-space">
                    {" "}
                    Showing {availabeJobs?.totalDocument?.showingData} -{" "}
                    {availabeJobs?.totalDocument?.showingEnd}
                  </Typography>
                  <Grid container justifyContent="flex-end">
                    <Grid item md={3}>
                      <Box display="flex" className="flex-column">
                        <Stack spacing={2} className="mb-3">
                          <Pagination
                            count={availabeJobs?.totalDocument?.totalPages}
                            color="secondary"
                            className="custom-pagination "
                            page={availabeJobs?.totalDocument?.pageNo}
                            onChange={handlePageChange}
                            sx={{
                              "& .MuiPaginationItem-page.Mui-selected": {
                                backgroundColor: "#F475A4",
                                boxSizing: "border-box",
                              },
                              "& .css-wjh20t-MuiPagination-ul": {
                                flexWrap: "nowrap",
                                justifyContent: "end",
                              },
                              "& .css-nhb8h9": {
                                flexWrap: "nowrap",
                                justifyContent: "end",
                              },
                            }}
                          />
                        </Stack>
                        {/* <Typography className="page-text">
                                Showing {availabeJobs?.totalDocument?.pageNo} -
                                {availabeJobs?.totalDocument?.totalPages} of
                                list
                              </Typography> */}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Style.Overview>
          </Box>
        </Box>
      </Style.Overview>
    </>
  )
}

export default MyJobs
