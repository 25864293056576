import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import io from "socket.io-client"
import { RootState, store } from "../../app/store"
import { toast } from "react-toastify"
export const notificationsApi = createApi({
  reducerPath: "notificationsApi",
  baseQuery: fetchBaseQuery({ baseUrl: "/" }),
  tagTypes: ["notifications", "activejobs"],
  endpoints: (builder) => ({
    getNotifications: builder.query<any, any>({
      queryFn: (arg: string | null) => ({ data: [] }), // Dummy query to satisfy RTK Query
      async onCacheEntryAdded(
        token,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        // const token = (store.getState() as RootState).auth.token
        if (!token) {
          return
        }
        const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL
        const socket = io(`${BASE_URL}/base?token=${token}`)

        socket.on("notifications", (notifications: any) => {
          toast.success(notifications?.content, {
            autoClose: 5000,
          })
          updateCachedData((draft) => {
            draft.push(notifications)
          })
        })
        socket.on("ping", () => {
          socket.emit("pong") // Respond with pong to server
        })
        socket.on("active-jobs-data", (activejobsdata: any) => {
          updateCachedData((draft) => {
            draft.push(activejobsdata)
          })
        })

        await cacheDataLoaded
        await new Promise((resolve) => socket.on("disconnect", resolve))

        cacheEntryRemoved.then(() => {
          socket.off("notifications")
        })
      },
      providesTags: ["notifications", "activejobs"],
    }),
  }),
})

export const { useGetNotificationsQuery } = notificationsApi
