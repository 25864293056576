import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react"
import { Button, Stack } from "react-bootstrap"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import Badge from "react-bootstrap/Badge"
import styles from "./ChatUi.module.css"
import LockIcon from "../../images/lock.svg"
import profileImg from "../../images/dummy-image.jpg"
import chatlistimg from "../../images/chatlistimg.svg"

import {
  CalculateTimeDifference,
  CalculateTimeDifferenceChat,
} from "../Notification/timeNotification"
import { useAppSelector } from "../../app/hooks"

import { Ticket, Tickets } from "../ChatenterFace/chatinterface"
import {
  _setMessage,
  _setTicket,
  _setUnseenCount,
} from "../../features/ChatAuth/chatSlice"
import {
  chatViewApi,
  useGetChatsMessageCountMutation,
  useGetChatsMessageQuery,
} from "../../features/ChatAuth/chatApi"
import { RootState, useAppDispatch } from "../../app/store"
import { CircularProgress, debounce } from "@mui/material"
import NullScreen from "../common/NullScreen"
import { setnewMessage } from "../../features/OverViewAuth/overViewSlice"
import { BsFillInfoCircleFill } from "react-icons/bs"
import ChatDeatilsModal from "./ChatModal"
import moment from "moment"

interface searchProps {
  searchQuery: string
  setUserName: (value: string) => void
  setChatResponsive: (value: boolean) => boolean
}
const ChatList = ({
  searchQuery,
  setUserName,
  setChatResponsive,
}: searchProps) => {
  const [scrollLimit, setScrollLimit] = useState<number>(8)
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [searchParams] = useSearchParams()
  const _chatId: string | null = searchParams.get("chatId")

  // for chat details

  const [open, setOpen] = React.useState(false)
  const [onShowDeatils, setOnShowDetails] = useState<string>()
  const handleOpen = (info: any) => {
    setOnShowDetails(info)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const { data: userMessage, isLoading: messageIsloading } =
    useGetChatsMessageQuery(
      {
        pageNo: 1,
        pageLimit: scrollLimit,
        searchName: searchQuery,
        userType: "PROVIDER",
      },
      { refetchOnMountOrArgChange: true },
    )

  const [seenMessageCount, { data, isLoading }] =
    useGetChatsMessageCountMutation()

  const state = useAppSelector((state: any) => state.authChatView)
  const TicketPath: Ticket[] =
    state?._search?.split("").length > 0
      ? state?._searchedTickets
      : state?._Tickets

  useEffect(() => {
    if (userMessage?.data?.length > 0) {
      const newTickets: Ticket[] = userMessage.data.map(
        (each: any) => each as Ticket,
      )

      setUserName(userMessage?.data)
      dispatch(_setTicket(newTickets as any))
      // navigate(
      //   `/dashboard/help-center?chatId=${newTickets[0]?._id}&adminId=${
      //     (newTickets as any)[0]?.adminstration?._id
      //   }`,
      // )
    }
  }, [userMessage, dispatch])

  const handleActive = (idPass: any) => {
    const payload = {
      userType: "PROVIDER",
      ticketId: idPass,
    }
    setChatResponsive(true)

    seenMessageCount(payload)
    dispatch(setnewMessage(null as any))
  }
  useEffect(() => {
    dispatch(_setUnseenCount(_chatId as any))
  }, [_chatId])

  // scrol functionality

  const chatListRef = useRef<HTMLDivElement>(null)
  const handleScroll = useCallback(
    debounce(() => {
      if (chatListRef.current) {
        const { scrollTop, clientHeight, scrollHeight } = chatListRef.current
        if (scrollHeight - scrollTop <= clientHeight * 1.5) {
          // Near bottom of the scrollable area, load more data
          if (!loadingMore) {
            setLoadingMore(true)
            setScrollLimit((prevLimit) => prevLimit + 5)
          }
        }
      }
    }, 300),
    [loadingMore],
  )

  useEffect(() => {
    const ref = chatListRef.current
    ref?.addEventListener("scroll", handleScroll)

    return () => {
      ref?.removeEventListener("scroll", handleScroll)
    }
  }, [handleScroll])
  useEffect(() => {
    if (!messageIsloading && userMessage) {
      setLoadingMore(false)
    }
  }, [userMessage, messageIsloading])

  return (
    <>
      <div
        ref={chatListRef}
        className={`${styles["chat-list"]} ${styles["custom-scroll"]} ${styles["chat-selected"]} 
        position-relative cursor-pointer p-2 p-lg-3 px-lg-1 pt-4 mb-2 mt-1 bg-white ${styles["rounded-xl"]} overflow-y-auto`}
      >
        {messageIsloading ? (
          <CircularProgress
            style={{
              color: "pink",
              fontSize: 24,
              position: "absolute",
              left: "50%",
              top: "50%",
            }}
            size={24}
          />
        ) : (
          <>
            {TicketPath?.length ? (
              TicketPath?.map((chat: any, index: number) => (
                <Fragment key={index}>
                  <Stack
                    key={index}
                    as={Link}
                    to={`/dashboard/help-center?chatId=${chat._id}&adminId=${chat?.adminstration?._id}`}
                    direction="horizontal"
                    gap={0}
                    className={`mb-1 p-1 align-items-start position-relative text-decoration-none ${
                      chat._id == _chatId && styles["chat-list-bg"]
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => handleActive(chat._id)}
                  >
                    <div
                      className={`p-1 ps-0 d-flex ${styles["chat-imgbx"]} position-relative my-auto `}
                    >
                      <img
                        src={profileImg}
                        alt="profileImg"
                        title="profile img"
                      />

                      <img
                        src={chat?.postId?.serviceDetails?.image || chatlistimg}
                        alt="profileImg"
                        title="profile img"
                        className={`${styles["service-img"]}`}
                      />

                      {/* {chat?.unSeen !== 0 && chat?.status !== "CLOSED" && (
                        <div className={styles["active-indicator"]}>
                          {chat?.unSeen}
                        </div>
                      )} */}
                    </div>
                    {chat._id !== _chatId && (
                      <>
                        {chat?.unSeen !== 0 && (
                          <div className={styles["active-indicator"]}>
                            {chat?.unSeen}
                          </div>
                        )}
                      </>
                    )}
                    <div className="w-100 overflow-hidden">
                      <div className="d-flex ms-2 ms-xxl-3">
                        <div className={`p-2 ${styles["chat-msg"]}`}>
                          <div className="d-flex justify-content-start gap-1 align-items-center">
                            <h6 className="pb-0 m-0 text-truncate">
                              {chat?.adminstration?.name}
                            </h6>

                            <Button
                              style={{
                                backgroundColor: "unset",
                                border: "unset",
                              }}
                              className="p-0"
                              onClick={() => handleOpen(chat)}
                            >
                              {" "}
                              <BsFillInfoCircleFill
                                style={{ color: "#F475A4" }}
                              />
                            </Button>
                            <ChatDeatilsModal
                              onShowDeatils={onShowDeatils as any}
                              open={open}
                              handleClose={handleClose}
                            />
                            <div>
                              {chat?.postId?.status == "Active" && (
                                <Badge
                                  className={` ${styles["orange-badge"]} ${styles["green-text"]} ms-auto`}
                                >
                                  {chat?.postId?.status}
                                </Badge>
                              )}
                            </div>
                          </div>
                          <p className={`m-0 text-truncate `}>
                            {chat?.postId?.serviceDetails?.name}{" "}
                          </p>
                        </div>
                        {chat?.postId?.from && (
                          <div className="p-1 ms-auto position-absolute end-0">
                            <div className={styles["time-text"]}>
                              {/* {moment(chat?.postId?.from).format("DD,MM,YYYY")} */}
                              {/* <CalculateTimeDifferenceChat
                              createdAts={
                                chat?.messages
                                  ?.filter(
                                    (matchId: any) =>
                                      matchId?.ticketId == chat?._id,
                                  )
                                  ?.map((matchId: any) => matchId.createdAt)
                                  .pop() || null
                              }
                            /> */}
                              <CalculateTimeDifferenceChat
                                createdAts={chat.updatedAt}
                              />
                            </div>
                          </div>
                        )}
                        {chat?.status === "CLOSED" && (
                          <img
                            className={` ${styles["lock-icon"]} ms-auto`}
                            src={LockIcon}
                            style={{
                              width: "18px",
                              height: "18px",
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </Stack>
                </Fragment>
              ))
            ) : (
              <NullScreen />
            )}
          </>
        )}
        {loadingMore && (
          <CircularProgress
            style={{
              color: "pink",
              fontSize: 24,
              position: "absolute",
              left: "50%",
            }}
            size={24}
          />
        )}
      </div>
    </>
  )
}

export default ChatList
