import React from "react"
import styles from "./SelectedImage.module.css"

interface props {
  selectedImage: string | null
  handleClosePreview: () => void
}

export default function PreviewImage({
  selectedImage,
  handleClosePreview,
}: props) {
  return (
    <>
      {selectedImage && (
        <div className={styles.selectedImage}>
          <div className={styles.imageContainer}>
            <button onClick={handleClosePreview} className={styles.closeButton}>
              <span>×</span>
            </button>
            <img
              src={selectedImage}
              alt="preview"
              className={styles.previewImage}
            />
          </div>
        </div>
      )}
    </>
  )
}
