import * as React from "react"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import * as Style from "./style"
import {
  useGetActivePostApiQuery,
  useGetGenrateostApiQuery,
  useUpcomingAppointmentsQuery,
} from "../../features/OverViewAuth/overViewApi"
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  SelectChangeEvent,
} from "@mui/material"
import { FaStar } from "react-icons/fa6"

interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  className: string
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, className, ...other } = props
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  }
}

export default function BasicTabs() {
  const [value, setValue] = React.useState(0)
  const [age, setAge] = React.useState("")

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    setAge(event.target.value)
  }
  const { data: dataList } = useUpcomingAppointmentsQuery()
  const { data: activePost } = useGetActivePostApiQuery({})
  const { data: genRatePost } = useGetGenrateostApiQuery({})

  const rows = [
    createData(
      "Supriya Jain",
      "Emilia Antoine",
      "Ipsum dolor sit amet consectetur. Augue orci eleifend etiam laoreet.",
      "Baby sitting",
      "11 Nov, Mon 9:00AM - 10:00PM",
      "Active",
      "4.5",
      "3.5",
    ),
    createData(
      "Supriya Jain",
      "Emilia Antoine",
      "Ipsum dolor sit amet consectetur. Augue orci eleifend etiam laoreet.",
      "Baby sitting",
      "11 Nov, Mon 9:00AM - 10:00PM",
      "Completed",
      "4.5",
      "3.5",
    ),
    createData(
      "Supriya Jain",
      "Emilia Antoine",
      "Ipsum dolor sit amet consectetur. Augue orci eleifend etiam laoreet.",
      "Baby sitting",
      "11 Nov, Mon 9:00AM - 10:00PM",
      "Cancelled",
      "4.5",
      "3.5",
    ),
    createData(
      "Supriya Jain",
      "Emilia Antoine",
      "Ipsum dolor sit amet consectetur. Augue orci eleifend etiam laoreet.",
      "Baby sitting",
      "11 Nov, Mon 9:00AM - 10:00PM",
      "Active",
      "4.5",
      "3.5",
    ),
    createData(
      "Supriya Jain",
      "Emilia Antoine",
      "Ipsum dolor sit amet consectetur. Augue orci eleifend etiam laoreet.",
      "Baby sitting",
      "11 Nov, Mon 9:00AM - 10:00PM",
      "Completed",
      "4.5",
      "3.5",
    ),
    createData(
      "Supriya Jain",
      "Emilia Antoine",
      "Ipsum dolor sit amet consectetur. Augue orci eleifend etiam laoreet.",
      "Baby sitting",
      "11 Nov, Mon 9:00AM - 10:00PM",
      "Completed",
      "4.5",
      "3.5",
    ),
  ]
  function createData(
    serviceseeker: string,
    providerassign: string,
    location: string,
    servicerequired: string,
    datetime: string,
    requeststatus: string,
    rating: string,
    providerrating: string,
  ) {
    return {
      serviceseeker,
      providerassign,
      location,
      servicerequired,
      datetime,
      requeststatus,
      rating,
      providerrating,
    }
  }

  return (
    <>
      <Style.Overview>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ display: "flex" }}>
              <Tabs
                value={value}
                onChange={handleTabChange}
                className="book-tab-wrapper"
                aria-label="basic tabs example"
              >
                <Tab label="All" className="book-tab" {...a11yProps(0)} />
                <Tab label="Active" className="book-tab" {...a11yProps(1)} />
                <Tab label="Complete" className="book-tab" {...a11yProps(2)} />
                <Tab label="Cancelled" className="book-tab" {...a11yProps(3)} />
              </Tabs>
              <Box></Box>
            </Box>
            <Box>
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                className="book-selectbx"
                size="small"
              >
                <InputLabel id="demo-select-small-label">Age</InputLabel>
                <Select
                  labelId="book-select-label"
                  id="demo-select-small"
                  value={age}
                  label="Age"
                  onChange={handleSelectChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Ten</MenuItem>
                  <MenuItem value={20}>Twenty</MenuItem>
                  <MenuItem value={30}>Thirty</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <CustomTabPanel
            value={value}
            index={0}
            className={`tab-content-wrapper as`}
          >
            <Style.Overview>
              <TableContainer
                component={Paper}
                className="dashboard-table-wrapper custom-scrollbar"
              >
                <Table
                  sx={{
                    minWidth: 650,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  className="dashboard-table"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="service-head">
                        Service seeker
                      </TableCell>
                      <TableCell align="left" className="service-head">
                        Service provider assigned
                      </TableCell>
                      <TableCell align="center">Location</TableCell>
                      <TableCell align="center">Service</TableCell>
                      <TableCell align="center">Date & Time</TableCell>
                      <TableCell align="center">Request Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.serviceseeker}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" align="center" scope="row">
                          <span>
                            {row.serviceseeker}
                            <span>
                              {row.rating}
                              <FaStar />
                            </span>
                          </span>
                        </TableCell>
                        <TableCell component="th" align="center" scope="row">
                          <span>
                            {row.providerassign}
                            <span>
                              {row.providerrating}
                              <FaStar />
                            </span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="location">
                            <span>{row.location}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{row.servicerequired}</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="datetime">
                            <span>{row.datetime}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className={row.requeststatus}>
                            {row.requeststatus}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Style.Overview>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={1}
            className="tab-content-wrapper"
          >
            <Style.Overview>
              <TableContainer
                component={Paper}
                className="dashboard-table-wrapper custom-scrollbar"
              >
                <Table
                  sx={{
                    minWidth: 650,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  className="dashboard-table"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="service-head">
                        Service seeker
                      </TableCell>
                      <TableCell align="left" className="service-head">
                        Service provider assigned
                      </TableCell>
                      <TableCell align="center">Location</TableCell>
                      <TableCell align="center">Service</TableCell>
                      <TableCell align="center">Date & Time</TableCell>
                      <TableCell align="center">Request Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.serviceseeker}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" align="center" scope="row">
                          <span>
                            {row.serviceseeker}
                            <span>
                              {row.rating}
                              <FaStar />
                            </span>
                          </span>
                        </TableCell>
                        <TableCell component="th" align="center" scope="row">
                          <span>
                            {row.providerassign}
                            <span>
                              {row.providerrating}
                              <FaStar />
                            </span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="location">
                            <span>{row.location}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{row.servicerequired}</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="datetime">
                            <span>{row.datetime}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className={row.requeststatus}>
                            {row.requeststatus}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Style.Overview>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={2}
            className="tab-content-wrapper"
          >
            <Style.Overview>
              <TableContainer
                component={Paper}
                className="dashboard-table-wrapper custom-scrollbar"
              >
                <Table
                  sx={{
                    minWidth: 650,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  className="dashboard-table"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="service-head">
                        Service seeker
                      </TableCell>
                      <TableCell align="left" className="service-head">
                        Service provider assigned
                      </TableCell>
                      <TableCell align="center">Location</TableCell>
                      <TableCell align="center">Service</TableCell>
                      <TableCell align="center">Date & Time</TableCell>
                      <TableCell align="center">Request Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.serviceseeker}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" align="center" scope="row">
                          <span>
                            {row.serviceseeker}
                            <span>
                              {row.rating}
                              <FaStar />
                            </span>
                          </span>
                        </TableCell>
                        <TableCell component="th" align="center" scope="row">
                          <span>
                            {row.providerassign}
                            <span>
                              {row.providerrating}
                              <FaStar />
                            </span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="location">
                            <span>{row.location}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{row.servicerequired}</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="datetime">
                            <span>{row.datetime}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className={row.requeststatus}>
                            {row.requeststatus}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Style.Overview>
          </CustomTabPanel>
          <CustomTabPanel
            value={value}
            index={3}
            className="tab-content-wrapper"
          >
            <Style.Overview>
              <TableContainer
                component={Paper}
                className="dashboard-table-wrapper custom-scrollbar"
              >
                <Table
                  sx={{
                    minWidth: 650,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  className="dashboard-table"
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" className="service-head">
                        Service seeker
                      </TableCell>
                      <TableCell align="left" className="service-head">
                        Service provider assigned
                      </TableCell>
                      <TableCell align="center">Location</TableCell>
                      <TableCell align="center">Service</TableCell>
                      <TableCell align="center">Date & Time</TableCell>
                      <TableCell align="center">Request Status</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow
                        key={row.serviceseeker}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" align="center" scope="row">
                          <span>
                            {row.serviceseeker}
                            <span>
                              {row.rating}
                              <FaStar />
                            </span>
                          </span>
                        </TableCell>
                        <TableCell component="th" align="center" scope="row">
                          <span>
                            {row.providerassign}
                            <span>
                              {row.providerrating}
                              <FaStar />
                            </span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="location">
                            <span>{row.location}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{row.servicerequired}</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="datetime">
                            <span>{row.datetime}</span>
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className={row.requeststatus}>
                            {row.requeststatus}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Style.Overview>
          </CustomTabPanel>
        </Box>
      </Style.Overview>
    </>
  )
}
