import { Margin, Padding } from "@mui/icons-material"
import { styled } from "@mui/material"

const Overview = styled("div")(({ theme }) => ({
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
  },
  "& .MuiTypography-body3": {
    color: theme.palette.gray.main300,
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
    lineHeight: " 13px",
  },
  "& .date-field": {
    border: "1px solid rgba(214, 221, 235, 1)",
    borderRadius: "10px",
    fontSize: "14px",
    fontWeight: "500",
    padding: "10px",
  },
  "& .slots-tag": {
    border: "1px solid #929292",
    borderRadius: "10px",
    fontSize: "14px",
    fontWeight: "600",
    padding: "10px",
    color: "#929292",
    cursor: "default",
  },
  "& .slots-tag-2": {
    border: "1px solid #F475A4",
    borderRadius: "10px",
    fontSize: "14px",
    fontWeight: "600",
    padding: "10px",
    color: "#fff",
    cursor: "default",
    backgroundColor: theme.palette.pink.main,
     
  },
  "& .slot-booking-text-sm": {
    fontSize: "16px",
  },
  "& .day-text": {
    color: "#AAAAAA",
    fontWeight: "700",
    fontSize: "20px",
  },
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.pink.main,
    "& .MuiListItem-root ": {
      color: theme.palette.white.main,
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },
  "& .slot-indicator": {
    width: "18px",
    height: "18px",
    display: "inline-block",
    borderRadius: "50%",
    marginRight: "6px",
    "&.available": {
      backgroundColor: "#22B7F2",
    },
    "&.booked": {
      backgroundColor: "#1FDA00",
    },
    "&.freezed": {
      backgroundColor: "#FFA639",
    },
  },
  "& .timing-slot-card": {
    padding: "22px",
    border: "1px solid #E9E9E9",
    borderRadius: "16px",
    "& .date-text": {
      minWidth: "135px",
    },
    "& .timing-card": {
      display: "inline-block",
      padding: "10px 18px",
      borderRadius: "6px",
      border: "1px solid",
      "& p": {
        fontSize: "14px",
        fontFamily: "Montserrat",
        marginBottom: "0px",
      },
      "&.available-timing": {
        borderColor: "#22B7F2",
        "& p": {
          color: "#22B7F2",
        },
      },

      "&.booked-timing": {
        borderColor: "#1FDA00",
        "& p": {
          color: "#1FDA00",
        },
      },
      "&.freezed-timing": {
        borderColor: "#FFA639",
        "& p": {
          color: "#FFA639",
        },
      },
      "&:last-child": {
        marginRight: "0px",
      },
    },
  },
  "& .Advertisement": {
    borderRadius: " 16px",
    background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    "& .MuiButtonBase-root": {
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
    },
  },
  "& .custom-calendar ": {
  },
  
  "& .css-1q04gal-MuiDateCalendar-root"  : {
      borderRadius:"10px" ,
      margin:"0 0"  
   },
  
  "& .MuiPickersCalendarHeader-root"  : {
    backgroundColor: "#F475A4",
    color:"#fff",
    margin:"0px",
    padding:"24px"   
   },
  "& .css-14iq4xa-MuiDayCalendar-root"  : {
    backgroundColor: "#fff",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px"
    
   },
   "& .MuiDayCalendar-root .MuiDayCalendar-weekDayLabel"  : {
    color: "#F475A4",
   },
   "& .MuiDayCalendar-root .MuiPickersDay-root"  : {
    color: "#000",
    fontWeight:"600",
   },
   "& .css-1tkx1wf-MuiSvgIcon-root-MuiPickersCalendarHeader-switchViewIcon"  : {
    fill: "#fff"
   },
   "& .css-1vooibu-MuiSvgIcon-root"  : {
    fill: "#fff"
   },
   "& .css-1cw4hi4"  : {
    fill: "#fff!important"
   },
   "& .css-sldnni"  : {
    fill: "#fff!important"
   },
   "& .MuiDayCalendar-root"  : {
    backgroundColor: "#fff",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px"
     
   },
   "& .css-xpgtxt-MuiButtonBase-root-MuiPickersDay-root"  : {
     backgroundColor: "#fff !important",
     border:"1px solid #F475A4 !important",
     color:"#000 !important",
     borderRadius:"10px"
   },
   "& .css-11kg56p-MuiButtonBase-root-MuiPickersDay-root:focus"  : {
       backgroundColor:"F475A4",
  },

   "& .Mui-selected.MuiPickersDay-root "  : {
      backgroundColor: "#F475A4",
      color: "#fff",
      borderRadius: "10px"
   },
   "& .css-11kg56p-MuiButtonBase-root-MuiPickersDay-root.Mui-selected:hover "  : {
    backgroundColor: "#F475A4",
 },
 "& .css-msb2at-MuiPickersYear-yearButton.Mui-selected "  : {
  backgroundColor: "#F475A4",
},
"& .css-11kg56p-MuiButtonBase-root-MuiPickersDay-root:focus.Mui-selected "  : {
  backgroundColor: "#F475A4",
}, 

"& .custom-calendar": {
  "@media (max-width: 1320px)": {
    maxWidth: "250px",
    marginLeft:"14px",
  },
},
  "& .slot-card": {
    backgroundColor: theme.palette.white.main,
    padding: "37px 25px",
    borderRadius: "16px",
    "@media (max-width: 768px)": {
      width: "700px",
    },
  },
  "& .dark-divider": {
    borderColor: theme.palette.gray.main400,
  },
  "& .green-text": {
    color: "#00C14D",
  },
  "& .yellow-text": {
    color: theme.palette.yellow.main,
  },
  "& .red-text": {
    color: theme.palette.red.main,
  },
  "& .approved-card": {
    backgroundColor: theme.palette.white.main,
    padding: "9px 19px",
    border: "1px solid #00C14D",
    boxShadow: "0px 4px 20px 0px #00000008",
    borderRadius: "16px",
    "&.pending-card": {
      borderColor: theme.palette.yellow.main,
    },
    "&.rejected-card": {
      borderColor: theme.palette.red.main,
    },
  },
  "& .view-profile": {
    display: "inline-block",
    width: "135px",
    padding: "12px 10px",
    border: "1px solid #F475A4",
    borderRadius: "8px",
    color: "#F475A4",
    fontSize: "14px",
    fontFamily: "Montserrat",
    textAlign: "center",
    textDecoration: "none",
  },
  "& .dashboard-table-wrapper": {
    backgroundColor: "transparent",
    boxShadow: "none",
    "& .dashboard-table": {
      backgroundColor: "transparent",
      boxShadow: "none",
      "& thead": {
        "& th": {
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
        },
      },
      "& .service-head": {
        paddingLeft: "20px",
        paddingRight: "20px",
      },
      "& .datetime": {
        "& span": {
          width: "130px",
          lineHeight: "23px",
        },
      },
      "& .Pending": {
        color: "#FFA639",
      },
      "& .Assigned": {
        color: "#009C35",
      },
      "& .Rejected": {
        color: "#FE2323",
      },
      "& .Completed": {
        color: "#009C35",
      },
      "& .Cancelled": {
        color: "#FE2323",
      },
      "& .location": {
        "& span": {
          width: "300px",
          lineHeight: "23px",
        },
      },
      "& .action-btn": {
        gap: "20px",
      },
      "& tbody tr td:first-child": {
        width: "85px",
        "& div": {
          "& img": {
            width: "100%",
          },
        },
      },
      "& tbody tr td:last-child": {
        "& span": {
          borderRadius: "0px 16px 16px 0",
        },
      },
      "& td": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
        },
      },
      "& th": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          backgroundColor: "#fff",
          color: theme.palette.black,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          gap: "6px",
          padding: "0 20px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
          "& span": {
            minHeight: "auto",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
            fontSize: "12px",
            color: theme.palette.gray.main700,
            padding: 0,
            "& svg": {
              fill: theme.palette.yellow.main,
            },
          },
        },
      },
    },
  },
}))
const Flexbox = styled("div")(({ theme }) => ({
  display: "flex",
  columnGap: "15px",
  "& .MuiTypography-body2": {
    color: theme.palette.pink.main,
    fontFamily: "Nunito Sans",
    fontSize: " 18px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
    textAlign: "center",
    marginTop: "10px",
  },
  "& .MuiTypography-body1": {
    color: theme.palette.grey,
    fontFamily: "Montserrat",
    fontSize: " 19px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
    textAlign: "center",
  },
  "& .MuiTypography-body3": {
    color: theme.palette.gray.main300,
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
    lineHeight: " 13px",
  },
  "& .MuiTypography-body5": {
    color: theme.palette.gray.main300,
    fontFamily: "Montserrat",
    fontSize: " 10px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
  },
  "& .overview-card": {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "40px 27px",
    width: "auto",
    minWidth: "205px",
    borderRadius: "16px",
    transition: ".2s ease",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  "& .pink-text": {
    color: theme.palette.pink.main,
  },
}))
const Upcoming = styled("div")(({ theme }) => ({
  maxWidth: "500px",

  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .Headingbox": {
    borderRadius: "16px 16px 0 0",

    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
    padding: "15px",
  },
  "& .ContentBox": {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    background: theme.palette.white.main,
    "& .MuiGrid-root": {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "20px",
    },
    "& .MuiButton-root": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,
      fontFamily: "Montserrat",
    },
  },
}))
const Bookedlog = styled("div")(({ theme }) => ({
  padding: "45px 0",

  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
}))
const Toprated = styled("div")(({ theme }) => ({
  display: "flex",

  gap: "10px",
  img: {
    height: "113px",
    objectFit: "Cover",
    width: "100%",
    borderRadius: " 8px",
  },
  "& .flexdiv": {
    display: "flex",
    gap: "16px",
    padding: "16px",
    backgroundColor: theme.palette.white.main,
    borderRadius: "8px",
  },
  "& .MuiTypography-root ": {
    fontFamily: "Poppins",
    fontSize: " 12px",
    fontStyle: "normal",
    fontWeight: "300",
    lineHeight: " normal",
    textAlign: "start",
  },
}))
const Toprated1 = styled("div")(({ theme }) => ({
  padding: "45px 0",
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
}))
const TableAppointment = styled("div")(({ theme }) => ({
  "& .MuiTableCell-root": {
    fontFamily: " Nunito Sans",
    padding: "0",
    borderBottom: "0",
  },
  "th.MuiTableCell-root.MuiTableCell-head ": {
    color: "#7c7c7c",
    padding: "10px 0",
  },
  td: {
    background: theme.palette.white.main,
  },
}))

export {
  Overview,
  Flexbox,
  Upcoming,
  Bookedlog,
  Toprated,
  Toprated1,
  TableAppointment,
}
