import React from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import * as Style from "./style"
import profileImg from "../../images/babysitting.png"
import { FaChevronLeft, FaStar } from "react-icons/fa6"
import { useSeekerProfileQuery } from "../../features/Auth/authApi"
import { Spinner } from "react-bootstrap"
import dummy from "../../images/dummy-image.jpg"
import { useNavigate, useParams } from "react-router-dom"
import moment from "moment"
import CenteredSpinner from "../common/CenteredSpinner"
import RatingStars from "../common/rating"

const JobViewsDetails = () => {
  const { id } = useParams()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const type = urlSearchParams.get("type")
  const post = urlSearchParams.get("post")

  const { data: seekerDetails, isLoading } = useSeekerProfileQuery({
    seekerId: id,
  })
  const navigate = useNavigate()

  const currentDate = moment()
  const handleNavigate = () => {
    if (type == "applied-job") {
      navigate("/dashboard/applied-job")
    } else {
      navigate(`/dashboard/approved-detail/${post}`)
    }
  }

  return (
    <>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <Style.Overview>
          <Button className="back-btn mb-0" onClick={handleNavigate}>
            <FaChevronLeft />
          </Button>
          <Grid container className="justify-content-center">
            <Grid xs={12} lg={7} className="">
              <Box className="w-100 mb-0 profile-banner-img">
                <Typography className="py-4 text-center Headingbox">
                  <strong> Profile Page </strong>
                </Typography>
              </Box>
              <Box className="review-card p-4 mt-0 d-flex flex-column align-items-center  single-review-card">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={7} sx={{ order: { xs: 2, sm: 0 } }}>
                    <Grid container className="mb-4">
                      <Grid md={5}>
                        <Typography variant="body1" className="pe-2">
                          <strong> Email </strong>
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Typography variant="body1">
                          {seekerDetails?.data?.seekerData?.email || "-----"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="mb-4">
                      <Grid md={5}>
                        <Typography variant="body1" className="pe-2">
                          <strong> Phone number </strong>
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Typography variant="body1">
                          {seekerDetails?.data?.seekerData?.contactNo ||
                            "-----"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="mb-4">
                      <Grid md={5}>
                        <Typography variant="body1" className="pe-2">
                          <strong> Rating </strong>
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Typography
                          variant="body1"
                          className="  gap-2 justify-content-center align-items-center mb-1"
                          style={{ color: "gold" }}
                        >
                          <RatingStars
                            rating={
                              seekerDetails?.data?.seekerData?.rating
                                ?.$numberDecimal
                            }
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* <Grid container className="mb-4">
                      <Grid md={5}>
                        <Typography variant="body1" className="text-dark">
                          Age
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Typography variant="body1" className="text-dark">
                          28 yrs
                        </Typography>
                      </Grid>
                    </Grid> */}
                    <Grid container className="mb-4">
                      <Grid md={5}>
                        <Typography variant="body1" className="pe-2">
                          <strong> Gender </strong>
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Typography variant="body1">
                          {seekerDetails?.data?.seekerData?.gender || "-----"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="mb-4">
                      <Grid md={5}>
                        <Typography variant="body1" className="pe-2">
                          <strong> Language </strong>
                        </Typography>
                      </Grid>
                      <Grid md={7}>
                        <Typography variant="body1">
                          {seekerDetails?.data?.seekerData?.language?.join(
                            " ",
                          ) || "-----"}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid container className="mb-4">
                      <Grid md={5}>
                        <Typography variant="body1" className="pe-2">
                          <strong> Location </strong>
                        </Typography>
                      </Grid>
                      <Grid md={4}>
                        <Typography variant="body1">
                          {seekerDetails?.data?.seekerData?.address?.location ||
                            "-----"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={5} sx={{ order: { xs: 1, sm: 0 } }}>
                    <Box className="mb-3 text-center">
                      <img
                        src={
                          seekerDetails?.data?.seekerData?.profilePic || dummy
                        }
                        className="profile-img "
                        style={{
                          width: "100px",
                          height: "auto",
                          borderRadius: "12px",
                        }}
                      />
                    </Box>
                    <Typography
                      className="profile-heading mb-3 text-center"
                      style={{ fontWeight: "bold", fontSize: "16px" }}
                    >
                      {seekerDetails?.data?.seekerData?.name || "-----"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container className=" py-0">
                  <Grid md={12}>
                    <Typography
                      variant="body1"
                      align="left"
                      className=" mb-3 pe-2"
                    >
                      <strong> Reviews </strong>
                    </Typography>
                    <Grid container className="mb-4">
                      {seekerDetails?.data?.seekersReview?.map(
                        (reviews: any, index: number) => (
                          <React.Fragment key={index}>
                            <Grid
                              xs={12}
                              className="border mb-1 rounded p-1"
                            >
                              <Box className="inner-review-card my-3">
                                <Box className="d-flex gap-2 justify-content-between align-items-center mb-4">
                                  <Box className="d-flex gap-2 align-items-center">
                                    <img
                                      src={
                                        reviews?.providerData?.profilePic ||
                                        dummy
                                      }
                                      className="inner-profile-img"
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "12px",
                                      }}
                                    />
                                    <Typography variant="body1">
                                      {reviews?.providerData?.name}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="body1"
                                      className="d-flex gap-2 align-items-center"
                                      style={{ color: "gold" }}
                                    >
                                      <RatingStars rating={reviews?.rating} />
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant="body1">
                                      {currentDate.diff(
                                        moment(reviews?.updatedAt),
                                        `days`,
                                      )}{" "}
                                      days ago
                                    </Typography>
                                  </Box>
                                </Box>
                                <Box>
                                  <Typography
                                    variant="body1"
                                    style={{ fontSize: "13px" }}
                                  >
                                    {reviews?.message}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>
                            <Grid xs={1}></Grid>
                          </React.Fragment>
                        ),
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Style.Overview>
      )}
    </>
  )
}
export default JobViewsDetails
