import { styled } from "@mui/material"

const Upcoming = styled("div")(({ theme }) => ({
  marginBottom: "20px",
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
  },
  "& .Headingbox": {
    borderRadius: "16px 16px 0 0",

    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
    padding: "15px",
  },
  "& .ContentBox": {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    background: theme.palette.white.main,
    "& .MuiGrid-root": {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "20px",
    },
    "& .MuiButton-root": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,
      fontFamily: "Montserrat",
    },
  },
  "& .flex-none": {
    flex: "none",
  },
  "& .max-w-550": {
    maxWidth: "550px",
  },
  "& .search-field": {
    width: "88%",
    flex: "none",
    "& label.Mui-focused": {
      color: theme.palette.pink.main,
    },
    "& label.MuiFormLabel-filled": {
      color: theme.palette.pink.main,
    },
    "& input": {
      padding: "16px 20px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      borderRight: "none",
      borderRadius: "11px 0px 0 11px",
      borderColor: theme.palette.pink.main,
      "&.Mui-focused": {
        borderColor: theme.palette.pink.main,
      },
    },
  },
  "& .search-btn": {
    backgroundColor: theme.palette.pink.main,
    borderRadius: "0px 11px 11px 0px",
    boxShadow: "none",
    "& svg": {
      width: "25px",
      height: "25px",
    },
  },
}))
const Overview = styled("div")(({ theme }) => ({
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 20px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: " normal",
  },
  "& .photo-upload": {
    width: "60px",
    height: "60px",
    objectFit: "cover",
    borderRadius: "8px",
  },
  "& .status-text-2": {
    fontSize: "20px",
    fontWeight: "400",
    fontFamily: "Montserrat",
    border: "1px solid #dddddd",
    boxShadow: "rgba(0, 0, 0, 0.23) 0px 4px 25px 0px",
    padding: "10px",
    borderRadius: "8px",
    "@media (max-width: 600px)": {
      fontSize: "14px",
      padding: "5px",
    },
    color: theme.palette.black.main,
    "& .completed": {
      color: theme.palette.green.main,
    },
    "& .cancelled": {
      color: theme.palette.red.main,
    },
  },
  "& .green-heading": {
    color: "#00c14d",
    fontSize: "36px",
    fontWeight: "600",
    lineHeight: "34px",
  },
  "& .Headingbox": {
    borderRadius: "16px 16px 0 0",
    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
    padding: "15px",
  },
  "& .display-contents": {
    display: "contents",
  },
  "& .custom-field": {
    width: "100%",
    maxWidth: "200px",
  },
  "& .view-field": {
    minWidth: "100px",
    maxWidth: "100px",
  },
  "& .custom-field label": {
    backgroundColor: "#fff",
    maxWidth: "calc(100% - 40px)",
  },
  "& .custom-field .MuiSelect-outlined": {
    backgroundColor: "#fff",
    borderRadius: "20px",
  },
  "& .custom-field .MuiInputBase-root": {
    borderRadius: "20px",
  },
  "& .custom-field fieldset": {
    border: "1px solid transparent",
    transition: "all 0.4s ease",
  },
  "& .MuiTypography-body1": {
    color: theme.palette.gray.main700,
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
    lineHeight: " 16px",
    "&.pink-text": {
      color: theme.palette.pink.main,
    },
    "& .pink-text": {
      color: theme.palette.pink.main,
    },
    "& .font-20": {
      fontSize: "20px",
    },
  },
  "& .search-field": {
    width: "88%",
    flex: "none",
    "& label.Mui-focused": {
      color: theme.palette.pink.main,
    },
    "& label.MuiFormLabel-filled": {
      color: theme.palette.pink.main,
    },
    "& input": {
      padding: "16px 20px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      backgroundColor: theme.palette.white.main,
      color: theme.palette.black.main,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      borderRight: "none",
      borderRadius: "11px 0px 0 11px",
      borderColor: theme.palette.pink.main,
      "&.Mui-focused": {
        borderColor: theme.palette.pink.main,
      },
    },
  },
  "& .search-btn": {
    backgroundColor: theme.palette.pink.main,
    borderRadius: "0px 11px 11px 0px",
    boxShadow: "none",
    "& svg": {
      width: "25px",
      height: "25px",
      fill: theme.palette.white.main,
    },
  },
  "& .max-w-550": {
    maxWidth: "550px",
  },
  "& .write-review-card": {
    padding: "38px",
    borderRadius: "30px",
    backgroundColor: theme.palette.white.main,
  },
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.pink.main,
    "& .MuiListItem-root ": {
      color: theme.palette.white.main,
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },
  "& .reviewradio": {
    display: "none",
  },
  "& .reviewlabel": {
    display: "flex",
    flexDirection: "column",
    padding: "10px",
    borderRadius: "10px",
    transition: ".4s",
    minWidth: "160px",
    cursor: "pointer",
    gap: "13px",
    alignItems: "center",
    "& .feedback-img": {
      width: "57px",
    },
    "& .label-text": {
      fontSize: "20px",
      color: theme.palette.black.main,
      transition: ".4s",
    },
  },
  "& .view-profile": {
    display: "inline-block",
    minWidth: "80px",
    padding: "12px 10px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.pink.main,
    fontFamily: "Montserrat",
    color: theme.palette.pink.main,
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "none",
    transition: "all 0.3s ease",
    "@media (max-width: 1300px)": {
      padding: "12px 3px",
      fontSize: "12px",
    },
    "&:hover": {
      backgroundColor: theme.palette.pink.main, // Apply hover background color
      color: "white", // Change text color on hover
      cursor: "pointer", // Change cursor to pointer on hover
    },
  },
  "& .review-field": {
    "& label.Mui-focused": {
      color: theme.palette.pink.main,
    },
    "& label.MuiFormLabel-filled": {
      color: theme.palette.pink.main,
    },
    "& input": {
      padding: "16px 20px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      borderColor: "#ADADAD",
      "&.Mui-focused": {
        borderColor: "#ADADAD",
      },
    },
  },
  "& .reviewradio:checked + .reviewlabel": {
    backgroundColor: theme.palette.pink.main,
    "& .label-text": {
      fontSize: "20px",
      color: theme.palette.white.main,
    },
  },
  "& .Advertisement": {
    borderRadius: " 16px",
    background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    "& .MuiButtonBase-root": {
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
    },
  },
  "& .seeker-card": {
    padding: "16px",
    background: theme.palette.white.main,
    borderRadius: "20px",
    "& .listing-seeker": {
      borderTop: "1px solid #e3e3e3",
    },
  },
  "& .assign-btn": {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "400",
    backgroundColor: theme.palette.green.main,
    color: theme.palette.white.main,
    boxShadow: "none",
    fontFamily: "Montserrat",
    padding: "15px 10px",
    textTransform: "capitalize",
  },
  "& .rejected-btn": {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "400",
    backgroundColor: "transparent",
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    boxShadow: "none",
    fontFamily: "Montserrat",
    padding: "15px 10px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.red.main,
      color: theme.palette.white.main,
    },
    "&.reject-fill-btn": {
      backgroundColor: theme.palette.red.main,
      color: theme.palette.white.main,
    },
  },
  "& .reject-card": {
    padding: "30px 42px",
    background: theme.palette.white.main,
    borderRadius: "20px",
    "& .yes-btn": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      backgroundColor: "transparent",
      color: theme.palette.gray.main500,
      borderColor: theme.palette.gray.main500,
      boxShadow: "none",
      fontFamily: "Montserrat",
      padding: "7px 10px",
      textTransform: "capitalize",
    },
    "& .no-btn": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      backgroundColor: theme.palette.pink.main,
      color: theme.palette.white.main,
      borderColor: theme.palette.pink.main,
      boxShadow: "none",
      fontFamily: "Montserrat",
      padding: "7px 10px",
      textTransform: "capitalize",
    },
  },
  "& .back-btn": {
    backgroundColor: "#F4F4F4",
    minWidth: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    marginBottom: "21px",
    "&.step-back-btn": {
      position: "absolute",
      top: "30px",
      left: "50px",
    },
    "& svg": {
      fill: "#3A3A3A",
    },
    "&.profile-back": {
      position: "absolute",
      top: 0,
      left: 0,
    },
  },
  "& .status-text": {
    "&.pending": {
      color: "#FFA639",
    },
    "&.assigned": {
      color: "#009C35",
    },
    "&.rejected": {
      color: "#FE2323",
    },
    "&.completed": {
      color: "#009C35",
    },
    "&.cancelled": {
      color: "#FE2323",
    },
  },
  "& .service-text": {
    fontSize: "20px",
    fontWeight: "400",
    fontFamily: "Montserrat",
    color: theme.palette.black.main,
  },
  "& .timeline-btn": {
    padding: "9px 25px",
    fontSize: "16px",
    fontWeight: "600",
    fontFamily: "Montserrat",
    color: "#ffffff",
    border: "1px solid #f475a4",
    backgroundColor: "#f475a4",
    borderRadius: "8px",
    textTransform: "capitalize",
    "& svg": {
      marginLeft: "9px",
      fill: "#A0A0A0",
    },
  },
  "& .feature-card": {
    backgroundColor: theme.palette.white.main,
    borderRadius: "16px",
    boxShadow: "0px 4px 20px 2px #00000008",
    "& .feature-card-img img": {
      width: "120px",
      borderRadius: "16px 0 0 16px    ",
    },
    "& .font-15": {
      fontSize: "15px",
    },
    "& .feature-card-content": {
      padding: "30px",
    },
    "& .field-label": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
      fontWeight: "700",
      "& svg": {
        fill: theme.palette.pink.main,
      },
      "&.age-label": {
        marginTop: "-25px",
        fontSize: "14px",
        fontWeight: "300",
      },
      "&.pink-text": {
        fontSize: "15px",
        color: theme.palette.pink.main,
        fontWeight: "500",
      },
    },
    "& .total-time input": {
      color: "#F475A4!important",
      background: "#F6F6F6",
      fontWeight: "600!important",
    },

    "& .feature-field": {
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
      "& .MuiInputBase-input": {
        fontSize: "16px",
        border: "1px solid #C1C1C1",
        padding: "16px 21px",
        borderRadius: "11px",
        color: theme.palette.black.main,
        fontFamily: "Montserrat",
        fontWeight: "400",
      },
      "&.title_field": {
        "& .MuiInputBase-input": {
          fontSize: "20px",
        },
      },
      "&.font-14": {
        "& .MuiInputBase-input": {
          padding: "5px 10px 28px",
          maxWidth: "94px",
          fontSize: "14px",
          textAlign: "center",
        },
      },
    },
    "& .person-text": {
      fontSize: "20px",
      color: theme.palette.pink.main,
      fontWeight: "500",
      fontFamily: "Montserrat",
    },
  },
  "& .service-detail-box-lg": {
    background: theme.palette.white.main,
    borderRadius: "16px",
    border: "1px solid #EDEDED",
  },
  "& .applied-btn": {
    background: theme.palette.pink.main,
    color: "#fff",
  },
  "& .captalize": {
    textTransform: "capitalize",
  },
  "& .profile-img-2": {
    width: "228px",
    height: "225px",
    borderRadius: "10%",
    objectFit: "cover",
    "@media (max-width: 1100px)": {
      width: "80px",
      height: "80px",
    },
  },
  "& .custom-pagination": {
    "& li": {
      "& button": {
        width: "32px",
        height: "32px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.white.main,
        boxShadow: "0px 10px 10px 0px #0000000D",
        border: "1px solid",
        borderColor: theme.palette.white.main,
        fontSize: "11px",
        margin: "0 4px",
        color: theme.palette.black.main,
        "&.MuiPaginationItem-page.Mui-selected": {
          backgroundColor: theme.palette.white.main,
          borderColor: theme.palette.pink.main,
        },
        "&.MuiPaginationItem-previousNext": {
          backgroundColor: theme.palette.pink.main,
          color: theme.palette.white.main,
          borderColor: theme.palette.pink.main,
        },
      },
      "&:first-child": {
        "&.MuiPaginationItem-previousNext": {
          marginRight: "19px",
        },
      },
      "&:last-child": {
        "&.MuiPaginationItem-previousNext": {
          marginLeft: "19px",
        },
      },
    },
  },
  "& .white-space": {
    whiteSpace: "nowrap",
  },
  "& .dashboard-table-wrapper": {
    backgroundColor: "transparent",
    boxShadow: "none",

    "& .dashboard-table": {
      backgroundColor: "transparent",
      boxShadow: "none",
      "& thead": {
        "& th": {
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 15px",
          fontStyle: "normal",
          fontWeight: "600",
          lineHeight: " 13px",
          minWidth: "66px",
        },
      },
      "& .minh-1": {
        minHeight: "1px",
      },
      "& .service-head": {},

      "& .datetime": {
        "& span": {
          width: "130px",
          lineHeight: "23px",
          minHeight: "21px",
        },
        "& .weekday": {
          fontWeight: "bold",
          color: "#777",
        },
      },
      "& .Pending": {
        color: "#FFA639",
      },
      "& .Assigned": {
        color: "#009C35",
      },
      "& .Rejected": {
        color: "#FE2323",
      },
      "& .Completed": {
        color: "#009C35",
      },
      "& .Cancelled": {
        color: "#FE2323",
      },
      "& .location": {
        "& span": {
          width: "180px",
          lineHeight: "23px",
          fontSize: "12px",
        },
      },
      "& .language": {
        maxWidth: "184px",
        fontSize: "12px",
      },
      "& .action-btn": {
        gap: "20px",
      },
      "& tbody tr ": {
        borderRadius: "16px ",
        overflow: "hidden",
        backgroundColor: "#fff",
        boxShadow: "0px 8px 6px -3px #00000008",
        transition: "all 0.4s ease",
      },
      "& tbody tr:hover ": {
        backgroundColor: "#f1f1f1",
      },
      "& tbody tr td:first-child": {
        width: "85px",
        borderRadius: "16px 0 0 16px ",
        overflow: "hidden",
        "& div": {
          "& img": {
            width: "100%",
          },
        },
      },
      "& tbody tr td:last-child": {
        "& span": {
          borderRadius: "0px 16px 16px 0",
        },
      },
      "& td": {
        padding: 0,
        paddingRight: "10px",
        // borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "transparent",
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 13px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
        },
      },
      "& th": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          backgroundColor: "transparent",
          color: theme.palette.black,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          gap: "6px",
          padding: "0 20px",
          // boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
          "& span": {
            minHeight: "auto",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
            fontSize: "12px",
            color: theme.palette.gray.main700,
            padding: 0,
            "& svg": {
              fill: theme.palette.yellow.main,
            },
          },
        },
      },
    },
  },
  "& .timeline-graph": {
    "& .MuiTimelineConnector-root": {
      display: "none",
    },
    "& .MuiTimelineDot-root": {
      margin: 0,
      width: "65px",
      height: "65px",
      borderRadius: "16px",
      padding: "0",
      border: "none",
      backgroundColor: theme.palette.white.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      boxShadow: "0px 4px 21px 0px #00000008",
      "& .timeline-step": {
        width: "40px",
        height: "40px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: " 9px",
        backgroundColor: theme.palette.pink.main,
        fontSize: "16px",
        fontWeight: "500",
        fontFamily: "Montserrat",
        color: theme.palette.white.main,
      },
    },
    "& .timeline-content": {
      // maxWidth: "500px",
      borderRadius: "16px",
      backgroundColor: theme.palette.white.main,
      padding: "17px 24px",
      marginTop: "-27px",
    },
    "& ul": {
      position: "relative",
      "&:before": {
        content: '""',
        position: "absolute",
        top: "10px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "1px",
        height: "100%",
        backgroundColor: "#AAAAAA",
      },
      "& li": {
        marginBottom: "60px",
        "@media (max-width: 600px)": {
          marginBottom: "20px",
        },
        "&:nth-child(2n)": {
          "& .timeline-content": {
            marginLeft: "auto",
          },
        },
      },
    },
    "& .timeline-heading": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "400",
      color: theme.palette.pink.main,
      marginBottom: "12px",
    },
    "& .timeline-desc": {
      fontSize: "16px",
      fontFamily: "Montserrat",
      fontWeight: "400",
      color: "#818181",
      marginBottom: "12px",
      lineHeight: "19px",
    },
  },
  "& .review-card": {
    background: theme.palette.white.main,
    borderRadius: "0px 0 16px 16px",
    boxShadow: "0px 4px 20px 2px #00000008",
    "& .reviewimg": {
      height: "74px",
      objectFit: "cover",
      width: "85px",
      borderRadius: "16px 0 0 16px",
    },
    "& .review-stars svg": {
      fill: "gold",
      "&.active ": {
        fill: theme.palette.yellow.main,
      },
    },

    "& .review-text": {
      maxWidth: "443px",
      lineHeight: "20px",
      display: "-webkit-box",
      overflow: "hidden",
      WebkitBoxOrient: "vertical",
      WebkitLineClamp: 2,
      textAlign: "left",
    },
  },
  "& .progressBar": {
    maxWidth: "100%",
    height: "15px",
    backgroundColor: theme.palette.pink.main,
    borderRadius: "14px",
  },
}))
const Flexbox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  // "& .MuiTypography-body2": {
  //     color: theme.palette.grey.main200,
  //     fontFamily: "Nunito Sans",
  //     fontSize: " 14px",
  //     fontStyle: "normal",
  //     fontWeight: "400",
  //     lineHeight: " 18px",
  // },
  "& .MuiTypography-body1": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
  },
  "& .Status.MuiBox-root": {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "20px",
    borderRadius: "16px",
    width: "110px",
  },
  " & .approved-card": {
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "20px",
    borderRadius: "16px",
    width: "370px",
    backgroundColor: "white",
  },
  " & .email-icon": {
    fontSize: "19px",
  },
  " & .applied-button": {
    backgroundColor: "transparent",
    border: "1px solid #F475A4",
    color: "#F475A4",
    padding: "10px 50px",
    borderRadius: "10px",
  },
  " & .cancel-button": {
    backgroundColor: "#F2F2F2",
    padding: "10px 50px",
    borderRadius: "10px",
    color: "#979797",
  },
  " & .successfull-content": {
    color: "#00C14D",
    fontSize: "20px",
  },
  " & .notification-text": {
    fontSize: "18px",
    margin: "10px 0px",
  },
  " & .canceled-button": {
    backgroundColor: "#D81A1A",
    padding: "10px 50px",
    borderRadius: "10px",
    color: "white",
  },
  " & .yes-button": {
    backgroundColor: "red",
  },
  "& .cancel-content": {
    fontSize: "18px",
    color: "#D81A1A",
  },
  "& .applied-card": {
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "20px",
    borderRadius: "16px",
    width: "340px",
    backgroundColor: "white",
    border: "1px solid #FFA639",
  },
  "& .approved-card": {
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "20px",
    borderRadius: "16px",
    width: "340px",
    backgroundColor: "white",
    border: "1px solid #00C14D",
  },
}))

export { Upcoming, Overview, Flexbox }
