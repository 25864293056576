import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Grid,
  Stack,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material"
import { FaStar } from "react-icons/fa6"
import * as Style from "./style"
import {
  useAppliedJobsQuery,
  useJobHistoryQuery,
} from "../../features/Auth/authApi"
import { Button, Spinner } from "react-bootstrap"
import { CiSearch } from "react-icons/ci"
import moment from "moment"
import dummy from "../../images/dummy-image.jpg"
import noData from "../../images/no-results.svg"
import { TbArrowsSort } from "react-icons/tb"
import { Link, useNavigate } from "react-router-dom"

import RatingStars from "../common/rating"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import CenteredSpinner from "../common/CenteredSpinner"

import { useAppDispatch } from "../../app/store"
import { FaChevronLeft } from "react-icons/fa"

const JobApplied = () => {
  const [selectedPage, setSelectedPage] = useState(1)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {
    data: appliedJobs,
    isLoading,
    isFetching,
  } = useAppliedJobsQuery({ pageNo: selectedPage })

  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  const trackServiceHandle = (event: any, rowData: any) => {
    const target = event.target

    if (target.classList.contains("view-profile")) {
      navigate(
        `/dashboard/applied-job-details/${
          rowData?.postData?.seekerDetails?._id
        }?type=${"applied-job"}`,
      )
    } else {
      navigate(`/dashboard/jobs-details/${rowData?._id}`)
    }
  }

  return (
    <>
      <Style.Overview>
        <Box className="d-flex justify-content-between align-items-center pb-5">
          <Box className="d-flex align-items-center gap-4"></Box>
          {appliedJobs?.data?.length > 0 && (
            <Box>
              <Typography className="status-text-2 mb-0">
                Status-{" "}
                <span className=" fw-bold" style={{ color: "#ff7100" }}>
                  Pending
                </span>
              </Typography>
            </Box>
          )}
        </Box>
        {isLoading || isFetching ? (
          <CenteredSpinner />
        ) : (
          <>
            <TableContainer
              component={Paper}
              className="dashboard-table-wrapper custom-scrollbar"
            >
              <Table
                sx={{
                  minWidth: 650,
                  border: "none",
                  backgroundColor: "transparent",
                }}
                className="dashboard-table"
                aria-label="simple table"
              >
                {appliedJobs?.data?.length > 0 && (
                  <TableHead>
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell align="center" className="service-head">
                        Name
                      </TableCell>
                      <TableCell align="center">Service</TableCell>
                      <TableCell align="center">Start Time</TableCell>
                      <TableCell align="center">Applied At</TableCell>
                      <TableCell align="center">End Time</TableCell>
                      <TableCell align="center">Status</TableCell>
                      <TableCell align="center">Action</TableCell>
                    </TableRow>
                  </TableHead>
                )}
                <TableBody>
                  {appliedJobs?.data?.length > 0 ? (
                    appliedJobs?.data?.map((row: any) => (
                      <TableRow
                        key={row._id}
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                            cursor: "pointer",
                          },
                        }}
                        onClick={(e) => trackServiceHandle(e, row)}
                      >
                        <TableCell>
                          <div
                            style={{
                              width: "74px",
                              height: "74px",
                              objectFit: "cover",
                            }}
                          >
                            <img
                              src={
                                row.postData?.seekerDetails?.profilePic || dummy
                              }
                              style={{
                                width: "74px",
                                height: "74px",
                                objectFit: "cover",
                              }}
                            />
                          </div>
                        </TableCell>
                        <TableCell component="td" align="center" scope="row">
                          <span className="text flex-column gap-2">
                            {row.postData?.seekerDetails?.name + " "}
                            <span className="minh-1">
                              <RatingStars
                                rating={
                                  row?.postData?.seekerDetails?.rating
                                    ?.$numberDecimal
                                }
                              />
                            </span>{" "}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span>{row?.postData?.jobDetails?.name}</span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="datetime">
                            <span className="d-flex flex-column">
                              <span className="weekday">
                                <FormatDate dateString={row?.postData?.from} />
                              </span>
                              {/* <span> */}
                              <span>
                                <FormatChangeTime
                                  dateString={row?.postData?.from}
                                />{" "}
                              </span>
                              <span>
                                <FormatTime dateString={row?.postData?.from} />
                              </span>
                            </span>
                            {/* </span> */}
                          </span>
                        </TableCell>
                        <TableCell align="center">
                          <span className="datetime">
                            <span className="d-flex flex-column">
                              <span className="weekday">
                                <FormatDate
                                  dateString={row?.concerned?.appliedAt}
                                />{" "}
                              </span>
                              {/* <span> */}
                              <span>
                                <FormatChangeTime
                                  dateString={row?.concerned?.appliedAt}
                                />{" "}
                              </span>
                              <span>
                                <FormatTime
                                  dateString={row?.concerned?.appliedAt}
                                />{" "}
                              </span>
                            </span>
                            {/* </span> */}
                          </span>
                        </TableCell>

                        {/* 3rdcell */}
                        <TableCell align="center">
                          <span className="datetime">
                            <span className="d-flex flex-column">
                              <span className="weekday">
                                <FormatDate dateString={row?.postData?.till} />
                              </span>
                              {/* <span> */}
                              <span>
                                <FormatChangeTime
                                  dateString={row?.postData?.till}
                                />
                              </span>
                              <span>
                                <FormatTime dateString={row?.postData?.till} />
                              </span>
                            </span>
                            {/* </span> */}
                          </span>
                        </TableCell>

                        <TableCell align="center">
                          <span>{row?.postData?.status}</span>
                        </TableCell>

                        <TableCell align="center">
                          <div>
                            <Link
                              to={`/dashboard/applied-job-details/${
                                row?.postData?.seekerDetails?._id
                              }?type=${"applied-job"}`}
                              // class change nai krni ager krni ta dono side toh
                              className="view-profile"
                            >
                              View profile
                            </Link>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <>
                      <TableRow
                        sx={{
                          height: "70vh",
                          backgroundColor: "white",
                        }}
                      >
                        <TableCell
                          align="center"
                          colSpan={8}
                          sx={{
                            width: "100%",
                            pointerEvents: "none",
                            border: "none",
                          }}
                        >
                          <img
                            src={noData}
                            style={{
                              width: "100%",
                              maxWidth: "180px",
                            }}
                          />
                          <span className="no-data-text"style={{
                                  fontSize: "20PX",
                                  fontWeight: "600",
                                  color: "#000",
                                }}
                          >No data found</span>
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {appliedJobs?.data?.length > 0 && (
              <Box className="d-md-flex justify-content-between pt-2">
                <Box>
                  <Typography variant="body1" className="w-auto white-space">
                    {" "}
                    Showing {appliedJobs?.totalDocument?.showingData} -{" "}
                    {appliedJobs?.totalDocument?.totalCount} Total
                  </Typography>
                </Box>

                <Grid container justifyContent="flex-end">
                  <Grid item md={3}>
                    <Box display="flex" className="flex-column">
                      <Stack spacing={2} className="mb-3">
                        <Pagination
                          count={appliedJobs?.totalDocument?.totalPages}
                          color="secondary"
                          className="custom-pagination"
                          page={appliedJobs?.totalDocument?.pageNo}
                          onChange={handlePageChange}
                          sx={{
                            "& .MuiPaginationItem-page.Mui-selected": {
                              backgroundColor: "#F475A4",
                              boxSizing: "border-box",
                            },
                            "& .MuiPagination-ul": {
                              justifyContent: "end",
                            },
                            "& .css-wjh20t-MuiPagination-ul": {
                              flexWrap: "nowrap",
                              justifyContent: "end",
                            },
                          }}
                        />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
                {/* <Box>
                <Stack spacing={2} className="mb-3">
                  <Pagination
                    count={appliedJobs?.totalDocument?.totalPages}
                    color="secondary"
                    className="custom-pagination "
                    page={appliedJobs?.totalDocument?.pageNo}
                    onChange={handlePageChange}
                    sx={{
                      "& .MuiPaginationItem-page.Mui-selected": {
                        backgroundColor: "#F475A4",
                        boxSizing: "border-box",
                      },
                    }}
                  />
                </Stack>
              </Box> */}
              </Box>
            )}
          </>
        )}
      </Style.Overview>
    </>
  )
}

export default JobApplied
