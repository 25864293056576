import React from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Paper,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import {
  useGetActivePostApiQuery,
  useGetGenrateostApiQuery,
  useUpcomingAppointmentsQuery,
} from "../../features/OverViewAuth/overViewApi"
import { Table } from "react-bootstrap"
import tableCellImg from "../../images/table-img.png"
import { Upcoming } from "@mui/icons-material"
import singleSeekerImg from "../../images/img-rounded.png"
import { FaStar } from "react-icons/fa6"
import { IoMailOutline } from "react-icons/io5"
import { FiPhone } from "react-icons/fi"
import { SlLocationPin } from "react-icons/sl"
import { Link } from "react-router-dom"

const SeekerProvider = () => {
  const { data: dataList } = useUpcomingAppointmentsQuery()
  const { data: activePost } = useGetActivePostApiQuery({})
  const { data: genRatePost } = useGetGenrateostApiQuery({})

  const rows = [
    createData(
      tableCellImg,
      "Supriya Jain",
      "hi@emiliaantoine.com",
      "+643452345",
      "25",
      "Eng/French",
      "4.5",
    ),
    createData(
      tableCellImg,
      "Supriya Jain",
      "hi@emiliaantoine.com",
      "+643452345",
      "25",
      "Eng/French",
      "4.5",
    ),
    createData(
      tableCellImg,
      "Supriya Jain",
      "hi@emiliaantoine.com",
      "+643452345",
      "25",
      "Eng/French",
      "4.5",
    ),
    createData(
      tableCellImg,
      "Supriya Jain",
      "hi@emiliaantoine.com",
      "+643452345",
      "25",
      "Eng/French",
      "4.5",
    ),
    createData(
      tableCellImg,
      "Supriya Jain",
      "hi@emiliaantoine.com",
      "+643452345",
      "25",
      "Eng/French",
      "4.5",
    ),
  ]
  function createData(
    userimage: string,
    name: string,
    phone: string,
    email: string,
    age: string,
    language: string,
    rating: string,
  ) {
    return { userimage, name, phone, email, age, language, rating }
  }
  return (
    <>
      {" "}
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Style.Overview>
            <Box>
              <Typography variant="body1" className="text-center pt-2 pb-3">
                Service Seeker
              </Typography>
              <Box className="seeker-card">
                <Box className="d-flex gap-2 align-items-center mb-3">
                  <Box>
                    <img src={singleSeekerImg} className="seeker-img" />
                  </Box>
                  <Box>
                    <Typography variant="body1" className="mb-2 text-black">
                      Supriya Jain
                    </Typography>
                    <Typography variant="body1" className="mb-2">
                      <IoMailOutline /> Lisa23@gmail.com
                    </Typography>
                    <Typography variant="body1">
                      <FiPhone /> 5647892635
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box className="py-3 listing-seeker">
                    <Typography variant="body1">Service Seeker</Typography>
                  </Box>
                </Box>
                <Box>
                  <Box className="py-3 listing-seeker">
                    <Typography variant="body1">
                      Date & Time - Wed, 10 Nov | 6:00am-9:10pm
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box className="py-3 listing-seeker">
                    <Typography variant="body1">
                      Number of provider - 2
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box className="py-3 listing-seeker">
                    <Typography variant="body1">
                      Age of provider - 24yrs - 30yrs
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box className="py-3 listing-seeker">
                    <Typography variant="body1">
                      Language of provider - Eng/French
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box className="py-3 listing-seeker">
                    <Typography variant="body1" className="d-flex gap-2">
                      <SlLocationPin /> Location: ipsum dolor sit amet
                      consectetur. Augue orci eleifend etiam laoreet.{" "}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" className="mt-4">
                  Post at 8th june, 10:50 am
                </Typography>
              </Box>
              <Box className="d-flex gap-2 mt-3">
                <Button variant="contained" className="w-100 assign-btn">
                  Assign
                </Button>
                <Button variant="outlined" className="w-100 rejected-btn">
                  Reject
                </Button>
              </Box>
              <Box className="reject-card mt-4">
                <Typography
                  variant="body1"
                  className="text-black text-center mb-3"
                >
                  Do you want to reject a service request?
                </Typography>

                <Box className="d-flex gap-2 mt-3 justify-content-center">
                  <Button variant="outlined" className="yes-btn">
                    Yes
                  </Button>
                  <Button variant="contained" className="no-btn">
                    No
                  </Button>
                </Box>
              </Box>
              <Box className="mt-3">
                <Button variant="contained" className="assign-btn px-5">
                  {" "}
                  Provider Assigned
                </Button>
              </Box>
              <Box className="mt-3">
                <Button
                  variant="outlined"
                  className="rejected-btn reject-fill-btn px-5"
                >
                  Request Rejected
                </Button>
              </Box>
            </Box>
          </Style.Overview>
        </Grid>
        <Grid item xs={8}>
          <Style.Overview>
            <TableContainer
              component={Paper}
              className="dashboard-table-wrapper"
            >
              <Table
                style={{
                  minWidth: 650,
                  border: "none",
                  backgroundColor: "transparent",
                }}
                className="dashboard-table"
                aria-label="simple table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align="left" className="service-head">
                      Name
                    </TableCell>
                    <TableCell align="center">Email</TableCell>
                    <TableCell align="center">Phone</TableCell>
                    <TableCell align="center">Age</TableCell>
                    <TableCell align="center">Language</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell>
                        <Box>
                          <img src={row.userimage} />
                        </Box>
                      </TableCell>
                      <TableCell component="th" align="center" scope="row">
                        <span>
                          {row.name}
                          <span>
                            {row.rating}
                            <FaStar />
                          </span>
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="email">
                          <span>{row.email}</span>
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span>{row.phone}</span>
                      </TableCell>
                      <TableCell align="center">
                        <span>{row.age}</span>
                      </TableCell>
                      <TableCell align="center">
                        <span className="language">
                          <span>{row.language}</span>
                        </span>
                      </TableCell>
                      <TableCell align="center">
                        <span>
                          <Link to="" className="view-profile">
                            View Profile
                          </Link>
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Style.Overview>
        </Grid>
      </Grid>
    </>
  )
}
export default SeekerProvider
