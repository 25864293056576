import React from "react"
import ChatErrorImg from "../../images/dummy-image.jpg"
import ErrorImg from "../../assets/img/table-error.png"
import NoChat from "../../images/NoChat.png"
import styles from "./ChatUi.module.css"
const NullScreen = () => {
  return (
    <>
      {" "}
      <div className="errorpage-wrapper">
        <div className="no-chat-history">
          <img
            src={NoChat}
            className="img-fluid"
            title="errorImg"
            alt="errorImg"
            width="200"
            height="150"
          />
          <h2
            style={{
              fontSize: "20px",
              color: "#F475A4",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            No chats Found{" "}
          </h2>
        </div>
      </div>
    </>
  )
}
export default NullScreen
