import { styled } from "@mui/material"

const Header = styled("div")(({ theme }) => ({
  "& .MuiIconButton-root": {
    color: theme.palette.black?.main,
  },
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.white?.main,
    backgroundImage: "none",
    boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.03)",
    color: theme.palette.black?.main,
  },
  "& .MuiAppBar-root": {
    boxShadow: "none",
    padding: "0",
  },
  "& .notification-btn1": {
    width: "43px",
    height: "43px",
    minWidth: "43px",
    backgroundColor: "#F475A4",
    borderColor: "#F475A4",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 480px)": {
      width: "35px",
      height: "35px",
      minWidth: "35px",
    },
  },
  "& .notification-btn1:hover": {

    backgroundColor: "#F475A4",
    borderColor: "#F475A4",
    borderRadius: "50%",

  },

  "& .notification-btn": {
    width: "43px",
    maxWidth: "43px",
    minWidth: "unset",
    height: "43px",
    backgroundColor: "#F1F1F1",
    borderColor: "#F1F1F1",
    borderRadius: "50%",

    "@media (max-width: 480px)": {
      width: "35px !important",
      height: "35px !important",
    },
    "&:focus": {
      backgroundColor: "#F1F1F1",
      borderColor: "#F1F1F1",
    },

    "& div": {
      width: "26px",
      "& img": {
        width: "100%",
        height: "auto",
        objectFit: "contain",
      },
    },
  },
}))

export { Header }
