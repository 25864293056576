import React, { useEffect, useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import styles from "./ChatUi.module.css"
import ChatList from "./ChatList"
import ChatArea from "./ChatArea"
import ChatBar from "./ChatBar"
import { useParams } from "react-router-dom"

const ChatWrapper = () => {
  const [searchQuery, setSearchQuery] = useState("")
  const [userName, setUserName] = useState([])
  const [chatResponsive, setChatResponsive] = useState(false)
  return (
    <div className={styles["chat-wrapper"]}>
      <div className="container">
        <Row className={`bg-white ${styles["rounded-xl"]}`}>
          <Col
            lg={5}
            xl={4}
            className={chatResponsive ? "d-none d-lg-block" : ""}
          >
            <ChatBar searchList={setSearchQuery} />
            <ChatList
              setUserName={setUserName as any}
              searchQuery={searchQuery}
              setChatResponsive={setChatResponsive as any}
            />
          </Col>
          <Col
            lg={7}
            xl={8}
            className={chatResponsive ? "d-block" : "d-none d-lg-block"}
          >
            {" "}
            <ChatArea
              userName={userName as any}
              setChatResponsive={setChatResponsive}
            />
          </Col>
        </Row>
      </div>
    </div>
  )
}

export default ChatWrapper
