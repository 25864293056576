import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material"
import Logo from "../../images/logo.png"
import {
  AiFillLock,
  AiFillUnlock,
  AiOutlineMail,
  AiOutlineUser,
} from "react-icons/ai"
import mommylogo from "../../images/login-logo.png"
import physioTherapy1 from "../../images/physioTherapy1.jpg"
import physioTherapy2 from "../../images/physioTherapy2.jpg"
import physioTherapy3 from "../../images/physioTherapy3.jpg"
import physioTherapy4 from "../../images/physioTherapy4.jpg"
import * as yup from "yup"
// import * as Style from "./style"
import { Controller, useForm } from "react-hook-form"
import { useUserRegisterMutation } from "../../features/Auth/authApi"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import CircularProgress from "@mui/material/CircularProgress"
import { useAppSelector } from "../../app/hooks"
import { toast } from "react-toastify"
import { useAppDispatch } from "../../app/store"
import {
  setLanguage,
  setStepsCount,
  setString,
} from "../../features/Auth/WrapperSlice"
import { onBoardKey } from "../../features/Auth/authSlice"
import { LocalPhone } from "@mui/icons-material"
interface signupProps {}
interface SignUpUser {
  name: string
  email: string
  password: string
  // confirmedPassword: string
  contactNo: string
}

const defaultValues = {
  name: "",
  email: "",
  password: "",
  // confirmedPassword: "",
  contactNo: "",
}

const Signup = ({}: signupProps) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const [showPassword, setShowPassword] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [loading, setLoading] = useState(false)
  const [userRegister, { isError, error, data }] = useUserRegisterMutation()
  const token = useAppSelector((state) => state.auth.token)
  const handleClickShowPassword = () => setShowPassword((show) => !show)
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup
        .string()
        .required("Please enter Name")
        .min(3, "Name should be of 3-25 characters only")
        .max(25, "Name should be of 3-25 characters only"),
      email: yup
        .string()
        .required("Please enter an email")
        .test("valid-email", "Please enter a valid email", (value) =>
          emailRegex.test(value),
        ),

      contactNo: yup
        .string()
        .required("Please enter your phone number")
        .min(6, "Phone Number must be at least 6 characters")
        .max(14, "Phone Number can't exceed 14 characters"),
      password: yup
        .string()
        .required("Please fill in a password")
        .test(
          "password-length",
          "Password must be 8 characters or more",
          (value: any) => value && (value as any).length >= 8,
        )
        .test(
          "password-strength",
          "Please choose a strong password. Try a mix of letters, numbers, and symbols",
          (value) =>
            /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%#*?&]{8,}$/.test(
              value,
            ),
        ),
    }),
  )

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SignUpUser>({
    resolver: schemaResolver,
  })

  const onSubmit = (values: any) => {
    dispatch(setString({}))
    dispatch(setStepsCount(""))
    dispatch(setLanguage(""))
    const payload = {
      name: values.name,
      region: values.region,
      email: values.email,
      password: values.password,
      contactNo: values.contactNo,
    }

    userRegister(payload)
    setLoading(true)
  }

  useEffect(() => {
    if (data?.message) {
      setLoading(false)

      toast.success(data?.message, { autoClose: 1000 })
      dispatch(onBoardKey("onboardingroute" as any))

      // setTimeout(() => {
      navigate("/provider-onboarding")
      // }, 500)
    } else {
      setLoading(false)
      toast.error((error as any)?.data?.message, { autoClose: 1000 })
    }
  }, [data, error])



  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          item
          lg={6}
          className="d-none d-lg-block"
          display="flex"
          alignItems="center"
        >
          <Box className="box-wrapper">
            <Typography
              sx={{
                textAlign: "center",
                color: " #858585",
                fontFamily: "Montserrat",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Welcome to
            </Typography>
            <Typography textAlign="center">
            <h1>Send A Therapist</h1>
              {/* <img src={mommylogo} width="300" height="auto" /> */}
            </Typography>
            <Box className="Imgbox" display="flex" flexWrap="wrap" gap={2}>
              <Box>
                <img src={physioTherapy1} className="login-left-img1"/>
              </Box>
              <Box mt={5}>
                <img src={physioTherapy2} className="login-left-img2"/>
              </Box>

              <Box>
                <img src={physioTherapy3} className="login-left-img3"/>
              </Box>

              <Box mt={5}>
                <img src={physioTherapy4} className="login-left-img4"/>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item lg={6}>
          <Box className="Loginform">
            <Typography
              variant="h4"
              mt={5}
              fontFamily="Montserrat"
              fontSize={22}
              fontWeight="600"
              color="pink.main"
              textAlign="center"
            >
              Provider's Sign up
            </Typography>

            <form onSubmit={handleSubmit(onSubmit)}>
              <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-name">Name</InputLabel>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }: any) => (
                    <OutlinedInput
                      id="outlined-adornment-name"
                      {...field}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <AiOutlineUser />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Name"
                      size="medium"
                    />
                  )}
                />
                {errors.name && (
                  <Typography variant="body2" color="error">
                    {errors.name.message}
                  </Typography>
                )}
              </FormControl>
              <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-email">
                  Email
                </InputLabel>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }: any) => (
                    <OutlinedInput
                      {...field}
                      type="email"
                      id="outlined-adornment-email"
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <AiOutlineMail />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="email"
                    />
                  )}
                />
                {errors.email && (
                  <Typography variant="body2" color="error">
                    {errors.email.message}
                  </Typography>
                )}
              </FormControl>

              <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-name">
                  Phone Number
                </InputLabel>
                <Controller
                  name="contactNo"
                  control={control}
                  render={({ field }: any) => (
                    <OutlinedInput
                      type="text"
                      inputMode="numeric"
                      {...field}
                      onKeyPress={(event) => {
                        const keyCode = event.keyCode || event.which
                        const keyValue = String.fromCharCode(keyCode)
                        const numericRegex = /^[0-9]+$/

                        if (!numericRegex.test(keyValue)) {
                          event.preventDefault()
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton edge="end">
                            <LocalPhone />
                          </IconButton>
                        </InputAdornment>
                      }
                      label="Phone Number"
                      size="medium"
                    />
                  )}
                />
                {errors.contactNo && (
                  <Typography variant="body2" color="error">
                    {errors.contactNo.message}
                  </Typography>
                )}
              </FormControl>

              <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>

                <Controller
                  name="password"
                  control={control}
                  render={({ field }: any) => (
                    <OutlinedInput
                      {...field}
                      id="outlined-adornment-password"
                      type={showPassword ? "text" : "password"}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <AiFillUnlock /> : <AiFillLock />}
                          </IconButton>
                        </InputAdornment>
                      }
                      label="password"
                    />
                  )}
                />
                {errors.password && (
                  <Typography variant="body2" color="error">
                    {errors.password.message}
                  </Typography>
                )}
              </FormControl>

              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ mt: 3, display: "block", cursor: "pointer" }}
                fullWidth
              >
                {loading ? (
                  <Typography fontSize={1}>
                    <CircularProgress style={{ color: "pink", fontSize: 23 }} size={23}  />
                  </Typography>
                ) : (
                  "Sign up"
                )}
              </Button>
            </form>
            <Box mt={3}>
              <Typography
                sx={{
                  color: "#7B7B7B",
                  margin: "8px 0 0 13px",
                  textAlign: "center",
                }}
              >
                Already have an account?{" "}
                <Typography
                  component="span"
                  sx={{ color: "#181818", cursor: "pointer" }}
                  onClick={() => navigate("/")}
                >
                  Sign in
                </Typography>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default Signup
