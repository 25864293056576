import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"
const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL

export const locationApi = createApi({
  reducerPath: "locationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/provider/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),
  // tagTypes: [],
  endpoints: (builder) => ({
    getLocation: builder.mutation<any, location>({
      query: (payload: location) => ({
        url: `get-userLocation?coordinates=${payload}`,
        method: "GET",
      }),
    }),
    getLocationSuggestion: builder.mutation({
      query: (payload: any) => ({
        url: `get-userLocation?search=${payload?.search || ""}&coordinates=${
          (payload as any)?.coordinates || ""
        }`,
        method: "GET",
      }),
    }),
  }),
})

export const { useGetLocationMutation, useGetLocationSuggestionMutation } =
  locationApi

export type location = {
  latitude: number
  longitude: number
}
