import {
  Palette ,
  PaletteOptions ,
} from '@mui/material/styles/createPalette';
import { createTheme } from '@mui/material/styles'

declare module '@mui/material/styles/createPalette' {
  interface Palette  {
    pink: {main: string};
    white: {main: string};
    black: {main: string};
    gray: {
      main: string,
      main100: string,
      main200: string,
      main300: string,
      main400:string,
      main500:string,
      main600:string,
      main700:string,
      main800:string,
    };
    green: {main: string};
    yellow: {main: string};
    red: {main: string};
  }

  interface PaletteOptions  {
    pink?: {main: string};
    green?: {main: string};
    black?: {main: string};
    white?: {main: string};
    red?: {main: string};
    gray?:   {main: string,
    main100: string,
    main200: string,
    main300: string,
    main400:string,
    main500:string,
    main600:string,
    main700:string,
    main800:string,
    };
    yellow?: {main: string};
  }
}




const colors = {
  white: {
    main: '#ffffff',
  },
  pink: {
    main: '#F475A4',
    main100: '#AD2B82',
  },
  black: {
    main: '#000'
  },
  red: {
    main: '#FE2323'
  },
  gray: {
    main: '#F9F9F9',
    main100: '#f7f7f7',
    main200: '#4E4E4E',
    main300: '#626262',
    main400:'#818181',
    main500:'#AFAFAF',
    main600:'#F9F9F9',
    main700: '#818181',
    main800: '#292929',

  },
  green: {
    main: '#009556'
  },
  
  yellow: {
    main : "#FFB800"
  }

}

const Default = createTheme({
  palette:colors,
  typography: {
    fontFamily: 'poppins',
  },
})


export default Default