import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
  Stack,
  Snackbar,
  Alert,
  Typography,
  Grid,
} from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"

import {
  AiFillLock,
  AiFillUnlock,
  AiOutlineMail,
  AiOutlineUser,
} from "react-icons/ai"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import mommylogo from "../../images/login-logo.png"
import physioTherapy1 from "../../images/physioTherapy1.jpg"
import physioTherapy2 from "../../images/physioTherapy2.jpg"
import physioTherapy3 from "../../images/physioTherapy3.jpg"
import physioTherapy4 from "../../images/physioTherapy4.jpg"
import { useAppSelector } from "../../app/hooks"
import { useForgetPasswordMutation } from "../../features/Auth/authApi"
import { Controller, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"

type Props = {}

interface forgetUser {
  email: string
}

export default function ForgetPassword({}: Props) {
  const [userLogin, { isError, error, data, isLoading }] =
    useForgetPasswordMutation()

  const navigate = useNavigate()
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .required("Please enter an email")
        .test("valid-email", "Please enter a valid email", (value) =>
          emailRegex.test(value),
        ),
    }),
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<forgetUser>({
    resolver: schemaResolver,
  })

  const onSubmit = (values: any) => {
    const payload = {
      email: values.email,
    }
    userLogin(payload)
  }

  useEffect(() => {
    if (data?.message) {
      toast.success(data?.message, { autoClose: 1000 })
      navigate("/")
    } else if ((error as any)?.data?.message) {
      toast.error((error as any)?.data?.message, { autoClose: 1000 })
    }
  }, [data, error])

  return (
    <>
      <Grid
        container
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          lg={6}
          className="d-none d-lg-block"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {" "}
          <Box className="box-wrapper">
            <Typography
              sx={{
                textAlign: "center",
                color: " #858585",
                fontFamily: "Montserrat",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Welcome to
            </Typography>
            <Typography textAlign="center">
              <h1>Send A Therapist</h1>
              {/* <img src={mommylogo} width="300" height="auto" /> */}
            </Typography>
            <Box className="Imgbox" display="flex" flexWrap="wrap" gap={2}>
              <Box>
                <img src={physioTherapy1} className="login-left-img1"/>
              </Box>
              <Box mt={5}>
                <img src={physioTherapy2} className="login-left-img2"/>
              </Box>

              <Box>
                <img src={physioTherapy3} className="login-left-img3"/>
              </Box>

              <Box mt={5}>
                <img src={physioTherapy4} className="login-left-img4"/>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box className="Loginform">
            <Typography
              variant="h2"
              mt={5}
              fontFamily="Montserrat"
              fontSize={22}
              fontWeight="600"
              color="pink.main"
              textAlign="center"
            >
              <span onClick={() => navigate("/")} style={{ cursor: "pointer" }}>
                <ChevronLeftIcon />
              </span>
              Forget password{" "}
            </Typography>{" "}
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputLabel
                htmlFor="outlined-adornment-password"
                sx={{ mt: 3, mb: 1 }}
              >
                Email
              </InputLabel>
              <Controller
                name="email"
                control={control}
                render={({ field }: any) => (
                  <OutlinedInput
                    placeholder="Email"
                    {...field}
                    type="email"
                    id="outlined-adornment-password"
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton edge="end">
                          <AiOutlineMail />
                        </IconButton>
                      </InputAdornment>
                    }
                    label=""
                    fullWidth
                  />
                )}
              />

              <Typography variant="body2" color="error">
                {(errors as any)?.email?.message}
              </Typography>

              <Button
                type="submit"
                size="large"
                variant="contained"
                sx={{ mt: 3, display: "block" }}
                disabled={isLoading}
                fullWidth
              >
                {isLoading ? (
                  <Typography fontSize={1}>
                    <CircularProgress
                      style={{ color: "pink", fontSize: 23 }}
                      size={23}
                    />
                  </Typography>
                ) : (
                  "Submit"
                )}
              </Button>
            </form>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}
