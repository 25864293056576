import { styled } from "@mui/material";

const Upcoming = styled("div")(({ theme }) => ({
    marginBottom: '20px',
    "& .MuiTypography-h2": {
        color: theme.palette.pink.main,
        fontFamily: "Montserrat",
        fontSize: " 16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " normal",
    },
    "& .Headingbox": {
        borderRadius: '16px 16px 0 0',

        display: "flex",
        justifyContent: "space-between",
        background: theme.palette.white.main,
        boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
        padding: '15px'

    },
    "& .ContentBox": {
        display: "flex",
        justifyContent: "space-between",
        padding: '15px',
        background: theme.palette.white.main,
        "& .MuiGrid-root": {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            marginTop: '20px',

        },
        '& .MuiButton-root': {
            borderColor: theme.palette.pink.main,
            color: theme.palette.pink.main,
            fontFamily: 'Montserrat',

        },
    },
    "& .flex-none" : {
        flex : "none"
    },
    "& .max-w-550" : {
        maxWidth: "550px"
    },
    "& .search-field" : {
        width: "88%",
        flex: "none",
        "& label.Mui-focused" : {
            color: theme.palette.pink.main
        },
        "& label.MuiFormLabel-filled" : {
            color: theme.palette.pink.main
        },
        "& input" : {
            padding: "16px 20px",
            fontSize: "16px",
            fontFamily: "Montserrat",
            color: theme.palette.black.main
        },
        "&  .MuiOutlinedInput-notchedOutline" :
        {
            borderRight: "none",
            borderRadius : "11px 0px 0 11px",
            borderColor : theme.palette.pink.main,
            "&.Mui-focused" : {
                borderColor : theme.palette.pink.main,
            }
        }
    },
    "& .search-btn" : {
        backgroundColor: theme.palette.pink.main,
        borderRadius : "0px 11px 11px 0px",
        boxShadow : "none",
        "& svg" : {
            width: "25px",
            height: "25px",
        }
    }
    
}));
const Overview = styled("div")(({ theme }) => ({
    "& .MuiTypography-h2": {
        color: theme.palette.pink.main,
        fontFamily: "Poppins",
        fontSize: " 16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " normal",
    },
    "& .MuiTypography-body3": {
        color: theme.palette.gray.main700,
        fontFamily: "Montserrat",
        fontSize: " 14px",
        fontStyle: "normal",
        fontWeight: "400",
        display: "block",
        lineHeight: " 13px",
    },
    "& .MuiDrawer-paper": {
        backgroundColor: theme.palette.pink.main,
        "& .MuiListItem-root ": {
            color: theme.palette.white.main,
            "& svg": {
                color: theme.palette.white.main,
            },
        },
    },
    "& .Advertisement": {
        borderRadius: " 16px",
        background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        "& .MuiButtonBase-root": {
            background: theme.palette.white.main,
            color: theme.palette.pink.main,
        },
    },
    "& .seeker-card" : {
        padding: "16px",
        background: theme.palette.white.main,
        borderRadius : "20px",
        "& .listing-seeker" : {
            borderTop : "1px solid #e3e3e3",
        }
    },
    "& .assign-btn" : {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "400",
        backgroundColor: theme.palette.green.main,
        color: theme.palette.white.main,
        boxShadow: "none",
        fontFamily: "Montserrat",
        padding: "15px 10px",
        textTransform : "capitalize"
    },
    "& .rejected-btn" : {
        fontSize: "14px",
        lineHeight: "18px",
        fontWeight: "400",
        backgroundColor: "transparent",
        color: theme.palette.red.main,
        borderColor: theme.palette.red.main,
        boxShadow: "none",
        fontFamily: "Montserrat",
        padding: "15px 10px",
        textTransform : "capitalize",
        "&:hover" : {
            backgroundColor: theme.palette.red.main,
            color: theme.palette.white.main
        },
        "&.reject-fill-btn" : {
            backgroundColor: theme.palette.red.main,
            color: theme.palette.white.main
        }
    },
    "& .reject-card":{
        padding: "30px 42px",
        background: theme.palette.white.main,
        borderRadius: "20px",
        "& .yes-btn" : {
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: "400",
            backgroundColor: "transparent",
            color: theme.palette.gray.main500,
            borderColor: theme.palette.gray.main500,
            boxShadow: "none",
            fontFamily: "Montserrat",
            padding: "7px 10px",
            textTransform : "capitalize"
        },
        "& .no-btn" : {
            fontSize: "14px",
            lineHeight: "18px",
            fontWeight: "400",
            backgroundColor: theme.palette.pink.main,
            color: theme.palette.white.main,
            borderColor: theme.palette.pink.main,
            boxShadow: "none",
            fontFamily: "Montserrat",
            padding: "7px 10px",
            textTransform : "capitalize"
        },
    },
    "& .dashboard-table-wrapper": {
        backgroundColor: "transparent",
        boxShadow: "none",
        "& .dashboard-table": {
            backgroundColor: "transparent",
            boxShadow: "none",
            "& thead":{
                "& th":{
                    color: theme.palette.gray.main700,
                    fontFamily: "Montserrat",
                    fontSize: " 14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: " 13px",
                },
            },
            "& .service-head": {
                paddingLeft: "20px",
                paddingRight: "20px",
            },
            "& .datetime":{
                "& span": {
                    width: "130px",
                    lineHeight: "23px"
                }
            },
            "& .Pending": {
                color: "#FFA639"
            },
            "& .Assigned": {
                color: "#009C35"
            },
            "& .Rejected": {
                color: "#FE2323"
            },
            "& .location": {
                "& span": {
                    width: "300px",
                    lineHeight: "23px"
                }
            },
            "& .action-btn": {
                gap: "20px"
            },
            "& .view-profile": {
                display: "inline-block",
                width: "135px",
                padding: "12px 10px",
                border: "1px solid #F475A4",
                borderRadius: "8px",
                color: "#F475A4",
                fontSize: "14px",
                textAlign: "center",
                textDecoration: "none"
            },
            "& tbody tr td:first-child":{
                width: "85px",
                "& div": {
                    "& img": {
                        width: "100%"
                    }
                }
            },
            "& tbody tr td:last-child":{
                "& span": {
                    borderRadius: "0px 16px 16px 0"
                }
            },
            "& td":{
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: "none",
                backgroundColor: "transparent",
                "& span": {
                    position: "relative",
                    minHeight: "75px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    color: theme.palette.gray.main700,
                    fontFamily: "Montserrat",
                    fontSize: " 14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: " 13px",
                    boxShadow: "0px 8px 6px -3px #00000008",
                    zIndex: "2",

                }
            },
            "& th":{
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: "none",
                backgroundColor: "transparent",
                "& span": {
                    position: "relative",
                    minHeight: "75px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    backgroundColor: "#fff",
                    color: theme.palette.black,
                    fontFamily: "Montserrat",
                    fontSize: " 14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: " 13px",
                    gap: "6px",
                    padding: "0 20px",
                    boxShadow: "0px 8px 6px -3px #00000008",
                    zIndex: "2",
                    "& span":{
                        minHeight: "auto",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "2px",
                        fontSize: "12px",
                        color: theme.palette.gray.main700,
                        padding: 0,
                        "& svg": {
                            fill : theme.palette.yellow.main
                        }
                    }
                }
            }
        },
    },
    
}));
const Flexbox = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "& .MuiTypography-body2": {
        color: theme.palette.grey,
        fontFamily: "Nunito Sans",
        fontSize: " 14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " 18px",
    },
    "& .MuiTypography-body1": {
        color: theme.palette.pink.main,
        fontFamily: "Montserrat",
        fontSize: " 24px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " 18px",
    },
    "& .Status.MuiBox-root": {
        background: theme.palette.white.main,
        boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
        padding: "20px",
        borderRadius: "16px",
        width: '110px',
    },
}));

export { Upcoming, Overview, Flexbox }
