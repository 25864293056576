import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import profileimage from "../../images/createProfile.png"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import * as Style from "./Style"
import Button from "@mui/material/Button"
import StepperComponent from "./Stepper"
const StepperLayout = () => {
  const steps = ["Personal info", "Service detail", "Profile"]
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())

  const isStepOptional = (step: number) => {
    return step === 1
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }
  return (
    <Style.PersonalInfo>
      <Grid container>
        <Grid item md={4} className="d-none d-lg-block">
          <Box className="profile-box">
            <Typography variant="h2" className="profile-title pink-text pb-4">
              Create your profile
            </Typography>
            <Typography variant="h6" className="sub-title pb-2">
              A few clicks away from creating your profile
            </Typography>
            <Typography className="paragraph">
              Are you a multitasking physiotherapist looking for reliable help with
              scheduling, patient management, and more? Look no further! Join our
              community of empowered physiotherapists who have found the perfect solution to
              balance work, patient care, and personal time
            </Typography>
            <Box className="profile-image">
              <img src={profileimage} className="image-size img-fluid" />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <StepperComponent />
        </Grid>
      </Grid>
    </Style.PersonalInfo>
  )
}

export default StepperLayout
