import React, { useEffect, useState } from "react"
import { Box, Grid, InputLabel, TextField, Typography } from "@mui/material"

import * as Style from "./Style"
import Button from "@mui/material/Button"
import MenuItem from "@mui/material/MenuItem"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { useForm } from "react-hook-form"
import { Form } from "react-bootstrap"
import { useAppSelector } from "../../app/hooks"
import { useAppDispatch } from "../../app/store"
import { setLanguage, setString } from "../../features/Auth/WrapperSlice"
import { useGetCountrysQuery } from "../../features/Auth/authApi"
import LocationSelect from "../location/locationSelect"

const Gender = [
  {
    value: "Male",
    label: "Male",
  },
  {
    value: "Female",
    label: "Female",
  },
  {
    value: "Other",
    label: "Other",
  },
]

interface PersonalAccount {
  name: string
  email: string
  contactNo: string
  gender: string
  dob: string
  region: string
  // address: string
}

interface PersonalStepProps {
  activeStep: string
  step: string
  handleNext: () => void
  handleBack: () => void
}
const PersonalStep: React.FC<PersonalStepProps> = ({
  activeStep,
  step,
  handleNext,
  handleBack,
}) => {
  const dispatch = useAppDispatch()
  const { data: countryList } = useGetCountrysQuery({})
  const [loactionSearchData, setLocationSearchData] = useState<string>("")
  const [errorMsg, setErrorMsg] = useState<string>()
  const [isFocused, setIsFocused] = useState({ country: false, gender: false })

  const stringValue = useAppSelector((state) => state?.wrapper?.stringValue)
  const userId = useAppSelector((state) => state.auth.userDetails)

  const reduxDetails = useAppSelector((state) => state.wrapper.stringValue)

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const schemaResolver = yupResolver(
    yup.object().shape({
      name: yup
        .string()
        .required("Please enter first Name")
        .min(3, "Name must be at least 3 characters")
        .max(25, "Name can't exceed 25 characters"),
      email: yup
        .string()
        .required("Please enter an email")
        .test("valid-email", "Please enter a valid email", (value) =>
          emailRegex.test(value),
        ),
      contactNo: yup
        .string()
        .required("Please enter a phone Number")
        .min(6, "Phone number must be at least 6 characters")
        .max(14, "Phone number can't exceed 14 characters"),
      gender: yup.string().required("Please select your gender"),
      dob: yup
        .string()
        .required("Please enter your date of birth")
        .test(
          "age",
          "The age of Provider should be between 18- 58 years",
          function (value) {
            const selectedDate = new Date(value)
            const currentDate = new Date()
            const age = currentDate.getFullYear() - selectedDate.getFullYear()
            const monthDiff = currentDate.getMonth() - selectedDate.getMonth()
            if (
              monthDiff < 0 ||
              (monthDiff === 0 &&
                currentDate.getDate() < selectedDate.getDate())
            ) {
            }
            return age >= 18 && age <= 58
          },
        ),

      region: yup.string().required("Please select your country"),
      // address: yup.string().required("Please enter your location"),
    }),
  )
  const {
    control,
    register,
    setValue,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<PersonalAccount>({
    resolver: schemaResolver,
  })

  const onSubmit = (value: any) => {
    dispatch(
      setString({
        name: value?.name,
        email: value?.email,
        contactNo: value?.contactNo,
        gender: value?.gender,
        region: value?.region,
        address: loactionSearchData,
        dob: value?.dob,
        services: (stringValue as any)?.services || null,
        languageAll: (stringValue as any)?.languageAll || null,
        experience: (stringValue as any)?.experience || null,
      }),
    )
    if (loactionSearchData !== "" && loactionSearchData !== undefined) {
      handleNext()
    } else {
      setErrorMsg("Please enter your location")
    }
  }

  useEffect(() => {
    if (stringValue) {
      setValue("name", userId?.user?.name)
      setValue("email", userId?.user?.email)
      setValue("contactNo", userId?.user?.contactNo)
      // setValue("gender", stringValue?.gender)
      // setValue("region", stringValue?.region)
      setLocationSearchData(stringValue?.address)
      // setValue("address", stringValue?.address)
      setValue("dob", stringValue?.dob)
    }
  }, [stringValue])

  return (
    <Style.PersonalInfo style={{ padding: "0px" }}>
      <Grid>
        <Grid item xs={12}>
          <Box sx={{ width: "100%" }}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Box className="py-3">
                <Typography sx={{ m: 0 }}>
                  <Box
                    className="d-flex"
                    component="form"
                    sx={{
                      "& > :not(style)": { mx: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Box>
                      <InputLabel sx={{ mb: 2 }} htmlFor="outlined-basic">
                        Name<span className="text-danger">*</span>
                      </InputLabel>

                      <TextField
                        id="outlined-basic"
                        // label="Enter your full name"
                        variant="outlined"
                        className="form-input"
                        {...register("name")}
                        disabled
                      />
                      <p className="text-danger"> {errors?.name?.message}</p>
                    </Box>
                    <Box>
                      <InputLabel sx={{ mb: 2 }} htmlFor="outlined-basic">
                        Email<span className="text-danger">*</span>
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        // label="Enter your email"
                        variant="outlined"
                        className="form-input "
                        {...register("email")}
                        disabled
                      />
                      <p className="text-danger"> {errors?.email?.message}</p>
                    </Box>
                  </Box>
                </Typography>
                <Typography sx={{ m: 0 }}>
                  <Box
                    className="d-flex"
                    component="form"
                    sx={{
                      "& > :not(style)": { mx: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Box>
                      <InputLabel sx={{ mb: 2 }} htmlFor="outlined-basic">
                        Phone Number<span className="text-danger">*</span>
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        // label="Enter your contact number"
                        variant="outlined"
                        className="form-input"
                        type="number"
                        {...register("contactNo")}
                        disabled
                      />
                      <p className="text-danger">
                        {" "}
                        {errors?.contactNo?.message}
                      </p>
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { mb: 2 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <InputLabel sx={{ mb: 2 }} htmlFor="outlined-basic">
                        Gender<span className="text-danger">*</span>
                      </InputLabel>
                      <TextField
                        id="filled-select-currency"
                        select
                        variant="filled"
                        className="form-input custom-dropdown mb-1"
                        defaultValue={stringValue?.gender}
                        value={watch("gender")}
                        label={
                          isFocused.gender || stringValue?.gender
                            ? ""
                            : "Gender"
                        }
                        {...register("gender")}
                        onChange={(e) => {
                          const gender = e.target.value
                          setValue("gender" as any, gender as any, {
                            shouldValidate: true,
                          })

                          const temp = { ...isFocused, gender: true }
                          setIsFocused(temp)
                        }}
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </TextField>
                      <p className="text-danger"> {errors?.gender?.message}</p>
                    </Box>
                  </Box>
                </Typography>
                <Typography sx={{ m: 0 }}>
                  <Box
                    className="d-flex"
                    component="form"
                    sx={{
                      "& > :not(style)": { mx: 2 },
                    }}
                    noValidate
                    autoComplete="off"
                  >
                    <Box>
                      <InputLabel sx={{ mb: 2 }} htmlFor="outlined-basic">
                        Date of Birth<span className="text-danger">*</span>
                      </InputLabel>
                      <TextField
                        id="outlined-basic"
                        max="2000-01-02"
                        variant="outlined"
                        className="form-input"
                        type="date"
                        {...register("dob")}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        InputProps={{
                          inputProps: {
                            min: "1000-01-01", // Minimum date allowed
                            max: "9999-12-31", // Maximum date allowed
                          },
                          inputComponent: (props) => (
                            <input
                              {...props}
                              pattern="[0-9]{4}"
                              title="Please enter a four-digit year"
                            />
                          ),
                        }}
                      />
                      <p
                        className="text-danger mt-1"
                        style={{ maxWidth: "300px" }}
                      >
                        {" "}
                        {errors?.dob?.message}
                      </p>
                    </Box>
                    <Box
                      component="form"
                      sx={{
                        "& > :not(style)": { mb: 2 },
                      }}
                      noValidate
                      autoComplete="off"
                    >
                      <InputLabel sx={{ mb: 2 }} htmlFor="outlined-basic">
                        Country<span className="text-danger">*</span>
                      </InputLabel>
                      <TextField
                        id="filled-select-currency"
                        select
                        variant="filled"
                        className="form-input mb-1"
                        defaultValue={stringValue?.region}
                        value={watch("region")}
                        label={
                          isFocused.country || stringValue?.region
                            ? ""
                            : "Country"
                        }
                        {...register("region")}
                        onChange={(e) => {
                          const region = e.target.value
                          setValue("region" as any, region as any, {
                            shouldValidate: true,
                          })
                          const temp = { ...isFocused, country: true }
                          setIsFocused(temp)
                        }}
                        SelectProps={{
                          MenuProps: {
                            PaperProps: {
                              style: {
                                maxHeight: 250,
                                width: 150,
                                overflowY: "auto",
                                scrollbarWidth: "thin",
                                scrollbarColor: "#f07fa9 transparent",
                                borderRadius: "12px",
                                cursor: "pointer",
                              },
                            },
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            transformOrigin: {
                              vertical: "top",
                              horizontal: "left",
                            },
                          },
                        }}
                      >
                        {countryList?.countries &&
                          countryList?.countries?.map((option: any) => (
                            <MenuItem key={option?.name} value={option?.name}>
                              {option?.name}
                            </MenuItem>
                          ))}
                      </TextField>
                      <p className="text-danger"> {errors?.region?.message}</p>
                    </Box>
                  </Box>
                </Typography>
                <Typography sx={{ m: 0 }}>
                  <Box className="d-flex mx-3">
                    <Box>
                      <InputLabel sx={{ mb: 2 }} htmlFor="outlined-basic">
                        Location<span className="text-danger">*</span>
                      </InputLabel>
                      {/* <TextField
                        id="outlined-basic"
                        // label="Enter your location"
                        variant="outlined"
                        className="form-input"
                        {...register("address")}
                        placeholder="Location"
                      /> */}
                      <Box
                        sx={{
                          width: 300,
                          "@media (max-width: 600px)": {
                            width: 200,
                          },
                        }}
                      >
                        <LocationSelect
                          placeholder="Enter Location"
                          setLocationSearchData={setLocationSearchData}
                          setErrorMsg={setErrorMsg}
                          loactionSearchData={loactionSearchData}
                        />
                      </Box>
                      <p className="text-danger mt-2">
                        {errorMsg}
                        {/* {(errors as any).location?.message} */}
                      </p>
                      {/* <p className="text-danger"> {errors?.address?.message}</p> */}
                    </Box>
                  </Box>
                </Typography>
              </Box>
              <Box className="d-flex mx-3">
                {(activeStep as any) === 0 ? (
                  ""
                ) : (
                  <Button
                    color="inherit"
                    disabled={(activeStep as any) === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                    className="next-button "
                  >
                    Back
                  </Button>
                )}
                <Button type="submit" className="next-button grey-button">
                  {(activeStep as any) === step.length - 1 ? "Finish" : "Next"}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Style.PersonalInfo>
  )
}

export default PersonalStep
