import React, { useState } from "react"
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Radio,
  Rating,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"
import profileImg from "../../images/babysitting.png"
import { CiMail } from "react-icons/ci"
import { LuPhone } from "react-icons/lu"
import { LuUserCircle } from "react-icons/lu"
import { FaPencilAlt } from "react-icons/fa"
import { FaRegUser } from "react-icons/fa6"
import { LuMapPin } from "react-icons/lu"
import { LuMail } from "react-icons/lu"
import {
  useGetActivePostApiQuery,
  useGetGenrateostApiQuery,
  useUpcomingAppointmentsQuery,
} from "../../features/OverViewAuth/overViewApi"
import { Link, useNavigate } from "react-router-dom"
import { useAccountProfileQuery } from "../../features/Auth/authApi"
import CircularIndeterminate from "../Loading/Loading"
import { LuLock } from "react-icons/lu"
import { Spinner } from "react-bootstrap"
import CenteredSpinner from "../common/CenteredSpinner"
import ChangePasswordModal from "./ChangePasswordModal"
import dummy from "../../images/dummy-image.jpg"
import { boolean } from "yup"
import EditAccount from "./EditAccount"
const MyAccount = () => {
  const { data: dataList, isLoading } = useAccountProfileQuery({})
  const [open, setOpen] = useState(false)
  const [editView, setEditView] = useState<boolean>(true)

  const navigate = useNavigate()
  const tagStyle = {
    border: "1px solid #F475A4",
    padding: "4px 8px",
    borderRadius: "8px",
    marginRight: "8px", // Add space between tags
    marginBottom: "8px", // Add space below each row of tags
    display: "inline-block",
    backgroundColor: "#F475A4", // Light background color
    color: " #fff",
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <Style.Overview>
          <Grid container>
            <Grid xs={12} md={5} lg={4} className="pe-2 pe-xl-4">
              <Box className="review-card text-center p-4 d-flex flex-column align-items-center h-100">
                <Box className="mb-4">
                  <img
                    src={dataList?.data?.profilePic || dummy}
                    className="profile-img-2"
                  />
                </Box>
                <Typography
                  variant="h2"
                  align="center"
                  className="mb-3 fw-bold"
                >
                  {dataList?.data?.name}
                </Typography>
                <Typography variant="body1" align="center" className="mb-3">
                  <CiMail /> {dataList?.data?.email}
                </Typography>
                <Typography variant="body1" align="center" className="mb-3">
                  <LuPhone /> {dataList?.data?.contactNo}
                </Typography>
                <Link
                  to=""
                  onClick={() => setEditView(!editView)}
                  className="view-profile mb-3"
                >
                  Edit <FaPencilAlt />
                </Link>

                {/* view Profile */}

                {/* <Link
                  to="/dashboard/profile-details"
                  className="view-profile mb-3"
                >
                  View public profile
                </Link> */}

                {/* change password */}

                <Link
                  to=""
                  className="view-profile mb-3"
                  onClick={() => setOpen(true)}
                >
                  Change Password <LuLock />
                </Link>
              </Box>
            </Grid>
            <Grid xs={12} md={7} lg={8} className="">
              {editView ? (
                <Box className="review-card p-4 h-100">
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">Name</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className="  ps-2">
                        {dataList?.data?.name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">E-Mail</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography
                        variant="body1"
                        className="ps-2"
                        sx={{ overflowWrap: "anywhere" }}
                      >
                        {dataList?.data?.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">Phone Number</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className="  ps-2">
                        {dataList?.data?.contactNo}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <Grid container className="mb-4">
                  <Grid md={4}>
                    <Typography variant="body1">Age</Typography>
                  </Grid>
                  <Grid md={8}>
                    <Typography variant="body1" className="  ps-2">
                      28 yrs
                    </Typography>
                  </Grid>
                </Grid> */}
                  <Grid container className="mb-4 ">
                    <Grid xs={4}>
                      <Typography variant="body1">Gender</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className="  ps-2">
                        {dataList?.data?.gender || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">Language</Typography>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <Typography
                        variant="body1"
                        className="  ps-2 overflow-anywhere"
                      >
                        {dataList?.data?.language?.join(" ") || "--"}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">Date of birth</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className="  ps-2">
                        {moment(dataList?.data?.dob).format("DD-MM-YYYY")}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">Origin country</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className="  ps-2">
                        {dataList?.data?.region}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">Location</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className="  ps-2">
                        {dataList?.data?.address}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1" className="mb-1">
                        Services Providing
                      </Typography>
                    </Grid>
                    <Grid md={8}>
                      <Typography variant="body1" className=" ">
                        {dataList?.data?.services?.map((service: any) => (
                          <span key={service.id} style={tagStyle}>
                            {service.name}{" "}
                          </span>
                        ))}{" "}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-4">
                    <Grid xs={4}>
                      <Typography variant="body1">Your bio</Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="body1" className="  ps-2">
                        {dataList?.data?.description}
                      </Typography>
                    </Grid>
                  </Grid>
                  {/* <Grid container className="mb-4">
                  <Grid md={4}>
                    <Typography variant="body1">Availability</Typography>
                  </Grid>\
                  <Grid md={8}>
                    <Typography variant="body1" className="  ps-2">
                      {dataList?.data?.availability?.days?.join("-")}
                    </Typography>
                    <Typography variant="body1" className="  ps-2">
                      {dataList?.data?.availability?.time?.from +
                        "-" +
                        dataList?.data?.availability?.time?.to}
                    </Typography>
                  </Grid>
                </Grid> */}
                </Box>
              ) : (
                <EditAccount editView={editView} setEditView={setEditView} />
              )}
            </Grid>
          </Grid>
        </Style.Overview>
      )}

      <ChangePasswordModal opens={open} handleClose={handleClose} />
    </>
  )
}
export default MyAccount
