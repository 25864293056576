export const globalToastStyle = {
  backgroundColor: "#fff",
  color: "#f371a1",
  padding: "12px", 
  borderRadius: "8px",
  boxShadow: "0 4px 12px rgba(0, 0, 0, 0.3)",
  maxWidth: "300px",
};

export const progressStyle = {
  backgroundColor: "rgba(227, 61, 148, 0.5)",
  color: "#fff",
};
