import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TextField,
  Grid,
  Stack,
  Pagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  PaginationItem,
} from "@mui/material"
import { FaStar } from "react-icons/fa6"
import * as Style from "./style"
import { useJobHistoryQuery } from "../../features/Auth/authApi"
import { Button, Spinner } from "react-bootstrap"
import { CiSearch } from "react-icons/ci"
import moment from "moment"
import dummy from "../../images/dummy-image.jpg"
import nodata from "../../images/no-results.svg"

import { TbArrowsSort } from "react-icons/tb"
import CenteredSpinner from "../common/CenteredSpinner"
import { FaEye } from "react-icons/fa"
import RatingStars from "../common/rating"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"

const statusButton = ["All", "Completed", "Expired"]
const JobHistory = () => {
  // const urlSearchParams = new URLSearchParams(window.location.search)
  // const type = urlSearchParams.get("type")

  const [selectedPage, setSelectedPage] = useState(1)
  const [statusChange, setStatusChange] = useState()
  const [statusIndex, setStatusIndex] = useState<number>()
  const [searchStatus, setSearchStatus] = useState()
  const [searchPayload, setSearchPayload] = useState()
  const [dateShortList, setDateShortList] = useState(-1)
  const [pageSelect, setPageSelect] = React.useState<number>(10)
  const [searchParams, setSearchParams] = useSearchParams()
  const { type } = useParams()
  const navigate = useNavigate()
  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  const {
    data: JobHistory,
    isLoading,
    isFetching,
  } = useJobHistoryQuery(
    {
      pageNo: selectedPage,
      postStatus: statusChange,
      searchName: searchPayload,
      createdAt: dateShortList,
      pageLimit: pageSelect,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  const handleButton = (name: any, index: number) => {
    setSearchPayload("" as any)
    setSearchStatus("" as any)
    setStatusIndex(index)

    setStatusChange(name)
  }

  useEffect(() => {
    setStatusChange(type as any)
  }, [type])

  const handleSearch = (event: any) => {
    setSearchStatus(event.target.value)
  }

  const enterSearch = (event: any) => {
    if (event.key == "Enter") {
      onSearchSubmit()
    }
  }

  const onSearchSubmit = () => {
    setSearchPayload(searchStatus)
    setStatusIndex(-1)
  }

  const handleSDateShort = () => {
    const shortDate = dateShortList === 1 ? -1 : 1
    setDateShortList(shortDate)
  }
  const handleChange = (event: any, name: any) => {
    setPageSelect(event.target.value)
    setSelectedPage(1)
  }

  return (
    <>
      <Style.Overview>
        <Box className="d-flex flex-column  mb-3">
          <Box className="d-flex flex-none w-100 max-w-550">
            <TextField
              id="outlined-basic"
              className="search-field"
              label="Search"
              variant="outlined"
              onChange={handleSearch}
              value={searchStatus}
              onKeyDown={enterSearch}
            />
            <Button
              variant="contained"
              className="search-btn"
              onClick={onSearchSubmit}
              onKeyDown={enterSearch}
            >
              <CiSearch />
            </Button>
          </Box>
          <div className="d-flex align-items-center justify-content-between">
            <Box>
              {statusButton?.map((item, index) => (
                <Button
                  key={index}
                  variant="transparent"
                  className="all-button mb-1"
                  style={{
                    backgroundColor:
                      statusChange === item ? "#F475A4" : "white",
                    color: statusChange === item ? "white" : "black",
                    marginRight: "10px",
                    padding: "6px 30px",
                    boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.07)",
                  }}
                  onClick={() => handleButton(item, index)}
                >
                  {item}
                </Button>
              ))}
            </Box>
            <Box>
              <FormControl
                sx={{ m: 1, mt: 3 }}
                className="custom-field w-100 view-field"
              >
                {/* Use startAdornment to add the Font Awesome icon */}
                <InputLabel
                  id="demo-simple-select-label"
                  className="label-with-icon"
                >
                  View
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  value={pageSelect}
                  onChange={(event) => handleChange(event, "page" as any)}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
        </Box>

        {isLoading || isFetching ? (
          <CenteredSpinner />
        ) : (
          <>
            <>
              <TableContainer
                component={Paper}
                className="dashboard-table-wrapper custom-scrollbar"
              >
                <Table
                  sx={{
                    minWidth: 650,
                    border: "none",
                    backgroundColor: "transparent",
                  }}
                  className="dashboard-table"
                  aria-label="simple table"
                >
                  {JobHistory?.data?.length > 0 && (
                    <TableHead>
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell align="center" className="service-head">
                          Name{" "}
                        </TableCell>
                        {/* <TableCell align="center">Email</TableCell> */}
                        <TableCell align="center">Service</TableCell>
                        <TableCell align="center">Language</TableCell>
                        <TableCell align="center" onClick={handleSDateShort}>
                          Start Time <TbArrowsSort />
                        </TableCell>
                        <TableCell align="center">Status</TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {JobHistory?.data?.length > 0 ? (
                      JobHistory?.data?.map((row: any) => (
                        <TableRow
                          key={row.serviceseeker}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            navigate(`/dashboard/approved-detail/${row?._id}`),
                              setSearchParams({ view: "booking-detail" })
                          }}
                        >
                          <TableCell>
                            <div
                              style={{
                                width: "74px",
                                height: "74px",
                                objectFit: "cover",
                              }}
                            >
                              <img
                                src={row.userimage || dummy}
                                style={{
                                  width: "74px",
                                  height: "74px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell component="td" align="center" scope="row">
                            <span className="text flex-column gap-2">
                              {row.seekerData?.name + " " || "-----"}
                              <span className="minh-1">
                                <RatingStars
                                  rating={
                                    row?.seekerData?.rating?.$numberDecimal
                                  }
                                />
                              </span>
                            </span>
                          </TableCell>
                          {/* <TableCell align="center">
                            <span>{row?.seekerData?.email}</span>
                          </TableCell> */}
                          <TableCell align="center">
                            <span>{row?.service?.name || "-----"}</span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="language text-center mx-auto">
                              <span>{row.language.join(", ") || "-----"}</span>
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span className="datetime">
                              <span className="d-flex flex-column">
                                <span className="weekday">
                                  {/* {activePost?.data.availabeJobs} */}
                                  <FormatDate dateString={row?.from} />
                                </span>
                                {/* <span> */}
                                <span>
                                  <FormatChangeTime dateString={row?.from} />
                                </span>
                                <span>
                                  <FormatTime dateString={row?.from} />
                                </span>
                              </span>
                            </span>
                          </TableCell>
                          <TableCell align="center">
                            <span
                              className={row.requeststatus}
                              style={{
                                fontSize: "14px",
                                fontWeight: "bold",
                                color:
                                  row?.status == "Completed"
                                    ? "green"
                                    : row?.status == "Expired"
                                    ? "red "
                                    : row?.status == "Declined"
                                    ? "yellow"
                                    : "",
                              }}
                            >
                              {row?.status}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <>
                        <TableRow
                          sx={{
                            height: "40vh",
                            backgroundColor: "white",
                          }}
                        >
                          {" "}
                          <TableCell
                            align="center"
                            colSpan={7}
                            sx={{
                              width: "100%",
                              pointerEvents: "none",
                              border: "none",
                            }}
                          >
                            <img
                              src={nodata}
                              style={{
                                width: "100%",
                                maxWidth: "180px",
                              }}
                            />
                            <span
                              className="no-data-text"
                              style={{
                                fontSize: "20PX",
                                fontWeight: "600",
                                color: "#000",
                              }}
                            >
                              No data found
                            </span>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>

              {JobHistory?.data?.length > 0 && (
                <Box className="d-md-flex justify-content-between pt-2">
                  <Typography variant="body1" className="w-auto white-space">
                    {" "}
                    Showing {JobHistory?.totalDocument?.showingData} -{" "}
                    {JobHistory?.totalDocument?.showingEnd} of{" "}
                    {JobHistory?.totalDocument?.totalCount}
                  </Typography>
                  <Grid container justifyContent="flex-end">
                    <Grid item md={3}>
                      <Box display="flex" className="flex-column">
                        <Stack spacing={0} className="mb-3">
                          <Pagination
                            count={JobHistory?.totalDocument?.totalPages}
                            color="secondary"
                            className="custom-pagination"
                            page={JobHistory?.totalDocument?.pageNo}
                            onChange={handlePageChange}
                            sx={{
                              "& .MuiPaginationItem-page.Mui-selected": {
                                backgroundColor: "#F475A4",
                                boxSizing: "border-box",
                              },
                              "& .MuiPagination-ul": {
                                justifyContent: "end",
                                flexWrap: "nowrap",
                              },
                            }}
                          />
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          </>
        )}
      </Style.Overview>
    </>
  )
}

export default JobHistory
