import React, { useEffect, useState } from "react"
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  Rating,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material"
import { useChanegPasswordsMutation } from "../../features/Auth/authApi"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { toast } from "react-toastify"
import { Visibility, VisibilityOff } from "@mui/icons-material"

type Props = {
  opens: boolean
  handleClose: () => void
}

interface ChangePasswordsProps {
  name: string
  password: string
  newPassword: string
}

interface PasswordState {
  password1: boolean
  password2: boolean
  password3: boolean
}
export default function ChangePasswordModal({ opens, handleClose }: Props) {
  const [
    changePasswords,
    { data: changeData, error: changeError, isLoading: changeIsLoading },
  ] = useChanegPasswordsMutation()

  const [showPasswords, setShowPasswords] = useState<PasswordState>({
    password1: false,
    password2: false,
    password3: false,
  })

  const onSubmit = (data: any) => {
    const payload = {
      password: data?.name,
      newPassword: data?.password,
    }

    changePasswords(payload)
  }

  const schemaResolver = yup.object().shape({
    name: yup.string() .label("Old password") .required(),
    password: yup
      .string()
      .label("New password")
      .required()  
      .test(
        "password-length",
        "Password must be 8 characters or more",
        (value: any) => value && (value as any).length >= 8,
      )
      .test(
        "password-strength",
        "Please choose a strong password. Try a mix of letters, numbers, and symbols",
        (value) =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%#*?&]{8,}$/.test(
            value,
          ),
      ),
    newPassword: yup
    .string()
    .label("Confirm password")
      .required()
      .oneOf([yup.ref("password"), ""], "Passwords not match"),
  })

  const {
    reset,
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ChangePasswordsProps, any>({
    resolver: yupResolver(schemaResolver),
  })

  useEffect(() => {
    if (changeData?.message) {
      toast.success(changeData?.message)
      handleClose()
      setValue("name", "")
      setValue("password", "")
      setValue("newPassword", "")
    } else if ((changeError as any)?.data?.message) {
      toast.error((changeError as any)?.data?.message)
    }
  }, [changeData, changeError])

  const emptyValue = () => {
    handleClose()
    setValue("name", "")
    setValue("password", "")
    setValue("newPassword", "")
    reset()
  }

  const toggleShowPassword = (key: any) => {
    setShowPasswords((prevState: any) => ({
      ...prevState,
      [key]: !prevState[key] as any,
    }))
  }
  return (
    <>
      <Dialog
        open={opens}
        onClose={(!changeIsLoading as any) && emptyValue}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle
          className="text-center"
          style={{
            fontSize: "20px",
            fontFamily: "Montserrat",
            color: "#F475A4",
          }}
        >
          Change Password
        </DialogTitle>
        <DialogContent>
          <Box className="mb-4 position-relative">
            <label
              className="field-label d-block"
              style={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                color: "#000",
              }}
            >
            Old Password
            </label>
            <input
              type={showPasswords.password1 ? "text" : "password"}
              placeholder="Enter old password"
              className="form-field"
              {...register("name")}
              style={{
                width: "100%",
                fontSize: "18px",
                fontFamily: "Montserrat",
                fontWeight: "400",
                border: "1px solid #C1C1C1",
                borderRadius: "11px",
                lineHeight: "22px",
                padding: "11px 21px",
                // color: "#000",
                backgroundColor: "transparent",
              }}
            />
            <IconButton
              onClick={() => toggleShowPassword("password1")}
              edge="end"
              style={{
                position: "absolute",
                right: "18px",
                top: "46px",
                transform: "translateY(-50%)",
              }}
            >
              {showPasswords.password1 ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            <span className="text-danger">{errors?.name?.message}</span>
          </Box>
          <Box className="mb-4 position-relative">
            <label
              className="field-label d-block"
              style={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                color: "#000",
              }}
            >
             New Password
            </label>
            <input
              type={showPasswords.password2 ? "text" : "password"}
              placeholder="Enter new password"
              className="form-field"
              {...register("password")}
              style={{
                width: "100%",
                fontSize: "18px",
                fontFamily: "Montserrat",
                fontWeight: "400",
                border: "1px solid #C1C1C1",
                borderRadius: "11px",
                lineHeight: "22px",
                padding: "11px 21px",
                // color: "#000",
                backgroundColor: "transparent",
              }}
            />
            <IconButton
              onClick={() => toggleShowPassword("password2")}
              edge="end"
              style={{
                position: "absolute",
                right: "18px",
                top: "46px",
                transform: "translateY(-50%)",
              }}
            >
              {showPasswords.password2 ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            <span className="text-danger">{errors?.password?.message}</span>
          </Box>
          <Box className="position-relative">
            <label
              className="field-label d-block"
              style={{
                fontSize: "16px",
                fontFamily: "Montserrat",
                color: "#000",
              }}
            >
              Confirm Password
            </label>
            <input
              type={showPasswords.password3 ? "text" : "password"}
              placeholder="Enter confirm password"
              className="form-field"
              {...register("newPassword")}
              style={{
                width: "100%",
                fontSize: "18px",
                fontFamily: "Montserrat",
                fontWeight: "400",
                border: "1px solid #C1C1C1",
                borderRadius: "11px",
                lineHeight: "22px",
                padding: "11px 21px",
                // color: "#000",
                backgroundColor: "transparent",
              }}
            />
            <IconButton
              onClick={() => toggleShowPassword("password3")}
              edge="end"
              style={{
                position: "absolute",
                right: "18px",
                top: "46px",
                transform: "translateY(-50%)",
              }}
            >
              {showPasswords.password3 ? <Visibility /> : <VisibilityOff />}
            </IconButton>
            <span className="text-danger">{errors?.newPassword?.message}</span>
          </Box>
        </DialogContent>
        <Box pb={2}>
          <DialogActions className="justify-content-between px-4">
            <Button
              onClick={handleSubmit(onSubmit)}
              style={{
                border: "1px solid #F475A4",
                borderRadius: "8px",
                background: "#F475A4",
                fontSize: "16px",
                fontFamily: "Montserrat",
                color: "#fff",
                textTransform: "capitalize",
                padding: "7px 28px",
                width:"204px"
              }}
            >
              {changeIsLoading ? (
                <Typography fontSize={1}>
                    <CircularProgress style={{ color: "pink", fontSize: 25 }} size={25}  />
                </Typography>
              ) : (
                "Change Password"
              )}
            </Button>
            <Button
              onClick={emptyValue}
              style={{
                border: "1px solid #BFBFBF",
                borderRadius: "8px",
                background: "#fff",
                fontSize: "16px",
                fontFamily: "Montserrat",
                color: "#A0A0A0",
                textTransform: "capitalize",
                padding: "7px 28px",
              }}
            >
              Cancel
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}
