import React from "react"
import errorpageImg from "../../images/error-page-img.png"
import "./ErrorPage.css"

function ErrorPage() {
  return (
    <>
      {" "}
      <div className="errorpage-wrapper">
        <div className="errorBoxCard">
          <h1 >404</h1>
          <img
            src={errorpageImg}
            className="img-fluid"
            title="errorImg"
            alt="errorImg"
            width="200"
            height="150"
          />
          <h2 >Something went wrong !</h2>

          <p>Page not found</p>
          <button className="view-profile">Go to home</button>
        </div>
      </div>
    </>
  )
}

export default ErrorPage