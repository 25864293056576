import React, { useEffect, useState } from "react"
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Radio,
  Rating,
  Select,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from "./style"
import moment from "moment"

import { CiMail } from "react-icons/ci"
import { LuPhone } from "react-icons/lu"
import { LuUserCircle } from "react-icons/lu"
import { FaPencilAlt } from "react-icons/fa"
import { FaRegUser } from "react-icons/fa6"
import { LuMapPin } from "react-icons/lu"
import { LuMail } from "react-icons/lu"
import { LuLock } from "react-icons/lu"
import { IoCamera, IoCameraOutline } from "react-icons/io5"
import { Link, useNavigate } from "react-router-dom"
import { MdOutlineMail } from "react-icons/md"
import { BsTelephone } from "react-icons/bs"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import {
  useAccountProfileQuery,
  useAccountUpdateMutation,
  useGetCountrysQuery,
  useGetServiceProvierQuery,
} from "../../features/Auth/authApi"
import { toast } from "react-toastify"
import ChangePasswordModal from "./ChangePasswordModal"
import dummy from "../../images/dummy-image.jpg"
import { Spinner } from "react-bootstrap"

import CenteredSpinner from "../common/CenteredSpinner"
import { useAppDispatch } from "../../app/store"
import LocationSelect from "../location/locationSelect"

interface propsView {
  editView: boolean
  setEditView: (value: boolean) => void
}
interface EditAccountUser {
  // profilePic: string
  name: string
  email: string
  contactNo: string
  services: (string | undefined)[]
  region: string
  gender: string
  // location: string
  language: (string | undefined)[]
  dob: string
  description: string
}

const EditAccount = ({ editView, setEditView }: propsView) => {
  const [previewImage, setPreviewImage] = useState(null)
  const [fileUpload, setFileUpload] = useState(null)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [loactionSearchData, setLocationSearchData] = useState<string>("")
  const [errorMsg, setErrorMsg] = useState<string>()
  const [open, setOpen] = React.useState(false)
  const { data: serviceProvider } = useGetServiceProvierQuery({})
  const { data: countrySelect } = useGetCountrysQuery({})
  const [currentPlace, setCurrentPlace] = useState(false)
  const { data: getProfile, isFetching, isLoading } = useAccountProfileQuery({})
  const [userDetails, setuserDetails] = useState([])
  const [isLoadinga, setIsLoadinga] = useState(false)

  const navigate = useNavigate()
  const [
    accountUpdate,
    { data: updateData, error: errorAccount, isLoading: updateIsloading },
  ] = useAccountUpdateMutation()
  const onChnage = (e: any) => {
    const file = e.target.files[0]

    setFileUpload(file)
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setPreviewImage((reader as any)?.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const schemaResolver = yup.object().shape({
    name: yup.string().required("Please enter first Name"),
    email: yup.string().email().required("Please enter an email"),
    contactNo: yup
      .string()
      .required("Please enter a phone number")
      .min(6, "Phone Number must be at least 6 characters")
      .max(14, "Phone Number can't exceed 14 characters"),
    services: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least one services")
      .required("Please enter services"),
    region: yup.string().required("Please enter Country"),
    gender: yup.string().required("Please enter gender"),
    language: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least one language")
      .max(3, "Please select up to 3 languages")
      .required("Please enter language"),
    // location: yup.string().required("Please enter address"),
    dob: yup
      .string()
      .required("Please enter your date of birth")
      .test(
        "age",
        "The age of Provider should be between 18- 58 years",
        function (value) {
          const selectedDate = new Date(value)
          const currentDate = new Date()
          const age = currentDate.getFullYear() - selectedDate.getFullYear()
          const monthDiff = currentDate.getMonth() - selectedDate.getMonth()
          if (
            monthDiff < 0 ||
            (monthDiff === 0 && currentDate.getDate() < selectedDate.getDate())
          ) {
          }
          return age >= 18 && age <= 58
        },
      ),
    description: yup
      .string()
      .required("Please enter your bio.")
      .min(10, "Bio must be at least 10 characters.")
      .max(500, "Bio should be maximum 500 characters"),
  })

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    setValue,
  } = useForm<EditAccountUser, any>({
    resolver: yupResolver(schemaResolver),
  })

  useEffect(() => {
    // Fetch services and languages data

    const fetchServicesAndLanguages = async () => {
      try {
        const servicesData = getProfile || []
        const array = servicesData?.data?.services?.map(
          (item: any) => item?._id,
        )
        const dob = servicesData?.data?.dob
        const formattedDate = dob ? moment(dob).format("YYYY-MM-DD") : null
        if (servicesData && servicesData.data) {
          setValue("name", servicesData?.data?.name)
          setValue("email", servicesData?.data?.email)
          setValue("contactNo", servicesData?.data?.contactNo)
          setLocationSearchData(servicesData?.data?.address)

          setValue("language" as any, servicesData?.data?.language as any)

          setValue("region" as any, servicesData?.data?.region as any)
          setValue("services" as any, (array as any) || "")
          setValue("description", servicesData?.data?.description)
          setValue("gender", servicesData?.data?.gender)
          setValue("dob" as any, formattedDate)
        }
      } catch (error) {
        console.error("Error fetching user profile:", error)
      } finally {
        setIsLoadinga(false)
      }
    }

    fetchServicesAndLanguages()
  }, [getProfile, setValue])

  const onSubmit = (data: any) => {
    const formData = new FormData()

    formData.append("name", data?.name)
    formData.append("email", data?.email)
    formData.append("contactNo", data?.contactNo)

    formData.append("address", loactionSearchData || "")
    formData.append("language", data?.language)
    formData.append("dob", data?.dob)
    formData.append("region", data?.region)
    formData.append("description", data?.description)
    formData.append("services", data?.services)
    // formData.append("services", JSON.stringify(data?.services))

    // Append the file to FormData
    if (fileUpload) {
      formData.append("profilePic", fileUpload)
    } else if (data?.gender) {
      formData.append("gender", data?.gender)
    }

    if (loactionSearchData !== "") {
      accountUpdate(formData)
    } else {
      setErrorMsg("Please enter address")
    }
  }

  const handleClose = () => {
    setOpen(false)
  }

  useEffect(() => {
    if (updateData?.message) {
      toast.success(updateData?.message)
      setEditView(!editView)
    } else {
      toast.error((errorAccount as any)?.data?.message)
    }
  }, [updateData, errorAccount])

  return (
    <>
      {isLoading ? (
        <Typography fontSize={1}>
          <CenteredSpinner />
        </Typography>
      ) : (
        <Style.Overview>
          <Grid container>
            {/* <Grid xs={12} md={5} lg={4} className="pe-2 pe-xl-4">
              <Box className="review-card text-center p-4 d-flex flex-column align-items-center h-100">
                <Box className="mb-2">
                  <img
                    src={getProfile?.data?.profilePic || dummy}
                    className="profile-img-2"
                  />
                </Box>
                <Typography variant="body1" align="center" className="mb-3">
                  {getProfile?.data?.name}
                </Typography>
                <Typography variant="body1" align="center" className="mb-3">
                  <CiMail /> {getProfile?.data?.email}
                </Typography>
                <Typography variant="body1" align="center" className="mb-3">
                  <LuPhone /> {getProfile?.data?.contactNo}
                </Typography>
              </Box>
            </Grid> */}

            {/* {updateIsloading ? (
              <>
                <Typography fontSize={1}>
                  <CircularProgress style={{ color: "pink" }} />
                </Typography>
              </>
            ) : ( */}
            <Grid xs={12} className="ps-0">
              <Box className="review-card p-4">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Grid container className="mb-2">
                    <Grid xs={12} md={4} style={{ margin: "auto" }}>
                      <Typography variant="body1">
                        Change profile photo
                      </Typography>
                    </Grid>
                    <Grid xs={12} md={8} className="text-start">
                      <Box className="d-inline-block position-relative ">
                        <label
                          htmlFor="upload-profile-pic"
                          className="upload-label"
                        >
                          <img
                            src={
                              previewImage || getProfile?.data?.profilePic
                                ? previewImage || getProfile?.data?.profilePic
                                : dummy
                            }
                            className="profile-img"
                          />
                          <span
                            style={{
                              position: "absolute",
                              backgroundColor: "#fdaf18",
                              borderRadius: "50%",
                              padding: "3px",
                              width: "25px",
                              height: "25px",
                              bottom: "-5px",
                              right: "-10px",
                              border: "2px solid #fff",
                              display: "flex",
                              color: "#fff",
                            }}
                          >
                            <IoCamera />
                          </span>
                        </label>
                        <input
                          type="file"
                          id="upload-profile-pic"
                          className="d-none"
                          onChange={onChnage}
                          accept=".jpg,.png"
                        />
                      </Box>
                    </Grid>
                  </Grid>
                  {/*change profile-picture */}

                  <Grid container className="mt-4">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">Name</Typography>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <Input
                        placeholder="Name"
                        className="feature-field font-16"
                        fullWidth
                        {...register("name")}
                        style={{}}
                      />
                      <p className="text-danger">{errors.name?.message}</p>
                    </Grid>
                  </Grid>
                  <Grid container className="my-2">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">E-Mail</Typography>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <Input
                        placeholder="Email"
                        disabled
                        className="feature-field icon-field font-16"
                        fullWidth
                        {...register("email")}
                        startAdornment={
                          <InputAdornment position="start"></InputAdornment>
                        }
                      />
                      <p className="text-danger">{errors.email?.message}</p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-2">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">Phone Number</Typography>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <Input
                        type="text"
                        inputMode="numeric"
                        placeholder="Phone Number"
                        className="feature-field font-16 icon-field"
                        fullWidth
                        {...register("contactNo")}
                        onKeyPress={(event) => {
                          const keyCode = event.keyCode || event.which
                          const keyValue = String.fromCharCode(keyCode)
                          const numericRegex = /^[0-9]+$/

                          if (!numericRegex.test(keyValue)) {
                            event.preventDefault()
                          }
                        }}
                        startAdornment={
                          <InputAdornment position="start"></InputAdornment>
                        }
                      />
                      <p className="text-danger">{errors.contactNo?.message}</p>
                    </Grid>
                  </Grid>

                  <Grid container className="mb-2">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">Gender</Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      md={8}
                      sx={{
                        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "4px!important",
                          },
                        "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
                          padding: "4px!important",
                        },
                      }}
                    >
                      <Select
                        placeholder="Gender"
                        value={watch("gender" as any) || []}
                        disabled
                        className="feature-field font-16 border-0"
                        {...register("gender" as any)}
                        onChange={(event) => {
                          const selectedGender = event.target.value
                          setValue("gender" as any, selectedGender as any, {
                            shouldValidate: true,
                          })
                        }}
                        fullWidth
                      >
                        <MenuItem value="Male">Male</MenuItem>
                        <MenuItem value="Female">Female</MenuItem>
                        <MenuItem value="Other">Other</MenuItem>
                      </Select>
                      <p className="text-danger">
                        {(errors as any)?.gender?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-2">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">Language</Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      md={8}
                      sx={{
                        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "4px!important",
                          },
                        "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
                          padding: "4px!important",
                        },
                      }}
                    >
                      <Select
                        labelId="demo-simple-select-label"
                        className="feature-field font-16 border-0"
                        multiple
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                              width: 150,
                              overflowY: "auto", // Enable vertical scrolling
                              scrollbarWidth: "thin", // Width of the scrollbar
                              scrollbarColor: "#f07fa9 transparent", // Color of the scrollbar thumb
                              borderRadius: "12px", // Border radius of the scrollbar thumb
                              cursor: "pointer", // Change cursor to pointer
                            },
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                        id="demo-simple-select"
                        {...register("language" as any)}
                        value={watch("language" as any) || []}
                        onChange={(event) => {
                          const selectedValues = event.target.value
                          setValue("language" as any, selectedValues as any, {
                            shouldValidate: true,
                          })
                        }}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((selectedItemId: any) => {
                              const selectedItem =
                                countrySelect?.languages?.find(
                                  (item: any) => item?.name === selectedItemId,
                                )
                              return (
                                <Chip
                                  key={selectedItemId}
                                  label={selectedItem?.name}
                                  // onDelete={() => {
                                  //   const updatedValues = watch(
                                  //     "language" as any,
                                  //   ).filter((id: any) => id !== selectedItemId)
                                  //   setValue("language" as any, updatedValues, {
                                  //     shouldValidate: true,
                                  //   })
                                  // }}
                                />
                              )
                            })}
                          </Box>
                        )}
                        fullWidth
                      >
                        {countrySelect?.languages?.map((item: any) => (
                          <MenuItem key={item.name} value={item.name}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <p className="text-danger">
                        {(errors as any).language?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-2">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">Date of birth</Typography>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <Input
                        type="date"
                        placeholder="Age"
                        className="feature-field font-16"
                        fullWidth
                        {...register("dob" as any)}
                      />
                      <p className="text-danger">
                        {(errors as any).dob?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-2">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">Origin country</Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      md={8}
                      sx={{
                        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "4px!important",
                          },
                        "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
                          padding: "4px!important",
                        },
                      }}
                    >
                      <Select
                        placeholder="Origin country"
                        className="feature-field font-16 border-0"
                        {...register("region" as any)}
                        value={watch("region" as any) || []}
                        fullWidth
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                              width: 150,
                              overflowY: "auto", // Enable vertical scrolling
                              scrollbarWidth: "thin", // Width of the scrollbar
                              scrollbarColor: "#f07fa9 transparent", // Color of the scrollbar thumb
                              borderRadius: "12px", // Border radius of the scrollbar thumb
                              cursor: "pointer", // Change cursor to pointer
                            },
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                      >
                        {countrySelect?.countries?.map((item: any) => (
                          <MenuItem value={item?.name}>{item?.name}</MenuItem>
                        ))}
                      </Select>

                      <p className="text-danger">
                        {(errors as any).region?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-2">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">Location</Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      md={8}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          borderRadius: "10px!important",
                        },
                        "& .css-fedopx-MuiStack-root": {
                          width: "100%",
                        },
                      }}
                    >
                      <LocationSelect
                        placeholder="Enter Location"
                        setLocationSearchData={setLocationSearchData}
                        setErrorMsg={setErrorMsg}
                        loactionSearchData={loactionSearchData}
                      />

                      <p className="text-danger">
                        {errorMsg}
                        {/* {(errors as any).location?.message} */}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-2">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">
                        Services Providing
                      </Typography>
                    </Grid>

                    <Grid
                      xs={12}
                      md={8}
                      className="custom-field"
                      sx={{
                        "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                          {
                            padding: "4px!important",
                          },
                        "& .css-qiwgdb.css-qiwgdb.css-qiwgdb": {
                          padding: "4px!important",
                        },
                      }}
                    >
                      <InputLabel id="demo-simple-select-label"></InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        className="feature-field font-16 border-0"
                        multiple
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 250,
                              width: 150,
                              overflowY: "auto", // Enable vertical scrolling
                              scrollbarWidth: "thin", // Width of the scrollbar
                              scrollbarColor: "#f07fa9 transparent", // Color of the scrollbar thumb
                              borderRadius: "12px", // Border radius of the scrollbar thumb
                              cursor: "pointer", // Change cursor to pointer
                            },
                          },
                          anchorOrigin: {
                            vertical: "bottom",
                            horizontal: "left",
                          },
                          transformOrigin: {
                            vertical: "top",
                            horizontal: "left",
                          },
                        }}
                        id="demo-simple-select"
                        {...register("services" as any)}
                        value={watch("services" as any) || []}
                        onChange={(event) => {
                          const selectedValues = event.target.value
                          setValue("services" as any, selectedValues as any, {
                            shouldValidate: true,
                          })
                        }}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {selected.map((selectedItemId: any) => {
                              const selectedItem = serviceProvider?.data?.find(
                                (item: any) => item?._id === selectedItemId,
                              )
                              return (
                                <Chip
                                  key={selectedItemId}
                                  label={selectedItem?.name}
                                  // onDelete={() => {
                                  //   const updatedValues = watch(
                                  //     "services" as any,
                                  //   ).filter((id: any) => id !== selectedItemId)
                                  //   setValue("services" as any, updatedValues, {
                                  //     shouldValidate: true,
                                  //   })
                                  // }}
                                />
                              )
                            })}
                          </Box>
                        )}
                        fullWidth
                      >
                        {serviceProvider?.data?.map((item: any) => (
                          <MenuItem key={item?._id} value={item?._id}>
                            {item?.name}
                          </MenuItem>
                        ))}
                      </Select>

                      <p className="text-danger">
                        {(errors as any).services?.message}
                      </p>
                    </Grid>
                  </Grid>
                  <Grid container className="mb-2">
                    <Grid xs={12} md={4}>
                      <Typography variant="body1">Your bio</Typography>
                    </Grid>
                    <Grid xs={12} md={8}>
                      <Input
                        placeholder="Bio"
                        className="feature-field font-16 "
                        fullWidth
                        {...register("description" as any)}
                      />
                      <p className="text-danger">
                        {(errors as any).description?.message}
                      </p>
                    </Grid>
                  </Grid>

                  <Grid container className="mb-2">
                    <Grid md={12}>
                      <Box className="text-end">
                        <Button
                          className="view-profile text-capitalize"
                          type="submit"
                        >
                          {updateIsloading ? (
                            <>
                              <Typography fontSize={1}>
                                <CircularProgress
                                  style={{ color: "pink" }}
                                  size={20}
                                />
                              </Typography>
                            </>
                          ) : (
                            "Save"
                          )}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            {/* )} */}
          </Grid>
        </Style.Overview>
      )}
      {/* <ChangePasswordModal opens={open} handleClose={handleClose} /> */}
    </>
  )
}
export default EditAccount
