import * as React from "react"
import PropTypes from "prop-types"
import { styled, useTheme } from "@mui/material/styles"
import { Typography, Box } from "@mui/material"
import MuiDrawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import Navbar from "../Navbar/Navbar"
import AdminHeader from "../ProviderHeader/ProviderHeader"
import { Outlet, useNavigate } from "react-router-dom"
import Logo from "../../images/mommy-help-logo.png"
import LogoPhone from "../../images/logo-home.svg"
import * as Style from "./style"
import "./style.css"
import { Theme, colors } from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"

interface dashProps {
  // children?: any
}
const drawerWidth = 300

const openedMixin = (theme: any) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
})
const closedMixin = (theme: any) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
})

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

interface propsType {
  open: any
  theme: any
}
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: propsType) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}))

const CustomerDashboardTemplate = ({}: dashProps) => {
  const navigate = useNavigate()
  const theme = useTheme()

  const [open, setOpen] = React.useState(true)

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 900) {
        setOpen(false)
      } else {
        setOpen(true)
      }
    }

    window.addEventListener("resize", handleResize)

    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Box sx={{ display: "flex", width: "100%" }}>
      {/* Header */}
      <AdminHeader
        handleDrawerOpen={handleDrawerOpen}
        position="fixed"
        open={open}
        theme=""
      />

      {/* Sidebar Drawer */}
      <Style.Header>
        <Drawer variant="permanent" open={open} theme={"" as any}>
          <DrawerHeader>
            {open ? (
              <Box
                mt={1}
                ml={3}
                sx={{ img: { maxWidth: "150px" }, cursor: "pointer" }}
                onClick={() => navigate("/dashboard")}
              >
               <Typography variant="h6" className="text-white dash-head">
                  Send A Therapist
                </Typography>
                {/* <img src={Logo} alt="LOGO" title="home" /> */}
              </Box>
            ) : (
              <Box
                ml={0}
                sx={{
                  img: { maxWidth: "40px" },
                  cursor: "pointer",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={LogoPhone}
                  alt="LOGO"
                  title="home"
                  className="img-fluid rounded"
                />
              </Box>
            )}
            <IconButton
              style={{ marginTop: "16px" }}
              onClick={handleDrawerClose}
            >
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Box pt={1} px={1} pb={0} className="custom-scrollbar max-h-100">
            <Box pb={0} pl={3}>
              {open && (
                <Typography variant="h6" className="text-white dash-head">
                  Provider Dashboard
                </Typography>
              )}{" "}
            </Box>
            <Box
              sx={{
                px: 1.5,
              }}
            >
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                sx={{
                  ...(open && { display: "none" }),
                  background: "rgba(255, 255, 255, 0.1)",
                  borderRadius: "10px",
                }}
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <Navbar open={open as any} />
          </Box>
        </Drawer>
      </Style.Header>
      {/* Wrapper for Pages */}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          mt: 8.3,
          background: theme.palette.gray.main600,
          minHeight: "calc(100vh - 96px)",
          width: "calc(100vw - 300px)",
          overflow: "hidden",
          "@media (max-width: 900px)": {
            p: 1,
          },
        }}
      >
        <Outlet />
      </Box>
    </Box>
  )
}
export default CustomerDashboardTemplate
