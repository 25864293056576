import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  ListItemIcon,
  InputBase,
} from "@mui/material"
import { styled, alpha } from "@mui/material/styles"
import { Logout } from "@mui/icons-material"
import MenuIcon from "@mui/icons-material/Menu"
import MuiAppBar from "@mui/material/AppBar"
import * as Style from "./style"
import { AiOutlineSearch } from "react-icons/ai"
import { useAppSelector } from "../../app/hooks"
import { logout } from "../../features/Auth/authSlice"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { useAppDispatch } from "../../app/store"
import { useGetProfileUserApiQuery } from "../../features/OverViewAuth/overViewApi"
import bellIcon from "../../images/notification.svg"
import dummy from "../../images/dummy-image.jpg"
import { IoMenuOutline } from "react-icons/io5"
import { api, useAccountProfileQuery } from "../../features/Auth/authApi"
import LogoutConfirm from "../Navbar/logout"
import NotificationChat from "./notificationModal/NotificationPage"
import LogoPhone from "../../images/logo-home.svg"
import message from "../../images/message.svg"
import chat from "../../images/chat.svg"
const drawerWidth = 300

interface propsAppbar {
  open: any
  theme: any
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }: propsAppbar) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

interface propsCustomer {
  open: any
  position: any
  handleDrawerOpen: any
}

const CustomerHeader = ({
  open,
  position,
  handleDrawerOpen,
}: propsCustomer) => {
  const [anchorEl, setAnchorEl] = useState(null)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const view = urlSearchParams.get("view")
  const userInfo = useAppSelector((state: any) => state.auth?.userInfo)
  const [logoutModal, setLogoutModal] = React.useState(false)
  const handleLogoutOpen = () => setLogoutModal(true)
  const handlelogoutClose = () => setLogoutModal(false)

  const location = useLocation()
  const { type } = useParams()
  const pathLocation = location?.pathname
  const navigate = useNavigate()
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const lastPathSegment = window.location.pathname
    .split("/")
    .filter((segment) => segment !== "")
    .pop()

  const capitalizedLastPathSegment = lastPathSegment
    ? lastPathSegment.charAt(0).toUpperCase() + lastPathSegment.slice(1)
    : ""

  const logoutButton = () => {
    handleLogoutOpen()
  }

  return (
    <Style.Header>
      <AppBar
        position={position}
        open={open}
        style={{ boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.23)" }}
        theme={"" as any}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            background: "pink?.main",
          }}
        >
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              // color="inherit"
              // aria-label="open drawer"
              // onClick={handleDrawerOpen}
              // edge="start"
              sx={{
                background: "#F475A4",
                height: "64px",
                width: "65px",
                marginLeft: -3,

                "@media (max-width:600px)": {
                  height: "56px",
                  width: "65px",
                },
                ...(open && { display: "none" }),
              }}
            >
              <Box
                ml={0}
                sx={{
                  img: { maxWidth: "40px" },
                  cursor: "pointer",
                  borderRadius: "10px",
                  marginLeft: "10px",
                  marginTop: "10px",
                  "@media (max-width:600px)": {
                    marginLeft: "15px",
                    marginTop: "5px",
                  },
                }}
                onClick={() => navigate("/dashboard")}
              >
                <img
                  src={LogoPhone}
                  alt="LOGO"
                  title="home"
                  style={{ background: "#F475A4" }}
                  className="img-fluid rounded "
                />
              </Box>{" "}
            </Box>

            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                marginLeft: open ? "0px" : "10px",
                "@media (max-width:900px)": {
                  fontSize: "15px",
                },
              }}
            >
              {capitalizedLastPathSegment == "Dashboard"
                ? "Overview"
                : type == "applied-job" || type == "applied-jobs"
                ? "My Jobs"
                : capitalizedLastPathSegment == "Applied-job"
                ? "Applied Jobs"
                : capitalizedLastPathSegment == "Approved-job"
                ? "Approved Jobs"
                : pathLocation.includes("job-detail")
                ? "My Jobs"
                : pathLocation.includes("verify-otp")
                ? "Active booking"
                : capitalizedLastPathSegment == "All" ||
                  capitalizedLastPathSegment == "Completed" ||
                  capitalizedLastPathSegment == "Expired"
                ? "Job History"
                : pathLocation.includes("write-review")
                ? "Rating & Review"
                : capitalizedLastPathSegment == "Rating"
                ? "Rating & Review"
                : capitalizedLastPathSegment == "Help-center"
                ? "Help Center"
                : pathLocation.includes("approved-detail") &&
                  view !== "booking-detail"
                ? "Approved jobs"
                : pathLocation.includes("jobs-details")
                ? "Job details"
                : pathLocation.includes("approved-detail") &&
                  view == "booking-detail"
                ? "Booking Details"
                : capitalizedLastPathSegment}
              <Typography
                variant="body2"
                fontWeight="300"
                sx={{
                  "@media (max-width:600px)": {
                    fontSize: "12px",
                  },
                }}
              >
                Welcome {userInfo?.user?.name}
              </Typography>
            </Typography>
          </Box>
          <Box
            display="flex"
            sx={{ cursor: "pointer", gap: "5px" }}
            alignItems="center"
          >
            <Box className="">
              <Button
                onClick={() => navigate(`/dashboard/help-center`)}
                className="notification-btn1"
              >
                <img src={chat} alt="message" />
              </Button>
            </Box>

            <div className="">
              <NotificationChat />
            </div>

            <div className="">
              <Avatar
                src={userInfo?.user?.profilePic || dummy}
                alt="Profile Picture"
                onClick={handleClick}
                style={{ cursor: "pointer" }}
              />
              <Menu
                className=""
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={() => {
                    navigate("/dashboard/myaccount"), handleClose()
                  }}
                >
                  My Profile
                </MenuItem>
                <MenuItem onClick={logoutButton}>Logout</MenuItem>
              </Menu>
            </div>
          </Box>
        </Toolbar>
      </AppBar>
      <LogoutConfirm
        logoutModal={logoutModal as any}
        handleClose={handlelogoutClose}
      />
    </Style.Header>
  )
}

export default CustomerHeader

CustomerHeader.propTypes = {
  open: PropTypes.bool.isRequired,
  position: PropTypes.string.isRequired,
  handleDrawerOpen: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
}
