enum MODALNAME {
  CLOSED,
  LOGIN,
  Signup,
  RESET_PASSWORD,
  FORGET_PASSWORD,
  CONFIRM_PASSWORD,
}

export { MODALNAME }
