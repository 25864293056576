import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material"

import serviceProviderIcon from "../../images/service-provider-icon.svg"
import serviceComplete from "../../images/complete-booking-icon.svg"
import serviceCen from "../../images/cancel-booking-icon.svg"
import serviceApply from "../../images/applied-icon.svg"
import serviceApproved from "../../images/circle-active.svg"
import serviceExpired from "../../images/cross-pink.png"
// import serviceProviderIcon from "../../images/service-provider.svg"
import tableCellImg from "../../images/table-img.png"
import { FaStar } from "react-icons/fa6"
import * as Style from "./style"
import { Link, useNavigate, useSearchParams } from "react-router-dom"
import dummy from "../../images/dummy-image.jpg"
import noBooking from "../../images/nobooking.png"
import noData from "../../images/no-results.svg"

import {
  useGetAvailablePostQuery,
  useProviderJobPostQuery,
  useProvidersStatusQuery,
} from "../../features/Auth/authApi"

import "./style.css"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import "react-circular-progressbar/dist/styles.css"
import { toast } from "react-toastify"
import RatingStars from "../common/rating"
import CenteredSpinner from "../common/CenteredSpinner"
import { MdOutlineLocationOn } from "react-icons/md"
import { appliedPostKey, logout } from "../../features/Auth/authSlice"
import { useAppDispatch } from "../../app/store"
import CountdownTimer, { TimeCounter } from "./TimeCount"
import { useAppSelector } from "../../app/hooks"
import ReviewmPopup from "./reviewnavigate/reviewmodal"
import StorylaneEmbed from "../StorylaneEmbed"

interface props {}

const CustomerLayout = ({}: props) => {
  const [ReviewPop, setReviewPop] = React.useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [datareview, setDatareview] = useState<string>("")
  const newUserDemo = useAppSelector((state) => state.auth?.newUserDemo)
  const { data } = useProvidersStatusQuery({})
  const dispatch = useAppDispatch()
  const urlSearchParams = new URLSearchParams(window.location.search)

  const _reviewtype = urlSearchParams.get("type")
  const {
    data: availabeJobs,
    isLoading,
    error: availabeJobsError,
  } = useGetAvailablePostQuery({
    pageLimit: "6",
  })

  const {
    data: activePost,
    refetch,
    error: activePostError,
    isLoading: activeIsloading,
    isFetching: activeIsFecthing,
  } = useProviderJobPostQuery()

  const TimerStyles2 = {
    width: "108px", // Adjust as needed
    height: "37px", // Adjust as needed
    backgroundColor: "#f0f0f0",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #868686",
    padding: "0px 20px 0px 20px",
    gap: "10px",
    margin: "0 auto",
    fontSize: "10px",
  }
  const TimerStyles = {
    width: "108px", // Adjust as needed
    height: "40px", // Adjust as needed
    backgroundColor: "rgb(244 117 164 / 18%)",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "2px dashed #f475a4",
    padding: "0px 20px 0px 20px",
    gap: "10px",
    margin: "0 auto",
    fontSize: "10px",
  }
  const navigate = useNavigate()

  const redirectPath = (name: any) => {
    switch (name) {
      case "Jobs to apply":
        navigate("/dashboard/jobs")
        break
      case "Applied":
        navigate("/dashboard/applied-job")
        break
      case "Approved":
        navigate("/dashboard/approved-job")
        break
      case "Completed":
        navigate("/dashboard/job-history/Completed")
        break
      case "Expired":
        navigate("/dashboard/job-history/Expired")
        break
      default:
        navigate("/dashboard/job-history/Declined")
    }
  }

  // copy phone number logic

  const handleDateClick = (type: string) => {
    const tempInput = document.createElement("input")

    tempInput.value = type
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand("copy")
    document.body.removeChild(tempInput)
    toast.success("Contact Copied" + " " + type, { autoClose: 1000 })
  }

  const currenTimeActive = useAppSelector(
    (state) => state.auth?.currenTimeActive,
  )

  useEffect(() => {
    if (_reviewtype == "review-post") {
      setReviewPop(true)
    }
  }, [_reviewtype])

  useEffect(() => {
    if (datareview == "overview") {
      setSearchParams({ type: "" })
    }
  }, [datareview])

  return (
    <>
      {newUserDemo == true ? (
        <StorylaneEmbed />
      ) : (
        <>
          {isLoading ? (
            <CenteredSpinner />
          ) : (
            <Style.Overview>
              <>
                <ReviewmPopup
                  ReviewPop={ReviewPop}
                  setDatareview={setDatareview as any}
                />
                <Box>
                  <Typography align="left" variant="h2" mb={2}>
                    Service in Progress
                  </Typography>
                </Box>

                <TableContainer
                  component={Paper}
                  className="dashboard-table-wrapper custom-scrollbar "
                >
                  <Table
                    sx={{
                      minWidth: 650,
                      border: "none",
                      backgroundColor: "transparent",
                    }}
                    className="dashboard-table"
                    aria-label="simple table"
                  >
                    {activePost?.data?.isData !== false && (
                      <TableHead>
                        <TableRow>
                          <TableCell></TableCell>

                          <TableCell align="center">Name</TableCell>
                          <TableCell align="center">Service Required</TableCell>
                          <TableCell align="center">
                            Location <MdOutlineLocationOn />
                          </TableCell>
                          <TableCell align="center">
                            Service Started on
                          </TableCell>

                          {/* {activePost?.data?.isJobStartOtpVerified !== false && ( */}
                          <TableCell align="center">
                            Time since service started
                          </TableCell>
                          {/* )} */}
                          <TableCell align="center">Action</TableCell>
                        </TableRow>
                      </TableHead>
                    )}

                    {activeIsloading ? (
                      <CenteredSpinner />
                    ) : (
                      <>
                        <TableBody>
                          {activePost?.data.till !== undefined ? (
                            <>
                              {activePost?.data?.isData !== true ? (
                                <TableRow
                                  sx={{
                                    "&:last-child td, &:last-child th": {
                                      border: 0,
                                    },
                                    cursor: "pointer",
                                  }}
                                  onClick={() =>
                                    navigate(
                                      `/dashboard/verify-otp/${activePost?.data._id}`,
                                    )
                                  }
                                >
                                  <TableCell>
                                    <div className="img-wrapper">
                                      <img
                                        src={
                                          activePost?.data.seekerData
                                            ?.profilePic || dummy
                                        }
                                        style={{
                                          width: "74px",
                                          height: "74px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    component="td"
                                    align="center"
                                    scope="row"
                                  >
                                    <span className="text flex-column gap-2">
                                      {activePost?.data.seekerData?.name}
                                      {activePost?.data.seekerData?.rating
                                        ?.$numberDecimal !== "0" && (
                                        <span className="minh-1">
                                          <RatingStars
                                            rating={
                                              activePost?.data.seekerData
                                                ?.rating?.$numberDecimal
                                            }
                                          />
                                        </span>
                                      )}
                                    </span>
                                  </TableCell>

                                  <TableCell align="center">
                                    <span>
                                      {activePost?.data?.service?.name}
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="location">
                                      <span>{activePost?.data.location}</span>
                                    </span>
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="datetime">
                                      <span className="d-flex flex-column">
                                        <span className="weekday">
                                          {/* {activePost?.data.availabeJobs} */}
                                          <FormatDate
                                            dateString={activePost?.data.from}
                                          />
                                        </span>
                                        {/* <span> */}
                                        <span>
                                          <FormatChangeTime
                                            dateString={activePost?.data.from}
                                            // timezone={"india"}
                                          />
                                        </span>
                                        <span>
                                          <FormatTime
                                            dateString={activePost?.data.from}
                                          />
                                        </span>
                                      </span>
                                      {/* </span> */}
                                    </span>
                                  </TableCell>

                                  <TableCell>
                                    {activePost?.data?.isJobStartOtpVerified !==
                                    false ? (
                                      <Button style={TimerStyles}>
                                        <TimeCounter
                                          from={activePost?.currentDate}
                                          till={activePost?.data.till}
                                          postActiveTimeStop={
                                            activePost?.data?.activeJobflow
                                          }
                                        />
                                      </Button>
                                    ) : (
                                      <>
                                        <Button
                                          style={TimerStyles2}
                                          className="mt-3 "
                                        >
                                          <CountdownTimer
                                            from={activePost?.data?.from as any}
                                            till={activePost?.data.till}
                                            postActiveTimeStop={
                                              activePost?.data
                                            }
                                          />
                                        </Button>
                                        <span
                                          style={{ color: "red" }}
                                          className="minh-1 mt-2 pb-3"
                                        >
                                          Service in waiting zone
                                        </span>
                                      </>
                                    )}
                                  </TableCell>
                                  <TableCell align="center">
                                    <span className="minh-1">
                                      <Link
                                        to={`/dashboard/verify-otp/${activePost?.data._id}`}
                                        className="view-profile"
                                      >
                                        {activePost?.data?.activeJobflow ==
                                          "JOB_ACTIVE" ||
                                        activePost?.data?.activeJobflow ==
                                          "CUSTOMER_STOP_OTP" ? (
                                          "Enter OTP"
                                        ) : activePost?.data?.activeJobflow ==
                                          "STOP_SERVICE" ? (
                                          "Genrate Otp"
                                        ) : (
                                          <>View Service </>
                                        )}
                                      </Link>
                                    </span>
                                  </TableCell>
                                </TableRow>
                              ) : (
                                <TableRow>
                                  <TableCell
                                    align="center"
                                    colSpan={7}
                                    sx={{
                                      width: "100%",
                                      pointerEvents: "none",
                                      border: "none",
                                    }}
                                  >
                                    <img
                                      src={noBooking}
                                      style={{
                                        width: "100%",
                                        maxWidth: "700px",
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              )}
                            </>
                          ) : (
                            <TableRow>
                              <TableCell
                                align="center"
                                colSpan={7}
                                sx={{
                                  width: "100%",
                                  pointerEvents: "none",
                                  border: "none",
                                }}
                              >
                                <img
                                  src={noBooking}
                                  style={{
                                    width: "100%",
                                    maxWidth: "700px",
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          )}
                        </TableBody>
                      </>
                    )}
                  </Table>
                </TableContainer>
              </>

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box>
                      <Typography align="left" variant="h2" my={4}>
                        Booking details
                      </Typography>
                    </Box>
                    <div className="custom-scrollbar w-100 overflow-auto cursor-pointer">
                      <Style.Flexbox className="justify-start">
                        {data?.data?.map((item: any) => (
                          <React.Fragment key={item?.name}>
                            <Box
                              className="Status overview-card  text-center"
                              onClick={() => redirectPath(item?.name)}
                            >
                              {item?.name == "Jobs to apply" && (
                                <img
                                  src={serviceProviderIcon}
                                  className="mb-2 "
                                />
                              )}
                              {item?.name == "Completed" && (
                                <img src={serviceComplete} className="mb-2" />
                              )}
                              {item?.name == "Cancelled" && (
                                <img src={serviceCen} className="mb-2" />
                              )}
                              {item?.name == "Applied" && (
                                <img src={serviceApply} className="mb-2" />
                              )}
                              {item?.name == "Approved" && (
                                <img src={serviceApproved} className="mb-2" />
                              )}
                              {item?.name == "Expired" && (
                                <img src={serviceExpired} className="mb-2" />
                              )}
                              <Typography
                                className="ovr-sm-text"
                                variant="body1"
                              >
                                {item?.name}
                              </Typography>
                              <Typography
                                variant="body1"
                                className="pink-text pink-lg-text mt-2"
                              >
                                {item?.value}
                              </Typography>
                            </Box>
                          </React.Fragment>
                        ))}
                      </Style.Flexbox>
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <Grid item xs={12}>
                    <Box>
                      <Typography align="left" variant="h2" mb={2}>
                        Jobs to apply
                      </Typography>

                      <Style.Overview>
                        <TableContainer
                          component={Paper}
                          className="dashboard-table-wrapper custom-scrollbar"
                        >
                          <Table
                            sx={{
                              minWidth: 650,
                              border: "none",
                              backgroundColor: "transparent",
                            }}
                            className="dashboard-table "
                            aria-label="simple table"
                          >
                            {availabeJobs?.data?.length > 0 && (
                              <TableHead>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell align="center">Name</TableCell>
                                  <TableCell
                                    align="center"
                                    className="service-head"
                                  >
                                    Service Required
                                  </TableCell>
                                  <TableCell align="center">Phone</TableCell>
                                  <TableCell align="center">
                                    Location <MdOutlineLocationOn />
                                  </TableCell>
                                  <TableCell align="center">
                                    Service Starts on
                                  </TableCell>
                                  <TableCell align="center">Action</TableCell>
                                </TableRow>
                              </TableHead>
                            )}
                            <TableBody>
                              {availabeJobs?.data?.length > 0 ? (
                                availabeJobs?.data?.map((row: any) => (
                                  <TableRow
                                    key={row.serviceseeker}
                                    sx={{
                                      "&:last-child td, &:last-child th": {
                                        border: 0,
                                      },
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      navigate(
                                        `/dashboard/job-detail/${
                                          row?._id
                                        }?type=${"applied-job"}`,
                                        dispatch(
                                          appliedPostKey(null as any),
                                        ) as any,
                                      )
                                    }}
                                  >
                                    <TableCell>
                                      <div
                                        style={{
                                          width: "74px",
                                          height: "74px",
                                          objectFit: "cover",
                                        }}
                                      >
                                        <img
                                          src={
                                            row.seekerData?.profilePic || dummy
                                          }
                                          style={{
                                            width: "74px",
                                            height: "74px",
                                            objectFit: "cover",
                                          }}
                                        />
                                      </div>
                                    </TableCell>
                                    <TableCell
                                      component="td"
                                      align="center"
                                      scope="row"
                                    >
                                      <span className="text flex-column gap-2">
                                        {row?.seekerData?.name}
                                        <span className="minh-1">
                                          <RatingStars
                                            rating={
                                              row?.seekerData?.rating
                                                ?.$numberDecimal
                                            }
                                          />
                                        </span>
                                      </span>
                                    </TableCell>
                                    <TableCell align="center">
                                      <span>{row.service?.name}</span>
                                    </TableCell>
                                    <TableCell
                                      align="center"
                                      onClick={() =>
                                        handleDateClick(
                                          row?.seekerData?.contactNo,
                                        )
                                      }
                                    >
                                      <span style={{ cursor: "copy" }}>
                                        {row?.seekerData?.contactNo}
                                      </span>
                                    </TableCell>
                                    <TableCell align="center">
                                      <span className="location">
                                        <span className="truncate">
                                          {row.location}
                                        </span>
                                      </span>
                                    </TableCell>
                                    <TableCell align="center">
                                      <span className="datetime">
                                        <span>
                                          {/* {(() => {
                                      const utcDate = moment.utc(row?.from)
                                      const localDate = utcDate.local()
                                      return localDate.format("ddd-MM-yyyy") // Adjust the format as needed
                                    })()} */}

                                          <span className="datetime">
                                            <span className="d-flex flex-column">
                                              <span className="weekday">
                                                <FormatDate
                                                  dateString={row?.from}
                                                />
                                              </span>
                                              {/* <span> */}
                                              <span>
                                                <FormatChangeTime
                                                  dateString={row?.from}
                                                />
                                              </span>
                                              <span>
                                                <FormatTime
                                                  dateString={row?.from}
                                                />
                                              </span>
                                            </span>
                                            {/* </span> */}
                                          </span>

                                          {/* <FormatTime dateString={row?.from} /> */}
                                        </span>
                                      </span>
                                    </TableCell>
                                    <TableCell align="center">
                                      <span>
                                        <Link
                                          to={`/dashboard/job-detail/${
                                            row?._id
                                          }?type=${"applied-job"}`}
                                          className="view-profile"
                                          onClick={() =>
                                            dispatch(
                                              appliedPostKey(null as any),
                                            )
                                          }
                                        >
                                          Apply
                                        </Link>
                                      </span>
                                    </TableCell>
                                  </TableRow>
                                ))
                              ) : (
                                <TableRow style={{ pointerEvents: "none" }}>
                                  <TableCell
                                    align="center"
                                    colSpan={7}
                                    sx={{ width: "100%" }}
                                  >
                                    <img
                                      src={noData}
                                      style={{
                                        width: "100%",
                                        maxWidth: "180px",
                                      }}
                                    />
                                    <span
                                      className="no-data-text"
                                      style={{
                                        fontSize: "20PX",
                                        fontWeight: "600",
                                        color: "#000",
                                      }}
                                    >
                                      No data found
                                    </span>
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Style.Overview>
                    </Box>
                  </Grid>

                  {availabeJobs?.data?.length > 0 &&
                    availabeJobs.data.length > 5 && (
                      <>
                        <Grid item xs={5}>
                          {" "}
                          <span>
                            {" "}
                            Total Results: {availabeJobs?.data?.length}
                          </span>
                        </Grid>
                        <Grid
                          item
                          xs={7}
                          className="d-flex justify-content-end pe-4"
                        >
                          <Button
                            className="view-profile "
                            style={{
                              padding: "5px",
                              fontSize: "12px",
                              color: "white",
                              backgroundColor: "#F475A4",
                            }}
                            onClick={() => navigate("/dashboard/jobs")}
                          >
                            More
                          </Button>
                        </Grid>
                      </>
                    )}
                </Grid>
              </Box>
              <Box>
                <Grid
                  container
                  spacing={2}
                  style={{ marginTop: "50px", marginBottom: "50px" }}
                ></Grid>
              </Box>
            </Style.Overview>
          )}
        </>
      )}
    </>
  )
}

export default CustomerLayout
