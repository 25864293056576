import { configureStore, combineReducers } from "@reduxjs/toolkit"
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux"
import { api } from "../features/Auth/authApi"
import auth, { logout } from "../features/Auth/authSlice"
import wrapper from "../features/Auth/WrapperSlice"
import storage from "redux-persist/lib/storage"
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist"
import authModalSlice from "../features/AuthModal/authModalSlice"
import overViewAuth from "../features/OverViewAuth/overViewSlice"
import { overViewApi } from "../features/OverViewAuth/overViewApi"
// import socketReducer from "../features/Socket/socketSlice"
import { notificationsApi } from "../features/Socket/socketApi"
import { setupListeners } from "@reduxjs/toolkit/query"
import { emailVerifyAuth } from "../features/EmailVerifyAuth/emailVerifyAuth"
import emailVerifySlice from "../features/EmailVerifyAuth/emailVerifySlice"
import { locationApi } from "../features/locationFunc/locationApi"
import { chatViewApi } from "../features/ChatAuth/chatApi"
import chatAuth, {
  _setMessage,
  _setSearchTickets,
  _setTicket,
  _setUnseenCount,
} from "../features/ChatAuth/chatSlice"
import { chatMessageApi } from "../features/ChatSocket/chat"
import unauthorizedMiddleware from "./unauthorizedMiddleware"

const persistConfig = {
  key: "root",
  whitelist: ["funnelData", "auth", "wrapper"],
  version: 1,
  storage,
}

// const unauthorizedMiddleware = (store: any) => (next: any) => (action: any) => {
//   if (action.type.endsWith("rejected")) {
//     // Assuming your error structure has a status field indicating the HTTP status
//     let status = action.error?.status || action.payload?.status
//     if (status === 401) {
//       // Dispatch a logout action or handle the error globally
//       console.log("Unauthorized access detected, handling logout.")
//       if (store.getState().auth.token)
//         store.dispatch(api.endpoints.LogoutProvider.initiate())
//       store.dispatch(api.util.resetApiState())
//       store.dispatch(overViewApi.util.resetApiState())
//       store.dispatch(notificationsApi.util.resetApiState())
//       store.dispatch(chatMessageApi.util.resetApiState())
//       store.dispatch(chatViewApi.util.resetApiState())
//       store.dispatch(_setMessage(null as any))
//       store.dispatch(_setTicket(null as any))
//       store.dispatch(_setSearchTickets(null as any))
//       store.dispatch(_setUnseenCount(null as any))
//       // Optionally, redirect or handle other UI effects
//     }
//   }
//   return next(action)
// }

const rootReducer = combineReducers({
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [emailVerifyAuth.reducerPath]: emailVerifyAuth.reducer,
  [api.reducerPath]: api.reducer,
  [overViewApi.reducerPath]: overViewApi.reducer,
  [chatMessageApi.reducerPath]: chatMessageApi.reducer,
  [chatViewApi.reducerPath]: chatViewApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer,

  auth,
  authModal: authModalSlice,
  authOverView: overViewAuth,
  authChatView: chatAuth,
  // postCreate: postcreateSlice,
  emailVerifySlice,
  wrapper,
  // socket: socketReducer,
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat([
      api.middleware,
      overViewApi.middleware,
      notificationsApi.middleware,
      emailVerifyAuth.middleware,
      chatViewApi.middleware,
      chatMessageApi.middleware,
      locationApi.middleware,
      unauthorizedMiddleware,
    ]),
})

export const persistor = persistStore(store)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>
export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
setupListeners(store.dispatch)
