import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { api } from "../Auth/authApi"
import { MODALNAME } from "../../constants"

const slice = createSlice({
  name: "authModal",
  initialState: { modalName: MODALNAME.CLOSED } as {
    modalName: any
  },
  reducers: {
    setModalName: (state, action: PayloadAction<number>) => {
      state.modalName = MODALNAME[action.payload]
    },

    closeModal: (state: any) => {
      state.modalName = MODALNAME.CLOSED
    },
  },
  extraReducers: (builder) => {
    // builder.addMatcher(
    //   api.endpoints.login.matchFulfilled,
    //   (state, { payload }) => {
    //     state.token = payload.access_token
    //     // state.user = payload.result.user;
    //   },
    // )
  },
})

export default slice.reducer
export const { closeModal, setModalName } = slice.actions

export const currentModalName = (state: RootState) => state.authModal
