import moment from "moment"
// import moment from "moment-timezone"

interface props {
  dateString: string
}

export function FormatDate({ dateString }: props) {
  const utcDate = moment.utc(dateString)
  const localDate = utcDate.local()
  return localDate.format("dddd")
}

interface Props {
  dateString: string
}

export function FormatTime({ dateString }: Props) {
  const utcDate = moment.utc(dateString)
  const localDateTime = utcDate.local()
  return <div>{localDateTime.format("D-MMMM")}</div>
}

interface Props {
  dateString: string
}

export function FormatSlot({ dateString }: Props) {
  const date = moment(dateString, "YYYY-MM-DD")

  const formattedDate = date.format("Do MMMM YYYY")

  return <div>{formattedDate}</div>
}

// import moment from "moment-timezone"
// interface Props {
//   dateString: string
//   timezone: string // Timezone identifier for the country, e.g., 'America/New_York' for USA Eastern Time
// }

// export const FormatChangeTime: React.FC<Props> = ({ dateString, timezone }) => {
//   const localDateTime = moment.utc(dateString).tz(timezone) as any
//   return <div>{localDateTime.format("h:mmA")}</div>
// }

interface Props {
  dateString: string
}

export function FormatChangeTime({ dateString }: Props) {
  const utcDate = moment.utc(dateString)
  const localDateTime = utcDate.local()
  return <div>{localDateTime.format("h:mm a")}</div>
}

export function FormatYear({ dateString }: Props) {
  const utcDate = moment.utc(dateString)
  const localDateTime = utcDate.local()
  return <div>{localDateTime.format("YYYY")}</div>
}
