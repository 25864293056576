import React, { useState } from "react"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Input,
} from "@mui/material"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import { styled } from "@mui/material/styles"
import Switch, { SwitchProps } from "@mui/material/Switch"
import { FaChevronLeft } from "react-icons/fa6"
import notifyIcon from "../../images/notify-icon.svg"
import { FaStar } from "react-icons/fa6"
import * as Style from "./style"
import offerImg from "../../images/cooking.png"
import { IoCalendarClearOutline, IoLocationOutline } from "react-icons/io5"
import { FaChevronRight } from "react-icons/fa6"
import { Link, useNavigate, useParams } from "react-router-dom"
import tableCellImg from "../../images/table-img.png"
import dummyImage from "../../images/dummy.png"
import dummy from "../../images/dummy-image.jpg"
import babyImg from "../../images/babysitting.png"
import moment from "moment"
import CircularIndeterminate from "../Loading/Loading"
import RatingStars from "../common/rating"
import chat from "../../images/chat.svg"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
  FormatYear,
} from "../common/utctolocalDate"
import CenteredSpinner from "../common/CenteredSpinner"
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab"
import { useApprovedJobsQuery } from "../../features/Auth/authApi"
import { CiMail } from "react-icons/ci"
import { LuPhone } from "react-icons/lu"
import { useCreateTicketMutation } from "../../features/ChatAuth/chatApi"
import { Height } from "@mui/icons-material"
import PreviewImage from "../common/previewImage"
const BookingServiceDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const [showFullTimeline, setShowFullTimeline] = useState(false)
  const [chatView, { data: CharacterData }] = useCreateTicketMutation()

  const urlSearchParams = new URLSearchParams(window.location.search)
  const view = urlSearchParams.get("view")
  const handleToggleTimeline = () => {
    setShowFullTimeline(!showFullTimeline)
  }
  const { data: approvedjobs, isLoading } = useApprovedJobsQuery({ postId: id })

  const handleChatView = async () => {
    const payload = {
      postId: id,
    }

    try {
      const response: any = await chatView(payload)
      if (response?.data?.status === 200) {
        const chatId = response.data?.data?._id
        const adminId = response.data?.data?.adminstration

        if (chatId && adminId) {
          navigate(`/dashboard/help-center?chatId=${chatId}&adminId=${adminId}`)
        } else {
          console.error("Invalid chatId or adminId in response:", response)
        }
      } else {
        console.error("API call was not successful:", response)
      }
    } catch (error) {
      console.error("Error making API call:", error)
    } finally {
    }
  }

  const handlenavigate = () => {
    if (view == "booking-detail") {
      navigate("/dashboard/job-history/All")
    } else {
      navigate(-1)
    }
  }

  // image preview on click

  const handleImageClick = (image: string) => {
    setSelectedImage(image)
  }

  const handleClosePreview = () => {
    setSelectedImage(null)
  }

  // *******
  return (
    <>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <Box className="">
            {approvedjobs?.data?.map((item: any) => (
              <Style.Overview>
                <Box className="feature-card py-4 px-2">
                  <Box className="d-flex justify-content-between align-items-center pb-3">
                    <Box className="d-flex align-items-center gap-4">
                      <Button
                        className="back-btn mb-0"
                        onClick={handlenavigate}
                      >
                        <FaChevronLeft />
                      </Button>
                      <Typography variant="h2" className=" mb-0">
                        Service details
                      </Typography>
                    </Box>

                    <Box>
                      <Typography className="status-text mb-0">
                        Status-{" "}
                        <span
                          className="completed fw-bold"
                          style={{
                            color:
                              item.status == "Completed"
                                ? "green"
                                : item.status == "Declined"
                                ? "red"
                                : item.status == "Expired"
                                ? "red"
                                : item.status == "Accepted"
                                ? "green"
                                : item.status == "Pending"
                                ? "yellow"
                                : "",
                            fontWeight: "bold",
                          }}
                        >
                          {item?.status}
                        </span>
                      </Typography>
                    </Box>
                  </Box>
                  <Box className="">
                    <Grid container className="p-4 service-detail-box-lg">
                      <Grid
                        xs={12}
                        md={5}
                        lg={4}
                        xl={3}
                        className="pe-2 pe-xl-4"
                      >
                        <Box className=" text-center d-flex flex-column align-items-center h-100">
                          <Box className="mb-2">
                            <img
                              src={item?.seekerData?.profilePic || dummy}
                              className="profile-img-2"
                            />
                          </Box>
                          <Typography
                            variant="body1"
                            align="center"
                            className="mb-3"
                          >
                            {" "}
                          </Typography>
                          <Typography
                            variant="body1"
                            align="center"
                            className="mb-3 fw-bold d-flex justify-content-center  gap-1 "
                          >
                            <CiMail />{" "}
                            <span style={{ overflowWrap: "anywhere" }}>
                              {item?.seekerData?.email}
                            </span>
                          </Typography>
                          <Typography
                            variant="body1"
                            align="center"
                            className="mb-3 fw-bold"
                          >
                            <LuPhone /> {item?.seekerData?.contactNo}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4}>
                        <Box>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <span className="fw-bold">
                                  Service required
                                </span>{" "}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <span>{item?.service?.name}</span>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <span className="fw-bold">
                                  Number of provider required
                                </span>{" "}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <span>{item?.personRequired}</span>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <span className="fw-bold">
                                  Age of provider{" "}
                                </span>{" "}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <span>{item?.ageRange.join("-")}</span>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <span className="fw-bold">
                                  {/* <IoCalendarClearOutline /> */}
                                  Service Date
                                </span>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <div className="d-flex gap-4">
                                  <span
                                    style={{
                                      display: "flex",
                                      gap:"3px",
                                      flexWrap:"wrap",
                                    }}
                                  >
                                    <span>
                                      <FormatDate dateString={item?.from} />-
                                    </span>
                                    <span>
                                      <FormatTime dateString={item?.from} />
                                    </span>
                                    <FormatYear dateString={item?.from} />
                                   
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <span className="fw-bold">
                                  {/* <IoCalendarClearOutline /> */}
                                  Service Time
                                </span>
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <div className="d-flex gap-4">
                                  <span
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <span>
                                      <FormatChangeTime
                                        dateString={item?.from}
                                      />
                                    </span>{" "}
                                    -
                                    <span>
                                      <FormatChangeTime
                                        dateString={item?.till}
                                      />
                                    </span>
                                  </span>
                                </div>
                              </Grid>
                            </Grid>
                          </Typography>

                          <Typography
                            variant="body1"
                            mb={1}
                            className="text-black d-flex gap-3"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                {" "}
                                <span className="fw-bold">Language</span>{" "}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <span> {item?.language?.join(" ")} </span>
                              </Grid>
                            </Grid>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={4}>
                        <Box>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={4}>
                                {" "}
                                <span className="fw-bold">Location</span>{" "}
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <span>{item?.location}</span>
                              </Grid>
                            </Grid>
                          </Typography>
                          <Typography
                            variant="body1"
                            mb={3}
                            className="text-black"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={4}>
                                {" "}
                                <span className="fw-bold">
                                  Additional instructions
                                </span>{" "}
                              </Grid>
                              <Grid item xs={12} md={8}>
                                <span>{item?.description}</span>
                              </Grid>
                            </Grid>
                          </Typography>
                          {item?.attachments?.length > 0 && (
                            <Typography
                              variant="body1"
                              mb={1}
                              className="text-black d-flex gap-3"
                            >
                              <span className="fw-bold">Uploaded Photos</span>
                            </Typography>
                          )}

                          {item?.attachments?.map(
                            (images: any, index: number) => (
                              <img
                                src={images || dummy}
                                alt={`thumbnail-${index}`}
                                style={{
                                  height: "48px",
                                  width: "48px",
                                  marginRight: "5px",
                                }}
                                onClick={() => handleImageClick(images)}
                              />
                            ),
                          )}

                          {/* image preview */}

                          <PreviewImage
                            selectedImage={selectedImage}
                            handleClosePreview={handleClosePreview}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Style.Overview>
            ))}
          </Box>
          <Style.Overview>
            <Box
              className="d-flex my-5"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography align="left" variant="h2" mb={2}>
                Timeline
              </Typography>
              <Box display="flex">
                {/* <Button className="timeline-btn me-4">
                  Refund <FaChevronRight />
                </Button> */}
                <Button className="timeline-btn" onClick={handleChatView}>
                  Chat{" "}
                  <img
                    src={chat}
                    alt="chat"
                    style={{ color: "#F475A4", marginLeft: "10px" }}
                  />
                </Button>
              </Box>
            </Box>

            <Box className="timeline-graph">
              {approvedjobs?.timeLine
                ?.slice(
                  0,
                  showFullTimeline ? approvedjobs?.timeLine?.length : 3,
                )
                .map((timeline: any, index: number) => (
                  <Timeline
                    key={timeline._id}
                    position={index % 2 === 0 ? "alternate" : "left"}
                  >
                    <TimelineItem>
                      <TimelineOppositeContent
                        sx={{ m: "10px 0 0" }}
                        align="right"
                        className="timeline-desc"
                        color="text.secondary"
                      >
                        <span className="datetime">
                          <span className="d-flex flex-column">
                            <span className="weekday">
                              <FormatDate dateString={timeline.createdAt} />
                            </span>
                            <span>
                              <FormatChangeTime
                                dateString={timeline.createdAt}
                              />
                            </span>
                            <span>
                              <FormatTime dateString={timeline.createdAt} />
                            </span>
                          </span>
                        </span>
                        <br />
                      </TimelineOppositeContent>
                      <TimelineSeparator>
                        <TimelineConnector />
                        <TimelineDot>
                          <Typography className="timeline-step">
                            0{approvedjobs?.timeLine?.length - index}
                          </Typography>
                        </TimelineDot>
                        <TimelineConnector />
                      </TimelineSeparator>
                      <TimelineContent sx={{ py: "12px", px: 0.5 }}>
                        <Box className="timeline-content ">
                          <Typography className="timeline-heading">
                            Status -{timeline?.postStatus}{" "}
                          </Typography>
                          <Typography className="timeline-desc"></Typography>
                          <Typography className="timeline-desc">
                            {timeline?.message}{" "}
                          </Typography>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  </Timeline>
                ))}
              {approvedjobs?.timeLine?.length > 3 && (
                <div className="d-flex justify-content-center">
                  <Button
                    onClick={handleToggleTimeline}
                    className="view-profile py-2 text-capitalize"
                  >
                    {showFullTimeline ? "Show Less" : "Show More"}
                  </Button>
                </div>
              )}
            </Box>
          </Style.Overview>
        </>
      )}
    </>
  )
}
export default BookingServiceDetails
