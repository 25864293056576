import React, { useEffect, useState } from "react"
import * as Style from "./style"
import { FaChevronLeft } from "react-icons/fa6"
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Pagination,
  Stack,
  Typography,
} from "@mui/material"
import notificationIcons from "../../images/notificationIcons.svg"
import { useNavigate } from "react-router-dom"
import Paper from "@mui/material/Paper"
import { styled } from "@mui/material/styles"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { api, useGetNotificationQuery } from "../../features/Auth/authApi"
import moment from "moment"
import { CalculateTimeDifference } from "./timeNotification"
import CenteredSpinner from "../common/CenteredSpinner"
import {
  notificationsApi,
  useGetNotificationsQuery,
} from "../../features/Socket/socketApi"
import { useAppSelector } from "../../app/hooks"
import { useStore } from "react-redux"
import { RootState } from "@reduxjs/toolkit/query"
import { useAppDispatch } from "../../app/store"

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}))

const Notification = () => {
  const store: any = useStore()
  const cachedQueries = store.getState().notificationsApi.queries
  // const getNotifications :any = useAppSelector((state)=> state.notificationsApi.queries)
  let dashboardResult: { [key: string]: any } = {}
  Object.values(cachedQueries).forEach((item: any) => {
    dashboardResult = {
      ...dashboardResult,
      ...{ [item?.endpointName]: item?.data },
    }
  })
  // console.log(dashboardResult.getNotifications ,"check notification data"  )

  // const {data } = useGetNotificationsQuery('fetchData', {

  //   queryFn: () => ({}),
  // });

  const [value, setValue] = React.useState(0)
  const [seenValue, setSeenValue] = useState("undefined")
  const [selectedPage, setSelectedPage] = useState(1)
  const [displayCount, setDisplayCount] = useState(10)
  const [pageLimit, setPageLimit] = useState(5)
  // const [refecthApiNewNoti, setRefecthApiNewNoti] = useState(false)

  // isSeenKey
  // const { data: notifications } = useGetNotificationsQuery({});

  const {
    data: notificationData,
    isLoading,
    isFetching,
    refetch,
  } = useGetNotificationQuery(
    {
      // isSeenKey: seenValue,
      // pageNo: selectedPage,
      pageLimit: pageLimit,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  )

  useEffect(() => {
    dispatch(api.util.invalidateTags(["countNotification"]))
  }, [notificationData])

  function samePageLinkNavigation(
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  ) {
    if (
      event.defaultPrevented ||
      event.button !== 0 ||
      event.metaKey ||
      event.ctrlKey ||
      event.altKey ||
      event.shiftKey
    ) {
      return false
    }
    return true
  }
  interface LinkTabProps {
    label?: string
    href?: string
    selected?: boolean
    onClick?: () => void
  }

  function LinkTab(props: LinkTabProps) {
    return (
      <Tab
        component="a"
        onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
          if (samePageLinkNavigation(event)) {
            event.preventDefault()
          }
        }}
        aria-current={props.selected && "page"}
        {...props}
      />
    )
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (
      event.type !== "click" ||
      (event.type === "click" &&
        samePageLinkNavigation(
          event as React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        ))
    ) {
      setValue(newValue)
    }
  }
  const navigate = useNavigate()

  const handleMessage = (value: any) => {
    setSeenValue(value)
  }
  const handlePageChange = (event: any, value: any) => {
    setSelectedPage(value)
  }

  const handleShowMore = () => {
    // setDisplayCount(displayCount + 10)
    setSelectedPage(selectedPage + 1)
  }
  useEffect(() => {
    if (selectedPage) {
      setPageLimit(selectedPage * 5)
    }
  }, [selectedPage])

  const handleRefresh = () => {
    refetch()
  }

  const dispatch = useAppDispatch()

  return (
    <>
      <Style.Overview>
        <Button
          className="back-btn mb-0"
          onClick={() => navigate(`/dashboard`)}
        >
          <FaChevronLeft />
        </Button>

        <Grid
          className="pt-lg"
          container
          justifyContent="center"
          xl={7}
          md={10}
          sm={12}
        >
          <Box className="notification-msg-topbar">
            <Grid className="notification-grid" container spacing={2}>
              <Grid item xs={8}>
                <Box sx={{ width: "100%" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="nav tabs example"
                    role="navigation"
                    className="notification-tabs"
                    indicatorColor="secondary"
                    textColor="primary"
                  >
                    <LinkTab
                      label="All"
                      onClick={() => handleMessage("undefined")}
                    />

                    {/* {dashboardResult?.getNotifications?.length > 0  && 
                     
                     <Button  style={{color: "red"}} onClick={()=>{dispatch(api.util.invalidateTags(['notifications']))}}>New</Button>
                     }  */}
                  </Tabs>
                </Box>
              </Grid>

              <Grid item xs={4}>
                {/* datePicker */}

                {/* <DateRangePickerValue/> */}
              </Grid>
            </Grid>
          </Box>

          <Grid className="pt-lg-inner" item sm={12} md={10}>
            <Box className="pt-lg-0 pt-4">
              {isLoading ? (
                <Typography fontSize={1}>
                  <CenteredSpinner />
                </Typography>
              ) : (
                notificationData?.map((noti: any) => (
                  <Box
                    key={noti?._id}
                    className="d-lg-flex align-items-center justify-content-center gap-4  mb-3"
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      if (noti?.postId?.status === "Accepted") {
                        navigate(
                          `/dashboard/approved-detail/${noti?.postId?._id}`,
                        )
                      } else if (
                        noti?.postId?.status === "Expired" ||
                        noti?.postId?.status === "Completed"
                      ) {
                        navigate(
                          `/dashboard/approved-detail/${noti?.postId?._id}?view=booking-detail`,
                        )
                      } else if (noti?.postId?.status === "Active") {
                        navigate(`/dashboard/verify-otp/${noti?.postId?._id}`)
                      }
                    }}
                  >
                    {" "}
                    {/* <Box
                      className={`feature-card w-100 d-flex align-items-start ${
                        noti?.actionRequired == false && `bg-light`
                      } gap-3 mb-3 mx-lg-3`}
                    > add this bg-light class*/}
                    <Box className="feature-card w-100 d-flex align-items-start bg-light gap-3 mb-3 mx-lg-3">
                      <img
                        src={notificationIcons}
                        className="notification-img"
                      />
                      <Box>
                        <Typography className=" mb-2 lh-sm text-black noti-text">
                          {noti?.type}
                        </Typography>
                        <Typography
                          variant="body1"
                          className="lh-sm text-black noti-content"
                        >
                          {noti?.content}
                        </Typography>
                        <Typography
                          variant="body1"
                          className="lh-sm text-black noti-time mt-1"
                        >
                          <CalculateTimeDifference
                            createdAt={noti?.createdAt}
                          />
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                ))
              )}
              {notificationData?.length > 4 && (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    {/* {notificationData?.data?.length > displayCount && ( */}
                    <Typography
                      onClick={handleShowMore}
                      fontWeight="bold"
                      color="textPrimary"
                      sx={{ cursor: "pointer" }}
                      className="view-profile"
                    >
                      {isFetching ? (
                        <CircularProgress
                          style={{ color: "pink", fontSize: 20 }}
                          size={20}
                        />
                      ) : (
                        "Show More"
                      )}
                    </Typography>
                    {/* )} */}
                  </Box>
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
      </Style.Overview>
    </>
  )
}
export default Notification
