import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"

import { BaseQueryError } from "@reduxjs/toolkit/dist/query/baseQueryTypes"

const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL

export const overViewApi = createApi({
  reducerPath: "overViewAuth",

  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/provider/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token

      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),

  tagTypes: ["appointments", `upComingappointment`],
  endpoints: (builder) => ({
    upcomingAppointments: builder.query<any, void>({
      query: (args: void) => "/get-upcoming-confirmed-posts",
      providesTags: ["upComingappointment"],
    }),

    providersSuggestions: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "get-service-suggestions",
        method: "GET",
        body: credentials,
      }),
    }),
    serviceSuggestions: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "get-service-suggestions",
        method: "GET",
        body: credentials,
      }),
    }),
    ratedProviders: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "get-top-rated-providers",
        method: "GET",
        body: credentials,
      }),
    }),
    countAppointments: builder.query<any, void>({
      query: () => ({
        url: "get-post-status-count",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    appointmentsHistory: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "get-appointments-history",
        method: "GET",
        body: credentials,
      }),
    }),

    getCompletePost: builder.query<any, any>({
      query: () => ({
        url: "get-completed-posts",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    // booking

    createPostApi: builder.mutation<any, any>({
      query: (payload) => ({
        url: "create-post",
        method: "POST",
        body: payload,
      }),
    }),

    //  Get job service

    getJobService: builder.query<any, any>({
      query: () => ({
        url: "get-job-services",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getActivePostApi: builder.query<any, any>({
      query: (payload) => ({
        url: `get-active-posts`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getGenrateostApi: builder.query<any, any>({
      query: (payload) => ({
        url: `get-generated-posts`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),
    getProfileUserApi: builder.query<any, any>({
      query: (payload) => ({
        url: `get-profile`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getspecificPostApi: builder.query<any, any>({
      query: (data) => ({
        url: `get-specific-post?postId=${data?.postId}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    postUpdateProfiletApi: builder.mutation<any, any>({
      query: (payload) => ({
        url: "update-account",
        method: "POST",
        body: payload,
      }),
    }),

    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
  }),
})

export const {
  useCountAppointmentsQuery,
  useUpcomingAppointmentsQuery,
  useProvidersSuggestionsMutation,
  useServiceSuggestionsMutation,
  useRatedProvidersMutation,
  useAppointmentsHistoryMutation,
  useGetCompletePostQuery,
  useCreatePostApiMutation,
  useGetJobServiceQuery,
  useGetActivePostApiQuery,
  useGetGenrateostApiQuery,
  useGetProfileUserApiQuery,
  useGetspecificPostApiQuery,
  usePostUpdateProfiletApiMutation,
} = overViewApi
