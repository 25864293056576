import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { api } from "./authApi"
import { toast } from "react-toastify"

const slice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    userInfo: null,
    socketId: null,
    notificationId: null,
    newUserDemo: null,
  } as {
    user: null | any
    token: null | string
    userInfo: null | boolean
    userDetails: null | any
    onboardDeatils: null | any
    appliedPostKey: null | any
    pagesElect: null | number
    navIndex: null | any
    alliedJobsCount: null | any
    bellNotification: null | any
    appliedCount: null | any
    reSponsiveSide: true | boolean
    currenTimeActive: null | any
    socketId: null | string
    notificationId: null | string
    newUserDemo: null | boolean
  },
  reducers: {
    updateToken: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },

    token: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },

    emailVerifyToken: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },
    logout: (state) => {
      state.user = null
      state.token = null
    },

    userInfo: (state: any, action: any) => {
      state.user = action.payload
    },

    setSocketId: (state: any, action: any) => {
      state.socketId = null
      state.socketId = action.payload
    },

    setNotificationId: (state: any, action: any) => {
      state.notificationId = null
      state.notificationId = action.payload
    },

    userDetails: (state: any, action: any) => {
      state.userDetails = null
      state.userDetails = action.payload
    },
    alreadyRated: (state: any, action: any) => {
      state.user = null
      state.alreadyRated = action.payload
    },

    onBoardKey: (state: any, action: any) => {
      state.onboardDetails = null
      state.onboardDeatils = action.payload
    },
    appliedPostKey: (state: any, action: any) => {
      state.appliedPostKey = null
      state.appliedPostKey = action.payload
    },

    setnPagesElect: (state: any, action: any) => {
      state.pagesElect = null
      state.pagesElect = action.payload
    },

    setnavIndex: (state: any, action: any) => {
      state.navIndex = null
      state.navIndex = action.payload
    },
    setCountJobhistory: (state: any, action: any) => {
      state.alliedJobsCount = null
      state.alliedJobsCount = action.payload
    },
    setBellNotification: (state: any, action: any) => {
      state.alliedJobsCount = null
      state.alliedJobsCount = action.payload
    },
    setreSponsiveSide: (state: any, action: any) => {
      state.reSponsiveSide = true
      state.reSponsiveSide = action.payload
    },
    setCurrenTimeActive: (state: any, action: any) => {
      state.currenTimeActive = true
      state.currenTimeActive = action.payload
    },
    setNewUserDemo: (state: any, action: any) => {
      state.newUserDemo = null
      state.newUserDemo = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.userInfo = payload
        state.newUserDemo = payload?.user?.newUser
      },
    )
    builder.addMatcher(
      api.endpoints.LogoutProvider.matchFulfilled,
      (state, { payload }) => {
        state.userInfo = null
        state.token = null
        state.pagesElect = null
        state.user = null
        state.bellNotification == null
        state.alliedJobsCount == null
        state.alliedJobsCount == null
        state.appliedCount == null
        state.currenTimeActive == null
        state.newUserDemo = null
        state.socketId = null
        state.notificationId = null
      },
    )
    builder.addMatcher(
      api.endpoints.userRegister.matchFulfilled,
      (state, { payload }) => {
        state.userDetails = payload
      },
    )
    // builder.addMatcher(
    //   api.endpoints.getNotification.matchFulfilled,
    //   (state, { payload }) => {
    //     // state.token = payload.token

    //     console.log(payload, "payload")

    //     state.bellNotification = payload
    //   },
    // )
    builder.addMatcher(
      api.endpoints.appliedJobs.matchFulfilled,
      (state, { payload }) => {
        // state.token = payload.tokens
        state.appliedCount == payload
      },
    )

    builder.addMatcher(
      api.endpoints.verifyOtp.matchFulfilled,
      (state, { payload }) => {
        state.currenTimeActive = payload
      },
    )
  },
})

export default slice.reducer
export const {
  logout,
  updateToken,
  token,
  emailVerifyToken,
  userInfo,
  setSocketId,
  setNotificationId,
  userDetails,
  alreadyRated,
  onBoardKey,
  appliedPostKey,
  setnPagesElect,
  setnavIndex,
  setCountJobhistory,
  setBellNotification,
  setCurrenTimeActive,
  setreSponsiveSide,
  setNewUserDemo,
} = slice.actions

export const selectCurrentUser = (state: RootState) => state.auth.token
