import { styled } from "@mui/material";

const Bookedlog = styled("div")(({ theme }) => ({
    padding: '45px 0',
    "& .MuiTypography-h2": {
        color: theme.palette.pink.main,
        fontFamily: "Poppins",
        fontSize: " 16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " normal",
    },
}));
const Overview = styled("div")(({ theme }) => ({
    "& .MuiTypography-h2": {
        color: theme.palette.pink.main,
        fontFamily: "Poppins",
        fontSize: " 16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " normal",
    },
    "& .MuiDrawer-paper": {
        backgroundColor: theme.palette.pink.main,
        "& .MuiListItem-root ": {
            color: theme.palette.white.main,
            "& svg": {
                color: theme.palette.white.main,
            },
        },
    },
    "& .Advertisement": {
        borderRadius: " 16px",
        background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        "& .MuiButtonBase-root": {
            background: theme.palette.white.main,
            color: theme.palette.pink.main,
        },
    },
    "& .book-tab-wrapper": {
        "& .MuiTabs-flexContainer": {
            gap: "21px",
        },
        "& .book-tab": {
            backgroundColor: theme.palette.white.main,
            border: "1px solid #e1e1e1",
            borderRadius: "8px",
            padding: "7px 26px",
            fontSize: "16px",
            color: theme.palette.gray.main400,
            letterSpacing: "0.5px",
            fontFamily: "Montserrat",
            fontWeight: "400",
            transition: ".4s",
            textTransform: "capitalize",
            "&.Mui-selected": {
                border: "1px solid",
                borderColor: theme.palette.pink.main,
                backgroundColor: theme.palette.pink.main,
                color: theme.palette.white.main
            }
        },
        "& .MuiTabs-indicator": {
            display: "none"
        }
    },
    "& .tab-content-wrapper > .MuiBox-root": {
        paddingLeft: "0px",
        paddingRight: "0px",
    },
    "& .book-selectbx": {
        "& .MuiInputBase-root": {
            borderRadius: "8px",
            "&.Mui-focused": {
                "& .MuiOutlinedInput-notchedOutline":
                {
                    borderColor: theme.palette.pink.main,
                }
            }
        },
        "& label": {
            fontSize: "16px",
            color: theme.palette.gray.main400,
            "&.Mui-focused": {
                color: theme.palette.pink.main
            },
            "&.MuiFormLabel-filled": {
                color: theme.palette.pink.main
            },
        },
        "& .MuiSelect-select": {
            backgroundColor: theme.palette.white.main,
            fontSize: "16px",
            color: theme.palette.gray.main400
        },
    },
    "& .dashboard-table-wrapper": {
        backgroundColor: "transparent",
        boxShadow: "none",
        "& .dashboard-table": {
            backgroundColor: "transparent",
            boxShadow: "none",
            "& thead": {
                "& th": {
                    color: theme.palette.gray.main700,
                    fontFamily: "Montserrat",
                    fontSize: " 14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: " 13px",
                },
            },
            "& .service-head": {
                paddingLeft: "20px",
                paddingRight: "20px",
            },
            "& .datetime": {
                "& span": {
                    width: "130px",
                    lineHeight: "23px"
                }
            },
            "& .Pending": {
                color: "#FFA639"
            },
            "& .Assigned": {
                color: "#009C35"
            },
            "& .Rejected": {
                color: "#FE2323"
            },
            "& .Active": {
                color: "#FFA639"
            },
            "& .Completed": {
                color: "#009C35"
            },
            "& .Cancelled": {
                color: "#FE2323"
            },
            "& .location": {
                "& span": {
                    width: "300px",
                    lineHeight: "23px"
                }
            },
            "& .action-btn": {
                gap: "20px"
            },
            "& .view-profile": {
                display: "inline-block",
                width: "135px",
                padding: "12px 10px",
                border: "1px solid #F475A4",
                borderRadius: "8px",
                color: "#F475A4",
                fontSize: "14px",
                textAlign: "center",
                textDecoration: "none"
            },
            "& tbody tr th:first-child": {
                "& span": {
                    borderRadius: "16px 0px 0px 16px"
                }
            },
            "& tbody tr td:last-child": {
                "& span": {
                    borderRadius: "0px 16px 16px 0"
                }
            },
            "& td": {
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: "none",
                backgroundColor: "transparent",
                "& span": {
                    position: "relative",
                    minHeight: "75px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#fff",
                    color: theme.palette.gray.main700,
                    fontFamily: "Montserrat",
                    fontSize: " 14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: " 13px",
                    boxShadow: "0px 8px 6px -3px #00000008",
                    zIndex: "2",

                }
            },
            "& th": {
                paddingLeft: 0,
                paddingRight: 0,
                borderBottom: "none",
                backgroundColor: "transparent",
                "& span": {
                    position: "relative",
                    minHeight: "75px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "start",
                    backgroundColor: "#fff",
                    color: theme.palette.black,
                    fontFamily: "Montserrat",
                    fontSize: " 14px",
                    fontStyle: "normal",
                    fontWeight: "400",
                    lineHeight: " 13px",
                    gap: "6px",
                    padding: "0 20px",
                    boxShadow: "0px 8px 6px -3px #00000008",
                    zIndex: "2",
                    "& span": {
                        minHeight: "auto",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "2px",
                        fontSize: "12px",
                        color: theme.palette.gray.main700,
                        padding: 0,
                        "& svg": {
                            fill: theme.palette.yellow.main
                        }
                    }
                }
            }
        },
    },
}));
const Flexbox = styled("div")(({ theme }) => ({
    display: "flex",
    gap: '15px',
    flexWrap: "wrap",
    "& .MuiTypography-body2": {
        color: theme.palette.grey,
        fontFamily: "Nunito Sans",
        fontSize: " 14px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " 18px",
    },
    "& .MuiTypography-body1": {
        color: theme.palette.pink.main,
        fontFamily: "Montserrat",
        fontSize: " 24px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " 18px",
    },
    "& .Status.MuiBox-root": {
        background: theme.palette.white.main,
        boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
        borderRadius: "16px",

    },
}));
const Toprated1 = styled("div")(({ theme }) => ({
    padding: '45px 0',
    "& .MuiTypography-h2": {
        color: theme.palette.pink.main,
        fontFamily: "Poppins",
        fontSize: " 16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " normal",
    },
}))
const Toprated = styled("div")(({ theme }) => ({
    display: "flex",

    gap: '10px',
    'img': {
        height: '113px',
        objectFit: 'Cover',
        width: '100%',
        borderRadius: ' 8px',
    },
    '& .flexdiv': {
        display: "flex", gap: "16px", padding: "16px", backgroundColor: theme.palette.white?.main, borderRadius: '8px'
    },
    '& .MuiTypography-root ': {

        fontFamily: "Poppins",
        fontSize: " 12px",
        fontStyle: "normal",
        fontWeight: "300",
        lineHeight: " normal",
        textAlign: 'start'
    },

}))
const Upcoming = styled("div")(({ theme }) => ({

    "& .MuiTypography-h2": {
        color: theme.palette.pink.main,
        fontFamily: "Poppins",
        fontSize: " 16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " normal",
    },
    "& .Headingbox": {
        borderRadius: '16px 16px 0 0',

        display: "flex",
        justifyContent: "space-between",
        background: theme.palette.white.main,
        boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
        padding: '15px'

    },
    "& .ContentBox": {
        display: "flex",
        justifyContent: "space-between",
        padding: '15px',
        background: theme.palette.white.main,
        "& .MuiGrid-root": {
            fontFamily: "Montserrat",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            marginTop: '20px',

        },
        '& .MuiButton-root': {
            borderColor: theme.palette.pink.main,
            color: theme.palette.pink.main,
            fontFamily: 'Montserrat',

        },
    },
}));
const Timeline = styled("div")(({ theme }) => ({
    '& .MuiTimelineDot-root ': {
        display: 'flex',
        width: ' 40px',
        height: '40px',
        justifyContent: ' center',
        alignItems: ' center',
        background: theme.palette.pink.main,
        flexShrink: '0'
    },
    "& .MuiTypography-h2": {
        color: theme.palette.pink.main,
        fontFamily: "Poppins",
        fontSize: " 16px",
        fontStyle: "normal",
        fontWeight: "400",
        lineHeight: " normal",
    },
}))
export { Bookedlog, Overview, Flexbox, Toprated1, Toprated, Upcoming, Timeline }