import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Checkbox,
  FormControlLabel,
  Modal,
} from "@mui/material"
import Logo from "../../images/logo.png"
import {
  AiFillLock,
  AiFillUnlock,
  AiOutlineMail,
  AiOutlineUser,
} from "react-icons/ai"
import { FcGoogle } from "react-icons/fc"
import * as yup from "yup"
import { Controller, useForm } from "react-hook-form"

import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import mommylogo from "../../images/login-logo.png"
import physioTherapy1 from "../../images/physioTherapy1.jpg"
import physioTherapy2 from "../../images/physioTherapy2.jpg"
import physioTherapy3 from "../../images/physioTherapy3.jpg"
import physioTherapy4 from "../../images/physioTherapy4.jpg"

import { useAppSelector } from "../../app/hooks"
import {
  api,
  useLoginMutation,
  useResendVerifyEmailMutation,
} from "../../features/Auth/authApi"
import CircularProgress from "@mui/material/CircularProgress"
import * as Style from "./style"
import { toast } from "react-toastify"
import { setResendButton } from "../../features/Auth/WrapperSlice"
import { store, useAppDispatch } from "../../app/store"
import {
  onBoardKey,
  token,
  updateToken,
  userDetails,
} from "../../features/Auth/authSlice"

interface loginUser {
  email: string
  password: string
}
interface loginProps {}

const Login = ({}: loginProps) => {
  const [showPassword, setShowPassword] = React.useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const [open, setOpen] = React.useState(false)
  const [EmailVeriFySend, setEmailVerFySend] = useState("")
  const handleOpen = () => setOpen(true)
  const handleResendOtp = () => setOpen(false)
  const dispatch = useAppDispatch()
  const userId = useAppSelector((state) => state.auth.userDetails)

  const resendButtonVerify = useAppSelector(
    (state) => state?.wrapper?.resendButton,
  )

  const handleClickShowPassword = () => setShowPassword((show) => !show)

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault()
  }
  const navigate = useNavigate()
  const [userLogin, { isError, error, data, isLoading }] = useLoginMutation()

  const [
    resendVerifyEmail,
    { data: verifyData, error: isverifyError, isLoading: verifyIsloading },
  ] = useResendVerifyEmailMutation()
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  const schemaResolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .required("Please enter an email")
        .test("valid-email", "Please enter a valid email", (value) =>
          emailRegex.test(value),
        ),
      password: yup.string().required("Please fill in a password"),
    }),
  )
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<loginUser>({
    resolver: schemaResolver,
  })

  const onSubmit = (values: any) => {
    setEmailVerFySend(values.email)
    const payload = {
      email: values.email.trim(),
      password: values.password,
    }

    userLogin(payload)
  }

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper", // Change background color to white
    border: "2px solid pink", // Pink border
    borderRadius: "8px", // Rounded corners

    boxShadow: 24,
    p: 4,
  }
  const viewProfileBtn = {
    display: "inline-block",
    width: "100px",
    padding: "8px 8px",
    border: "1px solid #F475A4",
    borderRadius: "8px",
    color: "#F475A4",
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "none",
    backgroundColor: "#fff",
    "&:hover": {
      color: "#F475A4",
      backgroundColor: "#fff",
    },
  }
  const viewProfileBtnTwo = {
    display: "inline-block",
    width: "100px",
    padding: "8px 8px",
    border: "1px solid #F475A4",
    borderRadius: "8px",
    color: "#fff",
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "none",
    backgroundColor: "#F475A4",
    "&:hover": {
      color: "#F475A4",
      backgroundColor: "#fff",
    },
  }

  useEffect(() => {
    if (error) {
      const errorMessage = (error as any)?.data?.message
      const isEmailVerified = (error as any)?.data?.user?.isEmailVerified
      const isKeyValid = (error as any)?.data

      if (isEmailVerified === false) {
        toast.error(errorMessage, { autoClose: 1000 })
        handleOpen()
      } else if (isKeyValid?.Key == false) {
        dispatch(userDetails({ user: isKeyValid?.userData } as any))
        navigate("/provider-onboarding")
        toast.error(errorMessage, { autoClose: 2000 })
      } else {
        // Other error handling logic here
        toast.error(errorMessage, { autoClose: 2000 })
        return
      }
    }

    // dispatch(setResendButton(null))
    if (data?.message) {
      dispatch(token(data?.token))
      navigate("/dashboard")
      toast.success(data?.message, {
        autoClose: 2000,
      })
      dispatch(setResendButton(null as any))
      dispatch(onBoardKey(null as any))
      store.dispatch(api.endpoints.getNotification.initiate({ pageLimit: 3 })),
        setOpenSnackbar(true)
    }
  }, [data, error])

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return
    }
    setOpenSnackbar(false)
  }

  const handleVerifyEmail = () => {
    const payload = {
      email: EmailVeriFySend,
    }
    resendVerifyEmail(payload)
  }

  // verify email toast message display

  useEffect(() => {
    if (verifyData?.message) {
      toast.success(verifyData?.message, { autoClose: 1000 })
      handleResendOtp()
    } else {
      toast.error((isverifyError as any)?.verifyData?.message, {
        autoClose: 1000,
      })
    }
  }, [verifyData])

  return (
    <>
      {/* <Style.LoginPage> */}
      {(error as any)?.data?.user?.isEmailVerified == false && (
        <Modal
          open={open}
          onClose={handleResendOtp}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {resendButtonVerify ? (
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Email verify
              </Typography>
              <Typography id="modal-modal-description" sx={{ my: 2 }}>
                Please verify the Link shared on your Registered email
              </Typography>
              <Button
                onClick={handleVerifyEmail}
                // disabled={!resendButtonVerify as any}
                variant="contained"
                sx={viewProfileBtnTwo}
                className="me-5 "
              >
                {verifyIsloading ? (
                  <Typography fontSize={1}>
                    <CircularProgress
                      style={{ color: "pink", fontSize: 20 }}
                      size={20}
                    />
                  </Typography>
                ) : (
                  "Resend"
                )}
              </Button>{" "}
              {/* Button for resending */}
              <Button
                variant="contained"
                sx={viewProfileBtn}
                className="ms-5 "
                onClick={handleResendOtp}
              >
                Close
              </Button>
            </Box>
          ) : (
            <></>
          )}
        </Modal>
      )}

      <Grid container>
        <Grid
          item
          lg={6}
          className="d-none d-lg-block"
          display="flex"
          alignItems="center"
        >
          <Box className="box-wrapper">
            <Typography
              sx={{
                textAlign: "center",
                color: " #858585",
                fontFamily: "Montserrat",
                fontSize: "30px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "normal",
              }}
            >
              Welcome to
            </Typography>
            <Typography textAlign="center">
            <h1>Send A Therapist</h1>
              {/* <img src={mommylogo} width="300" height="auto" /> */}
            </Typography>
            <Box className="Imgbox" display="flex" flexWrap="wrap" gap={2}>
              <Box>
                <img src={physioTherapy1} className="login-left-img1"/>
              </Box>
              <Box mt={5}>
                <img src={physioTherapy2} className="login-left-img2"/>
              </Box>

              <Box>
                <img src={physioTherapy3} className="login-left-img3"/>
              </Box>

              <Box mt={5}>
                <img src={physioTherapy4} className="login-left-img4"/>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={6}>
          <Box>
            <Box className="Loginform">
              <Typography
                variant="h4"
                mt={5}
                fontFamily="Montserrat"
                fontSize={22}
                fontWeight="600"
                color="pink.main"
                textAlign="center"
              >
                Login to your account
              </Typography>

              <form onSubmit={handleSubmit(onSubmit)}>
                <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Email
                  </InputLabel>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }: any) => (
                      <OutlinedInput
                        {...field}
                        onChange={(e: any) =>
                          field.onChange(e.target.value.trim())
                        }
                        onBlur={(e) => {
                          // Trigger re-validation on blur
                          e.target.value = e.target.value.trim()
                          field.onBlur()
                        }}
                        onKeyPress={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault() // Prevent default behavior
                            handleSubmit(onSubmit)() // Manually trigger form submission
                          }
                        }}
                        type="email"
                        id="outlined-adornment-password"
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton edge="end">
                              <AiOutlineMail />
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Email"
                      />
                    )}
                  />
                  <span className="text-danger">{errors?.email?.message}</span>
                </FormControl>
                <FormControl sx={{ mt: 2 }} variant="outlined" fullWidth>
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }: any) => (
                      <OutlinedInput
                        {...field}
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <AiFillUnlock /> : <AiFillLock />}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    )}
                  />
                  <span className="text-danger">
                    {errors?.password?.message}
                  </span>{" "}
                </FormControl>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography
                    onClick={() => navigate("/forget-password")}
                    sx={{
                      color: "#7B7B7B",
                      margin: "8px 0 0 13px",
                      cursor: "pointer",
                    }}
                  >
                    Forgot Password ?
                  </Typography>
                  {/* <Typography>
                    <FormControlLabel
                      control={<Checkbox defaultChecked />}
                      label="Keep me logged inbel"
                    />
                  </Typography> */}
                </Box>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ mt: 9, display: "block" }}
                  fullWidth
                >
                  {isLoading ? (
                    <Typography fontSize={1}>
                      <CircularProgress
                        style={{ color: "pink", fontSize: 25 }}
                        size={25}
                      />
                    </Typography>
                  ) : (
                    "Sign in"
                  )}
                </Button>
              </form>
              <Box mb={3} mt={3}>
                <Typography
                  sx={{
                    color: "#7B7B7B",
                    margin: "8px 0 0 13px",
                    textAlign: "center",
                  }}
                >
                  Don’t have an account?{" "}
                  <Typography
                    component="span"
                    sx={{ color: "#181818", cursor: "pointer" }}
                    onClick={() => navigate("/signup")}
                  >
                    Sign up
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {data?.message}
        </Alert>
      </Snackbar>
      {/* </Style.LoginPage> */}
    </>
  )
}

export default Login
