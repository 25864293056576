import ServiceSupport from "./ServiceSupport/ServiceSupport"
import Myaccount from "./Myaccount/Myaccount"
import ProfileDetails from "./Myaccount/ProfileDetails"
// import Feedback from "./Feedback/Feedback"
// import { Feedback } from "@mui/icons-material"
import Jobs from "./Jobs/Jobs"
import JobHistory from "./Jobs/JobHistory"
import Slots from "./Slots/Slots"
import SingleSeeker from "./Manage/SingleSeeker"
import ServiceProvider from "./Manage/ServiceProvider/ServiceProvider"
import ServiceSeeker from "./Manage/ServiceSeeker/ServiceSeeker"
// import Features from "./Features/Features"
// import Offers from "./Features/Offers/Offer"
import Booking from "./Booking/Booking"
import AuthWrapper from "./Auth/AuthWrapper"
import SignUpAuth from "./Auth/Signup"
import LoginAuth from "./Auth/Login"
import ForgetPasswordAuth from "./Auth/ForgetPassword"
import ResetPasswordAuth from "./Auth/ResetPassword"
import CustomerLayout from "./ProviderLayout/ProviderLayout"
import Rating from "./Rating/Rating"
import RateUs from "./Rating/RateUs"
import EmailVerify from "./Auth/EmailVerify"
import { useAppSelector } from "../app/hooks"
import EditAccount from "./Myaccount/EditAccount"
import ActiveBooking from "./ActiveBooking/ActiveBooking"
import JourneyPage from "../components/Journey/Journey"
import StepperComponent from "./PersonalInfo/Stepper"
import ThankYou from "./Rating/Thankyou"
import JobDetail from "./Jobs/JobDetail"
import JobApplied from "./Jobs/JobApplied"
import JobApproved from "./Jobs/JobApproved"
import WriteReview from "./Rating/WriteReview"
import ServiceDetail from "./Jobs/ServiceDetail"

import VerifyOtp from "./ProviderLayout/VerifyOtp"
import JobViewsDetails from "./Jobs/viewJobs"
import ApprovedDetail from "./Jobs/ApprovedDetail"
import StepperLayout from "./PersonalInfo/StepperLayout"
import Reminderjobs from "./Auth/reminderJobs"
import Appliedviewtarck from "./Jobs/Appliedviewtrack"
import Notification from "./Notification/Notification"
import ErrorPage from "./common/ErrorPage"
import ChatWrapper from "./chatUimommy/ChatWrapper"

const authRoute = [
  { index: true, element: <LoginAuth /> },
  {
    element: <SignUpAuth />,
    path: "/signup",
  },
  {
    element: <ForgetPasswordAuth />,
    path: "/forget-password",
  },

  {
    element: <ResetPasswordAuth />,
    path: "/reset-password",
  },
  {
    element: <Reminderjobs />,
    path: "job-reminder",
  },
]
const customerDashboard = [
  {
    index: true,
    element: <CustomerLayout />,
  },

  {
    path: "/dashboard/bookings",
    element: <Booking />,
  },
  {
    element: <Jobs />,
    path: "/dashboard/jobs",
  },

  { element: <ErrorPage />, path: "*" },
  // {
  //   element: <JobHistory />,
  //   path: "/dashboard/job-history",
  // },
  {
    element: <JobApplied />,
    path: "/dashboard/applied-job",
  },
  {
    element: <JobViewsDetails />,
    path: "/dashboard/applied-job-details/:id",
  },

  {
    element: <JobApproved />,
    path: "/dashboard/approved-job",
  },
  {
    element: <JobHistory />,
    path: "/dashboard/job-history/:type",
  },
  {
    element: <ServiceDetail />,
    path: "/dashboard/service-detail",
  },
  {
    element: <Notification />,
    path: "/dashboard/notification",
  },
  {
    element: <VerifyOtp />,
    path: "/dashboard/verify-otp/:id",
  },
  {
    element: <Slots />,
    path: "/dashboard/slots",
  },
  {
    element: <SingleSeeker />,
    path: "/dashboard/manage/single-seeker",
  },
  {
    element: <ServiceProvider />,
    path: "/dashboard/management/service-provider",
  },
  {
    element: <ServiceSeeker />,
    path: "/dashboard/management/service-seeker",
  },
  // {
  //   element: <Features />,
  //   path: "/dashboard/features",
  // },
  // {
  //   element: <Offers />,
  //   path: "/dashboard/features/offers",
  // },
  {
    element: <Rating />,
    path: "/dashboard/rating",
  },
  {
    element: <RateUs />,
    path: "/dashboard/rate-us",
  },
  {
    element: <ThankYou />,
    path: "/dashboard/thank-you",
  },
  {
    element: <WriteReview />,
    path: "/dashboard/write-review/:id",
  },
  {
    element: <JobDetail />,
    path: "/dashboard/job-detail/:id",
  },
  // {
  //   element: <Feedback />,
  //   path: "/dashboard/feedback",
  // },
  {
    element: <Myaccount />,
    path: "/dashboard/myaccount",
  },
  // {
  //   element: <EditAccount />,
  //   path: "/dashboard/myaccount/edit",
  // },
  // {
  //   element: <ProfileDetails />,
  //   path: "/dashboard/profile-details",
  // },
  {
    element: <ServiceSupport />,
    path: "/dashboard/service-support",
  },
  {
    element: <ActiveBooking />,
    path: "/dashboard/active-booking",
  },
  {
    element: <ApprovedDetail />,
    path: "/dashboard/approved-detail/:id",
  },
  {
    element: <ChatWrapper />,
    path: "/dashboard/help-center",
  },
  { element: <Appliedviewtarck />, path: "/dashboard/jobs-details/:id" },
  // {
  //   element: <OTPInput />,
  //   path: "/dashboard/new-otp",
  // },
]

export { authRoute, customerDashboard }
