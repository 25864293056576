import { styled } from "@mui/material"
export const PersonalInfo = styled("div")(({ theme }: any) => ({
  padding: "80px 129px 0px",
  "@media (max-width: 575px)": {
    padding: "30px 20px 0px",
  },
  "& .css-t6vaq9-MuiInputBase-root-MuiFilledInput-root:hover:not(.Mui-disabled, .Mui-error):before":
    {
      borderBottom: "none",
    },
  "& .css-t6vaq9-MuiInputBase-root-MuiFilledInput-root": {
    background: "#fff",
  },
  "& .css-1gjw0us-MuiFormLabel-root-MuiInputLabel-root": {
    background: "#fff",
  },
  "& .profile-box": {
    backgroundColor: "rgba(244, 117, 164, 0.16)",
    maxWidth: "449px",
    width: "100%",
    borderRadius: "34px",
  },
  "& .profile-title": {
    fontSize: " 24px",
    fontWeight: "700",
    lineHeight: "29px",
    fontFamily: "Montserrat",
    color: "#F475A4",
  },
  "& .sub-title": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    lineHeight: "25px",
    fontWeight: "500px",
  },
  "& .paragraph": {
    fontSize: "14px",
    fontFamily: "Montserrat",
    lineHeight: "25px",
    fontWeight: "500px",
  },
  " & .profile-image": {
    textAlign: "right",
    marginTop: "80px",
    paddingTop: "25px",
    background: "#F475A4", 
    borderRadius: "30px 15px",
  },
  " & .image-size": {
    width: "300px",
  },
  " & .profile-box": {
    padding: "30px 40px",
  },
  " & .form-input": {
    width: "300px",
    borderRadius: "34px",
    backgroundColor: "white",
    "@media (max-width: 575px)": {
      width: "200px",
    },
  },
  " & .form-input div": {
    borderRadius: "34px",
  },
  " & .css-z7uhs0-MuiStepConnector-line": {
    border: "1px dashed rgba(244, 117, 164, 1) ",
  },
  " & .css-95m0ql": {
    border: "1px dashed rgba(244, 117, 164, 1)!important ",
  },
  " & .my-two": {
    marginBottom: "0.5rem!important ",
    marginTop: "0.5rem!important ",
  },

  " & .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
    color: "rgba(244, 117, 164, 1)",
  },
  " & .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
    color: "rgba(244, 117, 164, 1)",
  },
  " & .next-button": {
    border: "1px solid rgba(244, 117, 164, 1)",
    borderRadius: "10px",
    padding: "10px 57px",
    color: "rgba(244, 117, 164, 1)",
  },
  " & .grey-button": {
    border: "1px solid #595656",
    color: "#595656",
  },
  " & .css-t6vaq9-MuiInputBase-root-MuiFilledInput-root::before": {
    borderBottom: "none",
    color: "#595656",
  },
  " & .css-t6vaq9-MuiInputBase-root-MuiFilledInput-root::after": {
    borderBottom: "none",
  },
  " & .css-a9r7wc:hover:not(.Mui-disabled, .Mui-error)::before ": {
    borderBottom: "none",
  },
  " & .css-4ff9q7.Mui-active": {
    color: "rgba(244, 117, 164, 1)",
  },
  " & .css-1f1lx0f": {
    background:"#fff",
  },
  " & .css-a9r7wc": {
    inset: "-1px 0px 0px!important",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    background:"#fff",
  },
  " & css-4ff9q7.Mui-completed ": {
    background:"rgb(244, 117, 164)!important",
  },
  
  " & .css-17gq4vl": {
    padding: "15px 12px 15px!important",
  },

  " & .css-a9r7wc::before": {
    borderBottom: "none",
  },
  " & .css-a9r7wc::after": {
    borderBottom: "none",
  },
  " & .css-igs3ac": {
    inset: "-5px 0px 0px",
    border: "1px solid rgba(0, 0, 0, 0.23)",
  },
  " & .css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input.css-d9oaum-MuiSelect-select-MuiInputBase-input-MuiFilledInput-input":
    {
      paddingTop: "16px",
      minHeight: "30px",
      border: "1px solid rgba(0, 0, 0, 0.38)",
    },
  " & .upload-photo": {
    backgroundColor: "#F0F0F0",
    width: "148px",
    borderRadius: "100px",
    height: "148px",
  },
  " & .upload-photo img": {
    borderRadius: "100px",
  },

  " & .upload-icon": {
    fontSize: "30px",
  },
  " & .card-background": {
    backgroundImage: 'url("../../src/images/background-save.png")',
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  },
  " & .card-content": {
    padding: "50px 100px 20px",
  },
  " & .save-button": {
    margin: "10px 100px",
    backgroundColor: "#F475A4",
    borderRadius: "50px",
    padding: "10px 50px",
  },
}))
