import React from "react"
import { Box } from "@mui/material"
import { Link } from "react-router-dom"
import user from "../../images/user.jpeg"
import dummy from "../../images/dummy-image.jpg"
import survice from "../../images/calendar.png"
import tick from "../../images/tick.svg"

import pickedMoney from "../../images/picked-money.png"
import * as Style from "./style"
import { useAppSelector } from "../../app/hooks"

type Props = {}

export default function DummyChatSecreen({}: Props) {
  const userInfo = useAppSelector((state: any) => state.auth?.userInfo)

  return (
    <div>
      {" "}
      <Box>
        <div className="d-flex gap-2 align-items-center justify-content-center">
          <img
            src={userInfo?.user?.profilePic || dummy}
            style={{
              width: "40px",
              height: "40px",
              objectFit: "cover",
              borderRadius: "50%",
            }}
          />
          <h3 className="text-sec">
            Welcome{" "}
            <span >{userInfo?.user?.name}</span>
          </h3>
        </div>
        <Style.Overview>
        <div className="card_sec d-flex flex-column  flex-xl-row gap-3">
            <div className="card_content">
              <img src={survice}  />
              <h3>Flexible Jobs</h3>
              <ul>
                <li>
                  <img src={tick} />
                  Choose jobs that fit your schedule and preferences.
                </li>
                <li>
                  <img src={tick} />
                  Get matched with customers based on your skills and
                  availability.
                </li>
                <li>
                  <img src={tick} />
                  Receive instant notifications for new job opportunities.
                </li>
              </ul>

              <span>
                <Link
                  to={"/dashboard/approved-job"}
                  style={{ fontWeight: "700" }}
                  className="view-profile"
                >
                  See jobs
                </Link>
              </span>
            </div>
            <div className="card_content">
              <img src={pickedMoney}  />
              <h3>Quick Payments</h3>
              <ul>
                <li>
                  <img src={tick} />
                  Get paid promptly after each service
                </li>
                <li>
                  <img src={tick} />
                  Track your booking service in real-time.
                </li>
                <li>
                  <img src={tick} />
                  Earn fair wages for your services.
                </li>
              </ul>

              <span>
                <Link
                  to={"/dashboard/jobs"}
                  style={{ fontWeight: "700", marginTop: "10px" }}
                  className="view-profile"
                >
                  Apply now
                </Link>
              </span>
            </div>
          </div>
        </Style.Overview>
      </Box>
    </div>
  )
}
