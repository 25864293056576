import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  Rating,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material"
import * as Style from "../ProviderLayout/style"
import {
  useCountAppointmentsQuery,
  useGetCompletePostQuery,
  useProvidersSuggestionsMutation,
  useRatedProvidersMutation,
  useServiceSuggestionsMutation,
  useUpcomingAppointmentsQuery,
} from "../../features/OverViewAuth/overViewApi"
import { Link } from "react-router-dom"
import { pink } from "@mui/material/colors"
import { useGetAvailablePostQuery, useProvidersStatusQuery } from "../../features/Auth/authApi"

interface props { }

const ActiveBooking = ({ }: props) => {
  return (
    <>
      <Style.Overview>
        </Style.Overview>
    </>
  )
}

export default ActiveBooking


