import React from "react"
import { Avatar, Box, Button, Divider, Grid, Paper, Rating, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, } from "@mui/material"
import { LiaAngleRightSolid } from "react-icons/lia"
import * as Style from './style'
import thankyouImg from '../../images/thankyou.png'
const ThankYou = () => {
    return (
        <>
            {" "}
            <Grid container spacing={2} justifyContent="center" className="mt-5 pt-3">
                <Grid item xs={4}>
                    <Style.Overview>
                        <Box className="write-review-card text-center">
                            <Typography variant="body1" className="text-center text-black mb-4 lh-sm"><span className="pink-text font-20">Thank You</span><br/>for sharing your review</Typography>
                            <img src={thankyouImg} className="my-5" />
                            <Typography variant="body1">Your review is send to Emilia Antoine</Typography>
                        </Box>
                    </Style.Overview>
                </Grid>
            </Grid>
        </>
    )
}
export default ThankYou
