import React, { Fragment, useEffect, useState } from "react"
import {
  Box,
  Button,
  Collapse,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material"
import { NavLink, useLocation, useParams } from "react-router-dom"
import ListItemIcon from "@mui/material/ListItemIcon"
import PropTypes from "prop-types"
import {
  MdOutlineAccountBalance,
  MdOutlineContactSupport,
} from "react-icons/md"
import { RxCalendar, RxDashboard } from "react-icons/rx"
import { CiFolderOn } from "react-icons/ci"
import { IoCalendarOutline } from "react-icons/io5"
import { GoHistory } from "react-icons/go"
import { BsBarChartLine } from "react-icons/bs"
import { FaRegStar } from "react-icons/fa"
import { IoSettingsOutline } from "react-icons/io5"
import { HiOutlineLogout } from "react-icons/hi"
import { PiHandPointing } from "react-icons/pi"
import { useAppSelector } from "../../app/hooks"
import { RootState, useAppDispatch } from "../../app/store"
import {
  logout,
  setnPagesElect,
  setnavIndex,
} from "../../features/Auth/authSlice"
import { IoHelp } from "react-icons/io5"
import { GoPulse } from "react-icons/go"
import { Modal } from "react-bootstrap"
import LogoutConfirm from "./logout"
import {
  useAppliedJobsQuery,
  useGetCountsAllQuery,
  useJobHistoryQuery,
} from "../../features/Auth/authApi"
import { useSelector } from "react-redux"
import approved from "../../images/approved-jobs.png"

const navList = [
  {
    label: "Overview",
    to: "/dashboard",
    icon: <RxDashboard fontSize={25} />,
    showItem: true,
  },
  {
    label: "Apply for Jobs",
    to: "/dashboard/jobs",
    icon: <CiFolderOn fontSize={25} />,
    showItem: true,
  },
  {
    label: "Job History",
    to: "/dashboard/job-history/All",
    icon: <GoHistory fontSize={20} />,
    showItem: true,
  },
  {
    label: "Applied jobs",
    to: "/dashboard/applied-job",
    icon: <PiHandPointing fontSize={20} />,
    showItem: true,
  },
  {
    label: "Approved jobs",
    to: "/dashboard/approved-job",
    icon: <GoPulse fontSize={20} />,
    showItem: true,
  },

  {
    label: "Slots",
    to: "/dashboard/slots",
    icon: <BsBarChartLine fontSize={25} />,
    showItem: true,
  },
  {
    label: "Rating & Reviews ",
    to: "/dashboard/rating",
    icon: <IoCalendarOutline fontSize={25} />,
    showItem: true,
    // children: [
    //   {
    //     label: "Rate Us",
    //     to: "/dashboard/rate-us",
    //     icon: <FaRegStar fontSize={20} />,
    //     showItem: true,
    //   },
    // ],
  },
  {
    label: "My Account",
    to: "/dashboard/myaccount",
    icon: <IoSettingsOutline fontSize={25} />,
    showItem: true,
  },
  // {
  //   label: "Service & Support",
  //   icon: <IoHelp fontSize={25} />,
  //   showItem: true,
  // },
  {
    label: "Logout",
    // to: "/",
    icon: <HiOutlineLogout fontSize={25} />,
    showItem: true,
  },
]
interface propsNav {
  open: boolean
}
const Navbar = ({ open }: propsNav) => {
  const [openItems, setOpenItems] = useState<number[]>([])
  const [activeTab, setActiveTab] = useState<String>("Overview")
  const [logoutModal, setLogoutModal] = React.useState(false)
  const [pathNameRoute, setPathNameRoute] = useState<String>("")
  const handleOpen = () => setLogoutModal(true)
  const handleClose = () => setLogoutModal(false)

  // const { data: appliedJobs, isLoading, isFetching } = useAppliedJobsQuery({})
  const { data: allCount } = useGetCountsAllQuery({})

  const { pathname } = useLocation()
  const { type } = useParams()

  const dispatch = useAppDispatch()

  const handleToggle = (index: number) => {
    if (openItems.includes(index)) {
      setOpenItems(openItems.filter((item) => item !== index))
    } else {
      setOpenItems([...openItems, index])
    }
  }

  const handleTab = (eachItem: any, hasChildren: any, index: any) => {
    setActiveTab(eachItem.label)

    if (eachItem.link) {
      window.open(eachItem.link, "_blank")
    }
    if (hasChildren) {
      handleToggle(index)
    }
    if (eachItem.label === "Logout") {
      handleOpen()
    }
  }

  const renderByType = (eachItem: any, index: number) => {
    const hasChildren = eachItem.children && eachItem.children.length > 0

    useEffect(() => {
      if (pathname == "/dashboard/job-history/Completed") {
        setPathNameRoute("/dashboard/job-history/All")
      } else if (pathname == "/dashboard/job-history/Declined") {
        setPathNameRoute("/dashboard/job-history/All")
      } else {
        setPathNameRoute(pathname)
      }
    }, [pathname])

    useEffect(() => {
      if (pathname === "/dashboard/jobs") {
        console.log("pathname")
      } else {
        dispatch(setnPagesElect(null as any))
      }
    }, [pathname])

    return (
      <>
        <Box pt={1} key={eachItem.label}>
          <Tooltip title={open ? "" : eachItem.label} placement="right" arrow>
          <ListItem
            className={`${pathNameRoute === eachItem.to && "activeTab"} `}
            component={(eachItem.to as any) ? (NavLink as any) : undefined}
            to={(eachItem.to as any) ? (eachItem.to as any) : undefined}
            style={{ cursor: `${eachItem.link && "pointer"}`, padding: "0" }}
            onClick={() => handleTab(eachItem, hasChildren, index)}
          >
            <ListItemButton
              className="sidebar-button"
              sx={{
                minHeight: 48,
                justifyContent: (open as any) ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: (open as any) ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {eachItem.icon}
              </ListItemIcon>
              <ListItemText
                primary={
                  eachItem.label === "Applied jobs" ? (
                    <React.Fragment>
                      {" Applied jobs"}
                      {/* {pathNameRoute !== "/dashboard/applied-job" && ( */}
                      <>
                        {allCount?.Applied !== undefined &&
                          allCount?.Applied !== 0 && (
                            <>
                              {" - "}
                              <span
                                style={{
                                  background: "#fff",
                                  color: "#f475a4",
                                  height: "24px",
                                  width: "24px",
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  // paddingTop: "1px",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                }}
                              >
                                {allCount?.Applied !== 0
                                  ? " " + allCount?.Applied
                                  : " "}
                              </span>
                            </>
                          )}
                      </>
                      {/* )} */}
                    </React.Fragment>
                  ) : eachItem.label === "Apply for Jobs" ? (
                    <React.Fragment>
                      {"Apply for Jobs"}
                      {/* {pathNameRoute !== "/dashboard/jobs" && ( // Check if totalCount exists */}
                      <>
                        {allCount?.Available !== undefined &&
                          allCount?.Available !== 0 && (
                            <>
                              {" - "}
                              {/* dsf */}
                              <span
                                style={{
                                  background: "#fff",
                                  color: "#f475a4",
                                  height: "24px",
                                  width: "24px",
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  // paddingTop: "1px",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                }}
                              >
                                {allCount?.Available !== 0
                                  ? " " + allCount?.Available
                                  : ""}
                              </span>
                            </>
                          )}
                      </>
                      {/* )} */}
                    </React.Fragment>
                  ) : eachItem.label === "Approved jobs" ? (
                    <React.Fragment>
                      {"Approved jobs"}
                      {/* {pathNameRoute !== "/dashboard/approved-job" && ( // Check if totalCount exists */}
                      <>
                        {allCount?.Approved !== undefined &&
                          allCount?.Approved !== 0 && (
                            <>
                              {" - "}
                              <span
                                style={{
                                  background: "#fff",
                                  color: "#f475a4",
                                  height: "24px",
                                  width: "24px",
                                  display: "inline-flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  borderRadius: "50%",
                                  // paddingTop: "1px",
                                  fontWeight: "600",
                                  fontSize: "15px",
                                }}
                              >
                                {allCount?.Approved !== 0
                                  ? " " + allCount?.Approved
                                  : " "}
                              </span>
                            </>
                          )}
                      </>
                      {/* )} */}
                    </React.Fragment>
                  ) : (
                    eachItem.label
                  )
                }
                sx={{
                  opacity: (open as any) ? 1 : 0,
                  fontFamily: "Montserrat",
                }}
              />
            </ListItemButton>
            </ListItem>
            </Tooltip>

          {hasChildren && (
            <Collapse
              in={openItems.includes(index as number)}
              className="ps-1"
              timeout="auto"
              unmountOnExit
            >
              {eachItem.children.map((childItem: any, childIndex: number) =>
                renderByType(childItem, childIndex),
              )}
            </Collapse>
          )}
        </Box>
      </>
    )
  }

  return (
    <>
      {navList.map((eachItem: any, index: number) => (
        <div key={index}>{renderByType(eachItem, index)}</div>
      ))}

      <LogoutConfirm
        key={"Logoutmodal"}
        logoutModal={logoutModal as any}
        handleClose={handleClose}
      />
    </>
  )
}

export default Navbar

Navbar.propTypes = {
  open: PropTypes.bool.isRequired,
}
