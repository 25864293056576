import logo from "./logo.svg"
import "./App.css"
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom"
import AppWrapper from "./components/AppWrapper"
import ProviderDashboardTemplate from "./components/ProviderDashboardTemplate/ProviderDashboardTemplate"
import Booking from "./components/Booking/Booking"
import Manage from "./components/Manage/Manage"
import { Feedback } from "@mui/icons-material"
import Myaccount from "./components/Myaccount/Myaccount"
import ServiceSupport from "./components/ServiceSupport/ServiceSupport"
import { ThemeProvider } from "@mui/material"
import theme from "./Themes/Default"
import { authRoute, customerDashboard } from "./components/routesfile"
import EmailVeriFy from "./components/Auth/EmailVerify"
import ResetPasswordAuth from "./components/Auth/ResetPassword"
import AuthWrapper from "./components/Auth/AuthWrapper"
import { useAppSelector } from "./app/hooks"
import Journey from "./components/Journey/Journey"
import PersonalStep from "./components/PersonalInfo/PersonalStep"
import StepperLayout from "./components/PersonalInfo/StepperLayout"
import ServiceStep from "./components/PersonalInfo/ServiceStep"
import ProfileStep from "./components/PersonalInfo/ProfileStep"
import ProfileSaved from "./components/PersonalInfo/ProfileSaved"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { useGetNotificationsQuery } from "./features/Socket/socketApi"
import { api } from "./features/Auth/authApi"
import { useAppDispatch } from "./app/store"
import { Message, Ticket } from "./components/ChatenterFace/chatinterface"
import { useSocketChatMessageQuery } from "./features/ChatSocket/chat"
import { _setMessage, _setTicket } from "./features/ChatAuth/chatSlice"
import { chatViewApi } from "./features/ChatAuth/chatApi"

interface NotificationProps {
  content: string
}
function App() {
  const token = useAppSelector((state: any) => state.auth?.token)
  const onboardKey = useAppSelector((state) => state.auth.onboardDeatils)
  const [getnotification, setGetNotification] = useState<NotificationProps[]>(
    [],
  )
  const { data: notificationData } = useGetNotificationsQuery(token, {
    refetchOnMountOrArgChange: true,
    skip: token == null,
  })
  const { data: chatMessage } = useSocketChatMessageQuery(token, {
    refetchOnMountOrArgChange: true,
    skip: token == null,
  })
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (getnotification.length) {
      const id = notificationData[0]._id

      dispatch(api.util.invalidateTags(["notifications", "activejobs"]))
      dispatch(chatViewApi.util.invalidateTags(["notifications"]))

      // toast.success(
      //   getnotification?.length > 0 && getnotification[0]?.content,
      //   {
      //     autoClose: 5000,
      //   },
      // )
    }
  }, [getnotification])

  useEffect(() => {
    if (notificationData) {
      setGetNotification(notificationData)
    }
  }, [notificationData])

  // useEffect(() => {
  //   if (chatMessage?.length) {
  //     dispatch(_setMessage(chatMessage))
  //   }
  // }, [chatMessage, dispatch])

  // const baseRoutes = [
  //   {
  //     index: true,
  //     element: <AuthWrapper />,
  //   },
  // ]
  const router = createBrowserRouter([
    {
      element: <EmailVeriFy />,
      path: "verify-email",
    },
    {
      element: <ResetPasswordAuth />,
      path: "reset-password",
    },
    {
      path: "/dashboard",
      element:
        token && token ? (
          <ProviderDashboardTemplate />
        ) : (
          <Navigate to="/" replace />
        ),

      children: customerDashboard,
    },
    {
      path: "/",
      element: !token ? <AuthWrapper /> : <Navigate to="/dashboard" replace />,
      children: authRoute,
    },

    {
      element: onboardKey ? <StepperLayout /> : <Navigate to="/" replace />,
      path: "/provider-onboarding",
    },
  ])

  return (
    <>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </>
  )
}

export default App
