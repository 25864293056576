import React, { CSSProperties, useEffect } from "react"
import { useAppDispatch } from "../../app/store"
import { useNavigate } from "react-router-dom"

import { toast } from "react-toastify"
import { Spinner } from "react-bootstrap"
import CenteredSpinner from "../common/CenteredSpinner"
import { useEmailVerifyMutation } from "../../features/EmailVerifyAuth/emailVerifyAuth"
import { emailVerifyToken } from "../../features/EmailVerifyAuth/emailVerifySlice"
import { useAppSelector } from "../../app/hooks"

type Props = {}

export default function EmailVerify({}: Props) {
  const dispatch = useAppDispatch()
  const urlSearchParams = new URLSearchParams(window.location.search)
  const token = urlSearchParams.get("token")

  const newToken = useAppSelector(
    (state) => state.emailVerifySlice.emailVerifyToken,
  )

  const navigate = useNavigate()
  const [emailVerifyData, { isError, error, data, isLoading }] =
    useEmailVerifyMutation() as any

  useEffect(() => {
    if (token) {
      dispatch(emailVerifyToken(token as any))
    }
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      emailVerifyData()
    }, 500)

    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    if (data?.message) {
      toast.success(data?.message, { autoClose: 1000 })
      const timer = setTimeout(() => {
        window.location.href = data?.redirect
      }, 1000)

      return () => clearTimeout(timer)
    } else {
      toast.error((error as any)?.data?.message, { autoClose: 1000 })
    }
  }, [data, navigate, error])

  const emailVerifyStyle: CSSProperties = {
    color: "pink",
    fontSize: "24px",
    textAlign: "center",
    marginTop: "50px",
  }

  return <>{isLoading && <CenteredSpinner />}</>
}
