import * as React from "react"
import Box from "@mui/material/Box"
import Stepper from "@mui/material/Stepper"
import Step from "@mui/material/Step"
import StepLabel from "@mui/material/StepLabel"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import PersonalStep from "./PersonalStep"
import ServiceStep from "./ServiceStep"
import ProfileStep from "./ProfileStep"
import ProfileSaved from "./ProfileSaved"
import { useAppDispatch } from "../../app/store"
import { setStepsCount } from "../../features/Auth/WrapperSlice"
import { useAppSelector } from "../../app/hooks"

const steps = ["Personal Info", "Service detail", "Profile"]

export default function HorizontalLinearStepper() {
  const stepstype = useAppSelector((state) => state?.wrapper?.stepsCount)
  const [activeStep, setActiveStep] = React.useState(0)
  const [skipped, setSkipped] = React.useState(new Set<number>())

  
  React.useEffect(() => {
    if (stepstype) {
      setActiveStep(stepstype as any)
    }
  }, [stepstype])

  const dispatch = useAppDispatch()

  const isStepOptional = (step: number) => {
    return step === 1
  }

  const isStepSkipped = (step: number) => {
    return skipped.has(step)
  }

  const handleNext = () => {
    let newSkipped = skipped
    if (isStepSkipped(activeStep as any)) {
      newSkipped = new Set(newSkipped.values())
      newSkipped.delete(activeStep as any)
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    if (activeStep === 0) {
      dispatch(setStepsCount(1))
    } else if (activeStep === 1) {
      dispatch(setStepsCount(2))
    }
    // else if (activeStep === 2) {
    //   dispatch(setStepsCount(3))
    // }
    else {
      dispatch(setStepsCount(0))
    }

    setSkipped(newSkipped)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
    if (activeStep === 1) {
      dispatch(setStepsCount(0))
    } else if (activeStep === 2) {
      dispatch(setStepsCount(1))
    } else if (activeStep === 3) {
      dispatch(setStepsCount(2))
    } else {
      dispatch(setStepsCount(0))
    }
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.")
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1)
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values())
      newSkipped.add(activeStep)
      return newSkipped
    })
  }

  const handleReset = () => {
    setActiveStep(0)
  }

  return (
    <Box sx={{ width: "100%" }} className="px-lg-5">
      <Stepper
        activeStep={activeStep}
      >
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {}
          const labelProps: {
            optional?: React.ReactNode
          } = {}
          if (isStepOptional(index)) {
            labelProps.optional = (
              <Typography variant="caption">
                {/* Optional */}
              </Typography>
            )
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false
          }
          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {activeStep === steps.length ? (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            <ProfileSaved />
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button onClick={handleReset}>Reset</Button>
          </Box>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Typography sx={{ mt: 2, mb: 1 }}>
            {activeStep === 0 ? (
              <PersonalStep
                activeStep={activeStep as any}
                step={steps as any}
                handleNext={handleNext}
                handleBack={handleBack}
              />
            ) : activeStep === 1 ? (
              <ServiceStep
                activeStep={activeStep as any}
                step={steps as any}
                handleNext={handleNext}
                handleBack={handleBack}
              />
            ) : activeStep === 2 ? (
              <ProfileStep
                activeStep={activeStep as any}
                step={steps as any}
                handleNext={handleNext}
                handleReset={handleReset}
                handleBack={handleBack}
              />
            ) : (
              ""
            )}
          </Typography>
          {/* <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )}
            <Button onClick={handleNext}>
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Box> */}
        </React.Fragment>
      )}
    </Box>
  )
}
