import React, { useState, useEffect } from "react"
import TextField from "@mui/material/TextField"
import Stack from "@mui/material/Stack"
import Autocomplete from "@mui/material/Autocomplete"
import {
  useGetLocationMutation,
  useGetLocationSuggestionMutation,
} from "../../features/locationFunc/locationApi"
import { Button } from "@mui/material"
import { LocationOn } from "@mui/icons-material"
import { TbRuler2Off } from "react-icons/tb"

interface PropsLocation {
  placeholder: string
  setLocationSearchData: (value: string) => void
  setErrorMsg: (value: string) => void
  loactionSearchData: string

  //   register: string
  //   errors: string
}

export default function ({
  placeholder,
  setLocationSearchData,
  setErrorMsg,
  loactionSearchData,
}: //   register,
//   errors,
PropsLocation) {
  const [latitude, setLatitude] = useState("")
  const [longitude, setLongitude] = useState("")
  const [error, setError] = useState(null)
  const [inputValue, setInputValue] = useState<string>("")
  const [currentPlace, setCurrentPlace] = useState(false)
  const [fetchingLocation, setFetchingLocation] = useState(false)
  const [search, { data, isLoading: locSearchLoading, error: locSearchError }] =
    useGetLocationSuggestionMutation()

  // const getLocation = () => {
  //   if ("geolocation" in navigator) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position: any) => {
  //         setLatitude(position.coords.latitude)
  //         setLongitude(position.coords.longitude)
  //         const payload = {
  //           coordinates: `${position.coords.longitude},${position.coords.latitude}`,
  //         }
  //         setError(null)

  //         search(payload as any)
  //           .then((response: any) => {
  //             console.log(response, "response")

  //             if (response?.data && response?.data?.data?.Results) {
  //               const labels = response?.data?.data?.Results.map(
  //                 (option: any) => option.Place.Label,
  //               )
  //               const labelString = labels.join(", ")
  //               setInputValue(labelString)
  //             }
  //           })
  //           .catch((error: any) => {})
  //       },
  //       (error) => {
  //         setError(error.message as any)
  //       },
  //     )
  //   } else {
  //     setError("Geolocation is not supported by your browser." as any)
  //   }
  // }

  const getLocation = () => {
    if ("geolocation" in navigator) {
      setFetchingLocation(true) // Set to true when fetching location starts
      navigator.geolocation.getCurrentPosition(
        (position: any) => {
          setLatitude(position.coords.latitude)
          setLongitude(position.coords.longitude)
          const payload = {
            coordinates: `${position.coords.longitude},${position.coords.latitude}`,
          }
          setError(null)

          search(payload as any)
            .then((response: any) => {
              if (response?.data && response?.data?.data?.Results) {
                const labels = response?.data?.data?.Results.map(
                  (option: any) => option.Place.Label,
                )
                const labelString = labels.join(", ")
                setInputValue(labelString)
                setLocationSearchData(labelString)
                setErrorMsg("" as any) as any
              }
            })
            .catch((error: any) => {})
            .finally(() => {
              setFetchingLocation(false)
            })
        },
        (error) => {
          setError(error.message as any)
          setFetchingLocation(false)
        },
      )
    } else {
      setError("Geolocation is not supported by your browser." as any)
      setFetchingLocation(false)
    }
  }

  useEffect(() => {
    if (loactionSearchData) {
      setInputValue(loactionSearchData as any)
    }
  }, [loactionSearchData])

  // Function to handle input change
  const handleInputChange = (event: any, value: any) => {
    const dataValue = event?.target?.value
    const payload = {
      search: dataValue,
    }
    setLocationSearchData(value)
    setCurrentPlace(false)
    setInputValue(value)
    search(payload as any)
    if (value) {
      setErrorMsg("" as any) as any
    }
  }

  const locationStyle = {
    height: "45px", // Adjust as needed
    borderRadius: "8px",
    padding: "13px",
    display: "flex",
    alignPropTypes: "center",
    justifyContent: "center",
    margin: "0 auto",
    fontSize: "10px",
    position: "relative",
    right: "0px",
    top: "11px",
    width: "100%",
  }

  useEffect(() => {
    if (inputValue !== "") {
      setCurrentPlace(false)
    }
  }, [inputValue])

  return (
    <Stack spacing={2} sx={{ position: "relative" }}>
      <Autocomplete
        id="free-solo-demo"
        freeSolo
        options={
          data?.data?.Results?.map((option: any) => option?.Place?.Label) || []
        }
        inputValue={inputValue as any}
        onInputChange={handleInputChange}
        onMouseDownCapture={() => setCurrentPlace(true)}
        loading={locSearchLoading || fetchingLocation}
        renderInput={(params) => (
          <TextField
            {...params}
            label={placeholder}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "34px",
              },
            }}
          />
        )}
      />
      {currentPlace !== false && (
        <Button
          variant="outlined"
          color="primary"
          className="view-profile"
          title="Use your current location"
          style={locationStyle as any}
          startIcon={<LocationOn />}
          onClick={getLocation}
          disabled={fetchingLocation}
        >
          {fetchingLocation ? "Loading..." : "Use your current location"}
        </Button>
      )}
    </Stack>
  )
}
