import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"

// const BASE_URL = import.meta.env.VITE_REACT_APP_SERVER_ENDPOINT as string
// const BASE_URL = "http://localhost:5000"
// const BASE_URL = "http://192.168.29.239:5000"
// const BASE_URL = "http://192.168.1.9:5000"
// const BASE_URL = "https://mommyshelp-dev.ddns.net"
const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL

export const emailVerifyAuth = createApi({
  reducerPath: "emailVerifyAuth",

  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/provider/`,
    prepareHeaders: (headers, { getState }) => {
      const emailVerifyToken = (getState() as RootState).emailVerifySlice
        .emailVerifyToken

     
      

      if (emailVerifyToken) {
        headers.set("authorization", `Bearer ${emailVerifyToken}`)
      }
      return headers
    },
  }),
  tagTypes: ["appointments", `upComingappointment`],
  endpoints: (builder) => ({
    emailVerify: builder.mutation<any, any>({
      query: () => ({
        url: "verify-email",
        method: "GET",
      }),
    }),

    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
  }),
})

export const { useEmailVerifyMutation } = emailVerifyAuth
