import React, { useEffect, useState } from "react"
import { useAppDispatch } from "../app/store"
import { setNewUserDemo } from "../features/Auth/authSlice"

const StorylaneEmbed: React.FC = () => {
  const [isVisible, setIsVisible] = useState(true) // State to manage visibility

  const dispatch = useAppDispatch()

  useEffect(() => {
    // Create the script element
    const script = document.createElement("script")
    script.src = "https://js.storylane.io/js/v2/storylane.js"
    script.async = true

    // Append the script to the document body
    document.body.appendChild(script)

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script)
    }
  }, [])

  // Function to handle close button click
  const handleClose = () => {
    dispatch(setNewUserDemo(null as any))
    setIsVisible(false)
  }

  // Only render the embed if it is visible
  if (!isVisible) return null

  return (
    <div
      className="sl-embed-container"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        margin: 0,
        padding: 0,
        zIndex: 9999,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#fff", // Optional: adds a background color for better visibility
        overflow: "hidden", // Ensure content doesn't overflow
      }}
    >
      {/* Close Button */}
      <button
        onClick={handleClose}
        style={{
          position: "absolute",
          top: "5px",
          right: "15px",
          padding: "8px 14px",
          fontSize: "16px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          zIndex: 10000, // Higher than the iframe to make sure it is clickable
          backgroundColor: "#F475A4", // Button color
          color: "#fff", // Text color
        }}
      >
        Skip
      </button>

      <div
        className="sl-embed"
        style={{
          position: "relative",
          width: "80%", // Adjust to fit desired width
          height: "0",
          paddingBottom: "43%", // Maintain 16:9 aspect ratio
          maxHeight: "90vh", // Ensure iframe doesn't exceed 90% of viewport height
          backgroundColor: "#fff",
          overflow: "hidden",
        }}
      >
        <iframe
          loading="lazy"
          src="https://app.storylane.io/demo/f4dbqmcz7hjv"
          name="sl-embed"
          allow="fullscreen"
          allowFullScreen
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            border: "none",
          }}
        ></iframe>
      </div>
    </div>
  )
}

export default StorylaneEmbed
