import { BorderBottom, Height } from "@mui/icons-material"
import { styled } from "@mui/material"

const Upcoming = styled("div")(({ theme }) => ({
  marginBottom: "20px",
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .Headingbox": {
    borderRadius: "16px 16px 0 0",

    display: "flex",
    justifyContent: "space-between",
    background: theme.palette.white.main,
    boxShadow: "0px 4px 27px 0px rgba(0, 0, 0, 0.07)",
    padding: "15px",
  },
  "& .ContentBox": {
    display: "flex",
    justifyContent: "space-between",
    padding: "15px",
    background: theme.palette.white.main,
    "& .MuiGrid-root": {
      fontFamily: "Montserrat",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "normal",
      marginTop: "20px",
    },
    "& .MuiButton-root": {
      borderColor: theme.palette.pink.main,
      color: theme.palette.pink.main,
      fontFamily: "Montserrat",
    },
  },
  "& .flex-none": {
    flex: "none",
  },
  "& .max-w-550": {
    maxWidth: "550px",
  },
  "& .search-field": {
    width: "88%",
    flex: "none",
    "& label.Mui-focused": {
      color: theme.palette.pink.main,
    },
    "& label.MuiFormLabel-filled": {
      color: theme.palette.pink.main,
    },
    "& input": {
      padding: "16px 20px",
      fontSize: "16px",
      fontFamily: "Montserrat",
      color: theme.palette.black.main,
    },
    "&  .MuiOutlinedInput-notchedOutline": {
      borderRight: "none",
      borderRadius: "11px 0px 0 11px",
      borderColor: theme.palette.pink.main,
      "&.Mui-focused": {
        borderColor: theme.palette.pink.main,
      },
    },
  },
  "& .search-btn": {
    backgroundColor: theme.palette.pink.main,
    borderRadius: "0px 11px 11px 0px",
    boxShadow: "none",
    "& svg": {
      width: "25px",
      height: "25px",
    },
  },
}))
const Overview = styled("div")(({ theme }) => ({
  "& .MuiTypography-h2": {
    color: theme.palette.pink.main,
    fontFamily: "Poppins",
    fontSize: " 16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " normal",
  },
  "& .MuiTypography-body1": {
    color: theme.palette.gray.main700,
    fontFamily: "Montserrat",
    fontSize: " 14px",
    fontStyle: "normal",
    fontWeight: "400",
    display: "block",
    lineHeight: " 13px",
  },
  "& .MuiDrawer-paper": {
    backgroundColor: theme.palette.pink.main,
    "& .MuiListItem-root ": {
      color: theme.palette.white.main,
      "& svg": {
        color: theme.palette.white.main,
      },
    },
  },
  "& .Advertisement": {
    borderRadius: " 16px",
    background: "linear-gradient(90deg, #F475A4 38.96%, #DD5084 99.94%)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    "& .MuiButtonBase-root": {
      background: theme.palette.white.main,
      color: theme.palette.pink.main,
    },
  },
  "& .seeker-card": {
    padding: "16px",
    background: theme.palette.white.main,
    borderRadius: "20px",
    "& .listing-seeker": {
      borderTop: "1px solid #e3e3e3",
    },
  },
  "& .assign-btn": {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "400",
    backgroundColor: theme.palette.green.main,
    color: theme.palette.white.main,
    boxShadow: "none",
    fontFamily: "Montserrat",
    padding: "15px 10px",
    textTransform: "capitalize",
    "&:disabled":{
      backgroundColor: theme.palette.gray.main500,
    }
  },
  "& .rejected-btn": {
    fontSize: "14px",
    lineHeight: "18px",
    fontWeight: "400",
    backgroundColor: "transparent",
    color: theme.palette.red.main,
    borderColor: theme.palette.red.main,
    boxShadow: "none",
    fontFamily: "Montserrat",
    padding: "15px 10px",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: theme.palette.red.main,
      color: theme.palette.white.main,
    },
    "&.reject-fill-btn": {
      backgroundColor: theme.palette.red.main,
      color: theme.palette.white.main,
    },
  },
  "& .view-profile": {
    display: "inline-block",
    minWidth: "135px",
    padding: "12px 10px",
    border: "1px solid",
    borderRadius: "8px",
    borderColor: theme.palette.pink.main,
    fontFamily: "Montserrat",
    color: theme.palette.pink.main,
    fontSize: "14px",
    textAlign: "center",
    textDecoration: "none",
    "&.active": {
      backgroundColor: theme.palette.pink.main,
      color: theme.palette.white.main,
    },
  },
  "& .reject-card": {
    padding: "30px 42px",
    background: theme.palette.white.main,
    borderRadius: "20px",
    "& .yes-btn": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      backgroundColor: "transparent",
      color: theme.palette.gray.main500,
      borderColor: theme.palette.gray.main500,
      boxShadow: "none",
      fontFamily: "Montserrat",
      padding: "7px 10px",
      textTransform: "capitalize",
    },
    "& .no-btn": {
      fontSize: "14px",
      lineHeight: "18px",
      fontWeight: "400",
      backgroundColor: theme.palette.pink.main,
      color: theme.palette.white.main,
      borderColor: theme.palette.pink.main,
      boxShadow: "none",
      fontFamily: "Montserrat",
      padding: "7px 10px",
      textTransform: "capitalize",
    },
  },
  "& .white-nowrap": {
    whiteSpace: "nowrap"
  },
  "& .back-btn": {
      backgroundColor: "#F4F4F4",
      minWidth: "40px",
      height: "40px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "50%",
      marginBottom: "21px",
      "& svg": {
          fill: "#3A3A3A"
      },
      "&.profile-back": {
          position: "absolute",
          top: 0,
          left: 0
      }
  },
  "& .dashboard-table-wrapper": {
    backgroundColor: "transparent",
    boxShadow: "none",
    "& .dashboard-table": {
      backgroundColor: "transparent",
      boxShadow: "none",
      "& thead": {
        "& th": {
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
        },
      },
      "& .service-head": {
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingTop: "0px",
        paddingBottom: "0px",
        "& .MuiTypography-subtitle1": {
          fontSize: "14px"
        }
      },
      "& .datetime": {
        "& span": {
          width: "130px",
          lineHeight: "23px",
        },
      },
      "& .Pending": {
        color: "#FFA639",
      },
      "& .Assigned": {
        color: "#009C35",
      },
      "& .Rejected": {
        color: "#FE2323",
      },
      "& .location": {
        "& span": {
          width: "300px",
          lineHeight: "23px",
        },
      },
      "& .action-btn": {
        gap: "20px",
      },
      "& .view-profile": {
        display: "inline-block",
        width: "135px",
        padding: "12px 10px",
        border: "1px solid #F475A4",
        borderRadius: "8px",
        color: "#F475A4",
        fontSize: "14px",
        textAlign: "center",
        textDecoration: "none",
      },
      "& tbody tr td:first-child": {
        width: "85px",
        "& div": {
          width: "85px",
          "& img": {
            width: "100%",
            height: "75px",
            borderRadius: "16px 0 0 16px",
            objectFit: "cover",
          },
        },
      },
      "& tbody tr td:last-child": {
        "& span": {
          borderRadius: "0px 16px 16px 0",
        },
      },
      "& td": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& > span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          color: theme.palette.gray.main700,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
        },
      },
      "& th": {
        paddingLeft: 0,
        paddingRight: 0,
        borderBottom: "none",
        backgroundColor: "transparent",
        "& span": {
          position: "relative",
          minHeight: "75px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "start",
          backgroundColor: "#fff",
          color: theme.palette.black,
          fontFamily: "Montserrat",
          fontSize: " 14px",
          fontStyle: "normal",
          fontWeight: "400",
          lineHeight: " 13px",
          gap: "6px",
          padding: "0 20px",
          boxShadow: "0px 8px 6px -3px #00000008",
          zIndex: "2",
          "& span": {
            minHeight: "auto",
            flexDirection: "row",
            alignItems: "center",
            gap: "2px",
            fontSize: "12px",
            color: theme.palette.gray.main700,
            padding: 0,
            "& svg": {
              fill: theme.palette.yellow.main,
            },
          },
        },
      },
    },
  },
  "& .no-data-text" : {
    fontSize: "18px",
    color: "#f00",
    fontWeight: "600",

  },
  // changes
  "& .pt-lg":{
    backgroundColor:"white",
    // width:"40vw",
    margin:"auto",
    // position:"relative",
    // top:"-25px",
    // left:"12px"
   
  },
  
  "& .notification-msg-topbar":{
   height:"60px",
   width:"100%",
   backgroundColor:"white",
   boxShadow: "0px 4px 20px 2px #00000008"
   
   
  },
  "& .feature-card": {
    // position:"relative",
    // left:"-10px",
    // top:"10px",
      backgroundColor: theme.palette.white.main,
      borderRadius: "16px",
      // boxShadow: "0px 4px 20px 2px #00000008",
      padding: "16px 20px",
      "& .notification-img": {
          width: "36px",
          height: "36px",
          borderRadius: "50%",
          objectFit: "cover",
      },
      "& .noti-text": {       
        fontSize: "16px",
        fontWeight: "600",
      },
      "& .noti-content": {       
        fontSize: "14px",
        fontWeight: "400",
      },
      "& .noti-time": {       
        fontSize: "14px",
        fontWeight: "500",
        color:"#A8A8A8!important",
      },
      "& .noti-btn": {       
        fontWeight: "500",
        backgroundColor: "#0996D2",
        border: "1px solid #0996D2",
        display: "inline-block",
        width: "135px",
        padding: "6px 10px",
      },
      
  },
  "& .notification-grid": {
   boxShadow:"none"   
  },
  "& .notification-grid-under": {
    border:"none",
    display:"flex",
    listStyle:'none' ,
    justifyContent:"center" ,
    gap:"32px",
    marginTop:"12px",
    fontSize:"1rem"
   },
   "& .notification-grid-under-1": {
   marginTop:"12px",
   border:'none'
   },
   "& .d-lg-flex": {
    marginTop:"12px",
    borderBottom:"0.4px solid #c9c2c2"
    },
    "& .notification-tabs": {
      paddingLeft:"60px",
      marginTop:"5px",
     
      },
      "& .notification-tabs a": {
        color:"gray",
       BorderBottom:"red"
        },
  
 
  "& .custom-pagination": {
    "& li": {
      "& button": {
        width: "32px",
        height: "32px",
        borderRadius: "4px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: theme.palette.white.main,
        boxShadow: "0px 10px 10px 0px #0000000D",
        border: "1px solid",
        borderColor: theme.palette.white.main,
        fontSize: "11px",
        margin: "0 4px",
        color: theme.palette.black.main,
        "&.MuiPaginationItem-page.Mui-selected": {
          backgroundColor: theme.palette.white.main,
          borderColor: theme.palette.pink.main,
        },
        "&.MuiPaginationItem-previousNext": {
          backgroundColor: theme.palette.pink.main,
          color: theme.palette.white.main,
          borderColor: theme.palette.pink.main,
        },
      },
      "&:first-child": {
        "&.MuiPaginationItem-previousNext": {
          marginRight: "19px",
        },
      },
      "&:last-child": {
        "&.MuiPaginationItem-previousNext": {
          marginLeft: "19px",
        },
      },
    },
  },
  "& .page-text": {
    fontSize: "16px",
    fontFamily: "Montserrat",
    color: theme.palette.black.main,
    opacity: 0.63,
  },
}))
const Flexbox = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  flexWrap: "wrap",
  // "& .MuiTypography-body2": {
  //   color: theme.palette.grey.main200,
  //   fontFamily: "Nunito Sans",
  //   fontSize: " 14px",
  //   fontStyle: "normal",
  //   fontWeight: "400",
  //   lineHeight: " 18px",
  // },
  "& .MuiTypography-body1": {
    color: theme.palette.pink.main,
    fontFamily: "Montserrat",
    fontSize: " 24px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: " 18px",
  },
  "& .Status.MuiBox-root": {
    background: theme.palette.white.main,
    boxShadow: "0px 4px 20px 2px rgba(0, 0, 0, 0.03)",
    padding: "20px",
    borderRadius: "16px",
    width: "110px",
  },
}))

export { Upcoming, Overview, Flexbox }
