import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { RootState } from "../../app/store"
import { useLocation } from "react-router-dom"
import { BaseQueryError } from "@reduxjs/toolkit/dist/query/baseQueryTypes"

const BASE_URL = import.meta.env.VITE_REACT_APP_API_URL

export const api = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: `${BASE_URL}/api/provider/`,
    prepareHeaders: (headers, { getState }) => {
      const token = (getState() as RootState).auth.token
      if (token) {
        headers.set("authorization", `Bearer ${token}`)
      }
      return headers
    },
  }),

  tagTypes: [
    "reviews",
    `profileAccount`,
    "updateAccount",
    "verifyOtp",
    "appliedjobs",
    "updateProfileGet",
    "notifications",
    "activejobs",
    "countNotification",
  ],
  endpoints: (builder) => ({
    login: builder.mutation<any, any>({
      query: (credentials) => ({
        url: "login",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags: ["updateProfileGet", "notifications"],
    }),

    // emailVerify: builder.mutation<any, any>({
    //   query: (credentials) => ({
    //     url: "verify-email",
    //     method: "GET",
    //   }),
    // }),

    userRegister: builder.mutation<any, any>({
      query: (userRegisterData) => ({
        url: "register",
        method: "POST",
        body: userRegisterData,
      }),
    }),

    LogoutProvider: builder.mutation<any, any>({
      query: (data) => ({
        url: "logout",
        method: "POST",
        body: data,
      }),
    }),

    forgetPassword: builder.mutation<any, any>({
      query: (forgetRequestData) => ({
        url: "forgot-password",
        // mode: "cors",
        method: "POST",
        body: forgetRequestData,
      }),
    }),

    resetPassword: builder.mutation<any, any>({
      query: (resetRequestData) => ({
        url: "reset-password",
        // mode: "cors",
        method: "POST",
        body: resetRequestData,
      }),
    }),

    changePassword: builder.mutation<any, any>({
      query: (resetData) => ({
        url: "reset-password",
        // mode: "cors",
        method: "POST",
        body: resetData,
      }),
    }),

    resendVerifyEmail: builder.mutation<any, any>({
      query: (resendVerify) => ({
        url: "resend-verification-mail",
        // mode: "cors",
        method: "POST",
        body: resendVerify,
      }),
    }),

    // over view count status

    providersStatus: builder.query<any, any>({
      query: () => ({
        url: "get-post-status-count",
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: ["verifyOtp", "notifications"],
    }),

    jobHistory: builder.query<any, any>({
      query: (data) => ({
        url: `get-job-history?pageNo=${data?.pageNo || ""}&postStatus=${
          data?.postStatus || ""
        }&searchName=${data?.searchName || ""}&createdAt=${
          data?.createdAt || ""
        }&pageLimit=${data?.pageLimit || ""}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    approvedJobs: builder.query<any, any>({
      query: (data) => ({
        url: `get-approved-posts?postId=${data?.postId || ""}&pageNo=${
          data?.pageNo || ""
        }`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    appliedJobs: builder.query<any, any>({
      query: (data) => ({
        url: `get-applied-posts?pageNo=${data?.pageNo}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: ["appliedjobs", "notifications", "updateProfileGet"],
    }),

    accountProfile: builder.query<any, any>({
      query: () => ({
        url: `get-profile`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: ["updateAccount", "updateProfileGet"],
    }),

    getSlots: builder.query<any, any>({
      query: (data) => ({
        url: `get-booked-slots?dateAndTime=${data?.dateAndTime || ""}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getAvailablePost: builder.query<any, any>({
      query: (data) => ({
        url: `get-available-posts?serviceId=${data?.serviceId || ""}&pageNo=${
          data?.pageNo || ""
        }&pageLimit=${data?.pageLimit || ""}&createdAt=${
          data?.createdAt || ""
        }`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: ["updateAccount", "appliedjobs", "notifications"],
    }),

    seekerReviews: builder.query<any, any>({
      query: () => ({
        url: `get-seekers-review`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    seekerReceivedReviewas: builder.query<any, any>({
      query: (data) => ({
        url: `get-received-review?pageNo=${data?.pageNo || ""}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    providerReviews: builder.query<any, any>({
      query: () => ({
        url: `get-provider-review`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: ["reviews"],
    }),

    seekerProfile: builder.query<any, any>({
      query: (data) => ({
        url: `get-seeker-profile-and-reviews?seekerId=${data?.seekerId}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getServiceProvier: builder.query<any, any>({
      query: (data) => ({
        url: `get-job-services`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),

    getCountrys: builder.query<any, any>({
      query: (data) => ({
        url: `get-country-info`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
    }),
    providerJobPost: builder.query<any, void>({
      query: (args: void) => ({
        url: `get-active-post`,
        method: "GET",
      }),
      providesTags: [
        "verifyOtp",
        "activejobs",
        "notifications",
        "updateProfileGet",
      ],
    }),

    timeLineProvider: builder.query<any, any>({
      query: (data) => ({
        url: `get-job-timeline?postId=${data?.postId}`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: ["verifyOtp", "notifications"],
    }),
    createReviewPost: builder.mutation<any, any>({
      query: (payload) => ({
        url: "create-update-seekers-review",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["reviews", "updateProfileGet"],
    }),

    appliedPost: builder.mutation<any, any>({
      query: (payload) => ({
        url: "apply-on-post",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: [
        "reviews",
        "appliedjobs",
        "updateProfileGet",
        "notifications",
      ],
    }),

    accountUpdate: builder.mutation<any, any>({
      query: (payload) => ({
        url: `update-provider-account`,
        // mode: "cors",
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["updateAccount"],
    }),
    providerDetails: builder.mutation<any, any>({
      query: (data) => ({
        url: `update-profile`,
        method: "POST",
        body: data,
      }),
    }),
    verifyOtp: builder.mutation<any, any>({
      query: (data) => ({
        url: `verify-otp`,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["verifyOtp", "notifications"],
    }),

    genrateOtp: builder.mutation<any, any>({
      query: (data) => ({
        url: `gen-otp`,
        method: "POST",
        body: data,
      }),
    }),

    // verifyOtp: builder.mutation<any, any>({
    //   query: (data) => ({
    //     url: `verify-otp`,
    //     method: "POST",
    //     body: data,
    //   }),
    //   invalidatesTags: ["verifyOtp"],
    // }),

    chanegPasswords: builder.mutation<any, any>({
      query: (data) => ({
        url: `change-password`,
        method: "POST",
        body: data,
      }),
    }),

    getNotification: builder.query<any, any>({
      query: (data) => ({
        url: `/get-notifications?pageNo=${data?.pageNo || ""}&pageLimit=${
          data?.pageLimit || ""
        }&isSeenKey=${data?.isSeenKey || ""}`,
        method: "GET",
      }),
      transformResponse: (response: any) => {
        if (response.data) return response.data
      },
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result?.map(({ _id }: any) => ({
                type: "notification" as const,
                _id,
              })),
              "notifications",
            ]
          : [{ type: "notifications", result }],
    }),

    getCountsAll: builder.query<any, any>({
      query: (data) => ({
        url: `/BookingCount`,
        method: "GET",
        onSuccess: (data: any) => {
          return data
        },
      }),
      providesTags: [
        "notifications",
        "appliedjobs",
        "updateAccount",
        "countNotification",
      ],
    }),

    protected: builder.mutation<{ message: string }, void>({
      query: () => "protected",
    }),
  }),
})

export const {
  useLoginMutation,
  useProtectedMutation,
  // useEmailVerifyMutation,
  useLogoutProviderMutation,
  useForgetPasswordMutation,
  useUserRegisterMutation,
  useResetPasswordMutation,
  useChangePasswordMutation,
  useResendVerifyEmailMutation,
  useProvidersStatusQuery,
  useJobHistoryQuery,
  useApprovedJobsQuery,
  useAppliedJobsQuery,
  useAccountProfileQuery,
  useGetSlotsQuery,
  useGetAvailablePostQuery,
  useSeekerReviewsQuery,
  useSeekerReceivedReviewasQuery,
  useProviderReviewsQuery,
  useSeekerProfileQuery,
  useGetServiceProvierQuery,
  useGetCountrysQuery,
  useProviderJobPostQuery,
  useTimeLineProviderQuery,
  useCreateReviewPostMutation,
  useAppliedPostMutation,
  useAccountUpdateMutation,
  useProviderDetailsMutation,
  useVerifyOtpMutation,
  useGenrateOtpMutation,
  useChanegPasswordsMutation,
  useGetNotificationQuery,
  useGetCountsAllQuery,
} = api
