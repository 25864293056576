import React, { useEffect, useState } from "react"
import { Box, Button, Grid, Typography } from "@mui/material"
import { IoCameraOutline } from "react-icons/io5"
import AddCirclePlus from "../../images/add-circle-plus.svg"
import "./multipleImage.css"
import { useLocation } from "react-router-dom"
interface PreviewImage {
  url: string
  file: File
}

interface Props {
  base64ToFile: (
    base64Data: string | ArrayBuffer | null,
    filename: string,
    mimeType: string,
  ) => void
  uploadImageArray: (images: any[]) => void
  handleFile: () => void
  setImageIndexCheck: (value: number) => void
  reapeatImage: []
}

const FileUpload = ({
  base64ToFile,
  uploadImageArray,
  handleFile,
  setImageIndexCheck,
  reapeatImage,
}: Props) => {
  const [previewImages, setPreviewImages] = useState<PreviewImage[]>([])
  const [crosIcon, setCrosIcon] = useState<number>()

  const loactionSearch = useLocation()

  useEffect(() => {
    // Call uploadImageArray whenever previewImages changes
    uploadImageArray(previewImages)
  }, [previewImages, uploadImageArray])

  const onChange = (e: any, index: number) => {
    setCrosIcon(index)
    const file = e.target.files[0]

    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        const convertedFile = base64ToFile(
          reader.result,
          file.name,
          file.type,
        ) as any
        if (index < previewImages?.length) {
          // If the index already exists, replace the existing image
          const newPreviewImages = [...previewImages]
          newPreviewImages[index] = convertedFile as any
          setPreviewImages(newPreviewImages)
        } else {
          // If the index is new, add the new image
          if (index < 3) {
            // Only allow uploading up to 3 images
            setPreviewImages((prevImages) => [...prevImages, convertedFile])
          }
        }
      }
      reader.readAsDataURL(file)
    }
  }

  const deleteImage = (index: number) => {
    const newPreviewImages = [...previewImages]
    newPreviewImages.splice(index, 1)
    setPreviewImages(newPreviewImages)
    if (index == 0) {
      setPreviewImages([])
      setTimeout(() => {
        handleFile()
      }, 200)
    }
  }

  useEffect(() => {
    if (loactionSearch?.pathname !== "/dashboard/create-booking") {
      setImageIndexCheck(previewImages as any)
    }
  }, [previewImages])

  useEffect(() => {
    setPreviewImages((reapeatImage as any) || [])
  }, [reapeatImage])

  return (
    <Box>
      <Grid container className="mb-1">
        {/* {crosIcon == undefined && (
          <span className="img-delete-btn" onClick={handleFile}>
            X
          </span>
        )} */}

        <Grid item xs={12} className="mb-2 profile-text">
          {loactionSearch?.pathname !== "/dashboard/help-center" && (
            <Typography variant="body1">Profile Photos</Typography>
          )}
        </Grid>
        {previewImages?.map((_, index) => (
          <Grid item key={index}>
            <Box className="d-inline-block position-relative mb-3 me-4">
              <img
                src={
                  previewImages[index]
                    ? URL.createObjectURL(previewImages[index] as any)
                    : ""
                }
                alt={`Preview ${index}`}
                className="edit-profile"
              />
              {index > -1 && (
                <span
                  className="img-delete-btn"
                  onClick={() => deleteImage(index)}
                >
                  X
                </span>
              )}
            </Box>
          </Grid>
        ))}
        {previewImages?.length < 3 && ( // Check if there is space for more images
          <Grid item>
            <Box className="d-inline-block position-relative">
              <input
                type="file"
                id="upload-profile-pic"
                className="d-none "
                accept="image/jpeg, image/jpg, image/png, image/x-png, image/bmp"
                onChange={(e) => onChange(e, previewImages?.length)} // Pass the next available index
              />
              <label
                htmlFor="upload-profile-pic"
                className="upload-label photo-upload-field px-3 position-relative"
              >
                {/* <IoCameraOutline /> */}
                <img src={AddCirclePlus} />
              </label>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}

export default FileUpload
