import React, { Fragment, useEffect, useState } from "react"
import {
  Paper,
  Typography,
  Grid,
  Button,
  Box,
  CircularProgress,
} from "@mui/material"
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { useGetSlotsQuery } from "../../features/Auth/authApi"
import moment from "moment"
import * as Style from "./style"
import { Spinner } from "react-bootstrap"
import CenteredSpinner from "../common/CenteredSpinner"
import { FormatDate, FormatSlot, FormatTime } from "../common/utctolocalDate"
import SlotModal from "../common/Modals/SlotModal"

interface Slot {
  time: string
}

const BookingSlots: React.FC = () => {
  const [dateHandle, setDateHandle] = useState<string>("")
  const [detailsHandle, setDetailsHandle] = useState<string>("")
  const [showSlot, setShowSlot] = useState<boolean>(false)
  const { data, error, isLoading, refetch, isFetching } = useGetSlotsQuery({
    dateAndTime: dateHandle || moment().format("YYYY-MM-DD"),
  })

  const handleDateSelect = (selectedDate: any) => {
    if (selectedDate) {
      const dateString = moment(selectedDate.$d).format("YYYY-MM-DD")

      setDateHandle(dateString as any)
    } else {
      console.log("sadsadsafd")
    }
  }

  const handleClose = () => {
    setShowSlot(false)
  }

  const handleDeatilsBook = (detials: any) => {
    setDetailsHandle(detials)
    setShowSlot(true)
  }

  return (
    <Style.Overview>
      <Box>
        <Typography align="left" variant="h2" mt={2} mb={3}>
          Booking Slots
        </Typography>
      </Box>
      <Grid container spacing={2} className="">
        <Grid item xs={12} lg={4} className="p-0 d-flex justify-content-center">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              className="custom-calendar "
              onChange={handleDateSelect}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} lg={8} className="bg-white rounded p-4">
          <Grid container spacing={2}>
            {isLoading ? (
              <CenteredSpinner />
            ) : (
              <>
                <Grid item xs={12} className="pt-0">
                  <Typography variant="h2" gutterBottom>
                    {dateHandle ? (
                      <FormatSlot dateString={dateHandle} />
                    ) : (
                      <FormatSlot dateString={moment().format("YYYY-MM-DD")} />
                    )}
                  </Typography>
                  <Typography variant="body1" gutterBottom className="day-text">
                    {dateHandle ? (
                      <FormatDate dateString={dateHandle} />
                    ) : (
                      <FormatDate dateString={moment().format("YYYY-MM-DD")} />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} className="pt-0">
                  <Typography className="slot-booking-text-sm" variant="h6">
                    Booked slots
                  </Typography>
                  <Grid container spacing={1}>
                    {data?.bookedSlots?.length > 0 ? (
                      data?.bookedSlots?.map((item: any, index: number) => (
                        <Fragment key={index}>
                          <Grid
                            item
                            key={index}
                            // onClick={() => setShowSlot(true)}
                            onClick={() => handleDeatilsBook(item)}
                            sx={{ cursor: "pointer" }}
                          >
                            <Button
                              variant="contained"
                              sx={{ margin: 0.5 }}
                              className="slots-tag-2 pe-none"
                            >
                              <>
                                {moment(item?.from).format("h:mm A")} -{" "}
                                {moment(item?.till).format("h:mm A")}
                              </>
                            </Button>
                          </Grid>
                        </Fragment>
                      ))
                    ) : (
                      <Grid className="pt-2 ps-2">
                        <Button
                          variant="contained"
                          sx={{
                            margin: 0.5,
                            backgroundColor: "red",
                            color: "white",
                          }}
                          className="slots-tag-2 pe-none "
                        >
                          No Booked Slots
                        </Button>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12} pt={0}>
              <Typography className="slot-booking-text-sm" variant="h6">
                Available slots
              </Typography>
              <Grid container spacing={1}>
                {data?.availableData?.map((item: any, index: number) => (
                  <Grid item key={index}>
                    <Button
                      variant="outlined"
                      sx={{ margin: 0.5 }}
                      className="slots-tag"
                    >
                      {moment(item?.availableSlotStart).format("h:mm A")} -{" "}
                      {moment(item?.availableSlotEnd).format("h:mm A")}
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <SlotModal
        showModalSlots={showSlot}
        handleClose={handleClose}
        slotdetails={detailsHandle || []}
        dateHandle={dateHandle}
      />
    </Style.Overview>
  )
}

export default BookingSlots
