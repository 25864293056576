import React from "react"
import { Box, Button, Grid, TextField, Typography } from "@mui/material"
import { IoStar } from "react-icons/io5"
import * as Style from "./style"
import moment from "moment"
import badReview from "../../images/bad.png"
import okayReview from "../../images/okay.png"
import greatReview from "../../images/great.png"
import awesomeReview from "../../images/awesome.png"

const SeekerProvider = () => {
  return (
    <>
      <Style.Overview>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box mb={5}>
              <Typography variant="body1" className="pink-text">
                Rate Us
              </Typography>
            </Box>
            <Box className="review-card overflow-auto p-4 mb-3 custom-scrollbar">
              <Typography variant="body1" className="mb-5">
                What do you think of our services
              </Typography>
              <Grid container spacing={2} justifyContent="center" className="mb-5">
                <Grid item xs={6} sm={3}>
                  <Box textAlign="center">
                    <input
                      type="radio"
                      name="review-radio"
                      className="reviewradio"
                      id="awesome-radio"
                    />
                    <label className="reviewlabel" htmlFor="awesome-radio">
                      <img src={awesomeReview} className="feedback-img" />
                      <Typography className="label-text">
                        Outstanding
                      </Typography>
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box textAlign="center">
                    <input
                      type="radio"
                      name="review-radio"
                      className="reviewradio"
                      id="great-radio"
                    />
                    <label className="reviewlabel" htmlFor="great-radio">
                      <img src={greatReview} className="feedback-img" />
                      <Typography className="label-text">Amazing</Typography>
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box textAlign="center">
                    <input
                      type="radio"
                      name="review-radio"
                      className="reviewradio"
                      id="okay-radio"
                    />
                    <label className="reviewlabel" htmlFor="okay-radio">
                      <img src={okayReview} className="feedback-img" />
                      <Typography className="label-text">Good</Typography>
                    </label>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box textAlign="center">
                    <input
                      type="radio"
                      name="review-radio"
                      className="reviewradio"
                      id="bad-radio"
                    />
                    <label className="reviewlabel" htmlFor="bad-radio">
                      <img src={badReview} className="feedback-img" />
                      <Typography className="label-text">Average</Typography>
                    </label>
                  </Box>
                </Grid>
              </Grid>
              <TextField
                id="outlined-textarea"
                label="Add Your Rating Message"
                placeholder="Enter your rating message here..."
                multiline
                rows="5"
                fullWidth
                className="review-field mb-4"
              />
              <Button className="view-profile">Submit</Button>
            </Box>
          </Grid>
        </Grid>
      </Style.Overview>
    </>
  )
}
export default SeekerProvider
