import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { overViewApi } from "./overViewApi"

const initialState = {
  name: "",
  email: "",
  password: "",
  confirmedPassword: "",
  region: "",
  newMessage: "",
}

const slice = createSlice({
  name: "overViewAuth",
  initialState: { user: null, token: null } as {
    user: null | any
    token: null | string
    newMessage: null | any
  },
  reducers: {
    token: (state: any, action: any) => {
      state.user = null
      state.token = action.payload
    },
    setnewMessage: (state: any, action: any) => {
      state.newMessage = null
      state.newMessage = action.payload
    },
  },
  extraReducers: (builder) => {
    // builder.addMatcher(
    //   api.endpoints.login.matchFulfilled,
    //   (state, { payload: {user} }: PayloadAction<{user:User}>) => {
    //     console.log("this is the pauyload", user);
    //     state.user = {...state.user, ...user};
    // },
    // ),
  },
})

export default slice.reducer
export const { token, setnewMessage } = slice.actions

export const selectCurrentUser = (state: RootState) => state.overViewAuth
