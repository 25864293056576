// import createSlice and createSelector from Redux Toolkit
import { createSlice, createSelector } from "@reduxjs/toolkit"
import { api } from "./authApi"

const initialState = {
  stringValue: {
    name: "",
    email: "",
    contactNo: "",
    gender: "",
    dob: "",
    region: "",
    address: "",
  },
  stepsCount: "",
  languageAll: "",
  resendButton: "",
}

const stringSlice = createSlice({
  name: "wrapper",
  initialState,
  reducers: {
    setString: (state, action) => {
      state.stringValue = action.payload
    },
    setStepsCount: (state, action) => {
      state.stepsCount = action.payload
    },
    setLanguage: (state, action) => {
      state.languageAll = action.payload
    },
    setResendButton: (state, action) => {
      state.resendButton = action.payload
    },
  },

  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.LogoutProvider.matchFulfilled,
      (state, { payload }) => {
        state.resendButton = ""
      },
    )
  },
})

// Export action creator
export const { setString, setStepsCount, setLanguage, setResendButton } =
  stringSlice.actions

// Export reducer
export default stringSlice.reducer

// Create a selector to retrieve the string value from the state
export const selectStringValue = (state: any) => state.wrapper.stringValue
