import * as React from 'react';
import { BsFillPatchQuestionFill, BsTicket } from "react-icons/bs";
import { HiOutlineReceiptRefund } from "react-icons/hi";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import * as Style from "./style";
import { Grid, Typography, Avatar,Box } from "@mui/material";
import { MdCall, MdOutlineDeveloperMode } from "react-icons/md";
import { LiaMoneyBillWaveAltSolid } from "react-icons/lia";

const ServiceSupport = () => {
  return (
    <>
      <Style.ServiceSupportBox>
        <Typography fontFamily="poppins" display="flex" fontSize="28px" mb={2}>How can we help you?</Typography>
        <Grid display="flex" gap={2} mb={2}>
          <Grid className="BoxQuery">
            <Typography><BsTicket style={{    fontSize: '21px',
    verticalAlign:' middle'}} /> Raise a Ticket</Typography>
          </Grid>
          <Grid className="BoxQuery">
            <Typography><HiOutlineReceiptRefund style={{    fontSize: '21px',
    verticalAlign:' middle'}} /> Request Refund</Typography>
          </Grid>

          <Grid className="BoxQuery">
            <Typography><MdOutlineDeveloperMode style={{    fontSize: '21px',
    verticalAlign:' middle'}} /> User Guide</Typography>
          </Grid>
        </Grid>
        <Grid display="flex" gap={2}>
          <Grid className="BoxQuery">
            <Typography><BsFillPatchQuestionFill style={{    fontSize: '21px',
    verticalAlign:' middle'}} /> FAQs</Typography>
          </Grid>
          <Grid className="BoxQuery">
            <Typography><MdCall style={{    fontSize: '21px',
    verticalAlign:' middle'}} /> Contact Us</Typography>
          </Grid>

          <Grid className="BoxQuery">
            <Typography><LiaMoneyBillWaveAltSolid style={{    fontSize: '21px',
    verticalAlign:' middle'}} /> Billing</Typography>
          </Grid>
        </Grid>
        <Box>
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Brunch this weekend?"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Ali Connors
              </Typography>
              {" — I'll be in your neighborhood doing errands this…"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Summer BBQ"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                to Scott, Alex, Jennifer
              </Typography>
              {" — Wish I could come, but I'm out of town this…"}
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Cindy Baker" src="/static/images/avatar/3.jpg" />
        </ListItemAvatar>
        <ListItemText
          primary="Oui Oui"
          secondary={
            <React.Fragment>
              <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
              >
                Sandra Adams
              </Typography>
              {' — Do you have Paris recommendations? Have you ever…'}
            </React.Fragment>
          }
        />
      </ListItem>
    </List>
        </Box>
      </Style.ServiceSupportBox>
    </>
  );
};
export default ServiceSupport;
