import * as React from "react"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import { Avatar } from "@mui/material"
import { InputLabel } from "@mui/material"
// import bellIcon from "../../../images/notification.svg"
import * as Style from "./style.css"
import classes from "./style.module.css"
import { Grid, Typography } from "@mui/material"
import { Link } from "react-router-dom"
import { boxStyle } from "./notificationStyle"
import { flexboxStyle } from "./notificationStyle"
import logoIcon from "../../../images/notification.svg"
import { BorderBottom } from "@mui/icons-material"
import {
  useGetCountsAllQuery,
  useGetNotificationQuery,
} from "../../../features/Auth/authApi"
import { CalculateTimeDifference } from "../../Notification/timeNotification"
import { useAppSelector } from "../../../app/hooks"
import { useStore } from "react-redux"
import bellIconNoti from "../../../images/notificationicon.svg"
import notiLogo from "../../../images/momy_logo.svg"
import NotificationModal from "./notificationModal"

export default function NotificationChat() {
  const store: any = useStore()
  const cachedQueries = store.getState().notificationsApi.queries
  // const getNotifications :any = useAppSelector((state)=> state.notificationsApi.queries)
  let dashboardResult: { [key: string]: any } = {}
  Object.values(cachedQueries).forEach((item: any) => {
    dashboardResult = {
      ...dashboardResult,
      ...{ [item?.endpointName]: item?.data },
    }
  })

  const { data: countSeenData } = useGetCountsAllQuery({})

  const [open, setOpen] = React.useState(false)

  // const notificationData = useAppSelector(
  //   (state) => state.auth.bellNotification,
  // )

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (
    event: React.SyntheticEvent<unknown>,
    reason?: string,
  ) => {
    if (reason !== "backdropClick") {
      setOpen(false)
    }
  }

  const paperStyle = {
    position: "absolute",
    right: "50px",
    borderRadius: "16px",
    top: "45px",
    backgroundColor: "white",
    boxShadow: "0px 4px 25px 0px rgba(0, 0, 0, 0.03)",
    "@media (max-width: 600px)": {
      right: "-14px",
      top: "15px",
    },
  }

  return (
    <>
      <>
        <Box>
          <Button onClick={handleClickOpen} className={classes.btn_cover}>
            <Avatar
              src={bellIconNoti}
              className={classes.logoIcon}
              alt="Profile Picture"
            />

            {countSeenData?.unSeen !== 0 &&
              countSeenData?.unSeen !== undefined && (
                <span className={classes.count_circle}>
                  {countSeenData?.unSeen}
                </span>
              )}
          </Button>

          <Dialog
            PaperProps={{
              sx: paperStyle,
            }}
            disableEscapeKeyDown
            open={open}
            onClose={handleClose}
            onBackdropClick={handleClose}
          >
            <Box sx={boxStyle} className="custom-scrollbar">
              <Box sx={flexboxStyle}>
                <InputLabel
                  style={{
                    fontSize: "14px",
                    color: "#f475a4",
                    paddingLeft: "10px",
                  }}
                >
                  Notifications
                </InputLabel>

                <InputLabel style={{ fontSize: "14px" }}>
                  <Link
                    style={{ textDecoration: "none", color: "#25324B" }}
                    onClick={handleClose}
                    to="/dashboard/notification"
                  >
                    See all
                  </Link>
                </InputLabel>
              </Box>
            </Box>
          </Dialog>
          {open && (
            <NotificationModal
              open={open}
              handleClose={handleClose as any}
              setOpen={setOpen}
            />
          )}
        </Box>
      </>
    </>
  )
}
