import React from "react"
import * as Style from "./style"
import { FaChevronLeft } from "react-icons/fa6"
import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Divider, FormControl, Grid, InputLabel, Modal, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { Link, useNavigate } from "react-router-dom"
import approvedImg from '../../images/babysitting.png'
import { CiMail } from "react-icons/ci";
import { IoCallOutline } from "react-icons/io5";
import { IoLocationOutline } from "react-icons/io5";
import { CgSandClock } from "react-icons/cg";
import { IoCalendarClearOutline } from "react-icons/io5";
import { CiClock2 } from "react-icons/ci";
import { FaStar } from "react-icons/fa";
import OutlinedInput from '@mui/material/OutlinedInput';
import providerimage from "../../images/cooking.png"
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs, { Dayjs } from 'dayjs';
import tableCellImg from "../../images/table-img.png"
const ServiceDetail = () => {
  const [value, setValue] = React.useState<Dayjs | null>(dayjs('2022-04-17T15:30'));
  const rows2 = [
    createData(tableCellImg, 'Supriya Jain', 'hi@emiliaantoine.com', 'Cooking', 'Ipsum dolor sit amet consectetur. Augue orci eleifend etiam laoreet.', '11 Nov, Mon 9:00AM - 10:00PM', "4.5"),
  ];
  function createData(
    userimage: string,
    serviceseeker: string,
    email: string,
    phone: string,
    location: string,
    datetime: string,
    rating: string,
  ) {
    return {
      userimage,
      serviceseeker,
      phone,
      location,
      email,
      datetime,
      rating,
    }
  }

  return (
    <>
      <Box className="py-5 px-5" style={{ backgroundColor: "white" }}>
        <Style.Overview>
          <Box className="d-flex justify-content-between align-items-center pb-5">
            <Box className="d-flex align-items-center gap-4">
              <Button
                className="back-btn mb-0"
              >
                <FaChevronLeft />
              </Button>
              <Typography variant="h6">Service details</Typography>
            </Box>
            <Box>
              <Typography variant="h6">Status- <span className="status-update" style={{ color: "#009C35" }}>Completed</span></Typography>
            </Box>
          </Box>
          <Grid container className="mb-5">
            <Grid item lg={6}>
              <Grid container className="mb-4" style={{ alignItems: "center" }}>
                <Grid md={3}>
                  <span className="fw-medium">Service required</span>
                </Grid>
                <Grid md={9}>
                  <FormControl sx={{ width: '25ch' }}>
                    <OutlinedInput placeholder="Please enter text" />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className="mb-4" style={{ alignItems: "center" }}>
                <Grid md={3}>
                  <span className="fw-medium">Age of service<br /> provider</span>
                </Grid>
                <Grid container spacing={2} md={9}>
                  <Grid item>
                    <FormControl sx={{ width: '10ch' }}>
                      <OutlinedInput placeholder="18 min" />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl sx={{ width: '10ch' }}>
                      <OutlinedInput placeholder="45 max" />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container className="mb-4" style={{ alignItems: "center" }}>
                <Grid md={3}>
                  <span className="fw-medium"> Service date</span>
                </Grid>
                <Grid md={7}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker label="Basic date picker" />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid container className="mb-4" style={{ alignItems: "center" }}>
                <Grid md={3}>
                  <span className="fw-medium">Service time</span>
                </Grid>
                <Grid md={6}>
                <Grid container spacing={2} md={12}>
                  <Grid item>
                    <FormControl sx={{ width: '14ch' }}>
                      <label>From</label>
                      <OutlinedInput placeholder="09 : 00 Am" />
                    </FormControl>
                  </Grid>
                  <Grid item>
                    <FormControl sx={{ width: '14ch' }}>
                    <label>To</label>
                      <OutlinedInput placeholder="11 : 00 Am" />
                    </FormControl>
                  </Grid>
                </Grid>
                </Grid>
              </Grid>
              <Grid container className="mb-4" style={{ alignItems: "center" }}>
                <Grid md={3}>
                  <span className="fw-medium">Language of service provider</span>
                </Grid>
                <Grid md={9}>
                  <FormControl sx={{ width: '25ch' }}>
                    <OutlinedInput placeholder="Language" />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container className="mb-4" style={{ alignItems: "center" }}>
                <Grid md={3}>
                  <span className="fw-medium">Number of <br/>service provider</span>
                </Grid>
                <Grid md={9}>
                  <FormControl sx={{ width: '10ch' }}>
                    <OutlinedInput placeholder="1" />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
            <Grid item lg={6}>
              <Box>
                <Typography
                  variant="h2"
                  className="text-black d-flex gap-3"
                  style={{ alignItems: "center" }}
                >
                  <span className="fw-medium"><span className="icon-style" style={{ fontSize: "18px" }}><IoLocationOutline /></span>Location</span>
                </Typography>
                <form>
                  <textarea style={{
                    width: "100%",
                    height: "91px",
                    padding: "12px 20px",
                    boxSizing: "border-box",
                    border: "2px solid #ccc",
                    borderRadius: "4px",

                  }}>Lorem ipsum dolor sit amet consectetur. Etiam id integer iaculis pulvinar sem cursus convallis. Lectus neque nisl eget scelerisque nunc. Et mi arcu.</textarea>
                </form>
              </Box>
              <Box className="mt-3">
                <Typography
                  variant="h2"
                  className="text-black d-flex gap-3"
                  style={{ alignItems: "center" }}
                >
                  <span className="fw-medium">Additional instructions</span>
                </Typography>
                <form>
                  <textarea style={{
                    width: "100%",
                    height: "91px",
                    padding: "12px 20px",
                    boxSizing: "border-box",
                    border: "2px solid #ccc",
                    borderRadius: "4px",

                  }}>Lorem ipsum dolor sit amet consectetur. Etiam id integer iaculis pulvinar sem cursus convallis. Lectus neque nisl eget scelerisque nunc. Et mi arcu.</textarea>
                </form>
              </Box>
              <Box className="mt-3">
                <Typography variant="h6">Photo</Typography>
                <Box className="provider-image">
                  <img src={providerimage} style={{ width: "130px", height: "130px", backgroundSize: "cover", borderRadius: "20px", }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Divider className="dark-divider my-5" />
          <TableBody style={{backgroundColor: "white", boxShadow: "0 4px 4px rgba(0, 0, 0, 0.2)", borderRadius:"10px"}}>
          {rows2.map((row) => (
            <TableRow
              key={row.serviceseeker}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell><div><img src={row.userimage} /></div></TableCell>
              <TableCell component="th" align="center" scope="row">
                <span>
                  {row.serviceseeker}<span>{row.rating}<FaStar /></span></span>
              </TableCell>
              <TableCell align="center"><span>{row.email}</span></TableCell>
              <TableCell align="center"><span>{row.phone}</span></TableCell>
              <TableCell align="center"><span className="location"><span>{row.location}</span></span></TableCell>
              <TableCell align="center"><span className="datetime"><span>{row.datetime}</span></span></TableCell>
              <TableCell align="center"><span><Link to="" className="view-profile">View Profile</Link></span></TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Style.Overview>
      </Box>
    </>
  );
};

export default ServiceDetail;