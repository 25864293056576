import React, { useEffect, useState } from "react"
import dayjs from "dayjs"
import {
  Box,
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Input,
  CircularProgress,
} from "@mui/material"
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"
import { styled } from "@mui/material/styles"
import Switch, { SwitchProps } from "@mui/material/Switch"
import { FaChevronLeft } from "react-icons/fa6"
import notifyIcon from "../../images/notify-icon.svg"
import { FaStar } from "react-icons/fa6"
import * as Style from "./style"
import offerImg from "../../images/cooking.png"
import { IoLocationOutline } from "react-icons/io5"
import { FaChevronRight } from "react-icons/fa6"
import { Link, useNavigate, useParams } from "react-router-dom"
import tableCellImg from "../../images/table-img.png"
import dummy from "../../images/dummy-image.jpg"
import babyImg from "../../images/babysitting.png"
import moment from "moment"
import CircularIndeterminate from "../Loading/Loading"
import RatingStars from "../common/rating"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
} from "../common/utctolocalDate"
import CenteredSpinner from "../common/CenteredSpinner"
import { LuPhone } from "react-icons/lu"
import { CiMail } from "react-icons/ci"
import { toast } from "react-toastify"
import { useAppliedPostMutation } from "../../features/Auth/authApi"
import { useGetspecificPostApiQuery } from "../../features/OverViewAuth/overViewApi"
import { useAppDispatch } from "../../app/store"
import { useAppSelector } from "../../app/hooks"
import { appliedPostKey } from "../../features/Auth/authSlice"
import PreviewImage from "../common/previewImage"
const BookingServiceDetails = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const [showFullTimeline, setShowFullTimeline] = useState(false)
  const [selectedImage, setSelectedImage] = useState<string | null>(null)
  const handleToggleTimeline = () => {
    setShowFullTimeline(!showFullTimeline)
  }

  const [appliedJobs, setAppliedJobs] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false)

  const [cencelJobs, setCencelJobs] = useState<boolean>(false)
  const urlSearchParams = new URLSearchParams(window.location.search)
  const type = urlSearchParams.get("type")

  const dispatch = useAppDispatch()
  const openAppliedButton = useAppSelector(
    (state) => state?.auth.appliedPostKey,
  )
  const { data: appliedJob, isLoading } = useGetspecificPostApiQuery({
    postId: id,
  })

  const [
    appliedPostProvider,
    { data: postData, error, isLoading: postIsloading },
  ] = useAppliedPostMutation()

  const handleClose = () => {
    setOpen(false)
    setCencelJobs(true), setAppliedJobs(false)
  }

  const handleAppliedPost = () => {
    setCencelJobs(false)
    const payload = {
      postId: id,
    }
    appliedPostProvider(payload)
  }

  useEffect(() => {
    if (postData?.message) {
      toast.success(postData?.message, { autoClose: 1000 })
      setAppliedJobs(true)
      dispatch(appliedPostKey("openAplledButton" as any))

      navigate("/dashboard/applied-job")
    } else {
      toast.error((error as any)?.data?.message, { autoClose: 1000 })
    }
  }, [postData, error])
  const handleNavigate = () => {
    if (type == "applied-job") {
      navigate("/dashboard")
    } else {
      navigate("/dashboard/jobs")
    }
  }

  useEffect(() => {
    if (openAppliedButton === "openAplledButton") {
      navigate("/dashboard/applied-job")
    }
  }, [openAppliedButton])

  const handleImageClick = (image: string) => {
    setSelectedImage(image)
  }

  const handleClosePreview = () => {
    setSelectedImage(null)
  }

  return (
    <>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <>
          <Style.Overview>
            <Box
              className="d-flex my-3"
              justifyContent="space-between"
              alignItems="center"
              flexWrap="wrap"
            >
              <Box className="d-flex align-items-center gap-4">
                <Button className="back-btn mb-0" onClick={handleNavigate}>
                  <FaChevronLeft />
                </Button>
                <Typography variant="h2" className=" mb-0">
                  Job details
                </Typography>
              </Box>
              <Box display="flex">
                <Button
                  className="view-profile captalize applied-btn text-captalize me-4"
                  onClick={handleAppliedPost}
                >
                  {postIsloading ? (
                    <CircularProgress style={{ color: "pink" }} size={15} />
                  ) : (
                    "Apply"
                  )}
                </Button>
                {/* <Button className="view-profile captalize">
              See Request <FaChevronRight />
            </Button> */}
              </Box>
            </Box>
          </Style.Overview>
          <Box className="">
            <Style.Overview>
              <Box className="feature-card py-4 px-2">
                <Grid container className="p-4 service-detail-box-lg">
                  <Grid xs={12} md={5} lg={4} xl={3} className="pe-2 pe-xl-4">
                    <Box className=" text-center d-flex flex-column align-items-center h-100">
                      <Box className="mb-2">
                        <img
                          src={
                            appliedJob?.data?.seekerData?.profilePic || dummy
                          }
                          className="profile-img-2"
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        align="center"
                        className="mb-3"
                      >
                        {" "}
                      </Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        className="mb-3 fw-bold d-flex justify-content-center  gap-1 "
                      >
                        <CiMail />{" "}
                        <span style={{ overflowWrap: "anywhere" }}>
                          {appliedJob?.data?.seekerData?.email}
                        </span>
                      </Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        className="mb-3 fw-bold"
                      >
                        <LuPhone /> {appliedJob?.data?.seekerData?.contactNo}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">Service required</span>{" "}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <span> {appliedJob?.data?.service?.name}</span>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">
                              Number of provider required
                            </span>{" "}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <span> {appliedJob?.data?.personRequired}</span>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">Age of provider </span>{" "}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <span> {appliedJob?.data?.ageRange.join("-")}</span>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">
                              {/* <IoCalendarClearOutline /> */}
                              Service Date
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="d-flex gap-4">
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <span>
                                  <FormatDate
                                    dateString={appliedJob?.data?.from}
                                  />
                                </span>
                                <span>
                                  <FormatTime
                                    dateString={appliedJob?.data?.from}
                                  />
                                </span>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">
                              {/* <IoCalendarClearOutline /> */}
                              Service Time
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="">
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  flexDirection: "column",
                                }}
                              >
                                <span className="d-flex ">
                                  <span>
                                    <FormatChangeTime
                                      dateString={appliedJob?.data?.from}
                                    />
                                  </span>{" "}
                                  -
                                  <span>
                                    <FormatChangeTime
                                      dateString={appliedJob?.data?.till}
                                    />
                                  </span>
                                </span>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography
                        variant="body1"
                        mb={1}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            {" "}
                            <span className="fw-bold">Language</span>{" "}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <span>
                              {" "}
                              {appliedJob?.data?.language?.join(" ")}
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box>
                      <Typography variant="body1" mb={3} className="text-black">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            {" "}
                            <span className="fw-bold">
                              {/* <IoLocationOutline /> */}
                              Location
                            </span>{" "}
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <span> {appliedJob?.data?.location}</span>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography variant="body1" mb={3} className="text-black">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            {" "}
                            <span
                              className="fw-bold"
                              style={{ overflowWrap: "anywhere" }}
                            >
                              Additional instructions
                            </span>{" "}
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <span> {appliedJob?.data?.description}</span>
                          </Grid>
                        </Grid>
                      </Typography>
                      {appliedJob?.data?.attachments?.length > 0 && (
                        <Typography
                          variant="body1"
                          mb={1}
                          className="text-black d-flex gap-3"
                        >
                          <span className="fw-bold ">Uploaded Photos</span>
                        </Typography>
                      )}
                      {appliedJob?.data?.attachments?.map((images: any) => (
                        <>
                          <img
                            src={images}
                            style={{
                              height: "48px",
                              width: "48px",
                              borderRadius: "5px",
                              cursor: "pointer",
                            }}
                            onClick={() => handleImageClick(images)}
                          />
                        </>
                      ))}
                    </Box>
                  </Grid>
                  <PreviewImage
                    selectedImage={selectedImage}
                    handleClosePreview={handleClosePreview}
                  />
                </Grid>
                {/* <Box className="notify-wrapper mt-5 d-sm-flex justify-content-between">
                  <Typography className="service-text fw-semibold mb-2">
                    Service provider is not assigned yet
                  </Typography>
                  <Typography className="notify-text">
                    <img src={notifyIcon} /> We will notify you once service
                    provider is assigned to you
                  </Typography>
                </Box>
                <hr className="divided-dots my-5" /> */}
              </Box>
            </Style.Overview>
            {/* ))} */}
          </Box>
        </>
      )}
    </>
  )
}
export default BookingServiceDetails
