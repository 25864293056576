import { Box } from "@mui/material"
import { IoStar, IoStarOutline } from "react-icons/io5"

interface props {
    rating: number
}

const RatingStars = ({ rating }: props) => {
  const starsArray = Array.from({ length: rating }, (_, index) => index + 1)
  let emptyArray: JSX.Element[] = [];

    if (rating < 5) {
      for (let i = 0; i < 5 - rating; i++) {
        emptyArray.push(<IoStarOutline key={rating + i} style={ {color:"rgb(175 174 174)"}} />);
      }
    }
    return (
      <Box className="d-flex review-stars gap-1">
        {starsArray.map((star) => (
          <IoStar key={star} style={{ color: "gold" }} />
        ))}
        {emptyArray}
      </Box>
    )
  }

export default RatingStars
  
