import React, { useEffect, useState } from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Input,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material"
import { FaChevronLeft, FaStar } from "react-icons/fa6"
import * as Style from "./style"
import { Link, useNavigate, useParams } from "react-router-dom"
import tableCellImg from "../../images/table-img.png"
import babyImg from "../../images/babysitting.png"
import { FaChevronRight } from "react-icons/fa6"
import { IoCalendarClearOutline } from "react-icons/io5"
import { CiClock2, CiMail } from "react-icons/ci"
import { IoLocationOutline } from "react-icons/io5"
import {
  useAppliedJobsQuery,
  useApprovedJobsQuery,
} from "../../features/Auth/authApi"
import dummy from "../../images/dummy-image.jpg"
import moment from "moment"
import CenteredSpinner from "../common/CenteredSpinner"
import {
  FormatChangeTime,
  FormatDate,
  FormatTime,
  FormatYear,
} from "../common/utctolocalDate"
import RatingStars from "../common/rating"
import { LuPhone } from "react-icons/lu"

const Appliedviewtarck = () => {
  const { id } = useParams()
  const navigate = useNavigate()

  const [viewApply, setViewApply] = useState<any>([])

  const { data: approvedjobs, isLoading, isFetching } = useAppliedJobsQuery({})

  useEffect(() => {
    const arrAyFilter = approvedjobs?.data?.filter(
      (item: any) => item?._id == id,
    )

    setViewApply(arrAyFilter?.[0])

    if (arrAyFilter && arrAyFilter?.[0]?._id !== id) {
      navigate("/dashboard/applied-job")
    }
  }, [approvedjobs, id])

  const dateFromBackend = (viewApply as any)?.postData?.from
  const dataNew = (viewApply as any)?.postData?.till

  return (
    <>
      {isLoading ? (
        <CenteredSpinner />
      ) : (
        <Box className="pt-md-5 px-md-5">
          <Style.Overview>
            <Box className="feature-card py-4 px-2">
              <Box className="d-flex justify-content-between align-items-center pb-2">
                <Box className="d-flex align-items-center gap-4">
                  <Button
                    className="back-btn mb-0"
                    onClick={() => navigate(-1)}
                  >
                    <FaChevronLeft />
                  </Button>
                </Box>
                <Box>
                  <Typography className="status-text-2 mb-0">
                    Status-{" "}
                    <span className="completed fw-bold">
                      {(viewApply as any)?.postData?.status}
                    </span>
                  </Typography>
                </Box>
              </Box>
              <Box className="">
                <Grid container className="p-4 service-detail-box-lg">
                  <Grid xs={12} md={5} lg={4} xl={3} className="pe-2 pe-xl-4">
                    <Box className=" text-center d-flex flex-column align-items-center h-100">
                      <Box className="mb-2">
                        <img
                          src={
                            (viewApply as any)?.postData?.seekerDetails
                              ?.profilePic || dummy
                          }
                          className="profile-img-2"
                        />
                      </Box>
                      <Typography
                        variant="body1"
                        align="center"
                        className="mb-3 fw-bold"
                      >
                        {" "}
                        {/* {(viewApply as any)?.postData?.seekerDetails?.name}{" "} */}
                      </Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        className="mb-3 fw-bold d-flex justify-content-center  gap-1 "
                      >
                        <CiMail />{" "}
                        <span style={{ overflowWrap: "anywhere" }}>
                          {" "}
                          {(viewApply as any)?.postData?.seekerDetails?.email}
                        </span>
                      </Typography>
                      <Typography
                        variant="body1"
                        align="center"
                        className="mb-3 fw-bold"
                      >
                        <LuPhone />{" "}
                        {(viewApply as any)?.postData?.seekerDetails?.contactNo}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">Service required</span>{" "}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <span>
                              {(viewApply as any)?.postData?.jobDetails?.name}
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">
                              Number of provider required
                            </span>{" "}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <span>
                              {(viewApply as any)?.postData?.personRequired}
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">Age of provider </span>{" "}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <span>
                              {(viewApply as any)?.postData?.ageRange.join("-")}
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">
                              {/* <IoCalendarClearOutline /> */}
                              Service Date
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="d-flex gap-4">
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <span>
                                  <FormatDate
                                    dateString={
                                      (viewApply as any)?.postData?.from
                                    }
                                  />
                                  -
                                </span>
                                <span>
                                  <FormatTime
                                    dateString={
                                      (viewApply as any)?.postData?.from
                                    }
                                  />
                                </span>
                                <span>
                                  <FormatYear
                                    dateString={
                                      (viewApply as any)?.postData?.from
                                    }
                                  />
                                </span>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography
                        variant="body1"
                        mb={3}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            <span className="fw-bold">
                              {/* <IoCalendarClearOutline /> */}
                              Service Time
                            </span>
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <div className="d-flex gap-4">
                              <span
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <span>
                                  <FormatChangeTime
                                    dateString={
                                      (viewApply as any)?.postData?.from
                                    }
                                  />
                                </span>{" "}
                                -
                                <span>
                                  <FormatChangeTime
                                    dateString={
                                      (viewApply as any)?.postData?.till
                                    }
                                  />
                                </span>
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Typography>

                      <Typography
                        variant="body1"
                        mb={1}
                        className="text-black d-flex gap-3"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={6}>
                            {" "}
                            <span className="fw-bold">Language</span>{" "}
                          </Grid>
                          <Grid item xs={12} md={6}>
                            <span>
                              {" "}
                              {(viewApply as any)?.postData?.language?.join(
                                " ",
                              )}{" "}
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={4}>
                    <Box>
                      <Typography variant="body1" mb={3} className="text-black">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            {" "}
                            <span className="fw-bold">Location</span>{" "}
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <span>
                              {(viewApply as any)?.postData?.location}
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                      <Typography variant="body1" mb={3} className="text-black">
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={4}>
                            {" "}
                            <span className="fw-bold">
                              Additional instructions
                            </span>{" "}
                          </Grid>
                          <Grid item xs={12} md={8}>
                            <span>
                              {(viewApply as any)?.postData?.description}
                            </span>
                          </Grid>
                        </Grid>
                      </Typography>
                      {(viewApply as any)?.postData?.attachments?.length >
                        0 && (
                        <Typography
                          variant="body1"
                          mb={1}
                          className="text-black d-flex gap-3"
                        >
                          <span className="fw-bold">Uploaded Photos</span>
                        </Typography>
                      )}
                      {(viewApply as any)?.postData?.attachments?.map(
                        (images: any) => (
                          <img
                            src={images || dummy}
                            style={{
                              height: "48px",
                              width: "48px",
                              marginRight: "5px",
                            }}
                          />
                        ),
                      )}
                    </Box>
                  </Grid>
                </Grid>
                {/* <Box className="notify-wrapper mt-5 d-sm-flex justify-content-between">
                  <Typography className="service-text fw-semibold mb-2">
                    Service provider is not assigned yet
                  </Typography>
                  <Typography className="notify-text">
                    <img src={notifyIcon} /> We will notify you once service
                    provider is assigned to you
                  </Typography>
                </Box>
                <hr className="divided-dots my-5" /> */}
              </Box>
              {/* old code */}
              {/* <Grid container className="mb-5">
                <Grid xs={12} md={5} lg={4} className="pe-2 pe-xl-4">
                  <Box className="review-card text-center d-flex flex-column align-items-center h-100">
                    <Box className="mb-4">
                      <img
                        src={
                          (viewApply as any)?.postData?.seekerDetails
                            ?.profilePic || dummy
                        }
                        className="profile-img-2"
                      />
                    </Box>
                    <Typography
                      variant="body1"
                      align="center"
                      className="mb-3 fw-bold"
                    >
                      {(viewApply as any)?.postData?.seekerDetails?.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      className="mb-3 fw-bold"
                    >
                      <CiMail />{" "}
                      {(viewApply as any)?.postData?.seekerDetails?.email}
                    </Typography>
                    <Typography
                      variant="body1"
                      align="center"
                      className="mb-3 fw-bold"
                    >
                      <LuPhone />{" "}
                      {(viewApply as any)?.postData?.seekerDetails?.contactNo}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box>
                    <Typography
                      variant="body1"
                      mb={5}
                      className=" d-flex gap-3"
                    >
                      <strong>
                        {" "}
                        <span className="text-black">
                          Service required
                        </span>{" "}
                      </strong>
                      -{(viewApply as any)?.postData?.jobDetails?.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      mb={5}
                      className=" d-flex gap-3"
                    >
                      <strong>
                        <span className="text-black">Service date</span>
                      </strong>
                      -
                      {dateFromBackend &&
                        moment(dateFromBackend).format("DD-MM-YYYY")}
                    </Typography>
                    <Typography
                      variant="body1"
                      mb={5}
                      className=" d-flex gap-3"
                    >
                      <strong>
                        <span className="text-black">Service time</span>
                      </strong>{" "}
                      -{" "}
                      {dateFromBackend && (
                        <span className="text-center">
                          {moment(dateFromBackend).format("hh:mm")}
                          <br />
                        </span>
                      )}
                      {dataNew && (
                        <span className="text-center">
                          {moment(dataNew).format("hh:mm")}m <br />
                        </span>
                      )}
                    </Typography>
                    <Typography
                      variant="body1"
                      mb={5}
                      className=" d-flex gap-3"
                    >
                      <strong>
                        {" "}
                        <span className="text-black">
                          Number of provider required
                        </span>
                      </strong>{" "}
                      - {(viewApply as any)?.postData?.personRequired}
                    </Typography>
                    <Typography
                      variant="body1"
                      mb={5}
                      className=" d-flex gap-3"
                    >
                      <strong>
                        {" "}
                        <span className="text-black">Age</span>{" "}
                      </strong>
                      -{(viewApply as any)?.postData?.ageRange?.join()}
                    </Typography>
                    <Typography
                      variant="body1"
                      mb={5}
                      className=" d-flex gap-3"
                    >
                      <strong>
                        {" "}
                        <span className="text-black">
                          Language of service provider
                        </span>
                      </strong>{" "}
                      - {(viewApply as any)?.postData?.language?.join()}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item lg={4}>
                  <Box>
                    <Typography variant="body1" mb={5} className="d-flex">
                      <strong>
                        {" "}
                        <span className="text-black d-inline-flex">
                          Location
                        </span>
                      </strong>
                      <span> - {(viewApply as any)?.postData?.location} </span>
                    </Typography>
                    <Typography variant="body1" mb={5}>
                      <strong>
                        <span className="text-black">
                          Additional instructions
                        </span>
                      </strong>{" "}
                      -{" "}
                      <span>{(viewApply as any)?.postData?.description} </span>
                    </Typography>
                    <Typography variant="body1" mb={5}>
                      <span className="fw-bold text-black">Photo</span>
                    </Typography>
                    {(viewApply as any)?.postData?.attachments?.map(
                      (item: any) => (
                        <img src={item || dummy} className="photo-upload" />
                      ),
                    )}
                  </Box>
                </Grid>
              </Grid> */}
            </Box>
          </Style.Overview>
        </Box>
      )}
    </>
  )
}
export default Appliedviewtarck
